import type {
  FunctionComponent,
  ReactNode,
} from "react"
import { useContext } from "react"
import type { CollectionContext } from "src/types"

export interface Props {
  children?: ReactNode;
}

export function createCollectionLoadedComponent(Context: CollectionContext): FunctionComponent<Props> {
  return function CollectionLoadedComponent(props: Props) {
    const { loaded } = useContext(Context)

    if (!loaded) {
      return null
    }

    return (
      <>
        {props.children}
      </>
    )
  }
}
