export function fileToDataURL(file: File) {
  const promise = new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.addEventListener(
      "load",
      () => {
        resolve(fileReader.result)
      },
      false,
    )

    fileReader.readAsDataURL(file)
  })

  return promise
}
