import { Dialog } from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"

import { NightscoutDialogContext } from "./NightscoutDialogContext"
import { NightscoutForm } from "./NightscoutForm"

interface Props {
  children: ReactNode;
}

export function NightscoutDialog(props: Props) {
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      setIsOpen(newOpen)
    },
    [setIsOpen],
  )

  const openNightscoutDialog = useCallback(
    () => {
      setIsOpen(true)
    },
    [setIsOpen],
  )

  const contextValue = useMemo(
    () => ({
      openNightscoutDialog,
    }),
    [openNightscoutDialog],
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <NightscoutDialogContext.Provider value={contextValue}>
        {props.children}
      </NightscoutDialogContext.Provider>
      <NightscoutForm/>
    </Dialog>
  )
}
