import {
  createContext,
  useContext,
} from "react"
import type { EditableEntryTemplate } from "../EditableEntryTemplate"

export interface DeleteFavoriteDialogContextValue {
  openDeleteFavoriteDialog: (entryTemplate: EditableEntryTemplate) => void;
}

type ContextType = DeleteFavoriteDialogContextValue | null
export const DeleteFavoriteDialogContext = createContext<ContextType>(null)

export function useDeleteFavoriteDialogContext() {
  const context = useContext(DeleteFavoriteDialogContext)

  if (context === null) {
    throw new Error("DeleteFavoriteDialog components must be wrapped in <DeleteFavoriteDialogContext />")
  }

  return context
}

