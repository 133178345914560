import {
  BGReadingFillIcon,
  BGReadingOutlineIcon,
  DeviceChangeIcon,
  ExerciseFillIcon,
  ExerciseOutlineIcon,
  FoodFillIcon,
  FoodOutlineIcon,
  InsulinFillIcon,
  InsulinOutlineIcon,
  LiquidFillIcon,
  LiquidOutlineIcon,
  MedicationFillIcon,
  MedicationOutlineIcon,
  NoteFillIcon,
  NoteOutlineIcon,
  QuestionMarkOutlineIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { ActivityType } from "src/types"

export interface Props extends ComponentPropsWithoutRef<"svg"> {
  activityType: ActivityType;
  fillIcon?: boolean;
}

export const ActivityIcon = forwardRef<SVGElement, Props>(
  function ActivityIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      activityType,
      fillIcon = false,
      ...svgProps
    } = props

    if (fillIcon) {
      switch (activityType) {
        case ActivityType.DeviceChange:
          return (
            <DeviceChangeIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Exercise:
          return (
            <ExerciseFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Food:
          return (
            <FoodFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Glucose:
          return (
            <BGReadingFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Liquid:
          return (
            <LiquidFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Insulin:
          return (
            <InsulinFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Medication:
          return (
            <MedicationFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        case ActivityType.Note:
          return (
            <NoteFillIcon
              {...svgProps}
              ref={ref}
            />
          )
        default:
          return (
            <QuestionMarkOutlineIcon
              {...svgProps}
              ref={ref}
            />
          )
      }
    }

    switch (activityType) {
      case ActivityType.DeviceChange:
        return (
          <DeviceChangeIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Exercise:
        return (
          <ExerciseOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Food:
        return (
          <FoodOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Glucose:
        return (
          <BGReadingOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Liquid:
        return (
          <LiquidOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Insulin:
        return (
          <InsulinOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Medication:
        return (
          <MedicationOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      case ActivityType.Note:
        return (
          <NoteOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
      default:
        return (
          <QuestionMarkOutlineIcon
            {...svgProps}
            ref={ref}
          />
        )
    }
  },
)
