import {
  InfoButton,
  Input,
  Label,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useRef,
} from "react"


import styles from "./AccountMedicationModal.module.scss"
import type { EditableAccountMedication } from "./EditableAccountMedication"
import { usePolyglot } from "src/contexts"

interface Props {
  currentAccountMedication: EditableAccountMedication;
  editAccountMedication: (e: EditableAccountMedication) => void;
}

const id = "medication_units_input"
const polyglotPrefix = "components.account_medication_modal.units."

/*
 * renders a text input for entering medication units
 */
export function MedicationUnitsInput(props: Props) {
  const {
    currentAccountMedication,
    editAccountMedication,
  } = props

  const polyglot = usePolyglot()
  const inputRef = useRef<HTMLInputElement>(null)

  // update the currentAccountMedication's units
  const onUnitsChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const units = e.target.value

      // update units
      editAccountMedication({
        ...currentAccountMedication,
        units,
      })
    },
    [
      currentAccountMedication,
      editAccountMedication,
    ],
  )

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Input
        className={styles.unitsInput}
        placeholder={polyglot.t(`${polyglotPrefix}placeholder`)}
        id={id}
        value={currentAccountMedication.units}
        onChange={onUnitsChange}
        type="text"
        ref={inputRef}
        required
      />
    </div>
  )
}
