import { useMemo } from "react"
import { useAccountProperty } from "src/hooks/me"
import { useFindUserEntries } from "src/hooks/userEntry"
import type { ActivityType } from "src/types"
import { createEntryQueryObject } from "src/utils"

const emptyNotes = [
  null,
  undefined,
  "",
]

/*
 * this returns the most recent notes for the specified activity type
 */
export function usePreviousNotes(
  activityType: ActivityType,
  skipSubentries: string[], // the IDs of subentries to skip, usually this is just the note that we are currently editing
  numberOfNotes = 5,
): string[] {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  // TODO this is not indexed
  const previousNEntriesQuery = useMemo(
    () => createEntryQueryObject(
      accountId,
      {
        selector: {
          subentries: {
            $elemMatch: {
              subentry_type: { $eq: activityType },
              note: {
                $nin: emptyNotes,
              },
            },
          },
        },
        sort: [{ started_at: "desc" as const }],
        limit: skipSubentries.length + numberOfNotes,
      },
    ),
    [
      activityType,
      skipSubentries.length,
      numberOfNotes,
    ],
  )

  const entries = useFindUserEntries(previousNEntriesQuery)

  return useMemo(
    () => {
      const previousNotes: string[] = []

      for (const entry of entries.result) {
        if (previousNotes.length >= numberOfNotes) {
          break
        }

        for (const subentry of entry.subentries) {
          if (previousNotes.length >= numberOfNotes) {
            break
          }

          if (
            subentry.subentry_type === activityType &&
            !skipSubentries.includes(subentry._id) &&
            !emptyNotes.includes(subentry.note) &&
            subentry.note &&
            !previousNotes.includes(subentry.note)
          ) {
            previousNotes.push(subentry.note)
          }
        }
      }

      return previousNotes
    },
    [
      activityType,
      entries.result,
      numberOfNotes,
    ],
  )
}
