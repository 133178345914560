import type {
  UseFloatingReturn,
  useInteractions,
  useTransitionStatus,
} from "@floating-ui/react"
import { createContext } from "react"

export interface NavigationMenuContextValue extends UseFloatingReturn, ReturnType<typeof useInteractions>, ReturnType<typeof useTransitionStatus> {
  open: boolean;
  setOpen: (o: boolean) => void;
  transitionMs: number;
}

type ContextType = NavigationMenuContextValue | null
export const NavigationMenuContext = createContext<ContextType>(null)

