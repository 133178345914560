import {
  Dialog,
  DialogButton,
  DialogButtons,
  DialogCloseX,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import type { MouseEventHandler } from "react"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "components.discard_changes_dialog."

interface Props {
  open: boolean;
  setOpen: (o: boolean) => void;
  discardChanges: MouseEventHandler<HTMLButtonElement>;
}

/*
 * renders a dialog warning the user that they have unsaved changes
 */
export function DiscardChangesDialog(props: Props) {
  const polyglot = usePolyglot()

  return (
    <Dialog open={props.open}
      onOpenChange={props.setOpen}
    >
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}heading`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogDescription>
          {polyglot.t(`${polyglotPrefix}description`)}
        </DialogDescription>
        <DialogButtons>
          <DialogButton
            stylePreset="destructive"
            onClick={props.discardChanges}
          >
            {polyglot.t(`${polyglotPrefix}discard_cta`)}
          </DialogButton>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
