import dayjs from "dayjs"
import { useMemo } from "react"
import { useScrollPositionContext } from "../ScrollPositionContext"
import { useVirtualListContext } from "../VirtualListContext"
import { useXAxisContext } from "../XAxisContext"

/*
 * returns the time of the specified "xPosition" where the xPosition is relative
 * to the left edge of the graph
 */
export function useXPositionAsTime(xPosition: number): string {
  const { scrollPosition } = useScrollPositionContext()
  const { containerBoundingClientRect } = useVirtualListContext()
  const containerWidth = containerBoundingClientRect.width

  const {
    cellWidth,
    graphStart,
    minutesPerCell,
  } = useXAxisContext()

  return useMemo(
    () => {
      if (!containerWidth) {
        return ""
      }
      if (isNaN(xPosition)) {
        return ""
      }

      const pixelsPerMinute = cellWidth / minutesPerCell
      const distanceFromRight = scrollPosition + (containerWidth - xPosition)
      const time = dayjs(graphStart)
        .subtract(
          distanceFromRight / pixelsPerMinute,
          "minutes",
        )
        .toISOString()
      return time
    },
    [
      xPosition,
      cellWidth,
      minutesPerCell,
      scrollPosition,
      graphStart,
      containerWidth,
    ],
  )
}
