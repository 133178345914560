import type { Medication } from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type MedicationsResponse = {
  meds: Array<Medication>;
}

export function transformMedicationsResponse(data: string): MedicationsResponse {
  const aliasedData = aliasDeletedProperty(data)
  const json = JSON.parse(aliasedData) as MedicationsResponse

  // TODO remove this once the backend provides IDs as strings instead of numbers
  json.meds = json.meds.map((med) => ({
    ...med,
    id: `${med.id}`,
    is_deleted: !!med.is_deleted,
  }))

  return json
}
