import type { ReactNode } from "react"

import { useXPositionAsTime } from "../hooks"
import { TooltipContext } from "./TooltipContext"
import type { TooltipOptions } from "./TooltipOptions"
import { TooltipPortal } from "./TooltipPortal"
import { useMouseX } from "./useMouseX"
import { useTooltip } from "./useTooltip"
import { useNearestGlucoseReading } from "src/hooks"

interface Props extends TooltipOptions {
  children: ReactNode;
}

/*
 * this component provides the tooltip context for its children and renders the tooltip portal
 */
export function TooltipContextProvider({
  children, ...options
}: Props) {
  const mouseX = useMouseX()
  const time = useXPositionAsTime(mouseX)
  const glucoseUserEvent = useNearestGlucoseReading(time)

  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip({
    ...options,
    glucoseUserEvent,
  })

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
      <TooltipPortal />
    </TooltipContext.Provider>
  )
}
