import { forwardRef } from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"

import {
  FeedItemContent,
  FeedItemContentText,
} from "./FeedItemContent"
import styles from "./FeedItemContent/FeedItemContent.module.scss"
import { FeedItemHeader } from "./FeedItemHeader"

import {
  ActivityIcon,
  ActivityNoteIcon,
  ActivityTitle,
  ActivityData,
  ActivityDescription,
  ActivityImageIcon,
  ActivityMessage,
} from "src/components"
import { useEntryModalPublicContext } from "src/components/EntryModal"
import type {
  ReadOnlySubEntry,
  ReadOnlyUserEntry,
  SubEntryType,
} from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userEntry: ReadOnlyUserEntry;
}

export const UserEntryFeedItem = forwardRef<HTMLDivElement, Props>(
  function UserEntryFeedItem(props: Props, propRef: Ref<HTMLDivElement>) {
    const { userEntry } = props
    const { openEntryModal } = useEntryModalPublicContext()

    return (
      <div
        className={props.className}
        ref={propRef}
      >
        <FeedItemHeader userItem={userEntry} />
        {userEntry.subentries.map(
          (subEntry: ReadOnlySubEntry) => {
            const onClick = () => openEntryModal({
              userEntry,
              subEntry,
            })

            const activity = subEntry[subEntry.subentry_type as (SubEntryType & keyof typeof subEntry)]
            const activityType = subEntry.subentry_type
            return (
              <FeedItemContent
                key={subEntry._id}
                onClick={onClick}
              >
                <ActivityIcon
                  activityType={activityType}
                  className={styles.feedItemContentIcon}
                />
                <FeedItemContentText>
                  <ActivityTitle
                    activity={activity}
                    activityType={activityType}
                    item={subEntry}
                    className={styles.feedItemContentTitle}
                  />
                  <ActivityDescription
                    className={styles.feedItemContentDescription}
                    activity={activity}
                    activityType={activityType}
                    item={subEntry}
                  >
                    <ActivityMessage
                      activity={activity}
                      activityType={activityType}
                      item={subEntry}
                      className={styles.feedItemContentMessage}
                    />
                    <ActivityNoteIcon
                      activity={activity}
                      activityType={activityType}
                      item={subEntry}
                      className={styles.feedItemContentNoteIcon}
                    />
                  </ActivityDescription>
                </FeedItemContentText>
                <div className={styles.feedItemContentDataContainer}>
                  <ActivityData
                    activity={activity}
                    activityType={activityType}
                    className={styles.feedItemContentData}
                  />
                  <ActivityImageIcon
                    item={subEntry}
                    className={styles.feedItemContentImageIcon}
                  />
                </div>
              </FeedItemContent>
            )
          },
        )}
      </div>
    )
  },
)
