import styles from "./FoodSearchNavigation.module.scss"
import { FoodSearchNavigationDot } from "./FoodSearchNavigationDot"
import type { FoodSearchResponse } from "src/types"

interface Props {
  currentFoodIndex: number;
  searchResults: FoodSearchResponse;
  setCurrentFoodIndex: (n: number) => void;
}

const maxNumberOfDots = 8

/*
 * this component renders the dots that represent search results
 */
export function FoodSearchNavigationDots(props: Props) {
  const {
    currentFoodIndex,
    searchResults,
    setCurrentFoodIndex,
  } = props

  const totalResults = searchResults.foods.length
  const numberOfVisibleDots = Math.min(
    totalResults,
    maxNumberOfDots,
  )
  // const distanceFromEnd = totalResults - 1 - currentFoodIndex

  // the relative index of the dot that represents the "middle"
  const middleVisibleDotIndex = Math.floor((numberOfVisibleDots - 1) / 2)

  // the first dot index i ranges 0 <-> (totalResults - numberOfVisibleDots)
  const firstVisibleDotIndex = Math.min(
    totalResults - numberOfVisibleDots,
    Math.max(
      0,
      currentFoodIndex - middleVisibleDotIndex,
    ),
  )

  // the last dot index i ranges from (numberOfVisibleDots - 1) <-> totalResults - 1
  const lastVisibleDotIndex = firstVisibleDotIndex + numberOfVisibleDots - 1

  // the number of previous indices that could be relevant to an animation
  const prependDots = currentFoodIndex - firstVisibleDotIndex

  // the number of next indices that could be relevant to an animation
  const appendDots = (firstVisibleDotIndex + numberOfVisibleDots) - currentFoodIndex

  // total number of dots to render, including visible and hidden dots
  const numberOfRenderedDots = numberOfVisibleDots + prependDots + appendDots

  // the lowest rendered dot index
  const firstRenderedDotIndex = firstVisibleDotIndex - prependDots

  // the "index" that is visually centered, when there are an even number of dots this could will be a half index
  const centerIndex = firstVisibleDotIndex + ((numberOfVisibleDots - 1) / 2)

  return (
    <div className={styles.dots}>
      {Array.from(Array(numberOfRenderedDots)).map(
        (_, index: number) => {
          const foodIndex = index + firstRenderedDotIndex
          const foodSearchFood = searchResults.foods[foodIndex]

          return (
            <FoodSearchNavigationDot
              key={`food-index-${foodIndex}`}
              centerIndex={centerIndex}
              currentFoodIndex={currentFoodIndex}
              foodSearchFood={foodSearchFood}
              firstVisibleDotIndex={firstVisibleDotIndex}
              foodIndex={foodIndex}
              lastVisibleDotIndex={lastVisibleDotIndex}
              setCurrentFoodIndex={setCurrentFoodIndex}
              totalResults={totalResults}
            />
          )
        },
      )}
    </div>
  )
}
