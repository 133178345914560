import {
  AccountOutlineIcon,
  DataSourceIcon,
  InfoOutlineIcon,
  InsulinMedicationIcon,
  PlusOutlineIcon, // TODO rename this
  RangesIcon,
  SettingsDisplayIcon, // TODO rename this
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  FunctionComponent,
  SVGProps,
} from "react"

import styles from "./SettingsIndex.module.scss"
import { PageLink } from "src/components"

const pages = [
  {
    routePath: "/settings/data_source" as const,
    polyglotPhraseKey: "pages.settings.pages.data_source",
    Icon: DataSourceIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/ranges" as const,
    polyglotPhraseKey: "pages.settings.pages.ranges",
    Icon: RangesIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/display" as const,
    polyglotPhraseKey: "pages.settings.pages.display",
    Icon: SettingsDisplayIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/insulin_and_medication" as const,
    polyglotPhraseKey: "pages.settings.pages.insulin_and_medication",
    Icon: InsulinMedicationIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/activity" as const,
    polyglotPhraseKey: "pages.settings.pages.activity",
    Icon: PlusOutlineIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/account" as const,
    polyglotPhraseKey: "pages.settings.pages.account",
    Icon: AccountOutlineIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
  {
    routePath: "/settings/about" as const,
    polyglotPhraseKey: "pages.settings.pages.about",
    Icon: InfoOutlineIcon as FunctionComponent<SVGProps<SVGElement>>,
  },
]

export function SettingsIndex() {
  return (
    <div className={styles.container}>
      {pages.map(
        (pageLinkProps) => (
          <PageLink
            key={pageLinkProps.routePath}
            {...pageLinkProps}
          />
        ),
      )}
    </div>
  )
}
