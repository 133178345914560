import {
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react"
import type { ForwardedRef } from "react"
import {
  forwardRef,
  useLayoutEffect,
} from "react"

import {
  useGetTimeAsXPosition,
  useGetGlucoseValueAsYPosition,
} from "../hooks"
import { useVirtualListContext } from "../VirtualListContext"
import { TooltipContent } from "./TooltipContent"
import { useTooltipContext } from "./useTooltipContext"

type Props = React.HTMLProps<HTMLDivElement>

/*
 * this portal renders the tooltip content in a different place in the DOM tree than where the
 * tooltip trigger is
 */
export const TooltipPortal = forwardRef<HTMLDivElement, Props>(
  function TooltipPortal(props: Props, propRef: ForwardedRef<HTMLDivElement>) {
    const state = useTooltipContext()

    const getTimeAsXPosition = useGetTimeAsXPosition()
    const getGlucoseValueAsYPosition = useGetGlucoseValueAsYPosition()
    const { containerBoundingClientRect } = useVirtualListContext()
    const xPosition = state.glucoseUserEvent === undefined
      ? undefined
      : getTimeAsXPosition(state.glucoseUserEvent.started_at)

    const yPosition = state.glucoseUserEvent === undefined
      ? undefined
      : getGlucoseValueAsYPosition(state.glucoseUserEvent?.glucose.mg_dl)

    useLayoutEffect(
      () => {
        if (yPosition === undefined || xPosition === undefined) {
          return
        }

        if (!containerBoundingClientRect.width) {
          return
        }

        if (!state.glucoseUserEvent) {
          return
        }

        state.refs.setReference({
          getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            x: containerBoundingClientRect.x + xPosition,
            y: containerBoundingClientRect.y + yPosition,
            left: containerBoundingClientRect.left + xPosition,
            right: containerBoundingClientRect.right + xPosition,
            top: containerBoundingClientRect.top + yPosition,
            bottom: containerBoundingClientRect.bottom + yPosition,
          }),
        })
      },
      [
        state.glucoseUserEvent,
        state.refs.setReference,
        containerBoundingClientRect,
        xPosition,
        yPosition,
      ],
    )

    const ref = useMergeRefs<HTMLDivElement>([
      state.refs.setFloating,
      propRef,
    ])

    return (
      <FloatingPortal>
        {state.glucoseUserEvent && (
          <TooltipContent
            glucoseUserEvent={state.glucoseUserEvent}
            ref={ref}
            style={{
              position: state.strategy,
              top: 0,
              left: 0, // TODO rtl ?
              // this element is positioned by transform
              transform: `translate(${state.x}px, ${state.y}px)`,
              visibility: state.open ? "visible" : "hidden",
              ...props.style,
            }}
            {...state.getFloatingProps(props)}
          />
        )}
      </FloatingPortal>
    )
  },
)
