import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { useUpsertCollectionItem } from "src/hooks/rxdb"
import { useUserDelete } from "src/hooks/userApi"
import { noticeError } from "src/utils"

type Response<Key extends string, T> = {
  [k in Key]: T;
}

type DeleteCollectionItem<Key extends string, T> = (id: string) => Promise<AxiosResponse<Response<Key, T>>>

type TransformResponse<Key extends string, T> = (data: string) => Response<Key, T>

function defaultTransformResponse<Key extends string, T>(data: string): Response<Key, T> {
  try {
    const response = JSON.parse(data) as Response<Key, T>
    return response
  } catch (e) {
    noticeError(
      e,
      "TransformResponse",
    )

    throw e
  }
}

export function useDeleteCollectionItem<Key extends string, T>(
  collectionKey: string,
  responseKey: Key,
  url: string,
  transformResponse: TransformResponse<Key, T> = defaultTransformResponse<Key, T>,
): DeleteCollectionItem<Key, T> {
  const userDelete = useUserDelete()
  const upsertItem = useUpsertCollectionItem<T>(collectionKey)

  return useCallback(
    async (id: string) => {
      try {
        if (!userDelete) {
          throw new Error(`cannot delete ${responseKey} for unauthorized user`)
        }

        const response = await userDelete<Response<Key, T>>({
          url: `${url}/${id}`,
          transformResponse,
        })

        await upsertItem(response.data[responseKey])
        return response
      } catch (e) {
        noticeError(
          e,
          "DeleteCollectionItem",
          {
            [`${responseKey}Id`]: id,
            collectionItem: responseKey,
            collectionKey,
          },
        )

        throw e
      }
    },
    [
      userDelete,
      upsertItem,
      responseKey,
      url,
    ],
  )
}
