import { useDeleteCollectionItem } from "src/hooks/rxdb"
import { entryTemplateCollectionKey } from "src/schema"
import type { EntryTemplate } from "src/types"

export function useDeleteEntryTemplate() {
  return useDeleteCollectionItem<"entry_template", EntryTemplate>(
    entryTemplateCollectionKey,
    "entry_template",
    "/api/v3/entry_templates",
  )
}
