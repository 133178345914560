import type {
  UseFloatingReturn,
  useInteractions,
  useTransitionStatus,
} from "@floating-ui/react"
import type {
  Dispatch,
  SetStateAction,
} from "react"
import { createContext } from "react"

type Id = string | undefined

export interface CreationOverlayContextValue extends UseFloatingReturn, ReturnType<typeof useInteractions>, ReturnType<typeof useTransitionStatus> {
  colorScheme: "dark" | "light";
  open: boolean;
  setOpen: (v: boolean) => void;
  creationTime?: string;
  labelId: Id;
  descriptionId: Id;
  setLabelId: Dispatch<SetStateAction<Id>>;
  setDescriptionId: Dispatch<SetStateAction<Id>>;
  transitionMs: number;
}

type ContextType = CreationOverlayContextValue | null
export const CreationOverlayContext = createContext<ContextType>(null)
