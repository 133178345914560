import { usePolyglot } from "src/contexts"
import type { NoteSubEntry } from "src/types"

const polyglotPrefix = "components.favorite_summary.message.note."

interface Props {
  subEntry: NoteSubEntry;
}

export function NoteMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  return (
    <>
      {subEntry.note || polyglot.t(`${polyglotPrefix}fallback`)}
    </>
  )
}
