import { useMemo } from "react"

import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"

export function useUserInitials() {
  const polyglot = usePolyglot()

  const userName = useAccountProperty(
    "nickname",
    polyglot.t("components.user_initial.default_user_name"),
  )

  return useMemo(
    () => (
      userName.split(" ")
        .reduce(
          (accumulator, s) => `${accumulator}${s[0] ?? ""}`,
          "",
        )
        .substring(
          0,
          2,
        )
    ),
    [userName],
  )
}
