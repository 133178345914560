import {
  Outlet,
  useRouterState,
} from "@tanstack/react-router"
import dayjs from "dayjs"
import { useEffect } from "react"

import {
  PageHeader,
  Page,
} from "src/components"
import {
  useMe,
  useAccountProperty,
} from "src/hooks"

export function HelpAndFeedback() {
  const { data: me } = useMe()
  const nickname = useAccountProperty(
    "nickname",
    undefined,
  )

  const currentPath = useRouterState({
    select: (s) => s.resolvedLocation.pathname,
  })

  const backRoutePath = currentPath !== "/help_and_feedback" ? "/help_and_feedback" : undefined

  useEffect(
    () => {
      window.Intercom(
        "boot",
        {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID ?? "",
          user_id: me ? `${me.account.id}` : undefined,
          email: me?.account.email,
          name: nickname,
          user_hash: me?.account.intercom_user_hash,
          created_at: me ? dayjs(me.account.created_at).unix() : undefined,
        },
      )

      // we shut down intercom when we navigate away from this page
      // otherwise, the intercom launcher may obfuscate the FAB on the home page
      return () => {
        window.Intercom("shutdown")
      }
    },
    [
      me,
      nickname,
    ],
  )

  return (
    <>
      <PageHeader backRoutePath={backRoutePath} />
      <Page>
        <Outlet />
      </Page>
    </>
  )
}
