import type { RxJsonSchema } from "rxdb"
import { medicationSchema } from "./medicationSchema"

import {
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./sharedSchema"
import type { AccountMedication } from "src/types"

// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const accountMedicationSchema: RxJsonSchema<AccountMedication> = {
  title: "account_med_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    account_id: {
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
      multipleOf: 1,
      type: "number",
    },
    is_active: { type: "boolean" },
    med_id: { type: "string" },
    units: { type: "string" },
    med: {
      type: "object",
      properties: { ...medicationSchema.properties },
      required: medicationSchema.required && [...medicationSchema.required],
    },
    ...sharedSchemaProperties,
  },
  required: [
    "is_active",
    "account_id",
    "units",
    ...sharedSchemaRequired,
  ],
  indexes: [
    [
      "account_id",
      "is_deleted",
    ],
    [
      "account_id",
      "is_deleted",
      "med.name",
    ],
    [
      "account_id",
      "is_deleted",
      "created_at",
    ],
    ...sharedSchemaIndexes,
  ],
}
