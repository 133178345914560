import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindUserEvents } from "./useFindUserEvents"
import { useAccountProperty } from "src/hooks/me"
import type { UserEvent } from "src/types"
import { createEventQueryObject } from "src/utils"

export function useOldestUserEvent():
| DeepReadonlyObject<UserEvent>
| undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createEventQueryObject(
      accountId,
      {
        sort: [{ started_at: "asc" as const }],
        limit: 1,
      },
    ),
    [accountId],
  )

  const { result } = useFindUserEvents(queryObject)
  return result[0]
}
