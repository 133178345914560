import {
  FieldSet,
  Legend,
  SelectionButton,
  SelectionButtons,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useMemo } from "react"

import styles from "./NoteInput.module.scss"
import { usePolyglot } from "src/contexts"
import { ActivityType } from "src/types"

interface Props {
  value: string | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  activityType: ActivityType;
}

const polyglotPrefix = "components.entry_modal.quick_notes."

const insulinQuickNotes = [
  `${polyglotPrefix}insulin.illness`,
  `${polyglotPrefix}insulin.stress`,
  `${polyglotPrefix}insulin.temperature`,
]

const noteQuickNotes = [
  `${polyglotPrefix}note.illness`,
  `${polyglotPrefix}note.stress`,
  `${polyglotPrefix}note.ketones`,
]

const glucoseQuickNotes = [
  `${polyglotPrefix}glucose.new_calibration`,
  `${polyglotPrefix}glucose.daily_calibration`,
  `${polyglotPrefix}glucose.cgm_error`,
]

export function QuickNotes(props: Props) {
  const polyglot = usePolyglot()

  const quickNotes = useMemo(
    () => {
      switch (props.activityType) {
        case ActivityType.Insulin:
          return insulinQuickNotes
        case ActivityType.Note:
          return noteQuickNotes
        case ActivityType.Glucose:
          return glucoseQuickNotes
      }

      return []
    },
    [props.activityType],
  )

  if (!quickNotes.length || !polyglot) {
    return null
  }

  return (
    <FieldSet>
      <Legend
        className={styles.legend}
        sizePreset="small"
      >
        {polyglot.t(`${polyglotPrefix}label`)}
      </Legend>
      <SelectionButtons>
        {
          quickNotes.map(
            (quickNote: string) => (
              <SelectionButton
                key={quickNote}
                htmlFor={quickNote}
                value={polyglot.t(quickNote)}
                onChange={props.onChange}
                checked={props.value === polyglot.t(quickNote)}
              >
                {polyglot.t(quickNote)}
              </SelectionButton>

            ),
          )
        }
      </SelectionButtons>
    </FieldSet>
  )
}
