import { useCallback } from "react"
import { useGetGlucoseValue } from "./useGetGlucoseValue"
import { useGlucoseUnit } from "src/hooks/settings"
import { GlucoseUnit } from "src/types"
import { round } from "src/utils"

/*
 * returns a function that returns the glucose value
 * in a readable scaled and rounded string
 */
export function useDisplayGlucoseValue(): (value: number) => string {
  const glucoseUnit = useGlucoseUnit()
  const getGlucoseValue = useGetGlucoseValue()


  return useCallback(
    (value: number) => {
      const scaledValue = getGlucoseValue(value)

      if (glucoseUnit === GlucoseUnit.MillimolesPerLiter) {
        const roundedValue = round(
          scaledValue,
          1,
        )

        return roundedValue.toLocaleString(
          navigator.language,
          { minimumFractionDigits: 1 },
        )
      }

      return round(scaledValue).toLocaleString(navigator.language)
    },
    [
      getGlucoseValue,
      glucoseUnit,
    ],
  )
}
