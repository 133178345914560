import classNames from "classnames"
import type {
  ReactNode,
  MouseEventHandler,
  CSSProperties,
} from "react"
import {
  useState,
  useMemo,
} from "react"
import { useTimeDifferenceInPixels } from "../hooks"
import { useXAxisContext } from "../XAxisContext"
import styles from "./GraphItem.module.scss"

interface Props {
  children: ReactNode;
  startedAt: string;
  endedAt: string;
  subCellStartTime: string;
  subCellStopTime: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export function GraphItem(props: Props) {
  const {
    onClick,
    startedAt,
    subCellStopTime,
    children,
  } = props

  const { subCellWidth } = useXAxisContext()

  const differenceInPixels = useTimeDifferenceInPixels(
    startedAt,
    subCellStopTime,
  )

  const activityStyle: CSSProperties = useMemo(
    () => ({ left: differenceInPixels }), // TODO rtl
    [differenceInPixels],
  )

  // TODO fix fade in
  // TODO also fade in glucose readings where applicable
  const [
    reveal,
    // setReveal,
  ] = useState(true)

  /*
  const revealActivity = useCallback(
    () => {
      setReveal(true)
    },
    [setReveal],
  )
   */

  const activityClasses = useMemo(
    () => {
      const compact = subCellWidth <= 80
      const extraCompact = subCellWidth <= 40

      return classNames(
        styles.graphItem,
        {
          [styles.hiddenGraphItem]: !reveal,
          [styles.compactGraphItem]: compact,
          [styles.extraCompactGraphItem]: extraCompact,
        },
      )
    },
    [
      reveal,
      subCellWidth,
    ],
  )

  const activityMarkerClasses = useMemo(
    () => classNames(
      styles.timeMarker,
      {
        [styles.hiddenTimeMarker]: !reveal,
      },
    ),
    [reveal],
  )

  return (
    <div className={styles.row}>
      <button
        className={activityClasses}
        style={activityStyle}
        onClick={onClick}
      >
        {children}
      </button>
      <div
        className={activityMarkerClasses}
        style={activityStyle}
      />
    </div>
  )
}
