import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseX,
  ModalContent,
  ModalHeading,
  ModalTrigger,
  TextArea,
  TextAreaContainer,
  FieldSet,
  SelectionButton,
  SelectionButtons,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"
import styles from "./LiquidTypeInput.module.scss"
import { usePreviousLiquidTypes } from "./usePreviousLiquidTypes"
import { usePolyglot } from "src/contexts"
import type { LiquidActivity } from "src/types"

const polyglotPrefix = "components.entry_modal.liquid.type."

interface Props {
  subentryId?: string;
  activity: LiquidActivity;
  updateActivity: (a: LiquidActivity) => void;
}

const liquidTypeSuggestions = [
  `${polyglotPrefix}suggestion.water`,
  `${polyglotPrefix}suggestion.coffee`,
  `${polyglotPrefix}suggestion.tea`,
  `${polyglotPrefix}suggestion.soda`,
  `${polyglotPrefix}suggestion.juice`,
]

export function LiquidTypeInput(props: Props) {
  const polyglot = usePolyglot()

  const {
    subentryId,
    activity,
    updateActivity,
  } = props

  const value = activity.liquid_type

  const suggestions = useMemo(
    () => liquidTypeSuggestions.map(
      (liquidTypePhraseKey: string) => polyglot.t(liquidTypePhraseKey),
    ),
    [polyglot],
  )

  const previousLiquidTypes = usePreviousLiquidTypes(
    subentryId ? [subentryId] : [],
    suggestions,
  )

  const setLiquidType = useCallback(
    (liquidType: string) => {
      updateActivity({
        ...activity,
        liquid_type: liquidType,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setLiquidType(e.target.value)
    },
    [setLiquidType],
  )

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setLiquidType(e.target.value)
    },
    [setLiquidType],
  )

  return (
    <Modal>
      <ModalTrigger asChild>
        <TextAreaContainer className={styles.trigger}>
          <TextArea
            className={styles.textArea}
            value={value ?? undefined}
            placeholder={polyglot.t(`${polyglotPrefix}trigger_placeholder`)}
            readOnly
          />
        </TextAreaContainer>
      </ModalTrigger>
      <ModalContent>
        <ModalHeading>
          {polyglot.t(`${polyglotPrefix}modal_heading`)}
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          <TextAreaContainer>
            <TextArea
              value={value ?? undefined}
              onChange={onTextAreaChange}
              placeholder={polyglot.t(`${polyglotPrefix}placeholder`)}
              maxLength={250}
              required
            />
          </TextAreaContainer>
          <FieldSet>
            <SelectionButtons>
              {suggestions.concat(previousLiquidTypes).map(
                (previousLiquidType: string) => (
                  <SelectionButton
                    key={previousLiquidType}
                    htmlFor={previousLiquidType}
                    value={previousLiquidType}
                    onChange={onInputChange}
                    checked={value === previousLiquidType}
                  >
                    {previousLiquidType}
                  </SelectionButton>
                ),
              )}
            </SelectionButtons>
          </FieldSet>
          <ModalButton
            stylePreset="primary"
            className={styles.done}
          >
            {polyglot.t(`${polyglotPrefix}done_cta`)}
          </ModalButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
