import { transformAccountInsulinsResponse } from "./transformAccountInsulinsResponse"
import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import {
  accountInsulinSchema,
  accountInsulinCollectionKey,
} from "src/schema"

export function useLoadAccountInsulinsCollection() {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadingState,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader(
    "account_insulins",
    "/api/v3/account_insulins",
    transformAccountInsulinsResponse,
  )

  useLoadRxDBCollection(
    accountInsulinSchema,
    accountInsulinCollectionKey,
    loader,
    resetLoader,
  )
}
