export enum ActivityType {
  BasalRate = "basal_rate",
  Battery = "battery",
  DeviceChange = "device_change",
  Exercise = "exercise",
  FillLevel = "fill_level",
  Food = "food",
  Glucose = "glucose",
  IOB = "iob",
  Insulin = "insulin",
  Liquid = "liquid",
  Medication = "med",
  Note = "note",
  ProfileSettingChange = "profile_settings_change",
  PumpStatus = "pump_status",
  ScheduleChange = "schedule_change",
  UserModeChange = "user_mode_change",
}
