import { useContext } from "react"
import type { RxQueryResult } from "rxdb-hooks"
import { RecentReadingContext } from "./RecentReadingContext"
import type { GlucoseUserEvent } from "src/types"

const defaultValue: RxQueryResult<GlucoseUserEvent> = Object.freeze({
  result: [],
  isFetching: true,
  isExhausted: false,
  pageCount: 0,
  currentPage: 0,
  fetchPage: () => { /* empty function */ },
  fetchMore: () => { /* empty function */ },
  resetList: () => { /* empty function */ },
})

export function useRecentReadingContext(accountId: number): RxQueryResult<GlucoseUserEvent> {
  const context = useContext(RecentReadingContext)

  if (!context) {
    throw new Error("elements with useRecentReadingContext must be wrapped in <RecentReadingContextProvider>")
  }

  return context[`${accountId}`] ?? defaultValue
}
