import { useMemo } from "react"
import { useFindAccountMedications } from "./useFindAccountMedications"
import { useAccountProperty } from "src/hooks/me"
import type { AccountMedication } from "src/types"
import { createAccountMedicationQueryObject } from "src/utils"

export function useAccountMedication(id: number | string): AccountMedication | undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createAccountMedicationQueryObject(
      accountId,
      {
        selector: {
          id: { $eq: `${id}` },
        },
        limit: 1,
      },
    ),
    [
      id,
      accountId,
    ],
  )

  const { result } = useFindAccountMedications(queryObject)
  return result[0]
}
