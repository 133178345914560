import classNames from "classnames"
import { forwardRef } from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import type { DeepReadonlyObject } from "rxdb"

import { TooltipArrow } from "./TooltipArrow"
import styles from "./TooltipContent.module.scss"
import { TrendIcon } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useDisplayTime,
  useReadableGlucoseString,
} from "src/hooks"
import type { GlucoseUserEvent } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  glucoseUserEvent: DeepReadonlyObject<GlucoseUserEvent>;
}

export const TooltipContent = forwardRef<HTMLDivElement, Props>(
  function TooltipContent(props: Props, propsRef: Ref<HTMLDivElement>) {
    const {
      glucoseUserEvent,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()
    const displayTime = useDisplayTime()
    const timeLabel = displayTime(glucoseUserEvent.started_at)
    const glucoseString = useReadableGlucoseString(glucoseUserEvent.glucose.mg_dl)

    return (
      <div
        {...htmlDivProps}
        ref={propsRef}
        className={classNames(
          props.className,
          styles.container,
        )}
      >
        <div>
          {timeLabel}
        </div>
        <div className={styles.reading}>
          {glucoseString}
          {glucoseUserEvent.glucose.trend && (
            <TrendIcon
              className={styles.trendIcon}
              trend={glucoseUserEvent.glucose.trend}
            />
          )}
        </div>
        <div className={styles.source}>
          {polyglot.t(`pages.home.graph.tooltip.source.${glucoseUserEvent.source}`)}
        </div>
        <TooltipArrow />
      </div>
    )
  },
)
