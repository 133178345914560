import { useReadableGlucoseString } from "src/hooks"
import type { GlucoseSubEntry } from "src/types"

interface Props {
  subEntry: GlucoseSubEntry;
}

export function GlucoseMessage(props: Props) {
  const { subEntry } = props

  const readableGlucoseString = useReadableGlucoseString(subEntry.glucose.mg_dl)

  return (
    <>
      {readableGlucoseString}
    </>
  )
}
