import type {
  FoodActivity,
  FoodSearchServing,
  FoodSearchFood,
} from "src/types"

interface Props {
  title?: string;
  foodSearchFood: FoodSearchFood;
  servings: number;
  foodSearchServing: FoodSearchServing;
  imageUrl?: string;
  thumbnailUrl?: string;
}

/*
 * this function converts a fatssecret food and one of its serving variants
 * to a food activity for a subentry
 */
export function foodToFoodActivity({
  title,
  foodSearchFood,
  servings,
  foodSearchServing,
}: Props): FoodActivity {
  const {
    calories = null,
    carbs = null,
    fat = null,
    fiber = null,
    protein = null,
    sugar = null,
  } = foodSearchServing

  return {
    title,
    carbs: carbs ? (Number(carbs) * servings) : null,
    fiber: fiber ? (Number(fiber) * servings) : null,
    fat: fat ? (Number(fat) * servings) : null,
    sugar: sugar ? (Number(sugar) * servings) : null,
    protein: protein ? (Number(protein) * servings) : null,
    calories: calories ? (Number(calories) * servings) : null,
    nutritional_reference: {
      brand: foodSearchFood.brand ?? null,
      image_path: null, // TODO
      multiple: servings,
      name: foodSearchFood.name,
      serving_qty: foodSearchServing.serving_qty,
      serving_uom: foodSearchServing.serving_uom,
      source: foodSearchFood.source,
      source_id: foodSearchFood.source_id,
    },
  }
}
