import { Toast } from "@tandemdiabetes/sugarmate-react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
}

export function AddSourceSuccessToast(props: Props) {
  const { id } = props

  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="success"
      body={polyglot.t(
        "toasts.add_source_success.body",
      )}
      id={id}
    />
  )
}
