import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindEntryTemplates } from "./useFindEntryTemplates"
import { useAccountProperty } from "src/hooks/me"
import type { EntryTemplate } from "src/types"
import { createEntryTemplateQueryObject } from "src/utils"

export function useEntryTemplate(id: string): DeepReadonlyObject<EntryTemplate> | undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createEntryTemplateQueryObject(
      accountId,
      {
        selector: {
          id: { $eq: `${id}` },
        },
        limit: 1,
      },
    ),
    [
      id,
      accountId,
    ],
  )

  const { result } = useFindEntryTemplates(queryObject)

  return result[0]
}
