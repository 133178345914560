import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import { useEventsAndSubEntriesOfType } from "src/hooks"
import { ActivityType } from "src/types"

export function useTotalCarbsDayMetric(dayStart: string) {
  const daySelector = useDaySelector(dayStart)

  const [
    totalCarbs,
    setTotalCarbs,
  ] = useState<number>(NaN)

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: foodEvents,
    subEntries: foodSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Food,
    daySelector,
  )

  useEffect(
    () => {
      if (isFetchingEntries || isFetchingEvents) {
        return
      }

      let carbs = 0

      foodEvents.forEach(
        (foodEvent) => {
          if (foodEvent.food.carbs) {
            carbs += foodEvent.food.carbs
          }
        },
      )

      foodSubEntries.forEach(
        (subEntry) => {
          if (subEntry.food.carbs) {
            carbs += subEntry.food.carbs
          }
        },
      )

      setTotalCarbs(carbs)
    },
    [
      isFetchingEntries,
      isFetchingEvents,
      setTotalCarbs,
      foodEvents,
      foodSubEntries,
    ],
  )

  return totalCarbs
}
