import { createContext } from "react"
import type { Tab } from "src/types"

export interface TabContextValue {
  currentTab: Tab;
  setCurrentTab: (t: Tab) => void;
}

type ContextType = TabContextValue | null

export const TabContext = createContext<ContextType>(null)
