import { transformAccountInsulin } from "./transformAccountInsulinResponse"
import type {
  AccountInsulin,
  StatusResponse,
} from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type AccountInsulinsResponse = StatusResponse & {
  account_insulins: Array<AccountInsulin>;
}

export function transformAccountInsulinsResponse(data: string): AccountInsulinsResponse {
  const aliasedData = aliasDeletedProperty(data)
  const json = JSON.parse(aliasedData) as AccountInsulinsResponse
  json.account_insulins = json.account_insulins.map(transformAccountInsulin)
  return json
}
