import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./AccountInsulinModal.module.scss"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { emptyAccountInsulin } from "./EditableAccountInsulin"
import { usePolyglot } from "src/contexts"
import { useInsulinCategories } from "src/hooks"

interface Props {
  currentAccountInsulin: EditableAccountInsulin;
  editAccountInsulin: (e: EditableAccountInsulin) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "insulin_category_select"
const polyglotPrefix = "components.account_insulin_modal.category."

function categoryToPhraseKey(category: string) {
  return category
    .toLowerCase()
    .replace(
      /[^a-z0-9 ]/g,
      "",
    )
    .replace(
      /[ ]+/g,
      "_",
    )
}

/*
 * renders a select element for choosing insulin category
 */
export function InsulinCategorySelect(props: Props) {
  const {
    currentAccountInsulin,
    editAccountInsulin,
  } = props

  const polyglot = usePolyglot()

  // alphabetized insulin categories
  const insulinCategories = useInsulinCategories()

  // the options to present in the select menu
  const options: Array<Option> = useMemo(
    () => {
      // the placeholder option
      const defaultOption: Option = {
        value: emptyAccountInsulin.insulin.category,
        children: polyglot.t(`${polyglotPrefix}placeholder`),
        disabled: true,
      }

      // add each insulin category to the options menu
      const categoryOptions = insulinCategories.map((category): Option => {
        // TODO in the future phrase keys should be based on the category field
        const phraseKey = `${polyglotPrefix}${categoryToPhraseKey(category)}`

        return {
          value: category,
          children: polyglot.has(phraseKey) ? polyglot.t(phraseKey) : category,
        }
      })

      // sort alphabetically according to display string and locale
      categoryOptions.sort((optionA, optionB) => optionA.children.localeCompare(
        optionB.children,
        navigator?.language,
      ))

      return [
        defaultOption,
        ...categoryOptions,
      ]
    },
    [insulinCategories],
  )

  // update the currentAccountInsulin's category
  const onCategoryChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const category = e.target.value

      // update category and clear insulin id
      editAccountInsulin({
        ...currentAccountInsulin,
        insulin_id: emptyAccountInsulin.insulin_id,
        insulin: {
          ...currentAccountInsulin.insulin,
          category,
        },
      })
    },
    [
      currentAccountInsulin,
      editAccountInsulin,
    ],
  )

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          value={currentAccountInsulin.insulin?.category ?? ""}
          onChange={onCategoryChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
