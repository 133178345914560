import { useContext } from "react"
import type { SessionStorageContextValue } from "./SessionStorageContext"
import { SessionStorageContext } from "./SessionStorageContext"

export function useSessionStorageContext(): SessionStorageContextValue {
  const context = useContext(SessionStorageContext)

  if (!context) {
    throw new Error("elements using useSessionStorageContext must be wrapped in <SessionStorageContextProvider>")
  }

  return context
}
