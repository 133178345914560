import type {
  MangoQuery,
  MangoQueryOperators,
} from "rxdb"

interface Item {
  updated_at: string;
  started_at: string;
  created_at: string;
}

// this extracts either a created_at or updated_at time from the query object
export function getTimeConstraintFromQueryObject(
  queryObject: MangoQuery<Item>,
): string | null {
  let startedAt = queryObject.selector?.started_at as MangoQueryOperators<Item["started_at"]>
  let updatedAt = queryObject.selector?.updated_at as MangoQueryOperators<Item["updated_at"]>
  let createdAt = queryObject.selector?.created_at as MangoQueryOperators<Item["created_at"]>

  // check the and array
  (queryObject.selector?.$and || []).forEach(
    (selector) => {
      startedAt ||= selector.started_at as MangoQueryOperators<Item["started_at"]>
      updatedAt ||= selector.updated_at as MangoQueryOperators<Item["updated_at"]>
      createdAt ||= selector.created_at as MangoQueryOperators<Item["created_at"]>
    },
  )

  const value =
    startedAt?.$gt ||
    startedAt?.$gte ||
    updatedAt?.$gt ||
    updatedAt?.$gte ||
    createdAt?.$gt ||
    createdAt?.$gte

  if (value) {
    return value
  }

  return null
}
