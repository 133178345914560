import dayjs from "dayjs"
import { useMemo } from "react"
import {
  useToday,
  useOldestActivity,
} from "src/hooks"

export function useOldestTime(): string {
  const oldestActivity = useOldestActivity()
  const today = useToday()

  const oldestTime = useMemo(
    () => {
      const startOfToday = dayjs(today)
        .startOf("day")
        .toISOString()

      if (oldestActivity === undefined) {
        return startOfToday
      }

      const oldestActivityStartOfDay = dayjs(oldestActivity.started_at)
        .startOf("day")
        .toISOString()

      const diff = dayjs(startOfToday).diff(
        oldestActivityStartOfDay,
        "day",
        true,
      )

      // if oldest activity is > 90 days ago, limit graph to 90 days
      if (diff > 90) {
        return dayjs(startOfToday).subtract(
          90,
          "day",
        )
          .toISOString()
      }

      // if oldest activity is in the future, use today as the oldest day
      if (diff < 0) {
        return startOfToday
      }

      // use the start of the day of the oldest activity
      return oldestActivityStartOfDay
    },
    [
      oldestActivity,
      today,
    ],
  )

  return oldestTime
}
