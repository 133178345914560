import { transformAccountMedicationResponse } from "./transformAccountMedicationResponse"
import { useDeleteCollectionItem } from "src/hooks/rxdb"
import { accountMedicationCollectionKey } from "src/schema"
import type { AccountMedication } from "src/types"

export function useDeleteAccountMedication() {
  return useDeleteCollectionItem<"account_med", AccountMedication>(
    accountMedicationCollectionKey,
    "account_med",
    "/api/v3/account_meds",
    transformAccountMedicationResponse,
  )
}
