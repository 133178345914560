import {
  BGReadingFillIcon,
  ExerciseFillIcon,
  FoodFillIcon,
  InsulinFillIcon,
  BedtimeFillIcon,
  WakeUpFillIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import classNames from "classnames"
import dayjs from "dayjs"
import type {
  ComponentPropsWithoutRef,
  MouseEventHandler,
} from "react"
import {
  useCallback,
  useMemo,
} from "react"

import { useDayMetricContext } from "../DayMetricContext"
import { AverageGlucoseDayMetricComponent } from "./AverageGlucoseDayMetricComponent"
import { BedTimeGlucoseDayMetricComponent } from "./BedTimeGlucoseDayMetricComponent"
import styles from "./DayMetricComponent.module.scss"
import { ExerciseDayMetricComponent } from "./ExerciseDayMetricComponent"
import { NormalRangeDayMetricComponent } from "./NormalRangeDayMetricComponent"
import { StandardDeviationDayMetricComponent } from "./StandardDeviationDayMetricComponent"
import { TotalCaloriesDayMetricComponent } from "./TotalCaloriesDayMetricComponent"
import { TotalCarbsDayMetricComponent } from "./TotalCarbsDayMetricComponent"
import { TotalUnitsDayMetricComponent } from "./TotalUnitsDayMetricComponent"
import { WakeUpGlucoseDayMetricComponent } from "./WakeUpGlucoseDayMetricComponent"
import { usePolyglot } from "src/contexts"
import {
  useDeviceSettingsProperty,
  useToday,
} from "src/hooks"
import { DayStat } from "src/types"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

/*
 * this component renders the metric that the user chooses to summarize
 * their day
 *
 * in order to do that it makes requests to the db
 */
export function DayMetricComponent(props: Props) {
  const polyglot = usePolyglot()
  const today = useToday()

  const {
    dayStart,
    ...buttonProps
  } = props

  const isToday = useMemo(
    () => dayjs(dayStart).isSame(
      today,
      "day",
    ),
    [
      today,
      dayStart,
    ],
  )

  const { openDayMetricModal } = useDayMetricContext()

  const dayMetric = useDeviceSettingsProperty("day_stat_header")

  const className = classNames(
    styles.container,
    props.className,
  )

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      openDayMetricModal()
      if (props.onClick) {
        props.onClick(e)
      }
    },
    [
      props.onClick,
      openDayMetricModal,
    ],
  )

  const childrenProps = {
    dayStart,
    ...buttonProps,
    className,
    onClick,
  }

  if (dayMetric === undefined && isToday) {
    return (
      <button
        {...buttonProps}
        className={className}
        onClick={onClick}
      >
        {polyglot.t("pages.home.activity_list.day_stat_cta")}
      </button>
    )
  }

  switch (dayMetric) {
    case DayStat.AverageGlucose:
      return (
        <AverageGlucoseDayMetricComponent {...childrenProps}>
          <BGReadingFillIcon className={styles.icon} />
        </AverageGlucoseDayMetricComponent>
      )
    case DayStat.WakeUpGlucose:
      return (
        <WakeUpGlucoseDayMetricComponent {...childrenProps}>
          <WakeUpFillIcon className={styles.icon} />
        </WakeUpGlucoseDayMetricComponent>
      )
    case DayStat.BedTimeGlucose:
      return (
        <BedTimeGlucoseDayMetricComponent {...childrenProps}>
          <BedtimeFillIcon className={styles.icon} />
        </BedTimeGlucoseDayMetricComponent>
      )
    case DayStat.Exercise:
      return (
        <ExerciseDayMetricComponent {...childrenProps}>
          <ExerciseFillIcon className={styles.icon} />
        </ExerciseDayMetricComponent>
      )
    case DayStat.NormalRange:
      return <NormalRangeDayMetricComponent {...childrenProps} />
    case DayStat.StandardDeviation:
      return <StandardDeviationDayMetricComponent {...childrenProps} />
    case DayStat.TotalCalories:
      return <TotalCaloriesDayMetricComponent {...childrenProps} />
    case DayStat.TotalCarbs:
      return (
        <TotalCarbsDayMetricComponent {...childrenProps}>
          <FoodFillIcon className={styles.icon} />
        </TotalCarbsDayMetricComponent>
      )
    case DayStat.TotalUnits:
      return (
        <TotalUnitsDayMetricComponent {...childrenProps}>
          <InsulinFillIcon className={styles.icon} />
        </TotalUnitsDayMetricComponent>
      )
  }

  return null
}
