import {
  createContext,
  useContext,
} from "react"
import type { Insight } from "src/types"

export interface EditInsightModalContextValue {
  openEditInsightModal: (insight: Insight) => void;
}

type ContextType = EditInsightModalContextValue | null
export const EditInsightModalContext = createContext<ContextType>(null)

export function useEditInsightModalContext() {
  const context = useContext(EditInsightModalContext)

  if (context === null) {
    throw new Error("EditInsightModal components must be wrapped in <EditInsightModalContext />")
  }

  return context
}
