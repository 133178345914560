import type { PartialGlucoseReadingProps } from "./PartialGlucoseReadingProps"

export function sortGlucoseReadings(
  a: PartialGlucoseReadingProps,
  b: PartialGlucoseReadingProps,
): number {
  if (a.readingTime === b.readingTime) {
    return 0
  }

  const aBeforeB = a.readingTime < b.readingTime
  if (aBeforeB) {
    return -1
  }

  return 1
}
