import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { transformSettingsRequest } from "./transformSettingsRequest"
import { useMe } from "src/hooks/me"
import { useUserPut } from "src/hooks/userApi"
import type { Settings } from "src/types"
import { noticeError } from "src/utils"

type UpdateSettingsFunction = (
  data: Partial<Settings>
) => Promise<AxiosResponse>

export function useUpdateSettings(): UpdateSettingsFunction {
  const {
    data: me,
    mutate,
  } = useMe()

  const userPut = useUserPut()
  const deviceId = me?.device.id

  return useCallback<UpdateSettingsFunction>(
    async (data) => {
      try {
        if (!deviceId) {
          throw new Error("cannot update settings for unknown device")
        }

        if (!userPut) {
          throw new Error("cannot update settings for unauthorized user")
        }

        const url = `/api/v2/devices/${deviceId}`

        const axiosResponse = await userPut({
          data,
          url,
          transformRequest: transformSettingsRequest,
        })

        void mutate()
        return axiosResponse
      } catch (e) {
        noticeError(
          e,
          "SaveSettings",
          { data },
        )
        throw e
      }
    },
    [
      deviceId,
      userPut,
      mutate,
    ],
  )
}
