import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import type { DeepReadonlyObject } from "rxdb"
import { defaultInsulinActivity } from "./defaultInsulinActivity"
import type {
  AccountInsulin,
  InsulinActivity,
} from "src/types"

export function useOnCurrentAccountInsulinUpdated(
  activity: InsulinActivity,
  updateActivity: (a: InsulinActivity) => void,
  currentAccountInsulin: DeepReadonlyObject<AccountInsulin> | undefined,
) {
  /*
   * this is "null" when the activity is the default insulin activity
   * this is "undefined" when the account insulin is still being found in the db
   */
  const previousAccountInsulinRef = useRef<DeepReadonlyObject<AccountInsulin> | undefined | null>(
    activity.insulin_med.account_insulin_id === defaultInsulinActivity.insulin_med.account_insulin_id
      ? null
      : currentAccountInsulin,
  )

  // this function updates the activity with the provided account insulin
  const updateActivityWithAccountInsulin = useCallback(
    (accountInsulin: DeepReadonlyObject<AccountInsulin>) => {
      updateActivity({
        ...activity,
        insulin_med: {
          account_insulin_id: Number(accountInsulin.id),
          insulin_id: Number(accountInsulin.insulin_id),
          category: accountInsulin.insulin.category ?? "",
          description: accountInsulin.insulin.description ?? "",
          name: accountInsulin.name_override ?? accountInsulin.insulin.name ?? "",
          form: accountInsulin.form ?? accountInsulin.insulin.forms?.[0] ?? "",
          onset: accountInsulin.onset ?? NaN,
          peak: accountInsulin.peak ?? null,
          duration: accountInsulin.duration ?? NaN,
          precision: accountInsulin.precision,
          secondary_precision: accountInsulin.secondary_precision ?? null,
          is_basal: accountInsulin.is_basal,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  useEffect(
    () => {
      // if the current account insulin is the same as the previous account insulin but has been updated
      if (
        !!currentAccountInsulin &&
        !!previousAccountInsulinRef.current &&
        previousAccountInsulinRef.current.id === currentAccountInsulin.id &&
        previousAccountInsulinRef.current.updated_at !== currentAccountInsulin.updated_at
      ) {
        updateActivityWithAccountInsulin(currentAccountInsulin)

        // if the current account insulin is different than the previous account insulin
      } else if (
        !!currentAccountInsulin &&
        previousAccountInsulinRef.current !== undefined && // only trigger this if the previous account insulin is null or defined
        previousAccountInsulinRef.current?.id !== currentAccountInsulin.id
      ) {
        updateActivityWithAccountInsulin(currentAccountInsulin)
      }

      // update the previousAccountInsulinRef
      if (activity.insulin_med.account_insulin_id === defaultInsulinActivity.insulin_med.account_insulin_id) {
        // null if the activity has the default insulin
        previousAccountInsulinRef.current = null
      } else if (currentAccountInsulin !== undefined) {
        // a value only if currentAccountInsulin is defined
        previousAccountInsulinRef.current = currentAccountInsulin
      }
    },
    [
      activity,
      currentAccountInsulin,
      previousAccountInsulinRef,
      updateActivityWithAccountInsulin,
    ],
  )
}
