import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import type {
  ReadOnlySubEntry,
  ReadOnlyUserEvent,
  ActivityPair,
} from "src/types"
import {
  ActivityType,
  ExerciseIntensity,
} from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & ActivityPair & {
  item: ReadOnlySubEntry | ReadOnlyUserEvent;
}

/*
 * this component returns null if there is not description
 * it relies on parity with the ActivityMessage and ActivityNoteIcon component
 */
export const ActivityDescription = forwardRef<HTMLDivElement, Props>(
  function ActivityDescription(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      activityType,
      item,
      ...htmlProps
    } = props

    if (activityType === ActivityType.Note) {
      return null
    }

    if (activityType === ActivityType.Exercise) {
      const userEvent = item as ReadOnlyUserEvent
      const subEntry = item as ReadOnlySubEntry

      const hasDuration = subEntry.duration_in_seconds || (
        userEvent.ended_at !== userEvent.started_at
      )

      if (!hasDuration && (activity.intensity === ExerciseIntensity.None) && !item.note) {
        return null
      }
    } else if (!item.note) {
      return null
    }

    return (
      <div
        {...htmlProps}
        ref={ref}
      />
    )
  },
)
