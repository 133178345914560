import { useEffect } from "react"
import type { CalculatorGroupsState } from "./useCalculatorGroupsState"
import { useAccountProperty } from "src/hooks/me"
import {
  useGlucoseUnit,
  useDeviceSettingsProperty,
} from "src/hooks/settings"
import { InsightCalculatorGroup } from "src/types"
import type { CalculationWorkerParamsData } from "src/workers/insightCalculationWorker"

export function useInsightCalculationWorker(
  calculatorGroupsState: CalculatorGroupsState,
  queryEndTime: string,
  timeSinceWakeUpInHours: number,
  worker: Worker,
): void {
  const glucoseUnit = useGlucoseUnit()
  const normalRangeTop = useDeviceSettingsProperty("range_normal_top")
  const normalRangeBottom = useDeviceSettingsProperty("range_normal_bottom")
  const sleepTop = useDeviceSettingsProperty("sleep_top")
  const sleepBottom = useDeviceSettingsProperty("sleep_bottom")
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  useEffect(
    () => {
      if (!worker) {
        return
      }

      const insightRanges = calculatorGroupsState[InsightCalculatorGroup.Exercise]

      const message: CalculationWorkerParamsData = {
        accountId,
        calculationType: InsightCalculatorGroup.Exercise,
        insightRanges,
        queryEndTime,
        timeSinceWakeUpInHours,
        params: {
          insightRanges,
          timeSinceWakeUpInHours,
        },
      }

      worker.postMessage(message)
    },
    [
      accountId,
      worker,
      calculatorGroupsState[InsightCalculatorGroup.Exercise],
      queryEndTime,
      timeSinceWakeUpInHours,
    ],
  )

  useEffect(
    () => {
      if (!worker) {
        return
      }

      const insightRanges = calculatorGroupsState[InsightCalculatorGroup.Food]

      const message: CalculationWorkerParamsData = {
        accountId,
        calculationType: InsightCalculatorGroup.Food,
        params: {
          insightRanges,
          timeSinceWakeUpInHours,
        },
        insightRanges,
        queryEndTime,
        timeSinceWakeUpInHours,
      }

      worker.postMessage(message)
    },
    [
      accountId,
      worker,
      calculatorGroupsState[InsightCalculatorGroup.Food],
      queryEndTime,
      timeSinceWakeUpInHours,
    ],
  )

  useEffect(
    () => {
      if (!worker) {
        return
      }

      const insightRanges = calculatorGroupsState[InsightCalculatorGroup.Glucose]

      const message: CalculationWorkerParamsData = {
        accountId,
        calculationType: InsightCalculatorGroup.Glucose,
        params: {
          glucoseUnit,
          insightRanges,
          normalRangeTop,
          normalRangeBottom,
          sleepTop,
          sleepBottom,
          timeSinceWakeUpInHours,
        },
        insightRanges,
        queryEndTime,
        timeSinceWakeUpInHours,
      }

      worker.postMessage(message)
    },
    [
      accountId,
      worker,
      glucoseUnit,
      calculatorGroupsState[InsightCalculatorGroup.Glucose],
      normalRangeTop,
      normalRangeBottom,
      sleepTop,
      sleepBottom,
      queryEndTime,
      timeSinceWakeUpInHours,
    ],
  )

  useEffect(
    () => {
      if (!worker) {
        return
      }

      const insightRanges = calculatorGroupsState[InsightCalculatorGroup.Insulin]

      const message: CalculationWorkerParamsData = {
        accountId,
        calculationType: InsightCalculatorGroup.Insulin,
        params: {
          insightRanges,
          timeSinceWakeUpInHours,
        },
        insightRanges,
        queryEndTime,
        timeSinceWakeUpInHours,
      }

      worker.postMessage(message)
    },
    [
      accountId,
      worker,
      calculatorGroupsState[InsightCalculatorGroup.Insulin],
      queryEndTime,
      timeSinceWakeUpInHours,
    ],
  )

  useEffect(
    () => {
      if (!worker) {
        return
      }

      const insightRanges = calculatorGroupsState[InsightCalculatorGroup.Quartile]

      const message: CalculationWorkerParamsData = {
        accountId,
        calculationType: InsightCalculatorGroup.Quartile,
        insightRanges,
        queryEndTime,
        timeSinceWakeUpInHours,
        params: undefined,
      }

      worker.postMessage(message)
    },
    [
      accountId,
      worker,
      calculatorGroupsState[InsightCalculatorGroup.Quartile],
      queryEndTime,
      timeSinceWakeUpInHours,
    ],
  )
}
