import dayjs from "dayjs"
import { useMemo } from "react"
import { useXAxisContext } from "../XAxisContext"
import styles from "./TimeMarker.module.scss"
import { useReadableTime } from "src/hooks"

interface Props {
  cellStopTime: string;
  time: string;
}

export function TimeMarker(props: Props) {
  const {
    minutesPerCell, cellWidth,
  } = useXAxisContext()
  const {
    time, cellStopTime,
  } = props
  const timeLabel = useReadableTime(time)

  const containerStyle = useMemo(
    () => {
      const diffFromStop = dayjs(time).diff(
        cellStopTime,
        "minutes",
      )
      const left = cellWidth * (diffFromStop / minutesPerCell)
      return { left }
    },
    [
      time,
      cellStopTime,
      minutesPerCell,
      cellWidth,
    ],
  )

  return (
    <div
      className={styles.container}
      style={containerStyle}
    >
      <div className={styles.line}/>
      <div className={styles.text}>
        {timeLabel}
      </div>
    </div>
  )
}
