import { DisconnectOutlineIcon } from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import { useMemo } from "react"
import styles from "./UserHeader.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useFirstGlucoseReading,
  useThisMinute,
} from "src/hooks"

const polyglotPrefix = "pages.home.user_header."

interface Props {
  userName: string;
}

export function StaleDataError(props: Props) {
  const { userName } = props

  const firstGlucoseReading = useFirstGlucoseReading().result[0]
  const polyglot = usePolyglot()
  const thisMinute = useThisMinute()

  const minutesAgo = useMemo(
    () => firstGlucoseReading && dayjs(thisMinute).diff(
      firstGlucoseReading.started_at ?? "",
      "minute",
    ),
    [
      firstGlucoseReading,
      thisMinute,
    ],
  )

  const message = useMemo(
    () => {
      if (minutesAgo === undefined) {
        return polyglot.t(`${polyglotPrefix}no_data_message`)
      }

      const minutes = minutesAgo % 60
      const hours = Math.floor(minutesAgo / 60) % 24
      const days = Math.floor(minutesAgo / (24 * 60))

      if (days > 0) {
        return polyglot.t(
          `${polyglotPrefix}stale_data_day_hour_min_message`,
          {
            days,
            hours,
            minutes,
          },
        )
      }

      if (hours > 0) {
        return polyglot.t(
          `${polyglotPrefix}stale_data_hour_min_message`,
          {
            hours,
            minutes,
          },
        )
      }

      return polyglot.t(
        `${polyglotPrefix}stale_data_min_message`,
        minutes,
      )
    },
    [minutesAgo],
  )

  return (
    <>
      <div className={styles.errorContainer}>
        <DisconnectOutlineIcon className={styles.errorIcon} />
        <div className={styles.errorText}>
          {message}
        </div>
      </div>
      <div className={styles.info}>
        {polyglot.t(
          `${polyglotPrefix}users_data`,
          { user: userName },
        )}
      </div>
    </>
  )
}
