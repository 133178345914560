import { useCallback } from "react"
import type { MouseEventHandler } from "react"

import { GraphItem } from "./GraphItem"
import styles from "./GraphItem.module.scss"
import {
  useEntryModalPublicContext,
  FavoriteIcon,
} from "src/components"
import { useEntryTemplateTitle } from "src/hooks"
import type { ReadOnlyUserEntry } from "src/types"

export interface Props {
  startedAt: string;
  endedAt: string;
  subCellStartTime: string;
  subCellStopTime: string;
  entryTemplateId: string;
  item: ReadOnlyUserEntry;
}

export function FavoriteGraphItem(props: Props) {
  const {
    entryTemplateId,
    subCellStartTime,
    subCellStopTime,
    item,
    startedAt,
    endedAt,
  } = props

  const { openEntryModal } = useEntryModalPublicContext()
  const entryTemplateTitle = useEntryTemplateTitle(entryTemplateId)

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()

      if (item) {
        openEntryModal({
          userEntry: item,
        })
      }
    },
    [
      item,
      openEntryModal,
    ],
  )

  return (
    <GraphItem
      onClick={onClick}
      startedAt={startedAt}
      endedAt={endedAt}
      subCellStartTime={subCellStartTime}
      subCellStopTime={subCellStopTime}
    >
      <FavoriteIcon className={styles.activityIcon}/>
      <div className={styles.message}>
        {entryTemplateTitle}
      </div>
    </GraphItem>
  )
}
