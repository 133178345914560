import { useCallback } from "react"
import type { SignOutParams } from "./AuthContext"
import type { SetAuthCookie } from "./useAuthCookieState"
import { get } from "src/api"
import { useSessionStorageContext } from "src/contexts/SessionStorageContext"
import { noticeError } from "src/utils"

type SignOut = () => Promise<void>

export function useSignOut(setAuthCookie: SetAuthCookie): SignOut {
  const {
    setLoginRedirect,
    setLogoutRedirect,
  } = useSessionStorageContext()

  return useCallback(
    async (params: SignOutParams = {}) => {
      const {
        loginRedirect,
        logoutRedirect,
      } = params

      setLoginRedirect(loginRedirect)
      setLogoutRedirect(logoutRedirect)

      try {
        // TODO sign out should be completed with a POST request not a get request
        await get({ url: "/sign_out" })
      } catch (e) {
        noticeError(
          e,
          "SignOut",
        )
      }

      setAuthCookie(undefined)
    },
    [
      setLoginRedirect,
      setLogoutRedirect,
      setAuthCookie,
    ],
  )
}
