import classNames from "classnames"
import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"
import { forwardRef } from "react"
import { InsightRangeString } from "../InsightRangeString"
import styles from "./Tile.module.scss"
import type { InsightRange } from "src/types"

interface Props extends HTMLAttributes<HTMLDivElement> {
  range: InsightRange;
}

export const TileTimeSpan = forwardRef<HTMLDivElement, Props>(
  function TileTimeSpan(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const {
      range,
      ...divProps
    } = props

    const className = classNames(
      props.className,
      styles.timeSpan,
    )

    return (
      <div
        {...divProps}
        ref={ref}
        className={className}
      >
        <InsightRangeString range={range} />
      </div>
    )

  },
)
