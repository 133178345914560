import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import {
  useUserPost,
  useMe,
} from "src/hooks"
import type { Report } from "src/types"
import { noticeError } from "src/utils"

interface ReportResponse {
  status: "fail" | "success";
  title: string;
  message: string;
}

type SendReportFunction = (report: Report) => Promise<AxiosResponse<ReportResponse>>

export function useSendReport(): SendReportFunction {
  const { data: me } = useMe()
  const userPost = useUserPost()
  const accountId = me?.account.id

  const sendReport = useCallback(
    async (report: Report): Promise<AxiosResponse<ReportResponse>> => {
      try {
        if (!accountId) {
          throw new Error("cannot create report for unknown account")
        }

        if (!userPost) {
          throw new Error("cannot create report for unauthorized user")
        }

        const url = `/api/v2/accounts/${accountId}/reports/mail`
        const axiosResponse = await userPost<ReportResponse>({
          url,
          data: report,
        })

        return axiosResponse
      } catch (e) {
        noticeError(
          e,
          "CreateReport",
          { data: report },
        )

        throw e
      }
    },

    [
      accountId,
      userPost,
    ],
  )

  return sendReport
}

