import {
  createContext,
  useContext,
} from "react"
import type { SourceType } from "src/types"

export interface DataConsentDialogContextValue {
  openConsentDialog: (sourceType: SourceType.Nightscout | SourceType.DexcomRest) => void;
}

type ContextType = DataConsentDialogContextValue | null

export const DataConsentDialogContext = createContext<ContextType>(null)

export function useDataConsentDialogContext() {
  const context = useContext(DataConsentDialogContext)

  if (context === null) {
    throw new Error("DataConsentDialog components must be wrapped in <DataConsentDialogContext />")
  }

  return context
}
