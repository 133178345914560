import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useFind } from "src/hooks/rxdb"
import { entryTemplateCollectionKey } from "src/schema"
import type { EntryTemplate } from "src/types"

export function useFindEntryTemplates(
  queryObject: MangoQuery<EntryTemplate>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<EntryTemplate> {
  return useFind<EntryTemplate>(
    queryObject,
    entryTemplateCollectionKey,
    options,
  )
}
