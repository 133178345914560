import styles from "./Help.module.scss"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.help_and_feedback.help."

export function Help() {
  const polyglot = usePolyglot()

  return (
    <div className={styles.container}>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/2658795-getting-started"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}getting_started`)}
      </a>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/2656539-general-questions"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}general_questions`)}
      </a>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/3073671-troubleshooting-cgm-readings"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}cgm_readings`)}
      </a>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/3073668-troubleshooting-alerts"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}alerts`)}
      </a>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/2658776-troubleshooting-apple-watch"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}apple_watch`)}
      </a>
      <a
        className={styles.link}
        href="https://help.sugarmate.io/en/collections/3073669-troubleshooting-other-issues"
        target="_blank"
        rel="noreferrer"
      >
        {polyglot.t(`${polyglotPrefix}other_issues`)}
      </a>
    </div>
  )
}
