import { Appearance } from "./Appearance"
import styles from "./Display.module.scss"
import { DotColor } from "./DotColor"
import { GraphIconScale } from "./GraphIconScale"
import { GraphIconText } from "./GraphIconText"
import { GraphRange } from "./GraphRange"
import { ShowDifference } from "./ShowDifference"
import { Themes } from "./Themes"

import {
  useDebouncedUpdater,
  useDeviceProperty,
  useUpdateSettings,
} from "src/hooks"

export function Display() {
  const updatedAt = useDeviceProperty(
    "updated_at",
    undefined,
  )

  // TODO catch error and show toast
  const updateSettings = useUpdateSettings()
  const debouncedUpdateSettings = useDebouncedUpdater(updateSettings)

  return (
    <div className={styles.container}>
      <GraphRange
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <ShowDifference
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <DotColor
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <Appearance
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <Themes
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <GraphIconScale
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <GraphIconText
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
    </div>
  )
}
