import { useCalculationsContext } from "../CalculationsContext"
import { TilePercentage } from "./TilePercentage"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucosePercentageAbove(props: Props) {
  const { range } = props

  const { glucoseCalculations } = useCalculationsContext()
  const { percentAboveNormal } = glucoseCalculations[range] || {}

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucosePercentageAbove} />
      <TileTimeSpan range={range} />
      <TilePercentage percentage={percentAboveNormal} />
    </>
  )
}
