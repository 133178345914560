import { useOldestUserEntry } from "src/hooks/userEntry"
import { useOldestUserEvent } from "src/hooks/userEvent"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"

export function useOldestActivity(): ReadOnlyUserEvent | ReadOnlyUserEntry | undefined {
  const oldestEvent = useOldestUserEvent()
  const oldestEntry = useOldestUserEntry()

  if (oldestEvent && oldestEntry) {
    if (oldestEvent.started_at < oldestEntry.started_at) {
      return oldestEvent
    }
    return oldestEntry

  } if (oldestEvent) {
    return oldestEvent
  } if (oldestEntry) {
    return oldestEntry
  }

  return undefined
}
