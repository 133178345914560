import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { InsulinActivity } from "src/types"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: InsulinActivity;
}

export const InsulinActivityTitle = forwardRef<HTMLDivElement, Props>(
  function InsulinActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const title = activity.num_units
      ? polyglot.t(
        "components.activity_summary.title.insulin_with_units",
        {
          insulin: activity.insulin_med.name,
          units: round(
            activity.num_units,
            3,
          ).toLocaleString(navigator.language),
        },
      ) : polyglot.t(
        "components.activity_summary.title.insulin",
        {
          insulin: activity.insulin_med.name,
        },
      )

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {title}
      </div>
    )
  },
)
