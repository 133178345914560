import dayjs, { unix } from "dayjs"
import type { ReactNode } from "react"
import {
  useEffect,
  useMemo,
} from "react"
import { AuthContext } from "./AuthContext"
import { useAuthCookieState } from "./useAuthCookieState"
import { useRefreshToken } from "./useRefreshToken"
import { useSignIn } from "./useSignIn"
import { useSignOut } from "./useSignOut"

interface Props {
  children: ReactNode;
}

export function AuthContextProvider(props: Props) {
  const [
    authCookie,
    setAuthCookie,
  ] = useAuthCookieState()

  const signIn = useSignIn(setAuthCookie)
  const signOut = useSignOut(setAuthCookie)
  const refreshToken = useRefreshToken(setAuthCookie)

  // if there is an auth cookie and it is expired then this is true
  const isExpired = useMemo(
    () => {
      if (!authCookie?.expires_in || !authCookie?.created_at) {
        return false
      }

      return unix(authCookie.created_at).add(
        authCookie.expires_in,
        "second",
      )
        .isBefore(dayjs())
    },
    [authCookie],
  )

  // if the token expires, refresh it
  useEffect(
    () => {
      if (isExpired && authCookie?.access_token && authCookie?.refresh_token) {
        // attempt to refresh the token when it is expired
        // this method handles the scenario in which the request fails
        void refreshToken(
          authCookie.access_token,
          authCookie.refresh_token,
        )
      } else if (!authCookie?.access_token) {
        setAuthCookie(undefined)
      }
    },
    [
      authCookie,
      isExpired,
      refreshToken,
    ],
  )

  const value = useMemo(
    () => ({
      accessToken: authCookie?.access_token,
      isExpired,
      signIn,
      signOut,
    }),
    [
      isExpired,
      authCookie,
      signIn,
      signOut,
    ],
  )

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  )
}
