import { useEffect } from "react"
import { usePageLifecycleContext } from "src/contexts"
import { PageLifecycle } from "src/types"
import { onLogOut } from "src/utils"

/*
 * this hook will execute a callback for a cleanup effect
 * to run when the user logs out in a different tab
 */
export function useOnHiddenLogOut(callback: () => unknown) {
  const pageLifecycle = usePageLifecycleContext()

  useEffect(
    () => {
      if (pageLifecycle === PageLifecycle.Hidden) {
        const unsubscribe = onLogOut(callback)
        return unsubscribe
      }
    },
    [
      callback,
      pageLifecycle,
    ],
  )
}
