import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useRxData } from "rxdb-hooks"
import { useQueryFunction } from "./useQueryFunction"

export function useFind<T>(
  queryObject: MangoQuery<T>,
  collectionKey: string,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<T> {

  const queryFunction = useQueryFunction<T>(queryObject)
  const result = useRxData<T>(
    collectionKey,
    queryFunction,
    {
      ...options,
      json: true,
    },
  )

  return result
}
