import { useCallback } from "react"
import { useYAxisContext } from "../YAxisContext"
import { clamp } from "src/utils"

/*
 * returns a function that converts a glucose value in mg/dl to a height
 * percentage of the graph range it returns a tuple where the first item is the
 * percentage number and the second value is a boolean that indicates if it was
 * clamped to fit the current range of the graph
 */
export function useGetGlucoseValueAsPercentage(): (glucoseValue: number) => [number, boolean] {
  const {
    max,
    min,
  } = useYAxisContext()

  return useCallback(
    (glucoseValue: number) => {
      const range = max - min
      const percentage = (100 * (glucoseValue - min)) / range
      const clamped = clamp(
        percentage,
        0,
        100,
      )
      return [
        clamped,
        clamped !== percentage,
      ]
    },
    [
      min,
      max,
    ],
  )
}
