import { useMemo } from "react"
import type { MangoQuerySelector } from "rxdb"

import { useAccountProperty } from "src/hooks/me"
import { useFindUserEntries } from "src/hooks/userEntry"
import { useFindUserEvents } from "src/hooks/userEvent"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
  UserEvent,
} from "src/types"
import { ActivityType } from "src/types"
import {
  createStartedAtSelector,
  createEventQueryObject,
  createEntryQueryObject,
  mergeSortedArrays,
} from "src/utils"

function compareItems(
  itemA: ReadOnlyUserEvent | ReadOnlyUserEntry,
  itemB: ReadOnlyUserEvent | ReadOnlyUserEntry,
): number {
  if (itemA.started_at < itemB.started_at) {
    return 1
  } if (itemA.started_at > itemB.started_at) {
    return -1
  }

  return 0
}

// TODO exclude all continuous event types here when we define those
const eventsSelector: MangoQuerySelector<UserEvent> = {
  event_type: { $nin: [ActivityType.Glucose] },
}

type ReturnValue = Array<ReadOnlyUserEvent | ReadOnlyUserEntry>

/*
 * returns an array of read only user events and entries except for glucose events
 */
export function useSortedNonContinuousItems(
  queryStart: string,
  queryEnd: string,
  limit?: number,
): ReturnValue {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const entriesQueryObject = useMemo(
    () => createEntryQueryObject(
      accountId,
      {
        selector: createStartedAtSelector(
          queryStart,
          queryEnd,
        ),
        limit,
      },
    ),
    [
      accountId,
      queryStart,
      queryEnd,
      limit,
    ],
  )

  const { result: entries } = useFindUserEntries(entriesQueryObject)

  const eventsQueryObject = useMemo(
    () => createEventQueryObject(
      accountId,
      {
        selector: {
          ...eventsSelector,
          ...createStartedAtSelector(
            queryStart,
            queryEnd,
          ),
        },
        limit,
      },
    ),
    [
      accountId,
      queryStart,
      queryEnd,
      limit,
    ],
  )

  const { result: events } = useFindUserEvents(eventsQueryObject)

  return useMemo(
    () => {
      if (!entries.length) {
        return events
      }

      if (!events.length) {
        return entries
      }

      return mergeSortedArrays<ReadOnlyUserEvent | ReadOnlyUserEntry>(
        entries,
        events,
        compareItems,
      )
    },
    [
      entries,
      events,
    ],
  )
}
