import { usePolyglot } from "src/contexts"
import type { FoodSubEntry } from "src/types"

const polyglotPrefix = "components.favorite_summary.message.food."

interface Props {
  subEntry: FoodSubEntry;
}

export function FoodMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  return (
    <>
      {subEntry.food.title || polyglot.t(`${polyglotPrefix}fallback`)}
    </>
  )
}
