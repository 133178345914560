import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStatus,
} from "@floating-ui/react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"

import type { CreationOverlayContextValue } from "./CreationOverlayContext"
import type { CreationOverlayPublicContextValue } from "./CreationOverlayPublicContext"

export interface Options {
  transitionMs?: number;
}

export function useCreationOverlay({
  transitionMs = 200,
}: Options): [CreationOverlayContextValue, CreationOverlayPublicContextValue] {
  const [
    labelId,
    setLabelId,
  ] = useState<string | undefined>()

  const [
    descriptionId,
    setDescriptionId,
  ] = useState<string | undefined>()

  const [
    creationTime,
    setCreationTime,
  ] = useState<string | undefined>()

  const [
    colorScheme,
    setColorScheme,
  ] = useState<"dark" | "light">("light")

  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        // this value describes if the current theme is considered dark or light
        const newColorScheme = getComputedStyle(document.body).getPropertyValue("--color-scheme") === "dark"
          ? "dark"
          : "light"
        setColorScheme(newColorScheme)
        setCreationTime(undefined)
      }

      setIsOpen(newOpen)
    },
    [
      setIsOpen,
      setColorScheme,
      setCreationTime,
    ],
  )

  const data = useFloating({
    open: isOpen,
    onOpenChange,
  })

  const context = data.context

  const transitionData = useTransitionStatus(
    context,
    { duration: transitionMs },
  )

  const clickInteraction = useClick(context)

  const dismissInteraction = useDismiss(
    context,
    // eager dismiss on mouse input, lazy on touch input
    { outsidePressEvent: "mousedown" },
  )

  const roleInteraction = useRole(context)

  const interactions = useInteractions([
    clickInteraction,
    dismissInteraction,
    roleInteraction,
  ])

  const openCreationOverlay = useCallback(
    (time?: string) => {
      onOpenChange(true)
      setCreationTime(time)
    },
    [
      onOpenChange,
      setCreationTime,
    ],
  )

  return useMemo(
    () => [
      {
        open: isOpen,
        setOpen: onOpenChange,
        ...data,
        ...interactions,
        ...transitionData,
        descriptionId,
        labelId,
        setDescriptionId,
        setLabelId,
        transitionMs,
        context,
        colorScheme,
        creationTime,
      },
      { openCreationOverlay },
    ],
    [
      creationTime,
      openCreationOverlay,
      data,
      descriptionId,
      interactions,
      isOpen,
      labelId,
      setDescriptionId,
      setLabelId,
      onOpenChange,
      transitionData,
      transitionMs,
      context,
      colorScheme,
    ],
  )
}
