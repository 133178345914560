import type { ExerciseActivity } from "src/types"
import {
  ExerciseIntensity,
  ExerciseType,
} from "src/types"

export const defaultExerciseActivity: ExerciseActivity = {
  intensity: ExerciseIntensity.None,
  exercise_type: ExerciseType.None,
}
