import {
  ActionSubmit,
  Dialog,
  DialogBody,
  DialogButton,
  DialogButtons,
  DialogCloseX,
  DialogContentForm,
  DialogHeading,
  Input,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  ReactNode,
  ChangeEventHandler,
  FormEventHandler,
} from "react"
import {
  useCallback,
  useState,
  useMemo,
} from "react"

import type { EditableEntryTemplate } from "../EditableEntryTemplate"
import styles from "./EditFavoriteDialog.module.scss"
import { EditFavoriteDialogContext } from "./EditFavoriteDialogContext"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import { usePolyglot } from "src/contexts"
import {
  useEditableType,
  useUpdateEntryTemplate,
} from "src/hooks"
import {
  EditFavoriteErrorToast,
  EditFavoriteSuccessToast,
} from "src/toasts"
import { injectProps } from "src/utils"

const polyglotPrefix = "components.favorites_modal.edit_favorite_dialog."

interface Props {
  children: ReactNode;
}

export function EditFavoriteDialog(props: Props) {
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const updateEntryTemplate = useUpdateEntryTemplate()

  const {
    current: currentEntryTemplate,
    hasChanged: entryTemplateHasChanged,
    save: resetEntryTemplate,
    update: editEntryTemplate,
  } = useEditableType<EditableEntryTemplate>({
    account_id: -1,
    created_at: "",
    deleted_at: "",
    id: "",
    last_used_at: "",
    subentries: [],
    title: "",
    updated_at: "",
  })

  const openDialog = useCallback(
    (newEntryTemplate: EditableEntryTemplate) => {
      resetEntryTemplate(newEntryTemplate)
      setIsOpen(true)
    },
    [
      setIsOpen,
      resetEntryTemplate,
    ],
  )

  const closeDialog = useCallback(
    () => {
      setIsOpen(false)
    },
    [setIsOpen],
  )

  const contextValue = useMemo(
    () => ({
      openEditFavoriteDialog: openDialog,
    }),
    [openDialog],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (newOpen) {
        return
      }

      if (entryTemplateHasChanged) {
        setIsDiscardOpen(true)
      } else {
        setIsOpen(false)
      }
    },
    [
      setIsOpen,
      setIsDiscardOpen,
      entryTemplateHasChanged,
    ],
  )

  const onTitleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      editEntryTemplate({
        ...currentEntryTemplate,
        title: e.target.value,
      })
    },
    [
      currentEntryTemplate,
      editEntryTemplate,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          await updateEntryTemplate({
            id: currentEntryTemplate.id,
            title: currentEntryTemplate.title,
          })

          const SuccessToastComponent = injectProps(
            EditFavoriteSuccessToast,
            { title: currentEntryTemplate.title },
          )

          showToast(
            "edit-favorite-success-toast",
            SuccessToastComponent,
          )

          closeDialog()
        } catch (e) {
          const ErrorToastComponent = injectProps(
            EditFavoriteErrorToast,
            { title: currentEntryTemplate.title },
          )

          showToast(
            "edit-favorite-error-toast",
            ErrorToastComponent,
          )
        }
      })()
    },
    [
      currentEntryTemplate,
      updateEntryTemplate,
      closeDialog,
    ],
  )

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={onOpenChange}
      >
        <EditFavoriteDialogContext.Provider value={contextValue}>
          {props.children}
        </EditFavoriteDialogContext.Provider>
        {currentEntryTemplate && (
          <DialogContentForm onSubmit={onSubmit}>
            <DialogHeading>
              {polyglot.t(`${polyglotPrefix}header`)}
              <DialogCloseX />
            </DialogHeading>
            <DialogBody className={styles.dialogBody}>
              <Input
                type="text"
                placeholder={polyglot.t(`${polyglotPrefix}placeholder`)}
                value={currentEntryTemplate.title}
                onChange={onTitleChange}
                required
              />
            </DialogBody>
            <DialogButtons>
              <DialogButton stylePreset="secondary">
                {polyglot.t(`${polyglotPrefix}cancel_cta`)}
              </DialogButton>
              <ActionSubmit
                value={polyglot.t(`${polyglotPrefix}confirm_cta`)}
                className={styles.dialogMultiButton}
                disabled={!entryTemplateHasChanged}
              />
            </DialogButtons>
            <DiscardChangesDialog
              open={isDiscardOpen}
              setOpen={setIsDiscardOpen}
              discardChanges={closeDialog}
            />
          </DialogContentForm>
        )}
      </Dialog>
    </>
  )
}
