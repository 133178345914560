import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import {
  useUpdateSettings,
  useDeviceSettingsProperty,
} from "src/hooks/settings"
import type { Insight } from "src/types"

export function useUpdateInsights() {
  const insights = useDeviceSettingsProperty("gauges")
  const updateSettings = useUpdateSettings()

  const updateInsight = useCallback(
    (newInsights: Array<Insight>): Promise<AxiosResponse> => {
      const data = {
        gauges: newInsights,
      }

      return updateSettings(data)
    },
    [
      insights,
      updateSettings,
    ],
  )

  return updateInsight
}
