import classNames from "classnames"
import {
  useCallback,
  useEffect,
} from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList } from "react-window"

import { useTabContext } from "../TabContext"
import { useItemContext } from "./ItemContext"
import { useReferencesContext } from "./ReferencesContext"
import { useScrollPositionContext } from "./ScrollPositionContext"
import styles from "./VirtualList.module.scss"
import { VirtualListItem } from "./VirtualListItem"
import { Tab } from "src/types"
import type {
  ReactWindowAutoSizerArgs,
  ReactWindowOnItemsRenderedArgs,
  ReactWindowOnScrollArgs,
} from "src/types"

export interface Props {
  scrollToId?: string;
  onScrollChange?: (time: string) => void;
}

/*
 * each item in the virtual list represents all of the entries and events for a specific day
 */
export function VirtualList(props: Props) {
  const {
    scrollToId,
    onScrollChange,
  } = props

  const { currentTab } = useTabContext()
  const { listElementRef } = useReferencesContext()

  const {
    setScrollPosition,
    scrollPositionRef,
    setVisibleIndices,
    visibleIndicesRef,
  } = useScrollPositionContext()

  const {
    itemCount,
    getIndexSize,
    getIndexId,
    getIndexItem,
    getIdIndexRef,
    defaultSize,
  } = useItemContext()

  // every time scrollToId changes days, scroll to the designated day
  useEffect(
    () => {
      if (scrollToId === undefined) {
        return
      }

      if (!listElementRef.current) {
        return
      }

      if (scrollToId === "top") {
        listElementRef.current.scrollToItem(
          0,
          "start",
        )
      }

      const newIndex = getIdIndexRef.current(scrollToId)
      const currentIndex = visibleIndicesRef.current[0]

      if (newIndex === currentIndex) {
        return
      }

      listElementRef.current.scrollToItem(
        newIndex,
        "start",
      )
    },
    [
      scrollToId,
      getIdIndexRef,
      listElementRef,
      visibleIndicesRef,
    ],
  )

  // when the first item changes, call onScrollChange
  const onItemsRendered = useCallback(
    ({
      visibleStartIndex,
      visibleStopIndex,
    }: ReactWindowOnItemsRenderedArgs) => {
      setVisibleIndices([
        visibleStartIndex,
        visibleStopIndex,
      ])

      const item = getIndexItem(visibleStartIndex)

      // scroll graph to the first visible feed item unless the user is at the
      // top of the feed
      if (item && scrollPositionRef.current !== 0) {
        if (item.created_at) {
          // scroll to the first visible entry or event
          onScrollChange?.(item.started_at)
        } else {
          // scroll to "now" if the first visible feed item is "today" with no events or entries
          onScrollChange?.("now")
        }
      }
    },
    [
      setVisibleIndices,
      onScrollChange,
      getIndexItem,
      scrollPositionRef,
    ],
  )

  const onScroll = useCallback(
    ({ scrollOffset }: ReactWindowOnScrollArgs) => {
      setScrollPosition(scrollOffset)

      // scroll graph to "now" when the user is at the top of the feed
      if (scrollOffset === 0) {
        onScrollChange?.("now")
      }
    },
    [
      setScrollPosition,
      onScrollChange,
    ],
  )

  // this ref callback is invoked immediately when the list is rendered
  // this tells the list to rerender so that we can account for the updated heights of the rendered items
  const updateList = useCallback(
    (node: VariableSizeList) => {
      if (node) {
        node.resetAfterIndex(
          0,
          true,
        )
      }
      listElementRef.current = node
    },
    [listElementRef],
  )

  const className = classNames(
    styles.container,
    {
      [styles.containerHidden]: currentTab !== Tab.Feed,
    },
  )

  return (
    <div className={className}>
      <AutoSizer>
        {({
          height,
          width,
        }: ReactWindowAutoSizerArgs) => (
          <VariableSizeList
            height={height}
            width={width}
            ref={updateList}
            itemCount={itemCount}
            estimatedItemSize={defaultSize}
            layout="vertical"
            overscanCount={3}
            itemSize={getIndexSize}
            itemKey={getIndexId}
            onItemsRendered={onItemsRendered}
            onScroll={onScroll}
          >
            {VirtualListItem}
          </VariableSizeList>
        )}
      </AutoSizer>
    </div>
  )
}
