import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { post } from "src/api"
import { useOauthAuthorizeContext } from "src/contexts"
import { noticeError } from "src/utils"

interface Response {
  access_token: string;
  refresh_token: string;
  expires_in?: number;
  token_type: string;
  created_at: number;
}

type SignIn = (email: string, password: string) => Promise<AxiosResponse<Response>>

export function useSignIn(): SignIn {
  const {
    p,
    device_type,
  } = useOauthAuthorizeContext()

  return useCallback(
    async (email: string, password: string) => {
      try {
        const response = await post<Response>({
          url: "/oauth/web",
          data: {
            email,
            password,
            p,
            device_type,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "SignIn",
          { email },
        )

        throw e
      }
    },
    [
      device_type,
      p,
    ],
  )
}

