import {
  ActionSubmit,
  Input,
  Label,
  useToasterContext,
  DialogContentForm,
  DialogBody,
  DialogHeading,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import escapeStringRegexp from "escape-string-regexp"
import type {
  ChangeEventHandler,
  FormEventHandler,
} from "react"
import { useCallback } from "react"
import styles from "./ChangePassword.module.scss"
import type { ChangePasswordFormState } from "./ChangePasswordFormState"
import { useChangePassword } from "./useChangePassword"
import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"
import {
  ChangePasswordErrorToast,
  ChangePasswordSuccessToast,
} from "src/toasts"
import { Status } from "src/types"

const currentPasswordId = "current-password-input"
const passwordId = "user-password-input"
const passwordConfirmationId = "user-password-confirmation-input"
const polyglotPrefix = "pages.settings.account.change_password."

interface Props {
  onSave: () => void;
  formState: ChangePasswordFormState;
  setFormState: (f: ChangePasswordFormState) => void;
}

/*
 * this updates the password for a logged in user
 */
export function ChangePasswordForm(props: Props) {
  const {
    onSave,
    formState,
    setFormState,
  } = props

  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const changePassword = useChangePassword()
  const email = useAccountProperty(
    "email",
    "",
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          const response = await changePassword(
            formState.currentPassword,
            formState.newPassword,
          )

          if (response.data.status === Status.Fail) {
            throw new Error("update password request failed")
          }

          showToast(
            "change-password-success-toast",
            ChangePasswordSuccessToast,
          )

          onSave()
        } catch (e) {
          showToast(
            "change-password-error-toast",
            ChangePasswordErrorToast,
          )
        }
      })()
    },
    [
      onSave,
      formState,
      changePassword,
      showToast,
    ],
  )

  const onCurrentPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        currentPassword: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onNewPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        newPassword: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onNewPasswordConfirmationChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        newPasswordConfirmation: e.target.value,
      })
    },
    [
      formState,
      setFormState,
      polyglot,
    ],
  )

  // this pattern will match an empty value or a value that exactly matches the password field
  const passwordConfirmationPattern = `^(${escapeStringRegexp(formState.newPassword)})?$`

  return (
    <DialogContentForm onSubmit={onSubmit}>
      <DialogHeading>
        {polyglot.t(`${polyglotPrefix}heading`)}
        <DialogCloseX />
      </DialogHeading>
      <DialogBody className={styles.dialogBody}>
        {/* password forms should have username fields for accessibility */}
        <input
          value={email}
          autoComplete="email"
          readOnly
          hidden
        />
        <div className={styles.inputGroup}>
          <Label
            htmlFor={currentPasswordId}
            className={styles.label}
            sizePreset="small"
          >
            {polyglot.t(`${polyglotPrefix}current_password.label`)}
          </Label>
          <Input
            id={currentPasswordId}
            placeholder={polyglot.t(`${polyglotPrefix}current_password.placeholder`)}
            className={styles.input}
            type="password"
            value={formState.currentPassword}
            onChange={onCurrentPasswordChange}
            autoComplete="current-password"
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <Label
            htmlFor={passwordId}
            className={styles.label}
            sizePreset="small"
          >
            {polyglot.t(`${polyglotPrefix}new_password.label`)}
          </Label>
          <ValidationContainer>
            <Input
              id={passwordId}
              placeholder={polyglot.t(`${polyglotPrefix}new_password.placeholder`)}
              className={styles.input}
              type="password"
              value={formState.newPassword}
              onChange={onNewPasswordChange}
              autoComplete="new-password"
              minLength={6}
              required
            />
            <ValidationMessage
              invalidMessage={{
                valueMissing: polyglot.t("validation_message.value_missing"),
                tooShort: polyglot.t("validation_message.password_too_short"),
              }}
            />
          </ValidationContainer>
        </div>
        <div className={styles.inputGroup}>
          <Label
            htmlFor={passwordConfirmationId}
            className={styles.label}
            sizePreset="small"
          >
            {polyglot.t(`${polyglotPrefix}new_password_confirmation.label`)}
          </Label>
          <ValidationContainer>
            <Input
              id={passwordConfirmationId}
              placeholder={polyglot.t(`${polyglotPrefix}new_password_confirmation.placeholder`)}
              className={styles.input}
              type="password"
              value={formState.newPasswordConfirmation}
              onChange={onNewPasswordConfirmationChange}
              pattern={passwordConfirmationPattern}
              autoComplete="new-password"
              required
            />
            <ValidationMessage
              invalidMessage={{
                valueMissing: polyglot.t("validation_message.value_missing"),
                patternMismatch: polyglot.t("validation_message.password_mismatch"),
              }}
            />
          </ValidationContainer>
        </div>
        <DialogButtons className={styles.dialogButtons}>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
          <ActionSubmit
            value={polyglot.t(`${polyglotPrefix}submit_cta`)}
            stylePreset="primary"
            className={styles.dialogMultiButton}
          />
        </DialogButtons>
      </DialogBody>
    </DialogContentForm>
  )
}
