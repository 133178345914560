import type { ReactNode } from "react"
import {
  useCallback,
  useState,
  useEffect,
} from "react"
import { useIsMounted } from "usehooks-ts"

import { defaultPolyglot } from "./defaultPolyglot"
import { getLocaleMatch } from "./getLocaleMatch"
import { loadPolyglotLocale } from "./loadPolyglotLocale"
import type { PolyglotContextValue } from "./PolyglotContext"
import { PolyglotContext } from "./PolyglotContext"
import { Locale } from "src/types"

interface Props {
  children: ReactNode;
}

export function PolyglotProvider(props: Props) {
  const isMounted = useIsMounted()

  const [
    polyglot,
    setPolyglot,
  ] = useState<PolyglotContextValue>(defaultPolyglot)

  const setupPolyglot = useCallback(
    async () => {
      const locale = getLocaleMatch()

      let newPolyglot = await loadPolyglotLocale(locale)

      if (!newPolyglot) {
        newPolyglot = await loadPolyglotLocale(Locale.English)
      }

      if (!newPolyglot) {
        newPolyglot = defaultPolyglot
      }

      if (isMounted()) {
        setPolyglot(newPolyglot)
      }
    },
    [
      setPolyglot,
      isMounted,
    ],
  )

  useEffect(
    () => {
      void setupPolyglot()
    },
    [setupPolyglot],
  )

  return (
    <PolyglotContext.Provider value={polyglot}>
      {props.children}
    </PolyglotContext.Provider>
  )
}
