import dayjs from "dayjs"
import { useMemo } from "react"
import type { MangoQuery } from "rxdb"
import { useXAxisContext } from "../XAxisContext"
import { useAccountProperty } from "src/hooks"
import {
  createStartedAtSelector,
  createQueryObject,
} from "src/utils"

interface QueryInterface {
  started_at: string;
  deleted_at: string | null;
}

export function useCellSelector(cellStart: string): MangoQuery<QueryInterface> {
  const { minutesPerCell } = useXAxisContext()
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  return useMemo(
    () => {
      const cellStop = dayjs(cellStart)
        .subtract(
          minutesPerCell,
          "minutes",
        )
        .toISOString()

      // note start and stop are flipped here
      return createQueryObject(
        accountId,
        {
          selector: createStartedAtSelector(
            cellStop,
            cellStart,
          ),
          sort: [{ started_at: "asc" as const }],
        },
      )
    },
    [
      accountId,
      cellStart,
      minutesPerCell,
    ],
  )
}
