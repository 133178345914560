import escapeStringRegexp from "escape-string-regexp"
import { useMemo } from "react"
import { useFindEntryTemplates } from "./useFindEntryTemplates"
import { useAccountProperty } from "src/hooks/me"
import { createEntryTemplateQueryObject } from "src/utils"

export function useSearchEntryTemplates(query: string) {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createEntryTemplateQueryObject(
      accountId,
      {
        selector: {
          title: {
            $regex: new RegExp(
              `.*${escapeStringRegexp(query)}.*`,
              "i",
            ),
          },
        },
        // TODO if we want case insensitive sorting need to save lowercase version of title in db
        sort: [{ title: "asc" as const }],
      },
    ),
    [
      accountId,
      query,
    ],
  )

  return useFindEntryTemplates(queryObject)
}
