import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import type { SetAuthCookie } from "./useAuthCookieState"
import { post } from "src/api"
import { noticeError } from "src/utils"

interface Response {
  access_token: string;
  refresh_token: string;
  expires_in?: number;
  token_type: string;
  created_at: number;
}

export type SignIn = (email: string, password: string) => Promise<AxiosResponse<Response>>

export function useSignIn(setAuthCookie: SetAuthCookie): SignIn {
  return useCallback(
    async (email: string, password: string) => {
      try {
        const response = await post<Response>({
          url: "/oauth/web",
          data: {
            email,
            password,
          },
        })

        setAuthCookie(response.data)

        return response
      } catch (e) {
        noticeError(
          e,
          "SignIn",
          { email },
        )

        throw e
      }
    },
    [setAuthCookie],
  )
}

