import type { CSSProperties } from "react"
import { useMemo } from "react"
import styles from "./Cell.module.scss"
import { CellContent } from "./CellContent"

interface Props {
  index: number;
  style: CSSProperties;
}

/*
 * this component just positions the cell and memoizes its children
 */
export function Cell(props: Props) {
  const {
    index,
    style,
  } = props

  const children = useMemo(
    () => (
      <CellContent
        index={index}
        className={styles.content}
      />
    ),
    [index],
  )

  return (
    <div
      className={styles.container}
      style={style}
    >
      {children}
    </div>
  )
}
