import dayjs from "dayjs"
import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import { useEventsAndSubEntriesOfType } from "src/hooks"
import { ActivityType } from "src/types"

export function useExerciseDayMetric(dayStart: string): number {
  const daySelector = useDaySelector(dayStart)

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: exerciseEvents,
    subEntries: exerciseSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Exercise,
    daySelector,
  )

  const [
    exerciseTotal,
    setExerciseTotal,
  ] = useState<number>(NaN)

  useEffect(
    () => {
      if (isFetchingEvents || isFetchingEntries) {
        return
      }

      let totalInMinutes = 0

      exerciseEvents.forEach(
        (exerciseEvent) => {
          const durationInMinutes = dayjs(exerciseEvent.started_at).diff(
            exerciseEvent.ended_at,
            "minute",
            true,
          )
          totalInMinutes += durationInMinutes
        },
      )

      exerciseSubEntries.forEach(
        (subEntry) => {
          if (subEntry.duration_in_seconds) {
            totalInMinutes += subEntry.duration_in_seconds / 60
          }
        },
      )

      setExerciseTotal(totalInMinutes)
    },
    [
      isFetchingEvents,
      isFetchingEntries,
      setExerciseTotal,
      exerciseEvents,
      exerciseSubEntries,
    ],
  )

  return exerciseTotal
}
