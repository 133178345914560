import { useMergeRefs } from "@floating-ui/react"
import type {
  Ref,
  ComponentPropsWithoutRef,
  HTMLProps,
  MutableRefObject,
} from "react"

import {
  cloneElement,
  forwardRef,
  isValidElement,
} from "react"

import { useNavigationMenuContext } from "./useNavigationMenuContext"

type Props = ComponentPropsWithoutRef<"button"> & { asChild?: boolean }

// if asChild is true then the ref could be to any kind of html element
type TriggerElement = HTMLButtonElement | HTMLElement

/*
 * this component renders a button that will open the nav menu when clicked
 */
export const NavigationMenuTrigger = forwardRef<TriggerElement, Props>(
  function NavigationMenuTrigger(props: Props, propRef: Ref<TriggerElement>) {
    const {
      children,
      asChild = false,
      ...restProps
    } = props

    const state = useNavigationMenuContext()

    const ref = useMergeRefs([
      state.refs.setReference,
      propRef,
    ])

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const childrenRef: MutableRefObject<any> = (children as any)?.ref
    const refWithChildrenRef = useMergeRefs([
      ref,
      childrenRef,
    ])

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        state.getReferenceProps({
          ref: refWithChildrenRef,
          ...restProps,
          ...children.props,
          "data-state": state.open ? "open" : "closed",
        } as HTMLProps<HTMLElement>),
      )
    }

    return (
      <button
        ref={ref}
        // The user can style the trigger based on the state
        data-state={state.open ? "open" : "closed"}
        {...state.getReferenceProps(restProps)}
      >
        {children}
      </button>
    )
  },
)
