import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileValue } from "./TileValue"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseFluxGrade(props: Props) {
  const { range } = props

  const { glucoseCalculations } = useCalculationsContext()
  const { fluxGrade } = glucoseCalculations[range] || {}

  const value = fluxGrade || "--"

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseFluxGrade} />
      <TileTimeSpan range={range} />
      <TileValue>
        {value}
      </TileValue>
    </>
  )
}
