import { useMemo } from "react"
import { useGetGlucoseValue } from "./useGetGlucoseValue"
import { useGlucoseUnit } from "src/hooks/settings"
import { GlucoseUnit } from "src/types"
import { round } from "src/utils"

interface InputProps {
  min: number;
  max: number;
  step: number;
}

/*
 * pass mg/dL properties and it will return either those same properties, or those proeprties scaled for mmol/L
 */
export function useGlucoseInputProps(props: InputProps): InputProps {
  const glucoseUnit = useGlucoseUnit()
  const getGlucoseValue = useGetGlucoseValue()

  return useMemo(
    () => ({
      // min and max should always be whole numbers for glucose
      min: round(getGlucoseValue(props.min)),
      // min and max should always be whole numbers for glucose
      max: round(getGlucoseValue(props.max)),
      // step is scaled
      step: glucoseUnit === GlucoseUnit.MillimolesPerLiter
        ? props.step / 10
        : props.step,
    }),
    [
      props,
      glucoseUnit,
      getGlucoseValue,
    ],
  )
}
