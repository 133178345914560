import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { defaultInsulinActivity } from "./defaultInsulinActivity"
import { usePolyglot } from "src/contexts"
import { useAccountInsulins } from "src/hooks"
import type {
  AccountInsulin,
  InsulinActivity,
} from "src/types"
import { insulinPrecisionToUnits } from "src/utils"

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const polyglotPrefix = "components.entry_modal.insulin.name."
const defaultAccountInsulinId = defaultInsulinActivity.insulin_med.account_insulin_id

export function useNameSelectOptions(activity: Partial<InsulinActivity>): Array<Option> {
  const polyglot = usePolyglot()
  const { result: accountInsulins } = useAccountInsulins()
  const activityAccountInsulinId = activity.insulin_med?.account_insulin_id

  // calculate the options for the select menu
  const selectOptions: Array<Option> = useMemo(
    () => {
      // the default option
      const defaultOption: Option = {
        value: "",
        children: polyglot.t(`${polyglotPrefix}placeholder`),
        disabled: true,
      }

      let activityHasMatch = activityAccountInsulinId === defaultAccountInsulinId
      const optionsArray: Array<Option> = []

      accountInsulins.forEach((accountInsulin: DeepReadonlyObject<AccountInsulin>) => {
        const insulinName = accountInsulin.name_override
          ?? accountInsulin.insulin.name
          ?? polyglot.t(`${polyglotPrefix}name_fallback`)

        const precision = polyglot.t(
          "unit.num_units_abbreviated",
          { units: insulinPrecisionToUnits(accountInsulin.precision).toLocaleString(navigator.language) },
        )

        const insulinString = polyglot.t(
          `${polyglotPrefix}insulin_string`,
          {
            name: insulinName,
            precision,
          },
        )

        optionsArray.push({
          value: accountInsulin.id,
          // TODO we are not yet localizing insulin names
          children: insulinString,
        })

        // TODO all ids will be strings in the future
        if (accountInsulin.id === `${activityAccountInsulinId}`) {
          activityHasMatch = true
        }
      })

      // if the insulin activity is not included in the current account
      // insulins then add it as a disabled option
      if (!activityHasMatch && activity.insulin_med) {
        const insulinName = activity.insulin_med.name

        const precision = polyglot.t(
          "unit.num_units_abbreviated",
          { units: insulinPrecisionToUnits(activity.insulin_med.precision).toLocaleString(navigator.language) },
        )

        const insulinString = polyglot.t(
          `${polyglotPrefix}insulin_string`,
          {
            name: insulinName,
            precision,
          },
        )

        optionsArray.push({
          value: `${activity.insulin_med.account_insulin_id}`,
          children: insulinString,
          disabled: true,
        })
      }

      return [
        defaultOption,
        ...optionsArray,
      ]
    },
    [
      activityAccountInsulinId,
      accountInsulins,
      polyglot,
    ],
  )

  return selectOptions
}
