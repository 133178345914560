import {
  createContext,
  useContext,
} from "react"
import type { SourceType } from "src/types"

export interface NoNewReadingsDialogContextValue {
  openNoNewReadingsDialog: (sourceType: SourceType.Nightscout | SourceType.DexcomRest) => void;
}

type ContextType = NoNewReadingsDialogContextValue | null

export const NoNewReadingsDialogContext = createContext<ContextType>(null)

export function useNoNewReadingsDialogContext() {
  const context = useContext(NoNewReadingsDialogContext)

  if (context === null) {
    throw new Error("NoNewReadingsDialog components must be wrapped in <NoNewReadingsDialogContext />")
  }

  return context
}
