import { useMergeRefs } from "@floating-ui/react"
import type {
  RefCallback,
  Ref,
} from "react"
import {
  useRef,
  useCallback,
} from "react"
import { useEventListener } from "usehooks-ts"

export function useOnMountAndResize<T extends HTMLElement>(
  callback: (element: T | null) => void,
): Ref<T> {
  const ref = useRef<T>(null)

  const refCallback: RefCallback<T> = useCallback(
    (node) => {
      callback(node)
    },
    [callback],
  )

  const onResize = useCallback(
    () => {
      callback(ref.current)
    },
    [
      ref,
      callback,
    ],
  )

  useEventListener(
    "resize",
    onResize,
  )

  return useMergeRefs([
    ref,
    refCallback,
  ])
}
