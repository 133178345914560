import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import type {
  ReadOnlySubEntry,
  ReadOnlyUserEvent,
} from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  item: ReadOnlySubEntry | ReadOnlyUserEvent;
}

export const NoteActivityTitle = forwardRef<HTMLDivElement, Props>(
  function NoteActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      item,
      ...htmlDivProps
    } = props

    if (!item.note) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {props.item.note}
      </div>
    )
  },
)
