import type { AxiosRequestConfig } from "axios"
import type { AxiosFunction } from "src/types"

type ResponseDataFunction<T> = (config: AxiosRequestConfig) => Promise<T>

// this returns a function that executes axiosFunction and returns only the
// response body, not any of the other info from the response
export function responseData<T>(
  axiosFunction: AxiosFunction,
): ResponseDataFunction<T> {
  const responseDataFunction = async (config: AxiosRequestConfig) => {
    const axiosResponse = await axiosFunction<T>(config)
    return axiosResponse.data
  }

  return responseDataFunction
}
