import { AccountInsulins } from "./AccountInsulins"
import { AccountMedications } from "./AccountMedications"
import styles from "./InsulinAndMedication.module.scss"
import {
  AccountInsulinModal,
  AccountMedicationModal,
} from "src/components"

export function InsulinAndMedication() {
  return (
    <div className={styles.container}>
      <AccountInsulinModal>
        <AccountInsulins />
      </AccountInsulinModal>
      <AccountMedicationModal>
        <AccountMedications />
      </AccountMedicationModal>
    </div>
  )
}
