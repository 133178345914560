import type { RxJsonSchema } from "rxdb"
import { activitySchema } from "./activitySchema"
import {
  dateTimeSchema,
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./newSharedSchema"
import type { UserEntry } from "src/types"

// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const userEntrySchema: RxJsonSchema<UserEntry> = {
  title: "user_entry_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    account_id: {
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
      multipleOf: 1,
      type: "number",
    },
    started_at: { ...dateTimeSchema },
    ended_at: { ...dateTimeSchema },
    duration_in_seconds: { type: "number" },
    entry_template_id: { type: "string" },
    updated_by_webhook: { type: "boolean" },
    pg_id: { type: "string" },
    subentries: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "object",
        properties: {
          _id: { type: "string" },
          subentry_type: {
            type: "string",
            maxLength: 128,
          },
          image_id: { type: "string" },
          image_url: { type: "string" },
          thumbnail: { type: "string" },
          note: {
            type: "string",
            maxLength: 2500,
          },
          duration_in_seconds: { type: "number" },
          export_source_id: { type: "string" },
          export_source: { type: "string" },
          pg_id: { type: "string" },
          ...activitySchema,
        },
        required: [
          "_id",
          "subentry_type",
          "pg_id",
        ],
      },
    },
    ...sharedSchemaProperties,
  },
  required: [
    ...sharedSchemaRequired,
    "account_id",
    "ended_at",
    "started_at",
    "subentries",
  ],
  indexes: [
    ...sharedSchemaIndexes,
    [
      "account_id",
      "deleted_at",
    ],
    [
      "account_id",
      "deleted_at",
      "started_at",
    ],
    // index subentries by subentry_type ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      "started_at",
    ],
    */
    // index glucose subentries glucose value ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      // "subentries.glucose.mg_dl", this doesn't work
      "started_at",
    ],
    */
    // index insulin subentries on insulin_med.is_basal ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      // "subentries.insulin.insulin_med.is_basal", this doesn't work
      "started_at",
    ],
    */
    // index for subentry_type and started_at ( sorted by glucose value )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      "started_at",
      // "subentries.glucose.mg_dl", this doesn't work
    ],
    */
    // index subentries by subentry_type and note ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      // "subentries.note", this doesn't work
      "started_at",
    ],
    */
    // index liquid subentries on liquid type ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      // "subentries.subentry_type", this doesn't work
      // "subentries.liquid.liquid_type", this doesn't work
      "started_at",
    ],
    */
  ],
}
