import type { ReactNode } from "react"
import { useMemo } from "react"

import { graphRangeToUnitsPerMarker } from "./graphRangeToUnitsPerMarker"
import { YAxisContext } from "./YAxisContext"
import {
  useDeviceSettingsProperty,
  useGetGlucoseValue,
  useSetGlucoseValue,
} from "src/hooks"

interface Props {
  children: ReactNode;
}

export function YAxisContextProvider(props: Props) {
  const min = useDeviceSettingsProperty("chart_bottom")
  const max = useDeviceSettingsProperty("chart_top")

  const getGlucoseValue = useGetGlucoseValue()
  const setGlucoseValue = useSetGlucoseValue()

  // units between yAxis markers measured in mgdl
  const unitsPerMarker = useMemo(
    () => {
      const range = getGlucoseValue(max - min)
      const interval = graphRangeToUnitsPerMarker(range)
      return setGlucoseValue(interval)
    },
    [
      min,
      max,
    ],
  )

  // where to draw yAxis markings measured in mgdl
  const unitMarkings = useMemo(
    () => {
      const labels: number[] = [
        min,
        max,
      ]
      const lowestLabelIndex = Math.ceil(min / unitsPerMarker)

      for (let i = lowestLabelIndex; i * unitsPerMarker <= max; i++) {
        const value = i * unitsPerMarker

        if (value !== min && value !== max) {
          labels.push(value)
        }
      }
      return labels
    },
    [
      min,
      max,
      unitsPerMarker,
    ],
  )

  const value = useMemo(
    () => ({
      min,
      max,
      unitsPerMarker,
      unitMarkings,
    }),
    [
      min,
      max,
      unitsPerMarker,
      unitMarkings,
    ],
  )

  return (
    <YAxisContext.Provider value={value}>
      {props.children}
    </YAxisContext.Provider>
  )
}
