import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { useUserPost } from "src/hooks/userApi"
import {
  fileToDataURL,
  noticeError,
} from "src/utils"

interface UploadImageResponse {
  image_id: string;
  image_url: string;
  thumbnail: string;
  status: string;
}

type UploadImage = (file: File) => Promise<AxiosResponse<UploadImageResponse>>

export function useUploadImage(): UploadImage {
  const userPost = useUserPost()
  const url = "/api/v2/upload_photo"

  const uploadImage = useCallback(
    async (file: File) => {
      try {
        if (!userPost) {
          throw new Error("cannot upload image for unauthenticated user")
        }

        const dataUrl = await fileToDataURL(file)

        const response = await userPost<UploadImageResponse>({
          url,
          data: {
            imgBase64: dataUrl,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "CreateImage",
        )

        throw e
      }
    },
    [
      userPost,
      url,
    ],
  )

  return uploadImage
}
