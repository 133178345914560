import type { CSSProperties } from "react"
import { useMemo } from "react"
import { VirtualListItemContent } from "./VirtualListItemContent"

interface Props {
  index: number;
  style: CSSProperties;
}

/*
 * this component just positions the item and memoizes its children
 */
export function VirtualListItem(props: Props) {
  const {
    index,
    style,
  } = props

  const children = useMemo(
    () => <VirtualListItemContent index={index} />,
    [index],
  )

  return (
    <div style={style}>
      {children}
    </div>
  )
}
