import { DragHandleIcon } from "@tandemdiabetes/sugarmate-react-components"

import styles from "./Activity.module.scss"
import { ActivityButtonIcon } from "./ActivityButtonIcon"
import { usePolyglot } from "src/contexts"
import type { FeedButton } from "src/types"

interface Props {
  activityType: FeedButton;
}

export function ActivityButton(props: Props) {
  const polyglot = usePolyglot()

  return (
    <>
      <ActivityButtonIcon
        activityType={props.activityType}
        className={styles.activityIcon}
      />
      <div className={styles.activityButtonText}>
        {polyglot.t(`pages.settings.activity.activity_button.${props.activityType}`)}
      </div>
      <DragHandleIcon className={styles.handleIcon} />
    </>
  )
}
