import {
  useCallback,
  useEffect,
  useRef,
} from "react"

import { defaultMedicationActivity } from "./defaultMedicationActivity"
import type {
  AccountMedication,
  EditableSubEntry,
  ActivityType,
  MedicationActivity,
} from "src/types"

export function useOnCurrentAccountMedicationDeleted(
  activity: MedicationActivity,
  updateActivity: (a: MedicationActivity) => void,
  currentAccountMedication: AccountMedication | undefined,
  currentSubEntry: EditableSubEntry<ActivityType.Medication>,
) {
  const previousAccountMedicationRef = useRef<AccountMedication | undefined>(currentAccountMedication)

  // reset the activity if it is a new activity and its account medication is removed
  const resetIfUnsaved = useCallback(
    () => {
      if (!currentSubEntry._id) {
        updateActivity({
          ...activity,
          medication: {
            ...defaultMedicationActivity.medication,
          },
        })
      }
    },
    [
      activity,
      currentSubEntry._id,
    ],
  )

  useEffect(
    () => {
      if (
        !currentAccountMedication &&
        !!previousAccountMedicationRef.current &&
        previousAccountMedicationRef.current.id === `${activity.medication.account_med_id}`
      ) {
        resetIfUnsaved()
      }

      previousAccountMedicationRef.current = currentAccountMedication
    },
    [
      activity,
      resetIfUnsaved,
      currentAccountMedication,
      previousAccountMedicationRef,
    ],
  )
}
