import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import { defaultMedicationActivity } from "./defaultMedicationActivity"
import type {
  AccountMedication,
  MedicationActivity,
} from "src/types"

export function useOnCurrentAccountMedicationUpdated(
  activity: MedicationActivity,
  updateActivity: (a: MedicationActivity) => void,
  currentAccountMedication: AccountMedication | undefined,
) {
  /*
   * this is "null" when the activity is the default medication activity
   * this is "undefined" when the account medication is still being found in the db
   */
  const previousAccountMedicationRef = useRef<AccountMedication | undefined | null>(
    activity.medication.account_med_id === defaultMedicationActivity.medication.account_med_id
      ? null
      : currentAccountMedication,
  )

  // this function updates the activity with the provided account medication
  const updateActivityWithAccountMedication = useCallback(
    (accountMedication: AccountMedication) => {
      updateActivity({
        ...activity,
        medication: {
          account_med_id: Number(accountMedication.id),
          med_id: Number(accountMedication.med_id),
          category: accountMedication.med.category ?? "",
          description: accountMedication.med.description ?? "",
          name: accountMedication.med.name ?? "",
          subcategory: accountMedication.med.subcategory ?? "",
          units: accountMedication.units,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  useEffect(
    () => {
      // if the current account medication is the same as the previous account medication but has been updated
      if (
        !!currentAccountMedication &&
        !!previousAccountMedicationRef.current &&
        previousAccountMedicationRef.current.id === currentAccountMedication.id &&
        previousAccountMedicationRef.current.updated_at !== currentAccountMedication.updated_at
      ) {
        updateActivityWithAccountMedication(currentAccountMedication)

        // if the current account medication is different than the previous account medication
      } else if (
        !!currentAccountMedication &&
        previousAccountMedicationRef.current !== undefined && // only trigger this if the previous account medication is null or defined
        previousAccountMedicationRef.current?.id !== currentAccountMedication.id
      ) {
        updateActivityWithAccountMedication(currentAccountMedication)
      }

      // update the previousAccountMedicationRef
      if (activity.medication.account_med_id === defaultMedicationActivity.medication.account_med_id) {
        // null if the activity has the default medication
        previousAccountMedicationRef.current = null
      } else if (currentAccountMedication !== undefined) {
        // a value only if currentAccountMedication is defined
        previousAccountMedicationRef.current = currentAccountMedication
      }
    },
    [
      activity,
      currentAccountMedication,
      previousAccountMedicationRef,
      updateActivityWithAccountMedication,
    ],
  )
}
