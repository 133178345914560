import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from "react"

import { CalculationsContext } from "./CalculationsContext"
import type { CalculationsContextValue } from "./CalculationsContext"
import { InsightCalculationWorkerComponent } from "./InsightCalculationWorkerComponent"
import { TimeInRangeCalculation } from "./TimeInRangeCalculation"
import { useCalculatorGroupsState } from "./useCalculatorGroupsState"

import {
  UserEventsLoadedComponent,
  UserEntriesLoadedComponent,
} from "src/components"
import {
  useDeviceSettingsProperty,
  useThisMinute,
} from "src/hooks"
import type { Insight } from "src/types"
import { InsightCalculatorGroup } from "src/types"
import { sleepHoursToTime } from "src/utils"
import type {
  CalculationWorkerValueData,
  QuartileCalculationValue,
  EmptyQuartileCalculationValue,
} from "src/workers/insightCalculationWorker"

interface Props {
  insights: Array<Insight>;
  children: ReactNode;
}

export function CalculationsContextProvider(props: Props) {
  const { insights } = props

  const sleepTop = useDeviceSettingsProperty("sleep_top")

  const queryEndTime = useThisMinute()

  const timeSinceWakeUpInHours = useMemo(
    () => {
      const diff = dayjs(queryEndTime).diff(
        sleepHoursToTime(sleepTop),
        "hour",
        true,
      )

      return (diff + 24) % 24
    },
    [
      queryEndTime,
      sleepTop,
    ],
  )

  const [
    exerciseCalculations,
    setExerciseCalculations,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.Exercise]>({})
  const [
    foodCalculations,
    setFoodCalculations,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.Food]>({})
  const [
    glucoseCalculations,
    setGlucoseCalculations,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.Glucose]>({})
  const [
    insulinCalculations,
    setInsulinCalculations,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.Insulin]>({})
  const [
    quartileCalculations,
    setQuartileCalculations,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.Quartile]>({})

  const quartileCalculationsRef = useRef<CalculationsContextValue[InsightCalculatorGroup.Quartile]>(quartileCalculations)

  const updateQuartileCalculations = useCallback(
    (quartileCalculationValue: QuartileCalculationValue | EmptyQuartileCalculationValue) => {
      const currentQuartileCalculationsValue = quartileCalculationsRef.current

      if (quartileCalculationValue[1] === null) {
        // delete calculaiton
        quartileCalculationsRef.current = { ...currentQuartileCalculationsValue }
        delete quartileCalculationsRef.current[quartileCalculationValue[0]]
      } else {
        // update calculaiton
        quartileCalculationsRef.current = {
          ...currentQuartileCalculationsValue,
          [quartileCalculationValue[0]]: quartileCalculationValue[1],
        }
      }

      setQuartileCalculations(quartileCalculationsRef.current)
    },
    [
      quartileCalculationsRef,
      setQuartileCalculations,
    ],
  )

  const [
    timeInRangeCalculation,
    setTimeInRangeCalculation,
  ] = useState<CalculationsContextValue[InsightCalculatorGroup.TimeInRange]>(null)

  const calculatorGroupsState = useCalculatorGroupsState(
    insights,
  )

  const [
    worker,
    setWorker,
  ] = useState<Worker | null>(null)

  const onMessage = useCallback(
    (message: MessageEvent<CalculationWorkerValueData>) => {
      switch (message.data.calculationType) {
        case InsightCalculatorGroup.Glucose:
          setGlucoseCalculations(message.data.value)
          break
        case InsightCalculatorGroup.Exercise:
          setExerciseCalculations(message.data.value)
          break
        case InsightCalculatorGroup.Food:
          setFoodCalculations(message.data.value)
          break
        case InsightCalculatorGroup.Insulin:
          setInsulinCalculations(message.data.value)
          break
        case InsightCalculatorGroup.Quartile:
          updateQuartileCalculations(message.data.value)
          break
      }
    },
    [
      setExerciseCalculations,
      setFoodCalculations,
      setGlucoseCalculations,
      setInsulinCalculations,
      updateQuartileCalculations,
    ],
  )

  useEffect(
    () => {
      const newWorker = new Worker(
        new URL(
          "src/workers/insightCalculationWorker/insightCalculationWorker.ts",
          import.meta.url,
        ),
      )

      setWorker(newWorker)

      newWorker.addEventListener(
        "message",
        onMessage,
      )

      return () => {
        newWorker.removeEventListener(
          "message",
          onMessage,
        )
        newWorker.terminate()
      }
    },
    [
      setWorker,
      onMessage,
    ],
  )

  const value = useMemo(
    () => ({
      queryEndTime,
      timeSinceWakeUpInHours,
      exerciseCalculations,
      foodCalculations,
      glucoseCalculations,
      insulinCalculations,
      quartileCalculations,
      timeInRangeCalculation,
    }),
    [
      queryEndTime,
      timeSinceWakeUpInHours,
      exerciseCalculations,
      foodCalculations,
      glucoseCalculations,
      insulinCalculations,
      quartileCalculations,
      timeInRangeCalculation,
    ],
  )

  return (
    <CalculationsContext.Provider value={value}>
      {props.children}
      <UserEventsLoadedComponent>
        <UserEntriesLoadedComponent>
          {calculatorGroupsState[InsightCalculatorGroup.TimeInRange] && (
            <TimeInRangeCalculation setState={setTimeInRangeCalculation} />
          )}
          {timeSinceWakeUpInHours && worker && (
            <InsightCalculationWorkerComponent
              calculatorGroupsState={calculatorGroupsState}
              queryEndTime={queryEndTime}
              timeSinceWakeUpInHours={timeSinceWakeUpInHours}
              worker={worker}
            />
          )}
        </UserEntriesLoadedComponent>
      </UserEventsLoadedComponent>
    </CalculationsContext.Provider>
  )
}
