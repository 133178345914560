import {
  ModalTrigger,
  IconButton,
  MagnifyingGlassIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { FoodTitleInput } from "./FoodTitleInput"
import { FoodSearchModal } from "src/components/FoodSearchModal"
import styles from "src/styles/entryModal.module.scss"
import type {
  FoodActivity,
  ActivityType,
  EditableSubEntry,
} from "src/types"

interface Props {
  activity: FoodActivity;
  updateActivity: (a: FoodActivity) => void;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Food>>) => void;
}

/*
 * this component renders the food title and the search button wrapped in the
 * food search modal context so that they can open and edit the search
 */
export function FoodTitleRow(props: Props) {
  const {
    activity,
    updateActivity,
    updateSubEntry,
  } = props

  return (
    <FoodSearchModal
      updateSubEntry={updateSubEntry}
      activity={activity}
    >
      <div className={styles.row}>
        <FoodTitleInput
          activity={activity}
          updateActivity={updateActivity}
        />
        <ModalTrigger asChild>
          <IconButton>
            <MagnifyingGlassIcon />
          </IconButton>
        </ModalTrigger>
      </div>
    </FoodSearchModal>
  )
}
