import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import {
  useUpdateSettings,
  useDeviceSettingsProperty,
} from "src/hooks/settings"
import type { Insight } from "src/types"

export function useRemoveInsight() {
  const insights = useDeviceSettingsProperty("gauges")
  const updateSettings = useUpdateSettings()

  const removeInsight = useCallback(
    (insight: Insight): Promise<AxiosResponse> => {
      const data = {
        gauges: insights.filter(
          (i: Insight) => i.key !== insight.key || i.range !== insight.range,
        ),
      }

      return updateSettings(data)
    },
    [
      insights,
      updateSettings,
    ],
  )

  return removeInsight
}
