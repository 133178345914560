import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { FoodActivity } from "src/types"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: FoodActivity;
}

export const FoodActivityData = forwardRef<HTMLDivElement, Props>(
  function FoodActivityData(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    if (activity.carbs === null || activity.carbs === undefined) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {polyglot.t(
          "unit.num_grams",
          {
            grams: round(
              activity.carbs,
              1,
            ).toLocaleString(navigator.language),
          },
        )}
      </div>
    )
  },
)
