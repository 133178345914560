import { useContext } from "react"
import { VirtualListContext } from "./VirtualListContext"
import type { VirtualListContextValue } from "./VirtualListContextValue"

export function useVirtualListContext(): VirtualListContextValue {
  const context = useContext(VirtualListContext)

  if (!context) {
    throw new Error("elements using VirtualListContext must be nested in VirtualListContextProvider")
  }

  return context
}
