/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { AxiosRequestConfig } from "axios"

function mergeParams(
  paramsA: AxiosRequestConfig["params"],
  paramsB: AxiosRequestConfig["params"],
): AxiosRequestConfig["params"] {
  if (paramsA === undefined && paramsB === undefined) {
    return undefined
  }

  const objectA = paramsA instanceof URLSearchParams ?
    Object.fromEntries(paramsA.entries()) :
    (paramsA ?? {})

  const objectB = paramsB instanceof URLSearchParams ?
    Object.fromEntries(paramsB.entries()) :
    (paramsB ?? {})

  return {
    ...objectA,
    ...objectB,
  }
}

/*
 * a function the merges two axios request configs
 */
export function mergeAxiosRequestConfigs(
  ...configs: Array<AxiosRequestConfig>
): AxiosRequestConfig {
  return configs.reduce(
    (
      accumulatedConfig: AxiosRequestConfig,
      currentConfig: AxiosRequestConfig,
    ) => ({
      ...accumulatedConfig,
      ...currentConfig,
      headers: {
        ...accumulatedConfig.headers,
        ...currentConfig.headers,
      },
      params: mergeParams(
        accumulatedConfig.params,
        currentConfig.params,
      ),
    }),
    {},
  )
}
