import {
  IconButton,
  Label,
  EditIcon,
  DialogTrigger,
} from "@tandemdiabetes/sugarmate-react-components"

import styles from "./Account.module.scss"
import { ChangePasswordDialog } from "./ChangePasswordDialog"
import { DeleteAccount } from "./DeleteAccount"
import { Email } from "./Email"
import { LogOut } from "./LogOut"
import { Nickname } from "./Nickname"
import { usePolyglot } from "src/contexts"
import {
  useDebouncedUpdater,
  useUpdateSettings,
} from "src/hooks"

const polyglotPrefix = "pages.settings.account."

export function Account() {
  const polyglot = usePolyglot()

  // TODO catch error and show toast
  const updateSettings = useUpdateSettings()
  const debouncedUpdateSettings = useDebouncedUpdater(updateSettings)

  return (
    <ChangePasswordDialog>
      <div className={styles.container}>
        <Email />
        <Nickname updateSettings={debouncedUpdateSettings} />
        <div className={styles.changePasswordRow}>
          <Label>
            {polyglot.t(`${polyglotPrefix}change_password.label`)}
          </Label>
          <DialogTrigger asChild>
            <IconButton>
              <EditIcon />
            </IconButton>
          </DialogTrigger>
        </div>
        <div className={styles.buttonsContainer}>
          <DeleteAccount />
          <LogOut />
        </div>
      </div>
    </ChangePasswordDialog>
  )
}
