import {
  GearFillIcon,
  IconButton,
  PlusIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"

import { useAccountMedicationModalContext } from "src/components/AccountMedicationModal"
import type { AccountMedication } from "src/types"

interface Props {
  currentAccountMedication?: AccountMedication;
}

export function AccountMedicationButton(props: Props) {
  const { currentAccountMedication } = props
  const { openAccountMedicationModal } = useAccountMedicationModalContext()

  const onClick = useCallback(
    () => {
      openAccountMedicationModal(currentAccountMedication)
    },
    [
      currentAccountMedication,
      openAccountMedicationModal,
    ],
  )

  return (
    <IconButton onClick={onClick}>
      {currentAccountMedication ? <GearFillIcon /> : <PlusIcon />}
    </IconButton>
  )
}
