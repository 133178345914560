import { useCallback } from "react"
import { useGlucoseUnit } from "src/hooks/settings"
import { GlucoseUnit } from "src/types"
import { mmollToMgdl } from "src/utils"


/*
 * returns a function that takes a user readable glucose value and returns that
 * value scaled to the the units stored on the backend
 * to the user's glucose unit
 */
export function useSetGlucoseValue(): (n: number) => number {
  const glucoseUnit = useGlucoseUnit()

  return useCallback(
    (value: number) => {
      if (glucoseUnit === GlucoseUnit.MillimolesPerLiter) {
        return mmollToMgdl(value)
      }

      return value
    },
    [glucoseUnit],
  )
}

