import Cookies from "js-cookie"
import type { ReactNode } from "react"
import {
  useMemo,
  useState,
  useCallback,
} from "react"
import { CookiesContext } from "./CookiesContext"

interface Props {
  children: ReactNode;
}

const dismissedAddSourcePromptCookieKey = "IS_dismissed_suggest_source_add"

// TODO TODO remove csrf token
const csrfTokenKey = "csrf_token"

export function CookiesContextProvider(props: Props) {
  const [csrfToken] = useState<string | undefined>(Cookies.get(csrfTokenKey))

  const [
    dismissedAddSourcePrompt,
    setDismissedAddSourcePromptState,
  ] = useState<boolean>(Cookies.get(dismissedAddSourcePromptCookieKey) === "true")

  const setDismissedAddSourcePrompt = useCallback(
    (dismissed: boolean) => {
      setDismissedAddSourcePromptState(dismissed)
      Cookies.set(
        dismissedAddSourcePromptCookieKey,
        dismissed ? "true" : "false",
      )
    },
    [setDismissedAddSourcePromptState],
  )

  const value = useMemo(
    () => ({
      dismissedAddSourcePrompt,
      setDismissedAddSourcePrompt,
      csrfToken,
    }),
    [
      dismissedAddSourcePrompt,
      setDismissedAddSourcePrompt,
      csrfToken,
    ],
  )

  return (
    <CookiesContext.Provider value={value}>
      {props.children}
    </CookiesContext.Provider>
  )
}
