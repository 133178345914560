import type { AxiosResponse } from "axios"
import { useMemo } from "react"

import { useSources } from "./useSources"
import { useUserPost } from "src/hooks/userApi"
import type { DexcomFollowSource } from "src/types"
import { noticeError } from "src/utils"

interface Response {
  source: DexcomFollowSource;
}

// pass the code from dexcom authorization
type CreateSourceFunction = (code: string) => Promise<AxiosResponse<Response>>

export function useCreateDexcomSource(): CreateSourceFunction | null {
  const { mutate } = useSources()
  const userPost = useUserPost()

  const createSource: CreateSourceFunction | null = useMemo(
    () => userPost && (
      async (code) => {
        const url = "/api/v3/sources/dexcom/callback"

        try {
          const axiosResponse = await userPost<Response>({
            url,
            data: {
              code,
            },
          })

          void mutate()
          return axiosResponse
        } catch (e) {
          noticeError(
            e,
            "CreateDexcomSource",
          )

          throw e
        }
      }
    ),
    [
      userPost,
      mutate,
    ],
  )

  return createSource
}
