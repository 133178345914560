import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { MedicationActivity } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: MedicationActivity;
}

export const MedicationActivityTitle = forwardRef<HTMLDivElement, Props>(
  function MedicationActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const title = activity.num_units
      ? polyglot.t(
        "components.activity_summary.title.medication_with_units",
        {
          medication: activity.medication.name,
          units: activity.medication.units,
          num_units: activity.num_units,
        },
      ) : polyglot.t(
        "components.activity_summary.title.medication",
        {
          medication: activity.medication.name,
        },
      )

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {title}
      </div>
    )
  },
)
