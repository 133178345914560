import {
  useCallback,
  useRef,
  useLayoutEffect,
} from "react"

import { FeedDayHeader } from "./FeedDayHeader"
import { FeedItem } from "./FeedItem"
import { useItemContext } from "./ItemContext"
import styles from "./VirtualListItemContent.module.scss"

interface Props {
  index: number;
}

/*
 * this component renders a header and all activities for a specific day
 */
export function VirtualListItemContent(props: Props) {
  const { index } = props

  const ref = useRef<HTMLDivElement>(null)
  const {
    itemCount,
    getIndexItem,
    updateIndexSize,
  } = useItemContext()

  const updateSize = useCallback(
    () => {
      const element = ref.current
      if (element) {
        const { height } = element.getBoundingClientRect()

        updateIndexSize(
          index,
          height,
        )
      }
    },
    [
      ref,
      index,
      updateIndexSize,
    ],
  )

  const item = getIndexItem(index)

  // the height of this element should only change when the activities change
  // if other things (like resizing the window) result in list item height changes
  // then this needs to be updated
  useLayoutEffect(
    () => {
      updateSize()
    },
    [
      updateSize,
      item,
    ],
  )

  if (!item) {
    return null
  }

  return (
    <div
      className={styles.container}
      ref={ref}
      data-last={index + 1 === itemCount}
    >
      {item.isLastOfDay && (
        <FeedDayHeader
          isFirstItem={item.isFirstItem}
          day={item.started_at}
          index={index}
        />
      )}
      {item.created_at && (
        <>
          <FeedItem
            userItem={item}
            key={item.id}
          />
        </>
      )}
    </div>
  )
}
