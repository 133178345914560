import { GlucoseUnits } from "./GlucoseUnits"
import { NormalRange } from "./NormalRange"
import styles from "./Ranges.module.scss"
import { SleepHours } from "./SleepHours"
import { UrgentLow } from "./UrgentLow"

import {
  useDebouncedUpdater,
  useDeviceProperty,
  useAccountProperty,
  useUpdateSettings,
} from "src/hooks"

export function Ranges() {
  const deviceUpdatedAt = useDeviceProperty(
    "updated_at",
    undefined,
  )

  const accountUpdatedAt = useAccountProperty(
    "updated_at",
    undefined,
  )

  const country = useAccountProperty(
    "country",
    undefined,
  )

  // TODO catch error and show toast
  const updateSettings = useUpdateSettings()
  const debouncedUpdateSettings = useDebouncedUpdater(updateSettings)

  return (
    <div className={styles.container}>
      <NormalRange
        updatedAt={deviceUpdatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <UrgentLow
        updatedAt={deviceUpdatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      {country !== "US" && (
        <GlucoseUnits
          updatedAt={accountUpdatedAt}
          updateSettings={debouncedUpdateSettings}
        />
      )}
      <SleepHours
        updatedAt={deviceUpdatedAt}
        updateSettings={debouncedUpdateSettings}
      />
    </div>
  )
}
