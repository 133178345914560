import { useCallback } from "react"
import { useScrollPositionContext } from "../ScrollPositionContext"
import { useVirtualListContext } from "../VirtualListContext"
import { useXAxisContext } from "../XAxisContext"
import { useGetTimeDifferenceInPixels } from "./useGetTimeDifferenceInPixels"

/*
 * returns a function that returns the xPosition of the specified "time"
 * relative to the left edgeo of the graph
 */
export function useGetTimeAsXPosition(): (time: string) => number {
  const { scrollPosition } = useScrollPositionContext()
  const { containerBoundingClientRect } = useVirtualListContext()
  const containerWidth = containerBoundingClientRect.width

  const getTimeDifferenceInPixels = useGetTimeDifferenceInPixels()

  const {
    graphStart,
  } = useXAxisContext()

  return useCallback(
    (time: string) => {
      if (!containerWidth || !time) {
        return NaN
      }

      const differenceInPixels = getTimeDifferenceInPixels(
        graphStart,
        time,
      )

      const pixelsFromLeftEdge = containerWidth - (differenceInPixels - scrollPosition)
      return pixelsFromLeftEdge
    },
    [
      scrollPosition,
      graphStart,
      containerWidth,
      getTimeDifferenceInPixels,
    ],
  )
}
