import { useMemo } from "react"
import { useFindMedications } from "./useFindMedications"
import { createMedicationQueryObject } from "src/utils"

export function useMedicationCategories(): string[] {
  const queryObject = useMemo(
    () => createMedicationQueryObject({
      sort: [{ category: "asc" as const }],
    }),
    [],
  )

  const { result } = useFindMedications(queryObject)

  const categories = useMemo(
    () => {
      const categorySet = new Set<string>()
      result.forEach((medication) => {
        if (medication.category) {
          categorySet.add(medication.category)
        }
      })
      return Array.from(categorySet.keys()).sort()
    },
    [result],
  )

  return categories
}
