import type { ItemContextValue } from "./ItemContextValue"

export const defaultItemContextValue: ItemContextValue = {
  itemCount: 90,
  getIndexSize: () => 38,
  getIndexId: () => "-1",
  getIndexItem: () => undefined,
  getIdIndexRef: { current: () => 0 },
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  updateIndexSize: () => {},
  defaultSize: 86, // the approximate size of a feed item
}
