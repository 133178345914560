import type { UserItemQuerySelector } from "src/types"

export function createStartedAtSelector(
  queryStartTime: string, // the time at which the query starts
  queryEndTime: string, // the time up to which the query goes
): UserItemQuerySelector {
  return {
    $and: [
      { started_at: { $gte: queryStartTime } },
      { started_at: { $lt: queryEndTime } },
    ],
  }
}
