import {
  ActionButton,
  ActionSubmit,
  Modal,
  ModalBody,
  ModalButton,
  ModalButtons,
  ModalCloseX,
  ModalContentForm,
  ModalHeading,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useMemo,
  useState,
} from "react"
import type {
  ReactNode,
  MouseEventHandler,
  FormEventHandler,
} from "react"

import { DiscardChangesDialog } from "../DiscardChangesDialog"
import { RemoveDialog } from "../RemoveDialog"
import styles from "./AccountInsulinModal.module.scss"
import { AccountInsulinModalContext } from "./AccountInsulinModalContext"
import type { EditableAccountInsulin } from "./EditableAccountInsulin"
import { emptyAccountInsulin } from "./EditableAccountInsulin"
import { InsulinCategorySelect } from "./InsulinCategorySelect"
import { InsulinFormSelect } from "./InsulinFormSelect"
import { InsulinIsBasalToggle } from "./InsulinIsBasalToggle"
import { InsulinNameSelect } from "./InsulinNameSelect"
import { InsulinPrecisionSelect } from "./InsulinPrecisionSelect"
import { usePolyglot } from "src/contexts"
import {
  useSaveAccountInsulin,
  useDeleteAccountInsulin,
  useEditableType,
  useInsulin,
  useLoadInsulinsCollection,
  useLoadAccountInsulinsCollection, // TODO move this
} from "src/hooks"
import {
  AddInsulinErrorToast,
  AddInsulinSuccessToast,
  EditInsulinErrorToast,
  EditInsulinSuccessToast,
  RemoveInsulinErrorToast,
  RemoveInsulinSuccessToast,
} from "src/toasts"

const polyglotPrefix = "components.account_insulin_modal."

interface Props {
  children: ReactNode;
}

export function AccountInsulinModal(props: Props) {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)
  const [
    isRemoveOpen,
    setIsRemoveOpen,
  ] = useState<boolean>(false)
  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  useLoadInsulinsCollection()
  useLoadAccountInsulinsCollection()

  const {
    current: currentAccountInsulin,
    hasChanged: accountInsulinHasChanged,
    save: resetAccountInsulin,
    update: editAccountInsulin,
  } = useEditableType<EditableAccountInsulin>(emptyAccountInsulin)

  const currentInsulin = useInsulin(currentAccountInsulin?.insulin_id)

  // closes the modal regardless of whether or not changes were saved
  const closeModal = useCallback(
    () => {
      setIsModalOpen(false)
    },
    [setIsModalOpen],
  )

  // opens the modal and fills it w/ the specified account insulin
  const openModal = useCallback(
    (accountInsulin: EditableAccountInsulin = emptyAccountInsulin) => {
      resetAccountInsulin(accountInsulin)
      setIsModalOpen(true)
    },
    [
      setIsModalOpen,
      resetAccountInsulin,
    ],
  )

  // show the warning dialog if changes have been made without saving
  // the only way to open this modal is with "openAccountInsulinModal"
  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        if (accountInsulinHasChanged) {
          setIsDiscardOpen(true)
        } else {
          setIsModalOpen(false)
        }
      }
    },
    [
      accountInsulinHasChanged,
      setIsModalOpen,
      setIsDiscardOpen,
    ],
  )

  // will either create or update the account insulin
  const saveAccountInsulin = useSaveAccountInsulin()

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          await saveAccountInsulin(currentAccountInsulin)
          closeModal()

          if (currentAccountInsulin.id) {
            showToast(
              "edit-insulin-success-toast",
              EditInsulinSuccessToast,
            )
          } else {
            showToast(
              "add-insulin-success-toast",
              AddInsulinSuccessToast,
            )
          }
        } catch (e) {
          if (currentAccountInsulin.id) {
            showToast(
              "edit-insulin-error-toast",
              EditInsulinErrorToast,
            )
          } else {
            showToast(
              "add-insulin-error-toast",
              AddInsulinErrorToast,
            )
          }
        }
      })()
    },
    [
      showToast,
      closeModal,
      saveAccountInsulin,
      currentAccountInsulin,
    ],
  )

  // will remove the account insulin
  const removeAccountInsulin = useDeleteAccountInsulin()

  const remove: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {

      void (async () => {
        if (!currentAccountInsulin.id) {
          throw new Error("cannot delete account insulin that has not been saved")
        }

        try {
          await removeAccountInsulin(currentAccountInsulin.id)

          showToast(
            "remove-insulin-success-toast",
            RemoveInsulinSuccessToast,
          )

          closeModal()
        } catch (e) {
          showToast(
            "remove-insulin-error-toast",
            RemoveInsulinErrorToast,
          )
        }
      })()
    },
    [
      showToast,
      closeModal,
      removeAccountInsulin,
      currentAccountInsulin,
    ],
  )

  // when the remove cta is clicked, open the remove dialog
  const onRemoveClick = useCallback(
    () => {
      setIsRemoveOpen(true)
    },
    [setIsRemoveOpen],
  )

  const isNewAccountInsulin = currentAccountInsulin.id === emptyAccountInsulin.id

  const contextValue = useMemo(
    () => ({
      openAccountInsulinModal: openModal,
    }),
    [openModal],
  )

  return (
    <Modal
      open={isModalOpen}
      onOpenChange={onOpenChange}
    >
      <AccountInsulinModalContext.Provider value={contextValue}>
        {props.children}
      </AccountInsulinModalContext.Provider>
      <ModalContentForm onSubmit={onSubmit}>
        <ModalHeading>
          {isNewAccountInsulin
            ? polyglot.t(`${polyglotPrefix}add_title`)
            : polyglot.t(
              `${polyglotPrefix}edit_title`,
              { name: currentInsulin?.name },
            )
          }
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          {isNewAccountInsulin && (
            <InsulinCategorySelect
              currentAccountInsulin={currentAccountInsulin}
              editAccountInsulin={editAccountInsulin}
            />
          )}
          {isNewAccountInsulin && (
            <InsulinNameSelect
              currentAccountInsulin={currentAccountInsulin}
              editAccountInsulin={editAccountInsulin}
            />
          )}
          <InsulinFormSelect
            currentAccountInsulin={currentAccountInsulin}
            editAccountInsulin={editAccountInsulin}
            currentInsulin={currentInsulin}
          />
          <InsulinPrecisionSelect
            currentAccountInsulin={currentAccountInsulin}
            editAccountInsulin={editAccountInsulin}
            currentInsulin={currentInsulin}
          />
          <InsulinIsBasalToggle
            currentAccountInsulin={currentAccountInsulin}
            editAccountInsulin={editAccountInsulin}
          />
          <ModalButtons>
            {isNewAccountInsulin ? (
              <ModalButton
                stylePreset="secondary"
                type="button"
              >
                {polyglot.t(`${polyglotPrefix}cancel_cta`)}
              </ModalButton>
            ) : (
              <ActionButton
                className={styles.modalMultiButton}
                stylePreset="secondary"
                onClick={onRemoveClick}
                type="button"
              >
                {polyglot.t(`${polyglotPrefix}remove_cta`)}
              </ActionButton>
            )}
            <ActionSubmit
              className={styles.modalMultiButton}
              stylePreset="primary"
              value={polyglot.t(`${polyglotPrefix}save_cta`)}
              disabled={!accountInsulinHasChanged}
            />
          </ModalButtons>
        </ModalBody>
        <DiscardChangesDialog
          open={isDiscardOpen}
          setOpen={setIsDiscardOpen}
          discardChanges={closeModal}
        />
        <RemoveDialog
          open={isRemoveOpen}
          setOpen={setIsRemoveOpen}
          remove={remove}
          title={polyglot.t(
            `${polyglotPrefix}remove_heading`,
            { name: currentInsulin?.name },
          )}
        />
      </ModalContentForm>
    </Modal>
  )
}
