import dayjs from "dayjs"
import { useCallback } from "react"
import { usePolyglot } from "src/contexts"

export function useDisplayDate() {
  const polyglot = usePolyglot()

  return useCallback(
    (day: string) => {
      const startOfToday = dayjs().startOf("day")
      const startOfDay = dayjs(day).startOf("day")
      const diff = startOfToday.diff(
        startOfDay,
        "days",
      )

      // say "Today" if the difference in days is 0
      if (diff === 0 && polyglot) {
        return polyglot.t("time.today_cap")
      }

      // say "Yesterday" if the difference in days is 1
      if (diff === 1 && polyglot) {
        return polyglot.t("time.yesterday_cap")
      }

      // otherwise format the date
      return new Intl.DateTimeFormat(
        navigator.language,
        {
          weekday: "short",
          month: "short",
          day: "numeric",
        },
      ).format(dayjs(day).toDate())
    },
    [polyglot],
  )
}
