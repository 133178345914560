import classNames from "classnames"
import { useCalculationsContext } from "../CalculationsContext"
import styles from "./DistributionPercentages.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

interface ValueProps {
  percentage: number;
}

function DistributionPercentageValue(props: ValueProps) {
  const { percentage } = props
  const value = isNaN(percentage) ? "--" : round(percentage * 100)

  return (
    <div className={styles.distributionPercentageValue}>
      {value}
      <span className={styles.distributionPercentageSymbol}>
        %
      </span>
    </div>
  )
}


export function GlucosePercentageDistribution(props: Props) {
  const { range } = props

  const { glucoseCalculations } = useCalculationsContext()
  const {
    percentAboveNormal,
    percentBelowNormal,
    percentWithinNormal,
  } = glucoseCalculations[range] || {}

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucosePercentageDistribution} />
      <TileTimeSpan
        className={styles.distributionPercentagesTimeSpan}
        range={range}
      />
      <div className={styles.distributionPercentages}>
        <div className={styles.distributionPercentage}>
          <DistributionPercentageValue percentage={percentBelowNormal} />
        </div>
        <div className={classNames(
          styles.distributionPercentage,
          styles.distributionWithinNormal,
        )}
        >
          <DistributionPercentageValue percentage={percentWithinNormal} />
        </div>
        <div className={styles.distributionPercentage}>
          <DistributionPercentageValue percentage={percentAboveNormal} />
        </div>
      </div>
    </>
  )
}
