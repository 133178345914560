import {
  forwardRef,
  useCallback,
} from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"
import {
  FeedItemContent,
  FeedItemContentText,
} from "./FeedItemContent"
import styles from "./FeedItemContent/FeedItemContent.module.scss"
import { FeedItemHeader } from "./FeedItemHeader"
import {
  FavoriteMessage,
  FavoriteTitle,
  FavoriteIcon,
  useEntryModalPublicContext,
} from "src/components"
import { useEntryTemplate } from "src/hooks"
import type { FavoriteUserEntry } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userEntry: FavoriteUserEntry;
}

export const FavoriteFeedItem = forwardRef<HTMLDivElement, Props>(
  function FavoriteFeedItem(props: Props, propRef: Ref<HTMLDivElement>) {
    const { userEntry } = props
    const entryTemplate = useEntryTemplate(userEntry.entry_template_id)
    const { openEntryModal } = useEntryModalPublicContext()

    const onClick = useCallback(
      () => openEntryModal({
        userEntry,
      }),
      [
        openEntryModal,
        userEntry,
      ],
    )

    return (
      <div
        className={props.className}
        ref={propRef}
      >
        <FeedItemHeader userItem={userEntry} />
        <FeedItemContent onClick={onClick}>
          <FavoriteIcon className={styles.feedItemContentIcon} />
          <FeedItemContentText>
            <FavoriteTitle
              entryTemplate={entryTemplate}
              className={styles.feedItemContentTitle}
            />
            <div className={styles.feedItemContentDescription}>
              <FavoriteMessage
                subEntries={userEntry.subentries}
                className={styles.feedItemContentMessage}
              />
            </div>
          </FeedItemContentText>
        </FeedItemContent>
      </div>
    )
  },
)
