import dayjs from "dayjs"

// round a time string down to the nearest fraction of an hour
export function roundTimeDown(timeString: string, hoursSubdivision: number) {
  const minutes = dayjs(timeString).minute()
  const minutesPerSubdivision = 60 / hoursSubdivision

  const nearestSubdivision = Math.floor(minutes / minutesPerSubdivision)
  const lastHour = dayjs(timeString).startOf("hour")
  return lastHour.add(
    nearestSubdivision * minutesPerSubdivision,
    "minutes",
  )
}
