import type {
  ReactNode,
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import { DeviceChangeMessage } from "./DeviceChangeMessage"
import { ExerciseMessage } from "./ExerciseMessage"
import { FoodMessage } from "./FoodMessage"
import { GlucoseMessage } from "./GlucoseMessage"
import { InsulinMessage } from "./InsulinMessage"
import { LiquidMessage } from "./LiquidMessage"
import { MedicationMessage } from "./MedicationMessage"
import { NoteMessage } from "./NoteMessage"

import { usePolyglot } from "src/contexts"
import type { ReadOnlyUserEntry } from "src/types"
import { ActivityType } from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & {
  subEntries: ReadOnlyUserEntry["subentries"];
}

export const FavoriteMessage = forwardRef<HTMLDivElement, Props>(
  function FavoriteMessage(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      subEntries,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {
          subEntries.map<ReactNode>(
            (subEntry) => {
              switch (subEntry.subentry_type) {
                case ActivityType.DeviceChange:
                  return (
                    <DeviceChangeMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Exercise:
                  return (
                    <ExerciseMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Food:
                  return (
                    <FoodMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Glucose:
                  return (
                    <GlucoseMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Insulin:
                  return (
                    <InsulinMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Liquid:
                  return (
                    <LiquidMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Medication:
                  return (
                    <MedicationMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                case ActivityType.Note:
                  return (
                    <NoteMessage
                      key={subEntry._id}
                      subEntry={subEntry}
                    />
                  )
                default:
                  return <></>
              }
            },
          ).reduce(
            (previous, current) => [
              previous,
              polyglot.t("components.favorite_summary.delimiter"),
              current,
            ],
          )
        }
      </div>
    )
  },
)
