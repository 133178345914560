import type { MangoQuery } from "rxdb"
import { createEventQueryObject } from "./createEventQueryObject"
import type {
  UserEventOfType,
  UserEventType,
  UserEvent,
} from "src/types"

const defaultSelector = {}

// adds "event_type" selector to event query
export function createEventQueryObjectForType<T extends UserEventType>(
  accountId: number,
  activityType: T,
  {
    selector,
    sort,
    limit,
  }: MangoQuery<UserEvent>,
) {
  return createEventQueryObject(
    accountId,
    {
      selector: {
        event_type: { $eq: activityType },
        ...(selector ?? defaultSelector),
      },
      sort,
      limit,
    },
  ) as MangoQuery<UserEventOfType<T>> & MangoQuery<UserEvent>
}
