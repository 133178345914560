import {
  Outlet,
  useRouterState,
} from "@tanstack/react-router"
import {
  PageHeader,
  Page,
} from "src/components"

export function Settings() {
  const currentPath = useRouterState({
    select: (s) => s.resolvedLocation.pathname,
  })

  const backRoutePath = currentPath !== "/settings" ? "/settings" : undefined

  return (
    <>
      <PageHeader backRoutePath={backRoutePath} />
      <Page>
        <Outlet />
      </Page>
    </>
  )
}
