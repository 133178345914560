import dayjs from "dayjs"
import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useAccountProperty } from "src/hooks/me"
import { useFindUserEvents } from "src/hooks/userEvent"
import type { GlucoseUserEvent } from "src/types"
import { ActivityType } from "src/types"
import { createEventQueryObjectForType } from "src/utils"

type GlucoseQueryResultItem = DeepReadonlyObject<GlucoseUserEvent> | undefined

// TODO can we optimize this ?
export function useNearestGlucoseReading(
  time: string,
): GlucoseQueryResultItem {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const previousGlucoseQuery = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          started_at: { $lte: time },
        },
        sort: [{ started_at: "desc" as const }],
        limit: 1,
      },
    ),
    [
      time,
      accountId,
    ],
  )

  const nextGlucoseQuery = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          started_at: { $gte: time },
        },
        sort: [{ started_at: "asc" as const }],
        limit: 1,
      },
    ),
    [
      time,
      accountId,
    ],
  )

  const nextGlucose = useFindUserEvents<GlucoseUserEvent>(nextGlucoseQuery)
  const previousGlucose = useFindUserEvents<GlucoseUserEvent>(previousGlucoseQuery)
  const nextReading = nextGlucose.result[0]
  const previousReading = previousGlucose.result[0]

  if (!time) {
    return undefined
  }

  if (nextReading && previousReading) {
    const nextDiff = Math.abs(dayjs(time).diff(nextReading.started_at))
    const previousDiff = dayjs(time).diff(previousReading.started_at)

    // return the reading that is closest to time
    if (nextDiff < previousDiff) {
      return nextReading
    }
    return previousReading

  } if (nextReading) {
    // return nextReading if there is no previousReading
    return nextReading
  } if (previousReading) {
    // return previousReading if there is no nextReading
    return previousReading
  }

  return undefined
}
