export enum DayStat {
  NormalRange = "normal_range",
  AverageGlucose = "average",
  BedTimeGlucose = "average_night", // TODO is this right ?
  WakeUpGlucose = "average_day", // TODO is this right ?
  TotalCarbs = "carbs",
  StandardDeviation = "stddev",
  TotalCalories = "calories",
  TotalUnits = "units",
  Exercise = "exercise",
}
