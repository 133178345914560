import { Toast } from "@tandemdiabetes/sugarmate-react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
}

export function ChangePasswordSuccessToast(props: Props) {
  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="success"
      body={polyglot.t("toasts.change_password_success.body")}
      id={props.id}
    />
  )
}
