import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import { useEventsAndSubEntriesOfType } from "src/hooks"
import { ActivityType } from "src/types"

export function useStandardDeviationDayMetric(dayStart: string) {
  const daySelector = useDaySelector(dayStart)

  const [
    standardDeviation,
    setStandardDeviation,
  ] = useState<number>(NaN)

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: glucoseEvents,
    subEntries: glucoseSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Glucose,
    daySelector,
  )

  useEffect(
    () => {
      if (isFetchingEntries || isFetchingEvents) {
        return
      }

      let sum = 0

      const totalReadings = glucoseEvents.length + glucoseSubEntries.length

      if (totalReadings === 0) {
        setStandardDeviation(NaN)
        return
      }

      glucoseEvents.forEach(
        (glucoseEvent) => {
          sum += glucoseEvent.glucose.mg_dl
        },
      )

      glucoseSubEntries.forEach(
        (subEntry) => {
          sum += subEntry.glucose.mg_dl
        },
      )

      const average = sum / totalReadings
      let sumOfSquares = 0

      glucoseEvents.forEach(
        (glucoseEvent) => {
          sumOfSquares += Math.pow(
            glucoseEvent.glucose.mg_dl - average,
            2,
          )
        },
      )

      glucoseSubEntries.forEach(
        (subEntry) => {
          sumOfSquares += Math.pow(
            subEntry.glucose.mg_dl - average,
            2,
          )
        },
      )

      setStandardDeviation(Math.sqrt(sumOfSquares / totalReadings))
    },
    [
      isFetchingEntries,
      isFetchingEvents,
      setStandardDeviation,
      glucoseEvents,
      glucoseSubEntries,
    ],
  )

  return standardDeviation
}
