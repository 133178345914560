import {
  createContext,
  useContext,
} from "react"
import type { EditableEntryTemplate } from "../EditableEntryTemplate"

export interface EditFavoriteDialogContextValue {
  openEditFavoriteDialog: (entryTemplate: EditableEntryTemplate) => void;
}

type ContextType = EditFavoriteDialogContextValue | null
export const EditFavoriteDialogContext = createContext<ContextType>(null)

export function useEditFavoriteDialogContext() {
  const context = useContext(EditFavoriteDialogContext)

  if (context === null) {
    throw new Error("EditFavoriteDialog components must be wrapped in <EditFavoriteDialogContext />")
  }

  return context
}

