const targetPixelsPerMarker = 160
const valuesInMinutes = [
  5,
  10,
  15,
  20,
  30,
  60,
  120,
  240,
]

/*
 * returns the number of minutes that should be between each vertical line that
 * marks the time on the x axis
 *
 * it returns the number that creates the closest result to one marker every
 * "targetPixelsPerMarker" pixels
 */
export function pixelsPerMinuteToMinutesPerMarker(
  pixelsPerMinute: number,
): number {
  const minutesBetweenTimeMarkers = valuesInMinutes.reduce(
    (nearestValue, nextValue) => {
      const nearestDiff = Math.abs(
        targetPixelsPerMarker - nearestValue * pixelsPerMinute,
      )
      const nextDiff = Math.abs(
        targetPixelsPerMarker - nextValue * pixelsPerMinute,
      )

      if (nextDiff <= nearestDiff) {
        return nextValue
      }

      return nearestValue
    },
    valuesInMinutes[0],
  )

  return minutesBetweenTimeMarkers
}
