import type { FoodSearchFood } from "src/types"

/*
 * this food is used as the placeholder current food when we have no search
 * results
 *
 * it is not meant to be displayed
 */
export const defaultFoodSearchFood: FoodSearchFood = {
  brand: "",
  name: "",
  source: "",
  source_id: "-1",
  nutritional_servings: [
    {
      serving_id: "-1",
      serving_qty: 0,
      serving_uom: "",
      default: true,
      carbs: 0,
      protein: 0,
      fat: 0,
      sugar: 0,
      fiber: 0,
      calories: 0,
    },
  ],
}
