import { useMemo } from "react"
import type { ReactNode } from "react"
import { OauthAuthorizeContext } from "./OauthAuthorizeContext"

interface Props {
  children: ReactNode;
}
export function OauthAuthorizeContextProvider(props: Props) {
  const value = useMemo(
    () => {
      const urlParams = new URLSearchParams(window.location.search)
      return {
        client_id: urlParams.get("client_id") ?? "",
        code_challenge: urlParams.get("code_challenge") ?? "",
        code_challenge_method: urlParams.get("code_challenge_method") ?? "",
        redirect_uri: urlParams.get("redirect_uri") ?? "",
        response_type: urlParams.get("response_type") ?? "",
        scope: urlParams.get("scope") ?? "",
        state: urlParams.get("state") ?? "",
        p: urlParams.get("p") ?? undefined,
        device_type: urlParams.get("device_type") ?? undefined,
      }
    },
    [],
  )

  return (
    <OauthAuthorizeContext.Provider value={value}>
      {props.children}
    </OauthAuthorizeContext.Provider>
  )
}
