import {
  Dialog,
  DialogCloseX,
  DialogButton,
  DialogButtons,
  DialogContent,
  DialogHeading,
  DialogTrigger,
  PopoverMenuItem,
  PopoverMenuItemIcon,
  PopoverMenuItemSeparator,
  CopyOutlineIcon,
  usePopoverContext,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useState,
} from "react"
import { useEntryModalPrivateContext } from "../EntryModalPrivateContext"
import { useEntryModalPublicContext } from "../EntryModalPublicContext"
import { DiscardChangesDialog } from "src/components/DiscardChangesDialog"
import { usePolyglot } from "src/contexts"
import {
  DuplicateSubEntryErrorToast,
  DuplicateSubEntrySuccessToast,
} from "src/toasts"

const polyglotPrefix = "components.entry_modal.sub_entry_context_menu."

export function DuplicateSubEntry() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const { duplicateCurrentSubEntry } = useEntryModalPrivateContext()
  const {
    openEntryModal,
    hasUnsavedChanges,
  } = useEntryModalPublicContext()

  const { setOpen: setPopoverOpen } = usePopoverContext()

  const [
    discardChangesOpen,
    setDiscardChangesOpen,
  ] = useState<boolean>(false)

  const openDiscardChangesDialog = useCallback(
    () => {
      setDiscardChangesOpen(true)
    },
    [setDiscardChangesOpen],
  )

  const duplicateCurrentSubEntryCallback = useCallback(
    () => {
      void (async () => {
        try {
          const newEntry = await duplicateCurrentSubEntry()
          openEntryModal({
            userEntry: newEntry,
            subEntry: newEntry.subentries[0],
          })

          showToast(
            "duplicate-sub-entry-success-toast",
            DuplicateSubEntrySuccessToast,
          )
        } catch (e) {
          showToast(
            "duplicate-sub-entry-error-toast",
            DuplicateSubEntryErrorToast,
          )
        }

        setPopoverOpen(false)
      })()
    },
    [
      showToast,
      duplicateCurrentSubEntry,
      openEntryModal,
      setPopoverOpen,
    ],
  )

  return (
    <Dialog>
      <DialogTrigger asChild>
        <PopoverMenuItem>
          <PopoverMenuItemIcon>
            <CopyOutlineIcon />
          </PopoverMenuItemIcon>
          {polyglot.t(`${polyglotPrefix}duplicate_cta`)}
        </PopoverMenuItem>
      </DialogTrigger>
      <PopoverMenuItemSeparator />
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}duplicate_dialog_header`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}duplicate_dialog_cancel`)}
          </DialogButton>
          <DialogButton
            stylePreset="primary"
            onClick={hasUnsavedChanges ? openDiscardChangesDialog : duplicateCurrentSubEntryCallback}
          >
            {polyglot.t(`${polyglotPrefix}duplicate_dialog_confirm`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
      <DiscardChangesDialog
        open={discardChangesOpen}
        setOpen={setDiscardChangesOpen}
        discardChanges={duplicateCurrentSubEntryCallback}
      />
    </Dialog>
  )
}
