import type {
  Source,
  StatusResponse,
} from "src/types"

type SourcesResponse = StatusResponse & {
  sources: Array<Source>;
}

export function transformSourcesResponse(
  data: string,
): Array<Source> | undefined {
  const json = JSON.parse(data) as SourcesResponse
  return json.sources
}
