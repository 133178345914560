import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { LiquidActivity } from "src/types"
import { liquidMicrolitersToUnit } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: LiquidActivity;
}

export const LiquidActivityTitle = forwardRef<HTMLDivElement, Props>(
  function LiquidActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const value = liquidMicrolitersToUnit(
      activity.microliters,
      activity.units,
    )

    const volume = polyglot.t(
      `unit.${activity.units}`,
      {
        smart_count: value,
        value: value.toLocaleString(navigator.language),
      },
    )

    const title = activity.liquid_type
      ? (
        polyglot.t(
          "components.activity_summary.title.liquid",
          {
            liquid: activity.liquid_type,
            volume,
          },
        )
      ) : volume

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {title}
      </div>
    )
  },
)
