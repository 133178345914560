import type { ComponentPropsWithoutRef } from "react"
import { useAverageGlucoseDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import {
  useReadableGlucoseUnit,
  useReadableGlucoseValue,
} from "src/hooks"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function AverageGlucoseDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()
  const glucoseUnit = useReadableGlucoseUnit()

  const value = useAverageGlucoseDayMetric(dayStart)
  const glucoseValue = useReadableGlucoseValue(value)

  // hide if the value is 0
  if (isNaN(value) || value === 0) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.average_glucose",
        {
          glucoseUnit,
          glucoseValue,
        },
      )}
    </button>
  )
}
