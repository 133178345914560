import type { RxJsonSchema } from "rxdb"
import { insulinSchema } from "./insulinSchema"

import {
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./sharedSchema"
import type { AccountInsulin } from "src/types"

// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const accountInsulinSchema: RxJsonSchema<AccountInsulin> = {
  title: "account_insulin_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    account_id: {
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
      multipleOf: 1,
      type: "number",
    },
    duration: { type: "number" },
    form: { type: "string" },
    is_active: { type: "boolean" },
    is_basal: { type: "boolean" },
    name_override: { type: "string" },
    onset: { type: "number" },
    peak: { type: "number" },
    precision: { type: "number" },
    secondary_precision: { type: "number" },
    insulin_id: { type: "string" },
    insulin: {
      type: "object",
      properties: { ...insulinSchema.properties },
      required: insulinSchema.required && [...insulinSchema.required],
    },
    ...sharedSchemaProperties,
  },
  required: [
    "is_active",
    "is_basal",
    "account_id",
    "precision",
    ...sharedSchemaRequired,
  ],
  indexes: [
    [
      "account_id",
      "is_deleted",
    ],
    [
      "account_id",
      "is_deleted",
      "insulin.name",
    ],
    [
      "account_id",
      "is_deleted",
      "created_at",
    ],
    ...sharedSchemaIndexes,
  ],
}
