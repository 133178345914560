import { useMemo } from "react"
import { useMe } from "src/hooks/me"
import type { UserDeviceSettings } from "src/types"
import {
  GraphDotColor,
  Theme,
  ThemeAppearance,
} from "src/types"

const defaultDeviceSettings = Object.freeze({
  // aps_sandbox?: boolean;
  chart_bottom: 0,
  chart_top: 400,
  dark_mode: false,
  day_stat_header: undefined,
  dot_color: GraphDotColor.Default,
  feed_buttons: [],
  gauges: [],
  graph_icon_info: true,
  graph_icon_scale: 1,
  hide_notes_history: false,
  range_normal_bottom: 70,
  range_normal_top: 180,
  sleep_bottom: 12,
  sleep_top: 28,
  show_delta: true,
  theme: Theme.Sugarmate,
  theme_appearance: ThemeAppearance.OS,
  urgent_low: 55,
})

export function useDeviceSettingsProperty<
  K extends keyof UserDeviceSettings & keyof typeof defaultDeviceSettings,
>(property: K) {
  const { data } = useMe()

  return useMemo(
    () => data?.device.settings[property] ?? defaultDeviceSettings[property],
    [
      data,
      property,
    ],
  )
}
