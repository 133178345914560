import {
  InsightKey,
  InsightRange,
} from "src/types"

export function copyDefaultInsightRangeByKey(): Record<InsightKey, InsightRange> {
  return { ...defaultInsightRangeByKey }
}

export const defaultInsightRangeByKey: Record<InsightKey, number> = {
  [InsightKey.ExerciseAverageDuration]: InsightRange.oneWeek,
  [InsightKey.ExerciseTotalDuration]: InsightRange.oneDay,
  [InsightKey.FoodAverageCalories]: InsightRange.oneWeek,
  [InsightKey.FoodAverageCarbs]: InsightRange.oneWeek,
  [InsightKey.FoodAverageFat]: InsightRange.oneWeek,
  [InsightKey.FoodAverageFiber]: InsightRange.oneWeek,
  [InsightKey.FoodAverageProtein]: InsightRange.oneWeek,
  [InsightKey.FoodTotalCalories]: InsightRange.oneDay,
  [InsightKey.FoodTotalCarbs]: InsightRange.oneDay,
  [InsightKey.FoodTotalFat]: InsightRange.oneDay,
  [InsightKey.FoodTotalFiber]: InsightRange.oneDay,
  [InsightKey.FoodTotalProtein]: InsightRange.oneDay,
  [InsightKey.GlucoseAverageReadingBedtime]: InsightRange.oneWeek,
  [InsightKey.GlucoseAverageReadingWakeUp]: InsightRange.oneWeek,
  [InsightKey.GlucoseAverageReading]: InsightRange.oneDay,
  [InsightKey.GlucoseCoefficientOfVariation]: InsightRange.oneWeek,
  [InsightKey.GlucoseFluxGrade]: InsightRange.oneDay,
  [InsightKey.GlucoseHighsAndLows]: InsightRange.oneWeek,
  [InsightKey.GlucoseManagementIndicator]: InsightRange.ninetyDays,
  [InsightKey.GlucoseMedianReading]: InsightRange.oneDay,
  [InsightKey.GlucosePercentageAbove]: InsightRange.oneDay,
  [InsightKey.GlucosePercentageBelow]: InsightRange.oneDay,
  [InsightKey.GlucosePercentageDistribution]: InsightRange.oneDay,
  [InsightKey.GlucosePercentageWithin]: InsightRange.oneDay,
  [InsightKey.GlucoseQuartiles]: InsightRange.oneWeek,
  [InsightKey.GlucoseStandardDeviation]: InsightRange.oneWeek,
  [InsightKey.GlucoseTotalUnicorns]: InsightRange.oneDay,
  [InsightKey.InsulinAverageBasal]: InsightRange.oneWeek,
  [InsightKey.InsulinAverageBolus]: InsightRange.oneWeek,
  [InsightKey.InsulinAverage]: InsightRange.oneWeek,
  [InsightKey.InsulinTotalBasal]: InsightRange.oneDay,
  [InsightKey.InsulinTotalBolus]: InsightRange.oneDay,
  [InsightKey.InsulinTotal]: InsightRange.oneDay,
  [InsightKey.MiniGraph]: InsightRange.sixHours,
  [InsightKey.TimeInRange]: InsightRange.upToNow,
}
