import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { post } from "src/api"
import type { StatusResponse } from "src/types"
import { noticeError } from "src/utils"

type ForgotPassword = (email: string) => Promise<AxiosResponse<StatusResponse>>

export function useForgotPassword(): ForgotPassword {
  return useCallback(
    async (email: string) => {
      try {
        const response = await post<StatusResponse>({
          url: "/api/v2/devices/forgot_password",
          data: {
            email,
          },
        })
        return response
      } catch (e) {
        noticeError(
          e,
          "ForgotPasswordEmail",
          { email },
        )

        throw e
      }
    },
    [],
  )
}
