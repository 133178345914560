import { useCallback } from "react"

import type { Handler } from "./Handler"
import { useMe } from "src/hooks"
import type { Settings } from "src/types"

export function useSettingsHandler(): Handler<Settings> {
  const { mutate } = useMe()

  return useCallback(
    async () => {
      await mutate()
    },
    [mutate],
  )
}
