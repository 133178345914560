import {
  Navigate,
  useRouterState,
} from "@tanstack/react-router"
import type { FC } from "react"
import { useEffect } from "react"
import {
  useAuthContext,
  useSessionStorageContext,
} from "src/contexts"

export function getDeauthProtectedComponent(Component: FC<object>) {
  return function DeauthProtectedComponent() {
    const currentPath = useRouterState({
      // note use .href if you want to include query params
      select: (s) => s.resolvedLocation.pathname,
    })

    const {
      accessToken,
      isExpired,
    } = useAuthContext()

    const {
      loginRedirect,
      logoutRedirect,
      setLogoutRedirect,
    } = useSessionStorageContext()

    useEffect(
      () => {
        const isValid = accessToken && !isExpired

        // if this path was the logout redirect and it loaded successfully,
        // then clear the logout redirect
        if (isValid && logoutRedirect === currentPath) {
          setLogoutRedirect()
        }
      },
      [
        isExpired,
        accessToken,
        logoutRedirect,
        currentPath,
        setLogoutRedirect,
      ],
    )

    return !accessToken
      ? <Component />
      : (
        <Navigate
          to={loginRedirect}
          params={{}}
          replace
        />
      )
  }
}
