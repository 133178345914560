import { Outlet } from "@tanstack/react-router"
import { FlashComponent } from "./FlashComponent"
import { ThemeComponent } from "./ThemeComponent"
import { WebSocketHandler } from "src/components/WebSocketHandler"
import {
  UserEntriesCollectionContextProvider,
  UserEventsCollectionContextProvider,
} from "src/contexts"

export function RootComponent() {
  return (
    <UserEventsCollectionContextProvider>
      <UserEntriesCollectionContextProvider>
        <FlashComponent>
          <ThemeComponent>
            <WebSocketHandler />
            <Outlet />
          </ThemeComponent>
        </FlashComponent>
      </UserEntriesCollectionContextProvider>
    </UserEventsCollectionContextProvider>
  )
}
