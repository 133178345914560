import { transformInsulinsResponse } from "./transformInsulinsResponse"
import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import {
  insulinSchema,
  insulinCollectionKey,
} from "src/schema"

export function useLoadInsulinsCollection() {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadingState,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader(
    "insulins",
    "/api/v3/insulins",
    transformInsulinsResponse,
  )

  useLoadRxDBCollection(
    insulinSchema,
    insulinCollectionKey,
    loader,
    resetLoader,
  )
}
