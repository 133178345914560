import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useCallback,
  useMemo,
  useRef,
} from "react"

import { useReferencesContext } from "../ReferencesContext"
import type { ScrollPositionContextValue } from "../ScrollPositionContext"
import { useScrollPositionContext } from "../ScrollPositionContext"
import { defaultItemContextValue } from "./defaultItemContextValue"
import { ItemContext } from "./ItemContext"
import type {
  ItemContextValue,
  FeedItem,
} from "./ItemContextValue"
import {
  useSortedNonContinuousItems,
  useToday,
} from "src/hooks"

interface Props {
  children: ReactNode;
  feedStop: string;
}

const { defaultSize } = defaultItemContextValue

function isSameDay(day1: string, day2: string): boolean {
  return dayjs(day1).isSame(
    dayjs(day2),
    "day",
  )
}

export function ItemContextProvider(props: Props) {
  const {
    feedStop,
    children,
  } = props

  const itemSizesRef = useRef<Record<string, number>>({})

  const today = useToday()

  const queryEnd = useMemo(
    () => dayjs(today)
      .endOf("day")
      .toISOString(),
    [today],
  )

  const { listElementRef } = useReferencesContext()
  const sortedNonContinuousItems = useSortedNonContinuousItems(
    feedStop,
    queryEnd,
  )

  const {
    items,
    idToIndex,
  } = useMemo(
    () => {
      const idToIndexMap: Record<string, number> = {}

      const firstItem = sortedNonContinuousItems[0]
      const addTodayHeader = firstItem === undefined || !dayjs(firstItem.started_at).isSame(
        today,
        "day",
      )

      const feedItems: Array<FeedItem> = sortedNonContinuousItems.map(
        (item, index) => {
          // offset the indices if we will add a header for today
          idToIndexMap[item.id] = addTodayHeader ? index + 1 : index

          const previousItem = sortedNonContinuousItems[index - 1]
          const nextItem = sortedNonContinuousItems[index + 1]

          const isFirstOfDay = !nextItem || !isSameDay(
            nextItem.started_at,
            item.started_at,
          )

          const isLastOfDay = !previousItem || !isSameDay(
            previousItem.started_at,
            item.started_at,
          )

          return {
            ...item,
            isFirstItem: index === 0,
            isFirstOfDay,
            isLastOfDay,
          }
        },
      )

      if (addTodayHeader) {
        feedItems.unshift({
          started_at: today,
          isFirstOfDay: true,
          isLastOfDay: true,
          id: "today",
          created_at: undefined,
        })
      }

      return {
        items: feedItems,
        idToIndex: idToIndexMap,
        today,
      }
    },
    [sortedNonContinuousItems],
  )

  const itemCount = Math.max(
    items.length,
    1,
  )

  const {
    scrollPositionRef,
    visibleIndices,
  } = useScrollPositionContext()

  const visibleIndicesRef =
    useRef<ScrollPositionContextValue["visibleIndices"]>(visibleIndices)
  visibleIndicesRef.current = visibleIndices

  const getIndexId = useCallback(
    (index: number) => items[index].id,
    [items],
  )

  const getIdIndexRef: ItemContextValue["getIdIndexRef"] = useRef(
    defaultItemContextValue.getIdIndexRef.current,
  )

  getIdIndexRef.current = useCallback(
    (id: string) => idToIndex[id] ?? 0,
    [idToIndex],
  )

  const getIndexItem = useCallback(
    (index: number) => items[index],
    [items],
  )

  const getIndexSize = useCallback(
    (index: number) => {
      const key = getIndexId(index)
      return itemSizesRef.current[key] ?? (index === 0 ? (defaultSize + 16) : defaultSize)
    },
    [
      defaultSize,
      itemSizesRef,
      getIndexId,
    ],
  )

  const updateIndexSize = useCallback(
    (index: number, size: number) => {
      // update the value stored for this index's size
      const key = getIndexId(index)

      const previousSize = itemSizesRef.current[key]
      itemSizesRef.current[key] = size

      // if the size has not changed, then return
      if (previousSize === size) {
        return
      }

      const scrollPosition = scrollPositionRef.current
      const listElement = listElementRef.current

      // if there is no reference to the list, then return
      if (!listElement || scrollPosition === null) {
        return
      }

      // tells list to update its layout
      listElement.resetAfterIndex(
        index,
        true,
      )

      // prevent the update from shifting the user's scroll position
      if (index < visibleIndicesRef.current[0]) {
        const sizeDiff = previousSize === undefined ? 0 : size - previousSize
        listElement.scrollTo(scrollPosition + sizeDiff)
      }
    },
    [
      listElementRef,
      itemSizesRef,
      getIndexId,
      visibleIndicesRef,
    ],
  )

  const value = useMemo(
    () => ({
      itemCount,
      defaultSize,
      getIndexId,
      getIndexItem,
      getIdIndexRef,
      getIndexSize,
      updateIndexSize,
    }),
    [
      itemCount,
      defaultSize,
      getIndexId,
      getIndexItem,
      getIdIndexRef,
      getIndexSize,
      updateIndexSize,
    ],
  )

  return (
    <ItemContext.Provider value={value}>
      {children}
    </ItemContext.Provider>
  )
}
