import dayjs from "dayjs"
import { useCallback } from "react"
import { useXAxisContext } from "../XAxisContext"

/*
 * returns a function that returns the xPosition of the specified "time"
 * relative to the left edgeo of its cell
 */
export function useGetTimeDifferenceInPixels(): (
  time: string,
  cellStopTime: string,
) => number {
  const {
    minutesPerCell,
    cellWidth,
  } = useXAxisContext()

  return useCallback(
    (
      time: string,
      cellStopTime: string,
    ) => {
      const pixelsPerMinute = cellWidth / minutesPerCell
      const diffFromLeftInMinutes = dayjs(time).diff(
        cellStopTime,
        "minutes",
        true,
      )
      return diffFromLeftInMinutes * pixelsPerMinute
    },
    [
      minutesPerCell,
      cellWidth,
    ],
  )
}
