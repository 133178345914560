import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { FoodActivityData } from "./FoodActivityData"
import type { ActivityPair } from "src/types"
import { ActivityType } from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & ActivityPair

export const ActivityData = forwardRef<HTMLDivElement, Props>(
  function ActivityData(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      activityType,
      ...htmlDivProps
    } = props

    if (activityType === ActivityType.Food) {
      return (
        <FoodActivityData
          {...htmlDivProps}
          activity={activity}
          ref={ref}
        />
      )
    }

    return null
  },
)
