import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { ExerciseActivity } from "src/types"
import { ExerciseType } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: ExerciseActivity;
}

export const ExerciseActivityTitle = forwardRef<HTMLDivElement, Props>(
  function ExerciseActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    if (!activity.exercise_type || activity.exercise_type === ExerciseType.None) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {polyglot.t(`exercise_type.${activity.exercise_type}`)}
      </div>
    )
  },
)
