import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import {
  forwardRef,
  useMemo,
} from "react"

import { usePolyglot } from "src/contexts"
import type { ReadOnlyUserEvent } from "src/types"
import { DataSource } from "src/types"

const polyglotPrefix = "components.activity_summary.source."

// note: this is only for events, not subentries
export type Props = ComponentPropsWithoutRef<"div"> & {
  item: ReadOnlyUserEvent;
}

export const ActivitySource = forwardRef<HTMLDivElement, Props>(
  function ActivitySource(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      item,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const children: string | null = useMemo(
      () => {
        const userEvent = item

        switch (userEvent.source) {
          case DataSource.AppleHealthKit:
            return polyglot.t(`${polyglotPrefix}apple_health_kit`)
          case DataSource.Dexcom:
          case DataSource.DexcomRest:
            return polyglot.t(`${polyglotPrefix}dexcom`)
          case DataSource.Nightscout:
            return polyglot.t(`${polyglotPrefix}nightscout`)
          case DataSource.Sugarmate:
            return null
          default:
            return null
        }
      },
      [
        polyglot,
        item,
      ],
    )

    if (!children) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {children}
      </div>
    )
  },
)
