import type { AxiosRequestConfig } from "axios"
import type { AxiosFunction } from "../types"
import { mergeAxiosConfigs } from "./mergeAxiosConfigs"

export function applyAxiosConfig(
  axiosFunction: AxiosFunction,
  preConfig: AxiosRequestConfig,
): AxiosFunction {
  return <T>(config: AxiosRequestConfig) => axiosFunction<T>(mergeAxiosConfigs(
    preConfig,
    config,
  ))
}
