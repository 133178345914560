const localStorageKey = "authCookiePresent"

/*
 * this should be updated when the user gains or uses an auth cookie
 */
export function setHasCookieLocalStorage(value: "true" | "false") {
  localStorage.setItem(
    localStorageKey,
    value,
  )
}

export function getHasCookieLocalStorage(): "true" | "false" {
  return localStorage.getItem(localStorageKey) === "true"
    ? "true"
    : "false"
}

/*
 * this function listens for changes to local storage and executes a callback
 * when the user is logged out in a different tab
 *
 * is returns an unsubscribe function
 */
export function onLogOut(callback: () => unknown): () => void {
  const onStorageEvent = () => {
    if (getHasCookieLocalStorage() === "false") {
      callback()
    }
  }

  addEventListener(
    "storage",
    onStorageEvent,
  )

  return () => {
    removeEventListener(
      "storage",
      onStorageEvent,
    )
  }
}
