import {
  Dialog,
  DialogCloseX,
  DialogContent,
  DialogHeading,
  DialogBody,
  DialogButton,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import { useCallback } from "react"

import { useDataConsentDialogContext } from "./DataConsentDialogContext"
import styles from "./DataSource.module.scss"
import { usePolyglot } from "src/contexts"
import { SourceType } from "src/types"

interface Props {
  children: ReactNode;
}

const polyglotPrefix = "pages.settings.data_source.add_data_source_dialog."

export function AddDataSourceDialog(props: Props) {
  const { openConsentDialog } = useDataConsentDialogContext()

  const openDexcomConsent = useCallback(
    () => {
      openConsentDialog(SourceType.DexcomRest)
    },
    [openConsentDialog],
  )

  const openNightscoutConsent = useCallback(
    () => {
      openConsentDialog(SourceType.Nightscout)
    },
    [openConsentDialog],
  )

  const polyglot = usePolyglot()

  return (
    <Dialog>
      {props.children}
      <DialogContent>
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}heading`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogBody className={styles.dialogBody}>
          <div>
            {polyglot.t(`${polyglotPrefix}description`)}
          </div>
          <DialogButton
            stylePreset="secondary"
            onClick={openDexcomConsent}
          >
            {polyglot.t(`${polyglotPrefix}dexcom_cta`)}
          </DialogButton>
          <DialogButton
            stylePreset="secondary"
            onClick={openNightscoutConsent}
          >
            {polyglot.t(`${polyglotPrefix}nightscout_cta`)}
          </DialogButton>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
