import styles from "./Tile.module.scss"
import { TileChildren } from "./TileChildren"
import type {
  InsightKey,
  InsightRange,
} from "src/types"

interface Props {
  id: string;
  insightKey: InsightKey;
  range?: InsightRange;
  units?: number;
}

export function OverlayTile(props: Props) {
  return (
    <div className={styles.container}
      id={props.id}
    >
      <button className={styles.overlayTile}>
        <TileChildren {...props} />
      </button>
    </div>
  )
}
