import dayjs from "dayjs"
import { useCallback } from "react"

export function useDisplayTime(): (s: string | number) => string {
  return useCallback(
    (value: string | number) => new Intl.DateTimeFormat(
      navigator.language,
      {
        timeStyle: "short",
      },
    ).format(dayjs(value).toDate()),
    [],
  )
}
