import {
  Form,
  Checkbox,
  CheckboxCheckMark,
  LinkOutlineIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"

const termsOfUseId = "terms-of-use-input"
const privacyPolicyId = "privacy-policy-input"
const polyglotPrefix = "pages.sign_up.consent_notices."

export function ConsentNoticesPage(props: RegistrationPageProps) {
  const {
    children,
    onSubmit,
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()

  const onTermsOfUseChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        termsOfUseConsented: e.target.checked,
      })
    },
    [updateRegistrationForm],
  )

  const onPrivacyPolicyChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        privacyPolicyConsented: e.target.checked,
      })
    },
    [updateRegistrationForm],
  )

  return (
    <Form
      className={styles.consentNoticesForm}
      onSubmit={onSubmit}
    >
      <div className={styles.heading}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </div>
      <div
        className={styles.privacyCheckboxContainer}
        data-checked={registrationForm.termsOfUseConsented}
      >
        <Checkbox
          className={styles.checkbox}
          htmlFor={termsOfUseId}
          checked={registrationForm.termsOfUseConsented}
          onChange={onTermsOfUseChange}
          required
        >
          <CheckboxCheckMark />
          {polyglot.t(`${polyglotPrefix}terms_of_use`)}
        </Checkbox>
        <a
          href="https://sugarmate.io/tos"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <LinkOutlineIcon className={styles.linkIcon} />
        </a>
      </div>
      <div
        className={styles.privacyCheckboxContainer}
        data-checked={registrationForm.privacyPolicyConsented}
      >
        <Checkbox
          className={styles.checkbox}
          htmlFor={privacyPolicyId}
          checked={registrationForm.privacyPolicyConsented}
          onChange={onPrivacyPolicyChange}
          required
        >
          <CheckboxCheckMark />
          {polyglot.t(`${polyglotPrefix}privacy_policy`)}
        </Checkbox>
        <a
          href="https://sugarmate.io/privacy"
          target="_blank"
          rel="noreferrer"
          className={styles.link}
        >
          <LinkOutlineIcon className={styles.linkIcon} />
        </a>
      </div>
      {children}
    </Form>
  )
}
