import dayjs from "dayjs"
import {
  useRef,
  useEffect,
  useCallback,
} from "react"
import type { Subscription } from "rxjs"

import {
  defaultFavicon,
  getFavicon,
  setFavicon,
} from "./favicon"
import {
  defaultTitle,
  getTitle,
  setTitle,
} from "./title"

import { useDarkMode } from "src/contexts"
import { useDisplayGlucoseString } from "src/hooks/glucoseFormatting"
import { useMe } from "src/hooks/me"
import { useOnHiddenLogOut } from "src/hooks/responsive"
import { useDisplayTime } from "src/hooks/timeFormatting"
import { useUserEventsCollection } from "src/hooks/userEvent"
import { ActivityType } from "src/types"
import type { GlucoseUserEvent } from "src/types"
import { createEventQueryObjectForType } from "src/utils"

/*
 * this component sets the tab title to be the most recent reading
 *
 * we can't use react-helmet because it doesn't update the title when the
 * page is in a hidden tab
 */
export function useUpdateTitleEffect() {
  const { data: me } = useMe()
  const accountId = me?.account.id ?? -1
  const darkMode = useDarkMode()
  const collection = useUserEventsCollection()
  const displayTime = useDisplayTime()
  const displayGlucoseString = useDisplayGlucoseString()
  const subscriptionRef = useRef<null | Subscription>(null)

  const resetTabTitle = useCallback(
    () => {
      setTitle(defaultTitle)
      setFavicon(defaultFavicon)
    },
    [],
  )

  const cancelSubscription = useCallback(
    () => {
      subscriptionRef.current?.unsubscribe()
    },
    [subscriptionRef],
  )

  useEffect(
    () => {
      if (!collection || accountId === -1) {
        return
      }

      let timeoutId: null | ReturnType<typeof setTimeout> = null

      const query = collection.findOne(
        createEventQueryObjectForType(
          accountId,
          ActivityType.Glucose,
          {},
        ),
      )

      const subscription = query.$.subscribe(
        (result) => {
          let glucoseEvent = result?.toJSON() as GlucoseUserEvent | null

          // now that there is a newer result, clear the timeout
          if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
          }

          const diff = dayjs().diff(
            glucoseEvent?.started_at,
            "minute",
            true,
          )

          if (diff > 10) {
            // this reading is not recent
            glucoseEvent = null
          } else {
            // update the title and favicon when this reading is not recent
            timeoutId = setTimeout(
              resetTabTitle,
              diff * 60 * 1000, // ms until execution
            )
          }

          setTitle(
            getTitle(
              displayTime,
              displayGlucoseString,
              glucoseEvent,
            ),
          )

          setFavicon(
            getFavicon(
              darkMode,
              glucoseEvent,
            ),
          )
        },
      )

      subscriptionRef.current = subscription

      return () => {
        subscription.unsubscribe()

        if (timeoutId) {
          clearTimeout(timeoutId)
        }

        resetTabTitle()
      }
    },
    [
      resetTabTitle,
      accountId,
      collection,
      displayGlucoseString,
      displayTime,
      darkMode,
      resetTabTitle,
    ],
  )

  useOnHiddenLogOut(resetTabTitle)
  useOnHiddenLogOut(cancelSubscription)
}
