import { useMemo } from "react"
import { useAccountProperty } from "src/hooks/me"
import { useFindUserEntries } from "src/hooks/userEntry"
import { useFindUserEvents } from "src/hooks/userEvent"
import type {
  UserItemQuerySelector,
  UserItemSharedProperties,
  UserEventType,
  UserEventOfType,
  SubEntryType,
  SubEntryOfType,
} from "src/types"
import {
  createEntryQueryObjectForType,
  createEventQueryObjectForType,
  userEntriesToSubEntryUserItems,
} from "src/utils"

type ReturnValue<A extends SubEntryType & UserEventType> = {
  isFetchingEvents: boolean;
  isFetchingEntries: boolean;
  events: Array<UserEventOfType<A>>;
  subEntries: Array<SubEntryOfType<A> & UserItemSharedProperties>;
}

export function useEventsAndSubEntriesOfType<A extends UserEventType & SubEntryType>(
  activityType: A,
  selector: UserItemQuerySelector,
  limit?: number,
): ReturnValue<A> {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const eventQueryObject = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      activityType,
      {
        selector,
        limit,
      },
    ),
    [
      accountId,
      activityType,
      selector,
      limit,
    ],
  )

  const entryQueryObject = useMemo(
    () => createEntryQueryObjectForType(
      accountId,
      activityType,
      {
        selector,
        limit,
      },
    ),
    [
      accountId,
      activityType,
      selector,
      limit,
    ],
  )

  const {
    result: events,
    isFetching: isFetchingEvents,
  } = useFindUserEvents<UserEventOfType<A>>(eventQueryObject)

  const {
    result: entries,
    isFetching: isFetchingEntries,
  } = useFindUserEntries(entryQueryObject)

  const subEntries = useMemo(
    () => userEntriesToSubEntryUserItems(
      entries,
      activityType,
    ),
    [
      entries,
      activityType,
    ],
  )

  return {
    isFetchingEvents,
    isFetchingEntries,
    events: events as Array<UserEventOfType<A>>,
    subEntries,
  }
}
