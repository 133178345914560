export enum InsightRange {
  upToNow = 0, // special value only for TimeInRange
  sinceWakeUp = -1,

  // times measured in hours
  oneHour = 1, // note this was removed and is no longer an option
  threeHours = 3,
  sixHours = 6,
  twelveHours = 12,
  oneDay = 24,
  threeDays = 3 * 24,
  oneWeek = 7 * 24,
  twoWeeks = 2 * 7 * 24,
  thirtyDays = 30 * 24,
  sixtyDays = 60 * 24,
  ninetyDays = 90 * 24,
}
