import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import {
  useUpdateSettings,
  useDeviceSettingsProperty,
} from "src/hooks/settings"
import type { Insight } from "src/types"

export function useUpdateInsight() {
  const insights = useDeviceSettingsProperty("gauges")
  const updateSettings = useUpdateSettings()

  const updateInsight = useCallback(
    (oldInsight: Insight, newInsight: Insight): Promise<AxiosResponse> => {
      const data = {
        gauges: insights.map(
          (i: Insight) => {
            if (i.key === oldInsight.key && i.range === oldInsight.range) {
              return newInsight
            }
            return i

          },
        ),
      }

      return updateSettings(data)
    },
    [
      insights,
      updateSettings,
    ],
  )

  return updateInsight
}
