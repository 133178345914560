import { useMemo } from "react"
import { useAccountProperty } from "src/hooks/me"
import { useFindUserEvents } from "src/hooks/userEvent"
import type { GlucoseUserEvent } from "src/types"
import { ActivityType } from "src/types"
import { createEventQueryObjectForType } from "src/utils"

/*
 * find the first glucose reading before the given time or in general
 * if no time is provided
 */
export function useFirstGlucoseReading(
  time?: string,
) {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const previousGlucoseQuery = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: (
          time
            ? { started_at: { $lt: time } }
            : {}
        ),
        sort: [{ started_at: "desc" as const }],
        limit: 1,
      },
    ),
    [
      time,
      accountId,
    ],
  )

  return useFindUserEvents<GlucoseUserEvent>(previousGlucoseQuery)
}
