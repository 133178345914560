import type { ComponentPropsWithoutRef } from "react"
import { useTotalUnitsDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function TotalUnitsDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  const value = useTotalUnitsDayMetric(dayStart)

  if (Number.isNaN(value)) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.total_units",
        {
          units: round(
            value,
            1,
          ).toLocaleString(navigator.language),
        },
      )}
    </button>
  )
}
