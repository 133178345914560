import type {
  Cache as SWRCache,
  State as SWRState,
} from "swr"
import { noticeError } from "src/utils"

const swrKeyPrefix = "sm-swr"

function getLocalStorageSWRKey(key: string): string {
  return `${swrKeyPrefix}${key}`
}

// this is a cache provider for SWR
// https://swr.vercel.app/docs/advanced/cache
export class LocalStorageSWRCache<Data> implements SWRCache<Data> {
  private map = new Map<string, SWRState<Data>>()

  get(key: string): SWRState<Data> | undefined {
    let value = this.map.get(key)

    if (value === undefined) {
      const localStorageSWRKey = getLocalStorageSWRKey(key)
      const localStorageValue = localStorage.getItem(localStorageSWRKey)

      if (localStorageValue !== null) {
        try {
          value = JSON.parse(localStorageValue) as SWRState<Data> | undefined
        } catch (e) {
          noticeError(
            e,
            "SWRLocalStorageParse",
            { value: localStorageValue },
          )

          // remove the item if JSON.parse throws an error
          localStorage.removeItem(localStorageSWRKey)
        }
      }
    }

    return value
  }

  set(key: string, value: SWRState<Data>): void {
    this.map.set(
      key,
      value,
    )

    try {
      const localStorageSWRKey = getLocalStorageSWRKey(key)
      localStorage.setItem(
        localStorageSWRKey,
        JSON.stringify(value),
      )
    } catch (e) {
      // JSON.stringify will throw an error if there are circular references or BigInts
      noticeError(
        e,
        "SWRLocalStorageStringify",
        { value },
      )
    }
  }

  delete(key: string): void {
    this.map.delete(key)
    const localStorageSWRKey = getLocalStorageSWRKey(key)
    localStorage.removeItem(localStorageSWRKey)
  }

  keys(): IterableIterator<string> {
    return this.map.keys()
  }
}
