import { Loading } from "./Loading"
import { NoDataSourceError } from "./NoDataSourceError"
import { Reading } from "./Reading"
import { StaleDataError } from "./StaleDataError"
import { useRecentReadingContext } from "src/contexts"
import {
  useMe,
  useSources,
} from "src/hooks"

interface Props {
  userName: string;
}

export function UserHeaderData(props: Props) {
  const { userName } = props
  const { data: me } = useMe()
  const accountId = me?.account.id ?? -1

  const { data: sources } = useSources()

  const {
    result,
    isFetching,
  } = useRecentReadingContext(accountId)

  if (sources === undefined || (isFetching && !result.length)) {
    return <Loading userName={userName} />
  }

  if (sources.length === 0) {
    return <NoDataSourceError userName={userName} />
  }

  if (!result[0]) {
    return <StaleDataError userName={userName} />
  }

  return (
    <Reading
      userName={userName}
      glucoseUserEvent={result[0]}
    />
  )
}
