import { debounce } from "lodash-es"
import {
  useCallback,
  useState,
  useRef,
} from "react"

interface ReturnValue {
  feedScrollTime: string | undefined;
  graphScrollId: string | undefined;
  // note time may also be "now" to signify scrolling to the end of the graph
  onFeedScroll: (time: string) => void;
  // note id may also be "top" to signify scrolling to the top of the feed
  onGraphScroll: (id: string) => void;
  updateScrollController: (controller: "graph" | "feed" | undefined) => void;
}

const debounceTime = 250

export function useScrollState(): ReturnValue {
  const scrollControllerRef = useRef<"graph" | "feed" | undefined>(undefined)

  /*
   * the graph or feed asserts themselves as the element controlling the scroll position
   * when the user interacts with them, so we know which element to listen to
   *
   * the other element follows the controller, and we ignore its scroll position changes
   */
  const updateScrollController = useCallback(
    (controller: "graph" | "feed" | undefined) => {
      scrollControllerRef.current = controller
    },
    [scrollControllerRef],
  )

  const [
    feedScrollTime,
    setFeedScrollTime,
  ] = useState<string | undefined>(undefined)

  const [
    graphScrollId,
    setGraphScrollId,
  ] = useState<string | undefined>(undefined)

  const debouncedSetFeedScrollTime = useCallback(
    debounce(
      setFeedScrollTime,
      debounceTime,
    ),
    [setFeedScrollTime],
  )

  const debouncedSetGraphScrollId = useCallback(
    debounce(
      setGraphScrollId,
      debounceTime,
    ),
    [setGraphScrollId],
  )

  const onFeedScroll = useCallback(
    (time: string) => {
      const scrollController = scrollControllerRef.current
      if (scrollController === "feed") {
        debouncedSetFeedScrollTime(time)
      }
    },
    [
      debouncedSetFeedScrollTime,
      scrollControllerRef,
    ],
  )

  const onGraphScroll = useCallback(
    (id?: string) => {
      const scrollController = scrollControllerRef.current
      if (scrollController === "graph") {
        debouncedSetGraphScrollId(id)
      }
    },
    [
      debouncedSetGraphScrollId,
      scrollControllerRef,
    ],
  )

  return {
    updateScrollController,
    feedScrollTime,
    onFeedScroll,
    graphScrollId,
    onGraphScroll,
  }
}
