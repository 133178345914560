import { useCallback } from "react"
import { CreationOverlay } from "./CreationOverlay"
import { Feed } from "./Feed"
import { Graph } from "./Graph"
import styles from "./Home.module.scss"
import {
  Insights,
  InsightsContextProvider,
} from "./Insights"
import { MultiTabHeader } from "./MultiTabHeader"
import { TabContextProvider } from "./TabContext"
import { useOldestTime } from "./useOldestTime"
import { UserHeader } from "./UserHeader"
import { useScrollState } from "./useScrollState"
import {
  EntryModal,
  FavoritesModal,
} from "src/components"

export function Home() {
  const oldestTime = useOldestTime()

  const {
    updateScrollController,
    feedScrollTime,
    onFeedScroll,
    graphScrollId,
    onGraphScroll,
  } = useScrollState()

  const graphUpdateScrollController = useCallback(
    () => {
      updateScrollController("graph")
    },
    [updateScrollController],
  )

  const feedUpdateScrollController = useCallback(
    () => {
      updateScrollController("feed")
    },
    [updateScrollController],
  )

  return (
    <>
      <UserHeader />
      <EntryModal>
        <FavoritesModal>
          <TabContextProvider>
            <InsightsContextProvider>
              <CreationOverlay>
                <div className={styles.container}>
                  <Graph
                    className={styles.graph}
                    scrollToTime={feedScrollTime}
                    onScrollChange={onGraphScroll}
                    graphStop={oldestTime}
                    updateScrollController={graphUpdateScrollController}
                  />
                  <MultiTabHeader className={styles.tabs} />
                  <Insights className={styles.insights} />
                  <Feed
                    className={styles.activityList}
                    scrollToId={graphScrollId}
                    onScrollChange={onFeedScroll}
                    feedStop={oldestTime}
                    updateScrollController={feedUpdateScrollController}
                  />
                </div>
              </CreationOverlay>
            </InsightsContextProvider>
          </TabContextProvider>
        </FavoritesModal>
      </EntryModal>
    </>
  )
}
