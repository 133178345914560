import {
  ModalTrigger,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"

import styles from "./NutritionDisplay.module.scss"
import { NutritionModal } from "./NutritionModal"
import { usePolyglot } from "src/contexts"
import type { FoodActivity } from "src/types"
import { round } from "src/utils"

const polyglotPrefix = "components.entry_modal.food."

interface Props {
  activity: FoodActivity;
  updateActivity: (a: FoodActivity) => void;
}

export function NutritionDisplay(props: Props) {
  const {
    activity, updateActivity,
  } = props

  const polyglot = usePolyglot()

  return (
    <NutritionModal
      activity={activity}
      updateActivity={updateActivity}
    >
      <div className={styles.nutritionDisplay}>
        <ModalTrigger asChild>
          <SliderValue
            className="entry-modal-nutrition-info-display-label"
            sizePreset="small"
          >
            <SliderValueInput
              className={styles.nutritionValue}
              value={round(
                activity.protein ?? 0,
                1,
              )}
              readOnly
            />
            <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}protein.label_unit`)}>
              <SliderValueLabel>
                {polyglot.t(`${polyglotPrefix}protein.label_detail`)}
              </SliderValueLabel>
            </SliderValueLabelContainer>
          </SliderValue>
        </ModalTrigger>
        <ModalTrigger asChild>
          <SliderValue
            className="entry-modal-nutrition-info-display-label"
            sizePreset="small"
          >
            <SliderValueInput
              className={styles.nutritionValue}
              value={round(
                activity.fat ?? 0,
                1,
              )}
              readOnly
            />
            <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}fat.label_unit`)}>
              <SliderValueLabel>
                {polyglot.t(`${polyglotPrefix}fat.label_detail`)}
              </SliderValueLabel>
            </SliderValueLabelContainer>
          </SliderValue>
        </ModalTrigger>
        <ModalTrigger asChild>
          <SliderValue
            className="entry-modal-nutrition-info-display-label"
            sizePreset="small"
          >
            <SliderValueInput
              className={styles.nutritionValue}
              value={round(
                activity.sugar ?? 0,
                1,
              )}
              readOnly
            />
            <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}sugar.label_unit`)}>
              <SliderValueLabel>
                {polyglot.t(`${polyglotPrefix}sugar.label_detail`)}
              </SliderValueLabel>
            </SliderValueLabelContainer>
          </SliderValue>
        </ModalTrigger>
        <ModalTrigger asChild>
          <SliderValue
            className="entry-modal-nutrition-info-display-label"
            sizePreset="small"
          >
            <SliderValueInput
              className={styles.nutritionValue}
              value={round(
                activity.fiber ?? 0,
                1,
              )}
              readOnly
            />
            <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}fiber.label_unit`)}>
              <SliderValueLabel>
                {polyglot.t(`${polyglotPrefix}fiber.label_detail`)}
              </SliderValueLabel>
            </SliderValueLabelContainer>
          </SliderValue>
        </ModalTrigger>
        <ModalTrigger asChild>
          <SliderValue
            className="entry-modal-nutrition-info-display-label"
            sizePreset="small"
          >
            <SliderValueInput
              className={styles.nutritionValue}
              value={round(activity.calories ?? 0)}
              readOnly
            />
            <SliderValueLabelContainer unit={polyglot.t(`${polyglotPrefix}calories.label_unit`)}>
              <SliderValueLabel>
                {polyglot.t(`${polyglotPrefix}calories.label_detail`)}
              </SliderValueLabel>
            </SliderValueLabelContainer>
          </SliderValue>
        </ModalTrigger>
      </div>
    </NutritionModal>
  )
}
