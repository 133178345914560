import type { SWRResponse } from "swr"
import useSWR from "swr"

import type { MeResponse } from "./MeResponse"
import { useMeSWRKey } from "./useMeSWRKey"
import { useUserGetData } from "src/hooks/userApi"

export function useMe(): SWRResponse<MeResponse> {
  const meKey = useMeSWRKey()
  const userGetData = useUserGetData<MeResponse>()

  return useSWR<MeResponse>(
    userGetData && meKey,
    userGetData,
  )
}
