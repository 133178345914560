import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useFind } from "src/hooks/rxdb"
import { medicationCollectionKey } from "src/schema"
import type { Medication } from "src/types"

export function useFindMedications(
  queryObject: MangoQuery<Medication>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<Medication> {
  return useFind<Medication>(
    queryObject,
    medicationCollectionKey,
    options,
  )
}
