import { useCallback } from "react"
import { useUserGet } from "src/hooks/userApi"
import { noticeError } from "src/utils"

interface RedirectUrlResponse {
  redirect_url: string;
}

export function useGetDexcomRedirectURL() {
  const userGet = useUserGet()

  return useCallback(
    async () => {
      try {
        if (!userGet) {
          throw new Error("cannot get redirect url for unknown user")
        }

        const response = await userGet<RedirectUrlResponse>({
          url: "/api/v3/sources/dexcom/redirect_url",
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "GetDexcomRedirectURL",
        )

        throw e
      }
    },
    [userGet],
  )
}
