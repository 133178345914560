import { createContext } from "react"
import type { RoutePath } from "src/types"

export interface SessionStorageContextValue {
  loginRedirect: RoutePath;
  setLoginRedirect: (s?: RoutePath) => void;
  logoutRedirect: RoutePath;
  setLogoutRedirect: (s?: RoutePath) => void;
}

type ContextType = SessionStorageContextValue | null

export const SessionStorageContext = createContext<ContextType>(null)
