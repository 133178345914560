import { Dialog } from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import {
  useState,
  useCallback,
} from "react"

import { ChangePasswordForm } from "./ChangePasswordForm"
import type { ChangePasswordFormState } from "./ChangePasswordFormState"
import { DiscardChangesDialog } from "src/components"

interface Props {
  children: ReactNode;
}

const defaultFormState: ChangePasswordFormState = {
  currentPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
}

export function ChangePasswordDialog(props: Props) {
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const [
    isDiscardOpen,
    setIsDiscardOpen,
  ] = useState<boolean>(false)

  const [
    formState,
    setFormState,
  ] = useState<ChangePasswordFormState>(defaultFormState)

  const closeDialog = useCallback(
    () => {
      setIsOpen(false)
    },
    [setIsOpen],
  )

  const onOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen && formState !== defaultFormState) {
        setIsDiscardOpen(true)
        return
      }

      if (newOpen) {
        setFormState(defaultFormState)
      }

      setIsOpen(newOpen)
    },
    [
      formState,
      setIsOpen,
      setFormState,
      setIsDiscardOpen,
    ],
  )

  return (
    <Dialog
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      {props.children}
      <ChangePasswordForm
        onSave={closeDialog}
        formState={formState}
        setFormState={setFormState}
      />
      <DiscardChangesDialog
        open={isDiscardOpen}
        setOpen={setIsDiscardOpen}
        discardChanges={closeDialog}
      />
    </Dialog>
  )
}
