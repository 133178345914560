import type { RxJsonSchema } from "rxdb"
import {
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./sharedSchema"
import type { Insulin } from "src/types"


// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const insulinSchema: RxJsonSchema<Insulin> = {
  title: "insulin_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    category: {
      type: "string",
      maxLength: 128,
    },
    description: { type: "string" },
    name: {
      type: "string",
      maxLength: 256,
    },
    duration_max: { type: "number" },
    duration_min: { type: "number" },
    onset_max: { type: "number" },
    onset_min: { type: "number" },
    peak_max: { type: "number" },
    peak_min: { type: "number" },
    forms: {
      type: "array",
      items: { type: "string" },
    },
    ...sharedSchemaProperties,
  },
  required: [...sharedSchemaRequired],
  indexes: [
    "is_deleted",
    [
      "is_deleted",
      "category",
    ],
    [
      "category",
      "is_deleted",
      "name",
    ],
    ...sharedSchemaIndexes,
  ],
}
