import type { ComponentPropsWithoutRef } from "react"
import { useNormalRangeDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function NormalRangeDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  const value = useNormalRangeDayMetric(dayStart)

  if (value === undefined) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.normal_range",
        {
          above: round(value.percentageAboveNormal * 100),
          within: round(value.percentageWithinNormal * 100),
          below: round(value.percentageBelowNormal * 100),
        },
      )}
    </button>
  )
}
