import {
  useCallback,
  useState,
} from "react"

import { useEventListener } from "usehooks-ts"

type RemToPixels = (n: number) => number

function getFontSizeInPx(): number {
  const documentStyles = getComputedStyle(document.documentElement)
  return parseFloat(documentStyles.fontSize)
}

// returns a function that converts rem to px
export function useRemToPixels(): RemToPixels {
  const [
    fontSize,
    setFontSize,
  ] = useState<number>(getFontSizeInPx())

  const updateFontSize = useCallback(
    () => {
      setFontSize(getFontSizeInPx())
    },
    [setFontSize],
  )

  useEventListener(
    "resize",
    updateFontSize,
  )

  return useCallback(
    (numberOfRem: number) => numberOfRem * fontSize,
    [fontSize],
  )
}
