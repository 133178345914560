import { useGetTimeDifferenceInPixels } from "./useGetTimeDifferenceInPixels"

export function useTimeDifferenceInPixels(
  timeA: string,
  timeB: string,
): number {
  const getTimeDifferenceInPixels = useGetTimeDifferenceInPixels()
  return getTimeDifferenceInPixels(
    timeA,
    timeB,
  )
}
