import {
  createContext,
  useContext,
} from "react"
import type { DeepReadonlyObject } from "rxdb"
import type { AccountMedication } from "src/types"

export interface AccountMedicationModalContextValue {
  openAccountMedicationModal: (
    accountMedication?: DeepReadonlyObject<AccountMedication>,
  ) => void;
}

type ContextType = AccountMedicationModalContextValue | null
export const AccountMedicationModalContext = createContext<ContextType>(null)

export function useAccountMedicationModalContext() {
  const context = useContext(AccountMedicationModalContext)

  if (context === null) {
    throw new Error("AccountMedicationModal components must be wrapped in <AccountMedicationModalContext />")
  }

  return context
}
