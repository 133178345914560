import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { LiquidActivity } from "src/types"
import { liquidMicrolitersToUnit } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: LiquidActivity;
}

export const LiquidActivityTitle = forwardRef<HTMLDivElement, Props>(
  function LiquidActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    if (!activity.microliters) {
      return null
    }

    const value = liquidMicrolitersToUnit(
      activity.microliters,
      activity.units,
    )

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {polyglot.t(
          `unit.${activity.units}`,
          {
            smart_count: value,
            value: value.toLocaleString(navigator.language),
          },
        )}
      </div>
    )
  },
)
