import type { ComponentPropsWithoutRef } from "react"
import { useExerciseDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function ExerciseDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  const timeInMinutes = useExerciseDayMetric(dayStart)

  if (Number.isNaN(timeInMinutes)) {
    return null
  }

  const hours = Math.floor(timeInMinutes / 60)
  const minutes = round(timeInMinutes % 60)

  const message = !hours
    ? polyglot.t(
      "pages.home.activity_list.day_stats.exercise_minutes",
      { minutes },
    )
    : !minutes
      ? polyglot.t(
        "pages.home.activity_list.day_stats.exercise_hours",
        { hours },
      )
      : polyglot.t(
        "pages.home.activity_list.day_stats.exercise_hours_and_minutes",
        {
          hours,
          minutes,
        },
      )

  return (
    <button {...buttonProps}>
      {children}
      {message}
    </button>
  )
}
