import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"

import { useUpsertCollectionItem } from "src/hooks/rxdb"
import { useUserPut } from "src/hooks/userApi"
import type { DeepPartial } from "src/types"
import { noticeError } from "src/utils"

type Response<Key extends string, T> = {
  [k in Key]: T;
}

type UpdateItem<T> = DeepReadonlyObject<DeepPartial<T>> & { id: string }

type UpdateCollectionItem<Key extends string, T> = (item: UpdateItem<T>) => Promise<AxiosResponse<Response<Key, T>>>

type TransformResponse<Key extends string, T> = (data: string) => Response<Key, T>

function defaultTransformResponse<Key extends string, T>(data: string): Response<Key, T> {
  try {
    const response = JSON.parse(data) as Response<Key, T>
    return response
  } catch (e) {
    noticeError(
      e,
      "TransformResponse",
    )

    throw e
  }
}

export function useUpdateCollectionItem<Key extends string, T>(
  collectionKey: string,
  responseKey: Key,
  url: string,
  transformResponse: TransformResponse<Key, T> = defaultTransformResponse<Key, T>,
): UpdateCollectionItem<Key, T> {
  const userPut = useUserPut()
  const upsertCollectionItem = useUpsertCollectionItem<T>(collectionKey)

  return useCallback(
    async (item: UpdateItem<T>) => {
      const data = { [responseKey]: item }

      try {
        if (!userPut) {
          throw new Error(`cannot update ${responseKey} for unauthorized user`)
        }

        const response = await userPut<Response<Key, T>>({
          data,
          url: `${url}/${item.id}`,
          transformResponse,
        })

        await upsertCollectionItem(response.data[responseKey])
        return response
      } catch (e) {
        noticeError(
          e,
          "UpdateCollectionItem",
          {
            [responseKey]: item,
            collectionItem: responseKey,
            collectionKey,
          },
        )

        throw e
      }
    },
    [
      url,
      responseKey,
      transformResponse,
      userPut,
      upsertCollectionItem,
    ],
  )
}
