import { transformAccountInsulinResponse } from "./transformAccountInsulinResponse"
import { useDeleteCollectionItem } from "src/hooks/rxdb"
import { accountInsulinCollectionKey } from "src/schema"
import type { AccountInsulin } from "src/types"

export function useDeleteAccountInsulin() {
  return useDeleteCollectionItem<"account_insulin", AccountInsulin>(
    accountInsulinCollectionKey,
    "account_insulin",
    "/api/v3/account_insulins",
    transformAccountInsulinResponse,
  )
}
