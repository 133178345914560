import { Locale } from "src/types"

const localeValues: string[] = Object.values(Locale)

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/languages
export function getLocaleMatch(): Locale {
  // find an exact language tag match
  const languageTagMatch = window.navigator.languages.find(
    (languageTag: string) => localeValues.includes(languageTag),
  )

  if (languageTagMatch) {
    return languageTagMatch as Locale
  }

  // find a match for the language but not necessarily the region / dialect
  const languageMatch = window.navigator.languages
    .map((languageTag: string) => languageTag.split("-")[0])
    .find((language: string) => localeValues.includes(language))

  if (languageMatch) {
    return languageMatch as Locale
  }

  // fallback to english if no match is found
  return Locale.English
}
