import {
  DialogContentForm,
  DialogBody,
  DialogHeading,
  DialogCloseX,
  ActionSubmit,
  DialogButton,
  DialogButtons,
  Label,
  Input,
  useToasterContext,
  useDialogContext,
} from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import type {
  FormEventHandler,
  FocusEventHandler,
  ChangeEventHandler,
} from "react"
import {
  useEffect,
  useCallback,
  useState,
} from "react"

import styles from "./DataSource.module.scss"
import { useDataSourceAlreadyExistsDialogContext } from "./DataSourceAlreadyExistsDialogContext"
import { useNoNewReadingsDialogContext } from "./NoNewReadingsDialogContext"
import { usePolyglot } from "src/contexts"
import { useCreateSource } from "src/hooks"
import {
  AddSourceErrorToast,
  AddSourceSuccessToast,
} from "src/toasts"
import { SourceType } from "src/types"

const polyglotPrefix = "pages.settings.data_source.nightscout_dialog."
const urlId = "nightscout-url-input"
const tokenId = "nightscout-auth-token-input"

const defaultFormState = {
  url: "",
  token: "",
}

export function NightscoutForm() {
  const createSource = useCreateSource()
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const {
    setOpen,
    open: isOpen,
  } = useDialogContext()
  const { openNoNewReadingsDialog } = useNoNewReadingsDialogContext()
  const { openDataSourceAlreadyExistsDialog } = useDataSourceAlreadyExistsDialogContext()

  const [
    formState,
    setFormState,
  ] = useState<{
    url: string;
    token: string;
  }>(defaultFormState)

  useEffect(
    () => {
      if (isOpen) {
        setFormState(defaultFormState)
      }
    },
    [isOpen],
  )

  const onNightscoutUrlChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        url: e.target.value,
      })
    },
    [
      setFormState,
      formState,
    ],
  )

  const onNightscoutUrlBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      let value = e.target.value
      const regex = /^(http|https):\/\//

      if (!regex.test(value)) {
        value = `http://${value}`
      }

      setFormState({
        ...formState,
        url: value,
      })
    },
    [
      setFormState,
      formState,
    ],
  )

  const onNightscoutTokenChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        token: e.target.value,
      })
    },
    [
      setFormState,
      formState,
    ],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          const response = await createSource({
            source_type: SourceType.Nightscout,
            url: formState.url,
            token: formState.token,
          })

          if (response.data.status === "fail") {
            throw new Error("request failed")
          }

          showToast(
            "add-nightscout-source-success-toast",
            AddSourceSuccessToast,
          )

          setOpen(false)

          if (response.data.already_exists !== undefined) {
            openDataSourceAlreadyExistsDialog()
          }

          const noNewReadings = response.data.last_point_at === undefined || (
            dayjs()
              .subtract(
                10,
                "minutes",
              )
              .isAfter(response.data.last_point_at)
          )

          if (noNewReadings) {
            openNoNewReadingsDialog(SourceType.Nightscout)
          }
        } catch (e) {
          showToast(
            "add-nightscout-source-error-toast",
            AddSourceErrorToast,
          )
        }
      })()
    },
    [
      setOpen,
      formState,
      createSource,
      showToast,
      openNoNewReadingsDialog,
      openDataSourceAlreadyExistsDialog,
    ],
  )

  return (
    <DialogContentForm onSubmit={onSubmit}>
      <DialogHeading>
        {polyglot.t(`${polyglotPrefix}heading`)}
        <DialogCloseX />
      </DialogHeading>
      <DialogBody className={styles.dialogBody}>
        <div>
          <Label
            htmlFor={urlId}
            sizePreset="small"
            className={styles.nightscoutLabel}
          >
            {polyglot.t(`${polyglotPrefix}url_label`)}
          </Label>
          <Input
            id={urlId}
            placeholder={polyglot.t(`${polyglotPrefix}url_placeholder`)}
            type="url"
            className={styles.nightscoutURLInput}
            value={formState.url}
            onChange={onNightscoutUrlChange}
            onBlur={onNightscoutUrlBlur}
            required
          />
          <Label
            htmlFor={tokenId}
            sizePreset="small"
            className={styles.nightscoutLabel}
          >
            {polyglot.t(`${polyglotPrefix}token_label`)}
          </Label>
          <Input
            id={tokenId}
            placeholder={polyglot.t(`${polyglotPrefix}token_placeholder`)}
            type="text"
            className={styles.nightscoutTokenInput}
            value={formState.token}
            onChange={onNightscoutTokenChange}
          />
        </div>
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
          <ActionSubmit
            value={polyglot.t(`${polyglotPrefix}submit_cta`)}
            className={styles.dialogMultiButton}
          />
        </DialogButtons>
      </DialogBody>
    </DialogContentForm>
  )
}
