import { useMemo } from "react"
import { usePolyglot } from "src/contexts"
import { ActivityType } from "src/types"

const polyglotPrefix = "components.activity_name."
interface Props {
  activityType: ActivityType;
}

export function ActivityName(props: Props) {
  const polyglot = usePolyglot()

  const localizationKey = useMemo(
    () => {
      switch (props.activityType) {
        case ActivityType.DeviceChange:
          return `${polyglotPrefix}device_change`
        case ActivityType.Exercise:
          return `${polyglotPrefix}exercise`
        case ActivityType.Food:
          return `${polyglotPrefix}food`
        case ActivityType.Glucose:
          return `${polyglotPrefix}glucose`
        case ActivityType.Liquid:
          return `${polyglotPrefix}liquid`
        case ActivityType.Insulin:
          return `${polyglotPrefix}insulin`
        case ActivityType.Medication:
          return `${polyglotPrefix}medication`
        case ActivityType.Note:
          return `${polyglotPrefix}note`
        default:
          return `${polyglotPrefix}unknown`
      }
    },
    [props.activityType],
  )

  return (
    <>
      {polyglot.t(localizationKey)}
    </>
  )
}
