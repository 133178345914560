import type { MeResponse } from "./MeResponse"
import {
  Theme,
  InsightKey,
} from "src/types"
import {
  isInStringEnum,
  noticeError,
} from "src/utils"

export function transformMeResponse(data: string): MeResponse | undefined {
  try {
    const json = JSON.parse(data) as MeResponse

    const theme = json.device.settings.theme
    const themeIsValid = isInStringEnum(
      theme ?? "",
      Theme,
    )

    if (!themeIsValid) {
      json.device.settings.theme = Theme.Sugarmate
    }

    // remove any unsupported gauges
    json.device.settings.gauges = json.device.settings.gauges?.filter(
      ({ key }) => isInStringEnum(
        key,
        InsightKey,
      ),
    )

    return json
  } catch (e) {
    noticeError(
      e,
      "MeResponseParse",
      { response: data },
    )
  }

  return undefined
}
