import type { PartialGraphItemProps } from "./PartialGraphItemProps"

export function sortGraphItems(
  a: PartialGraphItemProps,
  b: PartialGraphItemProps,
): number {
  if (a.startedAt === b.startedAt) {
    return 0
  }

  const aBeforeB = a.startedAt < b.startedAt

  if (aBeforeB) {
    return -1
  }

  return 1
}
