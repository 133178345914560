import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { UserEventsCollectionContext } from "src/contexts/CollectionContext"
import { useFindCollectionContext } from "src/hooks/rxdb"
import type { UserEvent } from "src/types"

export function useFindUserEvents<T extends UserEvent>(
  queryObject: MangoQuery<T>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<T> {
  return useFindCollectionContext<T>(
    UserEventsCollectionContext,
    queryObject,
    options,
  )
}
