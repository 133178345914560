import { useCalculationsContext } from "../CalculationsContext"
import styles from "./Tile.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function GlucoseCoefficientOfVariation(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()
  const { glucoseCalculations } = useCalculationsContext()
  const { coefficientOfVariation } = glucoseCalculations[range] || {}

  const value = isNaN(coefficientOfVariation) || coefficientOfVariation === undefined
    ? "--"
    : round(coefficientOfVariation)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseCoefficientOfVariation} />
      <TileTimeSpan range={range} />
      <TileValue>
        {value.toLocaleString(navigator.language)}
        <span className={styles.valuePercentSymbol}>
          %
        </span>
      </TileValue>
      <TileUnit>
        {polyglot.t("pages.home.insights.cv_unit")}
      </TileUnit>
    </>
  )
}
