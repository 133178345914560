import { LiquidUnit } from "src/types"

interface Properties {
  step: number;
  min: number;
  max: number;
}

export const inputPropertiesByUnit: Record<LiquidUnit, Properties> = {
  [LiquidUnit.Milliliters]: {
    step: 1,
    min: 0,
    max: 5000,
  },
  [LiquidUnit.Liters]: {
    step: 0.01,
    min: 0,
    max: 5,
  },
  [LiquidUnit.FluidOunces]: {
    step: 0.25,
    min: 0,
    max: 200,
  },
  [LiquidUnit.Cups]: {
    step: 0.25,
    min: 0,
    max: 20,
  },
  [LiquidUnit.Pints]: {
    step: 0.25,
    min: 0,
    max: 10,
  },
}
