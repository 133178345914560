import { useCallback } from "react"
import { useDisplayGlucoseUnit } from "./useDisplayGlucoseUnit"
import { useDisplayGlucoseValue } from "./useDisplayGlucoseValue"
import { usePolyglot } from "src/contexts"

/*
 * returns a function that returns the glucose value and unit
 * in a readable localized string
 */
export function useDisplayGlucoseString(): (value: number) => string {
  const polyglot = usePolyglot()
  const displayGlucoseValue = useDisplayGlucoseValue()
  const displayGlucoseUnit = useDisplayGlucoseUnit()

  return useCallback(
    (value: number) => polyglot.t(
      "unit.glucose_string",
      {
        value: displayGlucoseValue(value),
        unit: displayGlucoseUnit(),
      },
    ),
    [
      polyglot,
      displayGlucoseUnit,
      displayGlucoseValue,
    ],
  )
}
