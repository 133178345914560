import type { ComponentPropsWithoutRef } from "react"
import { useStandardDeviationDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import {
  useReadableGlucoseUnit,
  useReadableGlucoseValue,
} from "src/hooks"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function StandardDeviationDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()
  const glucoseUnit = useReadableGlucoseUnit()

  const value = useStandardDeviationDayMetric(dayStart)
  const deviation = useReadableGlucoseValue(value)

  if (isNaN(value)) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.standard_deviation",
        {
          deviation,
          glucoseUnit,
        },
      )}
    </button>
  )
}
