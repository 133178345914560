export enum ExerciseType {
  Archery = "archery",
  AustralianFootball = "au_football",
  Badminton = "badminton",
  Barre = "barre",
  Baseball = "baseball",
  Basketball = "basketball",
  Bowling = "bowling",
  Boxing = "boxing",
  Cardio = "cardio",
  Climbing = "climbing",
  Cricket = "cricket",
  CrossCountrySkiing = "cross_country_skiing",
  Curling = "curling",
  Cycling = "cycling",
  Dance = "dance",
  Disc = "disc",
  Elliptical = "elliptical",
  Equestrian = "equestrian",
  Fencing = "fencing",
  Fishing = "fishing",
  FitnessGaming = "fitness_gaming",
  Football = "football",
  Golf = "golf",
  Gymnastics = "gymnastics",
  HandCycling = "hand_cycling",
  Handball = "handball",
  HIIT = "hiit",
  Hiking = "hiking",
  Hockey = "hockey",
  Hunting = "hunting",
  JumpRope = "jump_rope",
  Kickboxing = "kickboxing",
  Lacrosse = "lacrosse",
  MartialArts = "martial_arts",
  Meditating = "meditating",
  MountainBiking = "mountain_biking",
  Multisport = "multisport",
  Paddling = "paddling",
  Pickleball = "pickleball",
  Pilates = "pilates",
  PingPong = "ping_pong",
  Polo = "polo",
  Raquetball = "racquetball",
  Rowing = "rowing",
  Rugby = "rugby",
  Running = "running",
  Sailing = "sailing",
  ScubaDiving = "scuba_diving",
  Skating = "skating",
  Skateboarding = "skateboarding",
  Skiing = "skiing",
  SnowSports = "snow_sports",
  Snowboarding = "snowboarding",
  Soccer = "soccer",
  Squash = "squash",
  Stairs = "stairs",
  StrengthTraining = "strength_training",
  Stretching = "stretching",
  Surfing = "surfing",
  Swimming = "swimming",
  TaiChi = "tai_chi",
  Tennis = "tennis",
  TrackAndField = "track_field",
  Volleyball = "volleyball",
  Walking = "walking",
  WaterFitness = "water_fitness",
  WaterPolo = "water_polo",
  WaterSports = "water_sports",
  Wheelchair = "wheelchair",
  Wrestling = "wrestling",
  Yoga = "yoga",
  None = "none",
}
