import dayjs from "dayjs"

/*
 * returns the timezone offset in hours
 */
export function getTimezoneOffset(): number {
  const tz = dayjs()
    .format("Z")
    .split(":")

  const multiplier = parseInt(tz[0]) > 0 ? 1 : -1
  const hours = Math.abs(parseInt(tz[0]))
  const minutes = Math.abs(parseInt(tz[1]))

  return (hours + (minutes / 60)) * multiplier
}
