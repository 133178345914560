import { useMemo } from "react"
import {
  useAccountProperty,
  useFindUserEntries,
} from "src/hooks"
import type { LiquidActivity } from "src/types"
import {
  ActivityType,
  LiquidUnit,
} from "src/types"
import { createEntryQueryObjectForType } from "src/utils"

export const fallbackDefaultLiquidActivity: LiquidActivity = {
  liquid_type: undefined,
  units: LiquidUnit.Milliliters,
  microliters: 0,
}

interface ReturnValue {
  defaultLiquidActivity: LiquidActivity;
  isFetching: boolean;
}

/*
 * returns a default liquid activity that uses the same units as the most
 * recent liquid activity
 */
export function useDefaultLiquidActivity(): ReturnValue {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const lastLiquidEntrySelector = useMemo(
    () => createEntryQueryObjectForType(
      accountId,
      ActivityType.Liquid,
      {
        sort: [{ started_at: "desc" as const }],
        limit: 1,
      },
    ),
    [accountId],
  )

  const {
    result,
    isFetching,
  } = useFindUserEntries(lastLiquidEntrySelector)

  const defaultLiquidActivity = useMemo(
    () => {
      const entry = result[0]

      for (const subentry of entry?.subentries ?? []) {
        if (subentry.subentry_type === ActivityType.Liquid) {
          return {
            ...fallbackDefaultLiquidActivity,
            units: subentry.liquid.units,
          }
        }
      }

      return fallbackDefaultLiquidActivity
    },
    [result],
  )

  return {
    isFetching,
    defaultLiquidActivity,
  }
}
