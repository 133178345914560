import dayjs from "dayjs"
import { useMemo } from "react"
import styles from "./Header.module.scss"
import { useScrollPositionContext } from "./ScrollPositionContext"
import { useXAxisContext } from "./XAxisContext"
import { useReadableDate } from "src/hooks"

export function Header() {
  const {
    firstCellEnd,
    graphStart,
    minutesPerCell,
  } = useXAxisContext()
  const { visibleIndices } = useScrollPositionContext()
  const lowestVisibleIndex = visibleIndices[0]

  const mostRecentCellDate: string = useMemo(
    () => dayjs(firstCellEnd)
    // get the end time of the newest visible cell
      .subtract(
        lowestVisibleIndex * minutesPerCell,
        "minutes",
      )
      .toString(),
    [
      firstCellEnd,
      graphStart,
      minutesPerCell,
      lowestVisibleIndex,
    ],
  )

  const readableDateString = useReadableDate(mostRecentCellDate)

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.container}>
        {readableDateString}
      </h2>
    </div>
  )
}
