import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { post } from "src/api"
import { noticeError } from "src/utils"

type ForgotPassword = (recaptchaToken: string) => Promise<AxiosResponse<{ success: boolean }>>

export function useForgotPasswordRecaptcha(): ForgotPassword {
  return useCallback(
    async (recaptchaToken: string) => {
      try {
        const response = await post<{ success: boolean }>({
          url: "/forgot_password",
          data: {
            "g-recaptcha-response-data": recaptchaToken,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "ForgotPasswordRecaptcha",
        )

        throw e
      }
    },
    [],
  )
}
