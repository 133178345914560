import dayjs from "dayjs"
import { useMemo } from "react"
import { useXAxisContext } from "../XAxisContext"
import { roundTimeUp } from "src/utils"

export function useCellStartAndStopTime(index: number) {
  const {
    graphStart, minutesPerCell,
  } = useXAxisContext()

  // the latest time in this list item
  const cellStartTime: string = useMemo(
    () => roundTimeUp(
      graphStart,
      60 / minutesPerCell,
    )
      .subtract(
        index * minutesPerCell,
        "minutes",
      )
      .toISOString(),
    [
      graphStart,
      minutesPerCell,
      index,
    ],
  )

  // the earliest time in this list item
  const cellStopTime: string = useMemo(
    () => dayjs(cellStartTime).subtract(
      minutesPerCell,
      "minutes",
    )
      .toISOString(),
    [
      cellStartTime,
      minutesPerCell,
    ],
  )

  return useMemo(
    () => [
      cellStartTime,
      cellStopTime,
    ],
    [
      cellStartTime,
      cellStopTime,
    ],
  )
}
