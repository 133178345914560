import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { useSources } from "./useSources"
import { useMe } from "src/hooks/me"
import { useUserDelete } from "src/hooks/userApi"
import type { Source } from "src/types"
import { noticeError } from "src/utils"

type DeleteSourceFunction = () => Promise<AxiosResponse<Array<Source>>>

export function useDeleteSource(sourceId: number): DeleteSourceFunction {
  const { data: me } = useMe()
  const { mutate } = useSources()
  const userDelete = useUserDelete()
  const accountId = me?.account.id

  const deleteSource: DeleteSourceFunction = useCallback(
    async () => {
      try {
        if (!accountId) {
          throw new Error("cannot update source for unknown account")
        }

        if (!userDelete) {
          throw new Error("cannot delete source for unauthorized user")
        }

        const url = `/api/v2/accounts/${accountId}/sources/${sourceId}`
        const axiosResponse = await userDelete<Array<Source>>({ url })

        void mutate()
        return axiosResponse
      } catch (e) {
        noticeError(
          e,
          "DeleteSource",
          { sourceId },
        )

        throw e
      }
    },
    [
      userDelete,
      accountId,
      sourceId,
      mutate,
    ],
  )

  return deleteSource
}
