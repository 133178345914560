import type { RawAxiosRequestHeaders } from "axios"

export const contentTypeHeader: RawAxiosRequestHeaders = {
  "Content-Type": "application/json",
}

export const acceptHeader: RawAxiosRequestHeaders = {
  Accept: "application/json",
}

export const baseURL: string = process.env.REACT_APP_SUGARMATE_BASE_URL ?? ""
