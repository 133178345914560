import { Toast } from "@tandemdiabetes/sugarmate-react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
  title: string;
}

export function DeleteFavoriteErrorToast(props: Props) {
  const {
    id,
    title,
  } = props

  const polyglot = usePolyglot()

  const body = polyglot.t(
    "toasts.delete_favorite_error.body",
    { title },
  )

  return (
    <Toast
      stylePreset="error"
      body={body}
      id={id}
    />
  )
}
