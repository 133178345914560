import {
  Dialog,
  DialogBody,
  DialogButton,
  DialogButtons,
  DialogCloseX,
  DialogContent,
  DialogHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  ReactNode,
  MouseEventHandler,
} from "react"
import {
  useCallback,
  useState,
} from "react"

import styles from "./DataSource.module.scss"
import {
  useAuthContext,
  usePolyglot,
} from "src/contexts"

interface Props {
  children?: ReactNode;
}

const polyglotPrefix = "pages.settings.data_source."

export function DataSourceAlreadyExistsDialogContent(props: Props) {
  const polyglot = usePolyglot()
  const { signOut } = useAuthContext()

  const [
    isConfirmOpen,
    setIsConfirmOpen,
  ] = useState<boolean>(false)

  const openConfirmDialog = useCallback(
    () => {
      setIsConfirmOpen(true)
    },
    [setIsConfirmOpen],
  )

  const onSignOutClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      void signOut({
        logoutRedirect: "/sign_in",
      })
    },
    [signOut],
  )

  return (
    <>
      <DialogContent>
        {props.children}
        <DialogHeading>
          {polyglot.t(`${polyglotPrefix}already_exists_dialog.heading`)}
          <DialogCloseX />
        </DialogHeading>
        <DialogBody className={styles.dialogBody}>
          {polyglot.t(`${polyglotPrefix}already_exists_dialog.description`)}
        </DialogBody>
        <DialogButtons>
          <DialogButton
            stylePreset="secondary"
            onClick={openConfirmDialog}
          >
            {polyglot.t(`${polyglotPrefix}already_exists_dialog.log_out_cta`)}
          </DialogButton>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}already_exists_dialog.confirm_cta`)}
          </DialogButton>
        </DialogButtons>
      </DialogContent>
      <Dialog
        open={isConfirmOpen}
        onOpenChange={setIsConfirmOpen}
      >
        <DialogContent>
          <DialogHeading>
            {polyglot.t(`${polyglotPrefix}log_out_dialog.heading`)}
            <DialogCloseX />
          </DialogHeading>
          <DialogButtons>
            <DialogButton
              stylePreset="destructive"
              onClick={onSignOutClick}
            >
              {polyglot.t(`${polyglotPrefix}log_out_dialog.confirm_cta`)}
            </DialogButton>
            <DialogButton>
              {polyglot.t(`${polyglotPrefix}log_out_dialog.cancel_cta`)}
            </DialogButton>
          </DialogButtons>
        </DialogContent>
      </Dialog>
    </>
  )
}
