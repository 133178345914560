import type {
  RxJsonSchema,
  JsonSchema,
} from "rxdb"

export const dateTimeSchema: JsonSchema = {
  type: "string",
  format: "date-time",
  maxLength: 64,
}

interface Shared {
  id: "string";
  is_deleted: boolean; // TODO change to deleted_at
  created_at: string;
  updated_at: string;
}

type SharedSchema = RxJsonSchema<Shared>

export const sharedSchemaProperties: SharedSchema["properties"] = {
  id: {
    type: "string",
    maxLength: 256,
  },
  created_at: { ...dateTimeSchema },
  updated_at: { ...dateTimeSchema },
  is_deleted: { type: "boolean" }, // TODO
}

export const sharedSchemaRequired: Exclude<SharedSchema["required"], undefined> = [
  "created_at",
  "id",
  "is_deleted", // TODO
  "updated_at",
]

export const sharedSchemaIndexes: Exclude<SharedSchema["indexes"], undefined> = ["updated_at"]
