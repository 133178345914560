import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function InsulinAverageBolus(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()
  const days = range / 24

  const { insulinCalculations } = useCalculationsContext()
  const { totalBolusUnits } = insulinCalculations[range] || {}

  const value = totalBolusUnits === undefined ?
    NaN :
    round(
      totalBolusUnits / days,
      3,
    )

  const unit = polyglot.t(
    "unit.units_day",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.InsulinAverageBolus} />
      <TileTimeSpan range={range} />
      <TileValue>
        {isNaN(value) ? "--" : value.toLocaleString(navigator.language)}
      </TileValue>
      <TileUnit>
        {unit}
      </TileUnit>
    </>
  )
}
