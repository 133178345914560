import {
  AddImageButton,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useState,
} from "react"

import { useUploadImage } from "src/hooks"
import { UploadImageErrorToast } from "src/toasts"
import type {
  EditableSubEntry,
  SubEntryType,
} from "src/types"

interface Props<T extends SubEntryType> {
  updateSubEntry: (s: Partial<EditableSubEntry<T>>) => void;
  required?: boolean;
}

export function UploadImage<T extends SubEntryType>(props: Props<T>) {
  const {
    updateSubEntry,
    required,
  } = props

  const [
    disabled,
    setDisabled,
  ] = useState<boolean>(false)

  const { showToast } = useToasterContext()
  const uploadImage = useUploadImage()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (ev) => {
      const file = ev.target.files?.[0]

      if (!file) {
        return
      }

      void (async () => {
        setDisabled(true)

        try {
          const response = await uploadImage(file)

          updateSubEntry({
            image_id: response.data.image_id,
            image_url: response.data.image_url,
            thumbnail: response.data.thumbnail,
          })
        } catch (e) {
          showToast(
            `upload-image-error-toast-${file.name}`,
            UploadImageErrorToast,
          )
        }

        setDisabled(false)
      })()
    },
    [
      uploadImage,
      updateSubEntry,
      showToast,
    ],
  )

  return (
    <AddImageButton
      htmlFor="upload_image"
      onChange={onChange}
      disabled={disabled}
      required={required}
    />
  )
}
