import { useMemo } from "react"
import { useFindAccountInsulins } from "./useFindAccountInsulins"
import { useAccountProperty } from "src/hooks/me"
import { createAccountInsulinQueryObject } from "src/utils"

export function useAccountInsulins() {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createAccountInsulinQueryObject(
      accountId,
      {
        sort: [{ "insulin.name": "asc" as const }],
      },
    ),
    [accountId],
  )

  return useFindAccountInsulins(queryObject)
}
