// limit a number to floor or ceiling if it isn't between them
export function clamp(n: number, floor: number, ceiling: number): number {
  return Math.max(
    Math.min(
      n,
      ceiling,
    ),
    floor,
  )
}
