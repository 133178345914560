import type { CalculatorGroupsState } from "./useCalculatorGroupsState"
import { useInsightCalculationWorker } from "./useInsightCalculationWorker"

interface Props {
  queryEndTime: string;
  calculatorGroupsState: CalculatorGroupsState;
  timeSinceWakeUpInHours: number;
  worker: Worker;
}
export function InsightCalculationWorkerComponent(props: Props) {
  const {
    calculatorGroupsState,
    queryEndTime,
    timeSinceWakeUpInHours,
    worker,
  } = props

  useInsightCalculationWorker(
    calculatorGroupsState,
    queryEndTime,
    timeSinceWakeUpInHours,
    worker,
  )

  return null
}
