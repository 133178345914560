import {
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandemdiabetes/sugarmate-react-components"

import { ContextMenu } from "./ContextMenu"
import { DateTimeInput } from "./DateTimeInput"
import { ImageThumbnail } from "./ImageThumbnail"
import { NoteInput } from "./NoteInput"
import { SubEntryFormButtons } from "./SubEntryFormButtons"
import { UploadImage } from "./UploadImage"
import {
  ActivityIcon,
  ActivityName,
} from "src/components/Activity"
import styles from "src/styles/entryModal.module.scss"
import type {
  NoteActivity,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  updateActivity: (a: NoteActivity) => void;
  currentSubEntry: EditableSubEntry<ActivityType.Note>;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Note>>) => void;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function NoteForm(props: Props) {
  const {
    currentSubEntry,
    updateSubEntry,
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          <ActivityIcon
            activityType={ActivityType.Note}
            className={styles.activityIcon}
          />
          <ActivityName activityType={ActivityType.Note} />
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <ContextMenu
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
        <ModalCloseX />
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <div className={styles.row}>
          <NoteInput
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
            required={!currentSubEntry.image_id}
          />
          <UploadImage
            updateSubEntry={updateSubEntry}
            required={!currentSubEntry.note && !currentSubEntry.image_id}
          />
        </div>
        <ImageThumbnail
          currentSubEntry={currentSubEntry}
          updateSubEntry={updateSubEntry}
        />
        <SubEntryFormButtons
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
      </ModalBody>
    </>
  )
}
