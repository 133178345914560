import { useMemo } from "react"
import { defaultMedicationActivity } from "./defaultMedicationActivity"
import { usePolyglot } from "src/contexts"
import { useAccountMedications } from "src/hooks"
import type {
  AccountMedication,
  MedicationActivity,
} from "src/types"

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const defaultAccountMedId = defaultMedicationActivity.medication.account_med_id

export function useNameSelectOptions(activity: Partial<MedicationActivity>): Array<Option> {
  const polyglot = usePolyglot()
  const { result: accountMedications } = useAccountMedications()
  const activityAccountMedId = activity.medication?.account_med_id

  // calculate the options for the select menu
  const selectOptions: Array<Option> = useMemo(
    () => {
      // the default option
      const defaultOption: Option = {
        value: "",
        children: polyglot.t("components.entry_modal.medication.name.placeholder"),
        disabled: true,
      }

      let activityHasMatch = activityAccountMedId === defaultAccountMedId
      const optionsArray: Array<Option> = []

      accountMedications.forEach((accountMedication: AccountMedication) => {
        optionsArray.push({
          value: accountMedication.id,
          // TODO we are not yet localizing medication names
          children: accountMedication.med.name ?? "",
        })

        // TODO all ids will be strings in the future
        if (accountMedication.id === `${activityAccountMedId}`) {
          activityHasMatch = true
        }
      })

      // if the medication activity is not included in the current account
      // medications then add it as a disabled option
      if (!activityHasMatch && activity.medication) {
        optionsArray.push({
          value: `${activity.medication.account_med_id}`,
          children: activity.medication.name,
          disabled: true,
        })
      }

      return [
        defaultOption,
        ...optionsArray,
      ]
    },
    [
      activityAccountMedId,
      accountMedications,
      polyglot,
    ],
  )

  return selectOptions
}
