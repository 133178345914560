import type { DeepReadonlyObject } from "rxdb"
import type { GlucoseUserEvent } from "src/types"
import { Trend } from "src/types"

export const defaultFavicon = "/favicon.ico"

export function setFavicon(url: string) {
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']")

  if (!link) {
    link = document.createElement("link")
    link.rel = "icon"
    document.head.appendChild(link)
  }

  link.href = url
}

export function getFavicon(
  darkMode: boolean,
  glucoseUserEvent?: DeepReadonlyObject<GlucoseUserEvent> | null,
) {
  if (glucoseUserEvent?.glucose.trend && glucoseUserEvent.glucose.trend !== Trend.None) {
    if (darkMode) {
      return `/trends/${glucoseUserEvent.glucose.trend}_WHITE.svg`
    }

    return `/trends/${glucoseUserEvent.glucose.trend}_BLACK.svg`
  }

  return defaultFavicon
}
