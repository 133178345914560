import { createContext } from "react"

export interface CookiesContextValue {
  dismissedAddSourcePrompt: boolean;
  setDismissedAddSourcePrompt: (b: boolean) => void;

  // TODO TODO remove csrf token
  csrfToken: string | undefined;
}

type ContextType = CookiesContextValue | null

export const CookiesContext = createContext<ContextType>(null)
