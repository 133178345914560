import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindUserEntries } from "./useFindUserEntries"
import { useAccountProperty } from "src/hooks/me"
import type { UserEntry } from "src/types"
import { createEntryQueryObject } from "src/utils"

export function useOldestUserEntry():
| DeepReadonlyObject<UserEntry>
| undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createEntryQueryObject(
      accountId,
      {
        sort: [{ started_at: "asc" as const }],
        limit: 1,
      },
    ),
    [accountId],
  )

  const { result } = useFindUserEntries(queryObject)
  return result[0]
}
