import type { ReactNode } from "react"
import {
  useMemo,
  useRef,
} from "react"
import type { VariableSizeList } from "react-window"
import { ReferencesContext } from "./ReferencesContext"

interface Props {
  children: ReactNode;
}

export function ReferencesContextProvider(props: Props) {
  const listElementRef = useRef<VariableSizeList>(null)
  const listHeaderRef = useRef<HTMLDivElement>(null)
  const listItemHeaderRef = useRef<HTMLDivElement>(null)

  const value = useMemo(
    () => ({
      listElementRef,
      listHeaderRef,
      listItemHeaderRef,
    }),
    [
      listElementRef,
      listHeaderRef,
      listItemHeaderRef,
    ],
  )

  return (
    <ReferencesContext.Provider value={value}>
      {props.children}
    </ReferencesContext.Provider>
  )
}
