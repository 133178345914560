import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindInsulins } from "./useFindInsulins"
import type { Insulin } from "src/types"
import { createInsulinQueryObject } from "src/utils"

export function useInsulin(id: number | string | undefined | null): DeepReadonlyObject<Insulin> | undefined {
  const queryObject = useMemo(
    () => {
      const queryId = (typeof id === "undefined" || typeof id === "object")
        ? ""
        : `${id}`

      return createInsulinQueryObject({
        selector: {
          id: { $eq: queryId },
        },
        limit: 1,
      })
    },
    [id],
  )

  const { result } = useFindInsulins(queryObject)
  return result[0]
}
