import type { MouseEventHandler } from "react"
import styles from "./EntryModalSubEntrySummary.module.scss"
import {
  ActivityData,
  ActivityDescription,
  ActivityIcon,
  ActivityMessage,
  ActivityNoteIcon,
  ActivityTitle,
  ActivityImageIcon,
} from "src/components/Activity"
import type {
  ActivityPair,
  ReadOnlySubEntry,
} from "src/types"

type Props = ActivityPair & {
  item: ReadOnlySubEntry;
  onClick?: MouseEventHandler;
}

export function EntryModalSubEntrySummary(props: Props) {
  const {
    onClick,
    item,
    ...activityPair
  } = props

  return (
    <button
      className={styles.container}
      onClick={onClick}
    >
      <ActivityIcon
        activityType={props.activityType}
        className={styles.icon}
      />
      <div className={styles.text}>
        <ActivityTitle
          {...activityPair}
          item={item}
          className={styles.title}
        />
        <ActivityDescription
          {...activityPair}
          item={item}
          className={styles.description}
        >
          <ActivityMessage
            {...activityPair}
            item={item}
            className={styles.message}
          />
          <ActivityNoteIcon
            {...activityPair}
            item={item}
            className={styles.noteIcon}
          />
        </ActivityDescription>
      </div>
      <div className={styles.dataContainer}>
        <ActivityData
          {...activityPair}
          className={styles.data}
        />
        <ActivityImageIcon
          item={item}
          className={styles.imageIcon}
        />
      </div>
    </button>
  )
}
