import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useFind } from "src/hooks/rxdb"
import { accountMedicationCollectionKey } from "src/schema"
import type { AccountMedication } from "src/types"

export function useFindAccountMedications(
  queryObject: MangoQuery<AccountMedication>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<AccountMedication> {
  return useFind<AccountMedication>(
    queryObject,
    accountMedicationCollectionKey,
    options,
  )
}
