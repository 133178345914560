import { Toast } from "@tandemdiabetes/sugarmate-react-components"
import { usePolyglot } from "src/contexts"

interface Props {
  id: string;
}

export function AddSourceDexcomConnectionErrorToast(props: Props) {
  const {
    id,
  } = props

  const polyglot = usePolyglot()

  return (
    <Toast
      stylePreset="error"
      body={polyglot.t("toasts.add_source_dexcom_connection_error.body")}
      id={id}
    />
  )
}
