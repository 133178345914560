import classNames from "classnames"
import { InsightsEditButton } from "../Insights"
import { useTabContext } from "../TabContext"
import styles from "./MultiTabHeader.module.scss"
import { TabComponent } from "./TabComponent"
import { Tab } from "src/types"

interface Props {
  className?: string;
}

export function MultiTabHeader(props: Props) {
  const className = classNames(
    styles.container,
    props.className,
  )

  const { currentTab } = useTabContext()

  return (
    <div className={className}>
      <div className={styles.tabs}>
        <TabComponent tab={Tab.Insights}/>
        <TabComponent tab={Tab.Feed} />
      </div>
      {currentTab === Tab.Insights && (
        <InsightsEditButton />
      )}
    </div>
  )
}
