import { useCallback } from "react"
import { usePolyglot } from "src/contexts"
import { useGlucoseUnit } from "src/hooks/settings"
import { GlucoseUnit } from "src/types"

/*
 * returns a function that returns the glucose unit
 * in a readable localized string
 */
export function useDisplayGlucoseUnit(): () => string {
  const glucoseUnit = useGlucoseUnit()
  const polyglot = usePolyglot()

  return useCallback(
    () => glucoseUnit === GlucoseUnit.MillimolesPerLiter
      ? polyglot.t("unit.mmoll")
      : polyglot.t("unit.mgdl"),
    [
      glucoseUnit,
      polyglot,
    ],
  )
}
