import type { AxiosRequestConfig } from "axios"
import { useMemo } from "react"
import { transformMeResponse } from "./transformMeResponse"

const url = "/api/v3/me"

// this is the key for the cached user data
export function useMeSWRKey(): AxiosRequestConfig {
  const swrKey = useMemo(
    () => ({
      url,
      transformResponse: transformMeResponse,
    }),
    [],
  )

  return swrKey
}
