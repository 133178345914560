import { useCallback } from "react"
import { get } from "src/api"
import { useOauthAuthorizeContext } from "src/contexts"
import { noticeError } from "src/utils"

interface AuthorizeRedirectResponse {
  status: "redirect";
  redirect_uri: string;
}

export function useOauthAuthorize() {
  const {
    client_id,
    code_challenge,
    code_challenge_method,
    redirect_uri,
    response_type,
    scope,
    state,
  } = useOauthAuthorizeContext()

  return useCallback(
    async (accessToken: string): Promise<void> => {
      try {
        const response = await get<AuthorizeRedirectResponse>({
          url: "/oauth/authorize",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          params: {
            client_id,
            code_challenge,
            code_challenge_method,
            redirect_uri,
            response_type,
            scope,
            state,
          },
        })

        if (response.data.status === "redirect") {
          location.replace(response.data.redirect_uri)
        } else {
          throw new Error("oauth authorization failed")
        }
      } catch (e) {
        noticeError(
          e,
          "OauthAuthorize",
        )

        throw e
      }
    },
    [
      client_id,
      code_challenge,
      code_challenge_method,
      redirect_uri,
      response_type,
      scope,
      state,
    ],
  )
}
