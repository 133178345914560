import dayjs from "dayjs"

export function getOldestDate(dateString: string, ...dateStrings: string[]) {
  let oldestDate = dateString

  dateStrings.forEach(
    (date) => {
      if (dayjs(date).isBefore(oldestDate)) {
        oldestDate = date
      }
    },
  )

  return oldestDate
}
