import { useRouterState } from "@tanstack/react-router"
import type {
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios"
import axios from "axios"
import {
  useCallback,
  useMemo,
} from "react"
import { useUserHeaders } from "./useUserHeaders"
import {
  mergeAxiosConfigs,
  applyAxiosConfig,
} from "src/api"
import { useAuthContext } from "src/contexts/AuthContext"
import type {
  AxiosFunction,
  RoutePath,
} from "src/types"

// this function creates hooks that return functions that inject auth headers into axios requests
// and handle 401 responses
export function createUseUserApiFunction(
  preconfig: AxiosRequestConfig,
): () => (AxiosFunction | null) {
  // this hook returns a function that makes axios requests with auth headers or undefined if the user is not authorized
  return (): AxiosFunction | null => {
    const authHeaders = useUserHeaders()
    const { signOut } = useAuthContext()

    // const currentPath = "/home"
    const currentPath = useRouterState({
      // note use .href if you want to include query params
      select: (s) => s.resolvedLocation.pathname,
    }) as RoutePath

    const axiosFunction = useCallback(
      <T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
        const instance = axios.create()

        // if the response is 401 then sign out
        instance.interceptors.response.use(
          (response: AxiosResponse<T>) => response,
          (error: AxiosError) => {
            if (error.response?.status === 401) {
              // TODO consider using "setAuthCookie" here ?
              void signOut({
                loginRedirect: currentPath,
                logoutRedirect: "/sign_in",
              })
            }

            throw error
          },
        )

        return instance.request<T>(mergeAxiosConfigs(
          preconfig,
          config,
        ))
      },
      [
        signOut,
        currentPath,
      ],
    )

    // this adds the auth headers to the config when making the request
    // this will return undefined if the user is not authorized
    const useAuthApiFunction = useMemo<AxiosFunction | null>(
      () => {
        if (!authHeaders) {
          return null
        }

        return applyAxiosConfig(
          axiosFunction,
          { headers: authHeaders },
        )
      },
      [
        authHeaders,
        axiosFunction,
      ],
    )

    return useAuthApiFunction
  }
}
