import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { useUserPut } from "src/hooks"
import type { StatusResponse } from "src/types"
import { noticeError } from "src/utils"

type ChangePassword = (currentPassword: string, newPassword: string) => Promise<AxiosResponse<StatusResponse>>

export function useChangePassword(): ChangePassword {
  const userPut = useUserPut()
  return useCallback(
    async (currentPassword: string, newPassword: string) => {
      try {
        if (!userPut) {
          throw new Error("cannot update password for unauthenticated user")
        }

        const response = await userPut<StatusResponse>({
          url: "/api/v3/passwords/edit",
          data: {
            new_password: newPassword,
            current_password: currentPassword,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "ChangePassword",
        )

        throw e
      }
    },
    [userPut],
  )
}
