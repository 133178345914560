import { useMemo } from "react"
import { useMe } from "./useMe"
import type { Account } from "src/types"

export function useAccountProperty<
  K extends string & keyof Account,
  V,
>(
  property: K,
  defaultValue?: V,
): V

export function useAccountProperty<
  K extends string & keyof Account,
>(
  property: K,
  defaultValue: Account[K],
): Account[K]

export function useAccountProperty<
  K extends keyof Account,
>(
  property: K,
  defaultValue?: Account[K],
) {
  const { data } = useMe()

  return useMemo(
    () => data?.account[property] ?? defaultValue ?? undefined,
    [
      data,
      property,
      defaultValue,
    ],
  )
}
