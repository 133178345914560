import { createConsumer } from "@rails/actioncable"
import {
  useMemo,
  useEffect,
} from "react"

import { useEntryTemplatesHandler } from "./useEntryTemplatesHandler"
import { useSettingsHandler } from "./useSettingsHandler"
import { useUserEntriesHandler } from "./useUserEntriesHandler"
import { useUserEventsHandler } from "./useUserEventsHandler"
import { baseURL } from "src/api"
import {
  useAuthContext,
  usePageLifecycleContext,
} from "src/contexts"
import type {
  EntryTemplate,
  Settings,
  UserEntry,
  UserEvent,
} from "src/types"
import { PageLifecycle } from "src/types"
import { noticeError } from "src/utils"

interface WebSocketData {
  message: {
    event?: UserEvent;
    entry?: UserEntry;
    settings?: Settings;
    entry_template?: EntryTemplate;
  };
}

function getWebSocketUrl(token: string) {
  const url = new URL(baseURL)
  const protocol = url.protocol === "http:" ? "ws:" : "wss:"
  const domain = url.hostname
  return `${protocol}//${domain}/cable?auth_token=${token}`
}

export function WebSocketHandler() {
  const pageLifecycle = usePageLifecycleContext()
  const {
    accessToken,
    isExpired,
  } = useAuthContext()
  const settingsHandler = useSettingsHandler()
  const entryTemplatesHandler = useEntryTemplatesHandler()
  const userEventsHandler = useUserEventsHandler()
  const userEntriesHandler = useUserEntriesHandler()

  const cable = useMemo(
    () => accessToken && !isExpired && createConsumer(getWebSocketUrl(accessToken)),
    [
      accessToken,
      isExpired,
    ],
  )

  const shouldConnect = (
    accessToken &&
    !isExpired && (
      pageLifecycle === PageLifecycle.Active ||
      pageLifecycle === PageLifecycle.Hidden ||
      pageLifecycle === PageLifecycle.Passive
    )
  )

  // listen for new user events and user entries and add them to the collection
  useEffect(
    () => {
      if (!shouldConnect || !cable) {
        return
      }

      const subscription = cable.subscriptions.create(
        { channel: "LiveChannel" },
        {
          // TODO handle connections / disconnections
          // https://guides.rubyonrails.org/action_cable_overview.html
          connected: () => {
            // console.debug("action cable connected")
          },
          disconnected: () => {
            // console.debug("action cable disconnected")
          },
          rejected: () => {
            noticeError(
              "action cable rejected",
              "WebsocketRejected",
            )
          },
          received: (data: WebSocketData) => {
            const { message } = data

            if (!message) {
              return
            }

            if (message.settings) {
              void settingsHandler(message.settings)
            }

            if (message.entry_template) {
              void entryTemplatesHandler(message.entry_template)
            }

            if (message.event) {
              void userEventsHandler(message.event)
            }

            if (message.entry) {
              void userEntriesHandler(message.entry)
            }
          },
        },
      )

      return () => {
        subscription.unsubscribe()
      }
    },
    [
      cable,
      userEventsHandler,
      userEntriesHandler,
      settingsHandler,
      shouldConnect,
    ],
  )

  return null
}
