import { CaretLeftIcon } from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import type {
  CSSProperties,
  Ref,
} from "react"
import {
  useCallback,
  useState,
} from "react"
import styles from "./Nightstand.module.scss"
import { usePolyglot } from "src/contexts"
import { useOnMountAndResize } from "src/hooks"
import { UserHeader } from "src/pages/Home/UserHeader"

function getScale() {
  const windowWidth = document.documentElement.clientWidth
  const elementWidth = Math.min(
    390,
    windowWidth,
  )
  const scale = windowWidth / elementWidth
  return scale
}

/*
 * the nightstand renders the user header in the center of the page
 * and scales it up as space provides
 */
export function Nightstand() {
  const polyglot = usePolyglot()

  const [
    transformStyles,
    setTransformStyles,
  ] = useState<CSSProperties>({
    transform: `scale(${getScale()})`,
  })

  const updateVirtualListContext: Ref<HTMLDivElement> = useCallback(
    () => {
      setTransformStyles({
        transform: `scale(${getScale()})`,
      })
    },
    [setTransformStyles],
  )
  const ref = useOnMountAndResize(updateVirtualListContext)

  return (
    <div className={styles.container}>
      <Link
        className={styles.backPrompt}
        to="/home"
      >
        <CaretLeftIcon className={styles.backIcon} />
        {polyglot.t("pages.nightstand.back_cta")}
      </Link>
      <div
        ref={ref}
        className={styles.data}
        style={transformStyles}
      >
        <UserHeader />
      </div>
    </div>
  )
}
