import type { JSXElementConstructor } from "react"

/* Higher Order Component that inject properties for the provided
 * component and returns a component that takes all of the remaining props
 * https://react-typescript-cheatsheet.netlify.app/docs/hoc/full_example/
 */
export function injectProps<TProps, TInjectedKeys extends keyof TProps>(
  Component: JSXElementConstructor<TProps>,
  injector: Pick<TProps, TInjectedKeys>,
) {
  return function Injected(props: Omit<TProps, TInjectedKeys>) {
    return (
      <Component
        {...(props as TProps)}
        {...injector}
      />
    )
  }
}
