import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindAccountMedications } from "./useFindAccountMedications"
import { useAccountProperty } from "src/hooks/me"
import type { AccountMedication } from "src/types"
import { createAccountMedicationQueryObject } from "src/utils"

export function useNewestAccountMedication(): DeepReadonlyObject<AccountMedication> | undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createAccountMedicationQueryObject(
      accountId,
      {
        limit: 1,
        sort: [{ created_at: "desc" as const }],
      },
    ),
    [accountId],
  )

  const { result } = useFindAccountMedications(queryObject)
  return result[0]
}
