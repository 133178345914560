import dayjs from "dayjs"
import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useNewestAccountMedication } from "src/hooks"
import type {
  AccountMedication,
  MedicationActivity,
} from "src/types"

export function useOnAccountMedicationCreated(
  activity: MedicationActivity,
  updateActivity: (a: MedicationActivity) => void,
) {
  const lastUpdatedRef = useRef<string>(dayjs().toISOString())
  const newestAccountMedication = useNewestAccountMedication()

  // this function updates the activity with the provided account medication
  const updateActivityWithAccountMedication = useCallback(
    (accountMedication: DeepReadonlyObject<AccountMedication>) => {
      updateActivity({
        ...activity,
        medication: {
          account_med_id: Number(accountMedication.id),
          med_id: Number(accountMedication.med_id),
          category: accountMedication.med.category ?? "",
          description: accountMedication.med.description ?? "",
          name: accountMedication.med.name ?? "",
          subcategory: accountMedication.med.subcategory ?? "",
          units: accountMedication.units,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  useEffect(
    () => {
      if (
        newestAccountMedication &&
        dayjs(newestAccountMedication.created_at).isAfter(lastUpdatedRef.current)
      ) {
        updateActivityWithAccountMedication(newestAccountMedication)
        lastUpdatedRef.current = newestAccountMedication.created_at
      }
    },
    [
      lastUpdatedRef,
      newestAccountMedication,
      updateActivityWithAccountMedication,
    ],
  )
}
