import classNames from "classnames"
import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"
import { forwardRef } from "react"
import styles from "./Tile.module.scss"

type Props = HTMLAttributes<HTMLDivElement>

export const TileValue = forwardRef<HTMLDivElement, Props>(
  function TileValue(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const className = classNames(
      props.className,
      styles.value,
    )

    return (
      <div
        {...props}
        ref={ref}
        className={className}
      />
    )

  },
)
