import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import type { DeepReadonlyObject } from "rxdb"

import { defaultInsulinActivity } from "./defaultInsulinActivity"
import type {
  ActivityType,
  InsulinActivity,
  AccountInsulin,
  EditableSubEntry,
} from "src/types"

export function useOnCurrentAccountInsulinDeleted(
  activity: InsulinActivity,
  updateActivity: (a: InsulinActivity) => void,
  currentAccountInsulin: DeepReadonlyObject<AccountInsulin> | undefined,
  currentSubEntry: EditableSubEntry<ActivityType.Insulin>,
) {
  const previousAccountInsulinRef = useRef<DeepReadonlyObject<AccountInsulin> | undefined>(currentAccountInsulin)

  // reset the activity if it is a new activity and its account insulin is removed
  const resetIfUnsaved = useCallback(
    () => {
      if (!currentSubEntry._id) {
        updateActivity({
          ...activity,
          insulin_med: {
            ...defaultInsulinActivity.insulin_med,
          },
        })
      }
    },
    [
      activity,
      currentSubEntry._id,
    ],
  )

  useEffect(
    () => {
      if (
        !currentAccountInsulin &&
        !!previousAccountInsulinRef.current &&
        previousAccountInsulinRef.current.id === `${activity.insulin_med.account_insulin_id}`
      ) {
        resetIfUnsaved()
      }

      previousAccountInsulinRef.current = currentAccountInsulin
    },
    [
      activity,
      resetIfUnsaved,
      currentAccountInsulin,
      previousAccountInsulinRef,
    ],
  )
}
