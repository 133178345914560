import type { DeepReadonlyObject } from "rxdb"
import type { GlucoseUserEvent } from "src/types"

export const defaultTitle = "Sugarmate"

export function setTitle(title: string) {
  document.title = title
}

export function getTitle(
  displayTime: (time: string) => string,
  displayGlucoseString: (reading: number) => string,
  glucoseUserEvent?: DeepReadonlyObject<GlucoseUserEvent> | null,
) {
  if (glucoseUserEvent) {
    const formattedGlucoseString = displayGlucoseString(glucoseUserEvent.glucose.mg_dl)
    const time = displayTime(glucoseUserEvent.started_at)

    return `${formattedGlucoseString} ${time}`
  }

  return defaultTitle
}
