import Polyglot from "node-polyglot"
import type { Locale } from "src/types"
import { noticeError } from "src/utils"

type LocaleValue = Phrases | string

interface Phrases {
  [key: string]: LocaleValue;
}

interface LocaleFile {
  default: Phrases;
}

export async function loadPolyglotLocale(locale: Locale): Promise<Polyglot | undefined> {
  try {
    const localeFile = await import(`src/locales/${locale}.json`) as LocaleFile
    const phrases = localeFile.default
    const polyglot = new Polyglot({
      phrases,
      locale,
    })

    return polyglot
  } catch (e) {
    noticeError(
      e,
      "CreatePolyglot",
      { locale },
    )
  }

  return undefined
}
