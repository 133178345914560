import { useMemo } from "react"
import {
  copyDefaultInsightRangeByKey,
  defaultInsightRangeByKey,
} from "./defaultInsightRangeByKey"
import { copyInsightRangesByKey } from "./insightRangesByKey"
import type {
  Insight,
  InsightKey,
  InsightRange,
} from "src/types"

interface ReturnValue {
  availableInsightRangesByKey: Record<InsightKey, Array<InsightRange>>;
  defaultInsightRangeByKey: Record<InsightKey, InsightRange | undefined>;
}

export function useAvailableInsightRanges(insights: Array<Insight>): ReturnValue {
  return useMemo(
    () => {

      // construct a record that has the list of available insight ranges for each insight key
      const availableInsightRangesByKey = copyInsightRangesByKey()
      insights.forEach(
        (insight: Insight) => {
          const range = insight.range || defaultInsightRangeByKey[insight.key]
          const indexOfRange = (availableInsightRangesByKey[insight.key] ?? []).indexOf(range)

          if (indexOfRange !== -1) {
            availableInsightRangesByKey[insight.key].splice(
              indexOfRange,
              1,
            )
          }
        },
      )

      // construct a record that has a viable default insight range for each insight key
      const availableDefaultInsightRangeByKey: Record<InsightKey, InsightRange | undefined> = copyDefaultInsightRangeByKey()

      for (const insightKey in availableDefaultInsightRangeByKey) {
        const defaultRange = availableDefaultInsightRangeByKey[insightKey as InsightKey]

        // use the lowest available value if the default is not available
        if (defaultRange !== undefined && !availableInsightRangesByKey[insightKey as InsightKey].includes(defaultRange)) {
          availableDefaultInsightRangeByKey[insightKey as InsightKey] = availableInsightRangesByKey[insightKey as InsightKey][0]
        }
      }

      return {
        defaultInsightRangeByKey: availableDefaultInsightRangeByKey,
        availableInsightRangesByKey,
      }

    },
    [insights],
  )
}
