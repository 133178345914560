import type { DeepReadonlyObject } from "rxdb"
import type {
  AccountMedication,
  Medication,
} from "src/types"

export type EditableAccountMedication = DeepReadonlyObject<Partial<Omit<AccountMedication, "med">>> & {
  med?: DeepReadonlyObject<Partial<Medication>>;
}

export const emptyAccountMedication = {
  id: undefined,
  med_id: "",
  units: "",
  med: {
    category: "",
  },
}
