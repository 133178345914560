import type { AxiosRequestConfig } from "axios"
import { useMemo } from "react"

import { transformSourcesResponse } from "./transformSourcesResponse"

// this is the key for the cached sources data
export function useSourcesSWRKey(): AxiosRequestConfig | undefined {
  const swrKey = useMemo(
    () => {
      const url = "/api/v3/sources"

      return {
        url,
        transformResponse: transformSourcesResponse,
      }
    },
    [],
  )

  return swrKey
}
