import dayjs from "dayjs"
import {
  useEffect,
  useState,
  useCallback,
} from "react"
import { useInterval } from "usehooks-ts"
import { usePageLifecycleContext } from "src/contexts"
import { PageLifecycle } from "src/types"

interface State {
  time: string;
  timeUntilNextUpdate: number;
}

function getTimeState(): State {
  return {
    time: dayjs().toISOString(),
    timeUntilNextUpdate: dayjs()
      .add(
        1,
        "minute",
      )
      .startOf("minute")
      .diff(
        dayjs(),
        "millisecond",
      ),
  }
}

// returns the starting time of this minute
export function useThisMinute(): string {
  const pageLifecycle = usePageLifecycleContext()

  const [
    timeState,
    setTimeState,
  ] = useState<State>(getTimeState())

  const updateState = useCallback(
    () => {
      setTimeState(getTimeState())
    },
    [setTimeState],
  )

  // when the page becomes unhidden, update the state
  useEffect(
    () => {
      if (
        pageLifecycle === PageLifecycle.Active ||
        pageLifecycle === PageLifecycle.Passive
      ) {
        updateState()
      }
    },
    [
      updateState,
      pageLifecycle,
    ],
  )

  // note that browsers will not execute this when the page is hidden
  useInterval(
    updateState,
    timeState.timeUntilNextUpdate,
  )

  return timeState.time
}
