import { useMemo } from "react"
import type { PartialGraphItemProps } from "../Cell/PartialGraphItemProps"

/*
 * sorts the graphItems into the available space above and below the glucose readings
 */
export function useSortedGraphItems(
  graphItems: Array<PartialGraphItemProps>,
  maxTopGraphItems: number,
  maxBottomGraphItems: number,
): [Array<PartialGraphItemProps>, Array<PartialGraphItemProps>] {
  return useMemo(
    () => {
      const numberOfGraphItems = graphItems.length

      if (numberOfGraphItems === 0) {
        return [
          [],
          [],
        ]
      }

      const topGraphItems: Array<PartialGraphItemProps> = []
      const bottomGraphItems: Array<PartialGraphItemProps> = []

      graphItems.forEach((activity) => {
        const spaceRemainingTop = maxTopGraphItems - topGraphItems.length
        const spaceRemainingBottom =
        maxBottomGraphItems - bottomGraphItems.length

        if (spaceRemainingTop > spaceRemainingBottom) {
          topGraphItems.push(activity)
        } else if (spaceRemainingBottom > spaceRemainingTop) {
          bottomGraphItems.push(activity)

        // if there is no more space remaining
        } else if (spaceRemainingTop === 0 && spaceRemainingBottom === 0) {
        // TODO handle when there is not more space available here
        // if there is equal space remaning and the first item was on top
        } else if (maxTopGraphItems > maxBottomGraphItems) {
          bottomGraphItems.push(activity)

        // if there is equal space remaning and the first item was on bottom
        } else {
          topGraphItems.push(activity)
        }
      })

      return [
        topGraphItems,
        bottomGraphItems,
      ]
    },
    [
      maxTopGraphItems,
      maxBottomGraphItems,
      graphItems,
    ],
  )
}
