import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import { useEventsAndSubEntriesOfType } from "src/hooks"
import { ActivityType } from "src/types"

export function useTotalUnitsDayMetric(dayStart: string) {
  const daySelector = useDaySelector(dayStart)

  const [
    totalUnits,
    setTotalUnits,
  ] = useState<number>(NaN)

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: insulinEvents,
    subEntries: insulinSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Insulin,
    daySelector,
  )

  useEffect(
    () => {
      if (isFetchingEntries || isFetchingEvents) {
        return
      }

      let units = 0

      insulinEvents.forEach(
        (insulinEvent) => {
          if (insulinEvent.insulin.num_units) {
            units += insulinEvent.insulin.num_units
          }
        },
      )

      insulinSubEntries.forEach(
        (subEntry) => {
          if (subEntry.insulin.num_units) {
            units += subEntry.insulin.num_units
          }
        },
      )

      setTotalUnits(units)
    },
    [
      isFetchingEntries,
      isFetchingEvents,
      setTotalUnits,
      insulinEvents,
      insulinSubEntries,
    ],
  )

  return totalUnits
}
