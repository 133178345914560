import type { ReactNode } from "react"

import { NavigationMenuContent } from "./NavigationMenuContent"
import { NavigationMenuContext } from "./NavigationMenuContext"
import type { NavigationMenuOptions } from "./NavigationMenuOptions"
import { useNavigationMenu } from "./useNavigationMenu"

export interface Props extends NavigationMenuOptions {
  children: ReactNode;
}

/*
 * this component renders a navigation menu in a floating content container
 * that is revealed or hidden when the children call the "setOpen" method
 * from the NavigationMenuContext
 */
export function NavigationMenu({
  children,
  ...options
}: Props) {
  const menu = useNavigationMenu(options)

  return (
    <NavigationMenuContext.Provider value={menu}>
      {children}
      <NavigationMenuContent />
    </NavigationMenuContext.Provider>
  )
}
