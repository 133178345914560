import {
  createContext,
  useContext,
} from "react"
import type {
  SubEntryType,
  ReadOnlyUserEntry,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

export interface EntryModalPrivateContextValue {
  formId: string;
  addSubEntry: () => void;
  destroyEntry: () => Promise<void>;
  removeCurrentSubEntry: () => void;
  saveEntry: () => Promise<ReadOnlyUserEntry>;
  duplicateEntry: () => Promise<ReadOnlyUserEntry>;
  duplicateCurrentSubEntry: () => Promise<ReadOnlyUserEntry>;
  showEntry: () => void;
  showSubEntry: (subEntry: EditableSubEntry<SubEntryType>) => void;
  originalUserEntry: EditableUserEntry;
  originalSubEntry: EditableSubEntry | undefined;
}

type ContextType = EntryModalPrivateContextValue | null
export const EntryModalPrivateContext = createContext<ContextType>(null)

export function useEntryModalPrivateContext() {
  const context = useContext(EntryModalPrivateContext)

  if (context === null) {
    throw new Error("EntryModal components must be wrapped in <EntryModalPrivateContext />")
  }

  return context
}

