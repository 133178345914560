import { round } from "./round"
import { LiquidUnit } from "src/types"

const microlitersPerOz = 29573.5

/*
 * this function convers ml to liquid unit and currently only uses US units
 *
 * note: a fluid ounce is 29.5735 in the US, an imperial fluid ounce is 28.4131
 * note: a cup is 236.588 ml (8 us fl oz) in the US and 250 ml in Australia, Canada, New Zealand, and the UK, and an imperial cup is 284.131 ml
 * note: a pint is 473.176 ml (16 us fl oz) in the US and an imperial pint is 568 (20 imperial fl oz)
 */
export function liquidUnitToMicroliters(value: number, unit: LiquidUnit): number {
  switch (unit) {
    case LiquidUnit.Cups:
      return round(value * 8 * microlitersPerOz)
    case LiquidUnit.Pints:
      return round(value * 16 * microlitersPerOz)
    case LiquidUnit.FluidOunces:
      return round(value * microlitersPerOz)
    case LiquidUnit.Liters:
      return value * 1000000
    case LiquidUnit.Milliliters:
      return value * 1000
    default:
      return NaN
  }
}
