import {
  ModalBody,
  ModalCloseX,
  ModalHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import { useEffect } from "react"

import { ContextMenu } from "../ContextMenu"
import { DateTimeInput } from "../DateTimeInput"
import { ImageThumbnail } from "../ImageThumbnail"
import { NoteInput } from "../NoteInput"
import { SubEntryFormButtons } from "../SubEntryFormButtons"
import { UploadImage } from "../UploadImage"

import { LiquidRangeInput } from "./LiquidRangeInput"
import { LiquidTypeInput } from "./LiquidTypeInput"
import { useDefaultLiquidActivity } from "./useDefaultLiquidActivity"

import {
  ActivityIcon,
  ActivityName,
} from "src/components/Activity"
import styles from "src/styles/entryModal.module.scss"
import type {
  LiquidActivity,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  updateActivity: (a: LiquidActivity) => void;
  currentSubEntry: EditableSubEntry<ActivityType.Liquid>;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Liquid>>) => void;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function LiquidForm(props: Props) {
  const {
    updateActivity,
    updateSubEntry,
    currentSubEntry,
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const {
    defaultLiquidActivity,
    isFetching,
  } = useDefaultLiquidActivity()

  const {
    liquid: activity = defaultLiquidActivity,
  } = currentSubEntry

  // initialize the activity to the default if it isn't defined
  useEffect(
    () => {
      if (!currentSubEntry.liquid && !isFetching) {
        updateActivity(defaultLiquidActivity)
      }
    },
    [
      isFetching,
      defaultLiquidActivity,
      currentSubEntry.liquid,
      updateActivity,
    ],
  )

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          <ActivityIcon
            activityType={ActivityType.Liquid}
            className={styles.activityIcon}
          />
          <ActivityName activityType={ActivityType.Liquid} />
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <ContextMenu
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
        <ModalCloseX />
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <LiquidTypeInput
          subentryId={currentSubEntry._id}
          activity={activity}
          updateActivity={updateActivity}
        />
        <LiquidRangeInput
          activity={activity}
          updateActivity={updateActivity}
        />
        <div className={styles.row}>
          <NoteInput
            currentSubEntry={currentSubEntry}
            updateSubEntry={updateSubEntry}
          />
          <UploadImage updateSubEntry={updateSubEntry} />
        </div>
        <ImageThumbnail
          currentSubEntry={currentSubEntry}
          updateSubEntry={updateSubEntry}
        />
        <SubEntryFormButtons
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
        />
      </ModalBody>
    </>
  )
}
