import { RouterProvider } from "@tanstack/react-router"
import { createRoot } from "react-dom/client"
import { AppContext } from "./contexts"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"
import { router } from "./router"

const root = createRoot(
  document.getElementById("root") as HTMLElement,
)

root.render(
  <AppContext>
    <RouterProvider router={router} />
  </AppContext>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
