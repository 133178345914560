import classNames from "classnames"
import type {
  ComponentProps,
  FunctionComponent,
  SVGProps,
} from "react"
import styles from "./PageLink.module.scss"
import { usePolyglot } from "src/contexts"

export interface Props extends ComponentProps<"button"> {
  polyglotPhraseKey: string;
  Icon: FunctionComponent<SVGProps<SVGElement>>;
}

/*
 * this component looks just like PageLink but is a button not a hyperlink
 */
export function PageLinkButton(props: Props) {
  const {
    Icon,
    polyglotPhraseKey,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  return (
    <button
      {...buttonProps}
      className={classNames(
        styles.container,
        props.className,
      )}
    >
      <Icon className={styles.icon} />
      {polyglot.t(polyglotPhraseKey)}
    </button>
  )
}
