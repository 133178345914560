import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { UserEntriesCollectionContext } from "src/contexts/CollectionContext"
import { useFindCollectionContext } from "src/hooks/rxdb"
import type { UserEntry } from "src/types"

export function useFindUserEntries<T extends UserEntry>(
  queryObject: MangoQuery<T>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<T> {
  return useFindCollectionContext<T>(
    UserEntriesCollectionContext,
    queryObject,
    options,
  )
}
