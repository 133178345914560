import {
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react"
import { useIsMounted } from "usehooks-ts"
import { useUserPost } from "src/hooks/userApi"
import type { FoodSearchResponse } from "src/types"
import { noticeError } from "src/utils"

interface FoodSearchResponseState extends FoodSearchResponse {
  searchExpression: string;
}

const defaultState: FoodSearchResponseState = {
  searchExpression: "",
  foods: [],
}

export function useSearchFoods(currentSearchExpression: string) {
  const isMounted = useIsMounted()
  const userPost = useUserPost()

  // the search expression for which we are currently searching
  const searchExpressionRef = useRef<string>(currentSearchExpression)

  const [
    searchResultsState,
    setSearchResultsState,
  ] = useState<FoodSearchResponseState>(defaultState)

  // the search function
  const search = useCallback(
    async (searchExpression: string) => {
      try {
        searchExpressionRef.current = searchExpression

        if (!userPost) {
          throw new Error("cannot search food for unauthorized user")
        }

        const response = await userPost<FoodSearchResponse>({
          url: "/api/v3/nutritional/foods/search",
          data: {
            query: searchExpression,
          },
        })

        // check that this response is still relevant and component is still mounted
        if (
          isMounted() &&
          searchExpression === searchExpressionRef.current
        ) {
          setSearchResultsState({
            ...response.data,
            searchExpression,
          })
        }
      } catch (e) {
        noticeError(
          e,
          "SearchFood",
          { searchExpression },
        )
      }
    },
    [
      userPost,
      isMounted,
      searchExpressionRef,
      setSearchResultsState,
    ],
  )

  // function to reset to default if there is no search expression
  const clearSearch = useCallback(
    () => {
      searchExpressionRef.current = defaultState.searchExpression
      setSearchResultsState(defaultState)
    },
    [
      searchExpressionRef,
      setSearchResultsState,
    ],
  )

  // this effect checks when we might need to execute the search function
  useEffect(
    () => {
      // if the searchExpression changed, query starting w/ the first page
      if (currentSearchExpression !== searchExpressionRef.current) {
        if (!currentSearchExpression) {
          // reset search
          clearSearch()
        } else {
          // submit a search if there is a search expression
          void search(currentSearchExpression)
        }
        return
      }
    },
    [
      currentSearchExpression,
      search,
      clearSearch,
      searchResultsState,
    ],
  )

  return searchResultsState
}
