import { useCallback } from "react"

import { DeviceChangeForm } from "./DeviceChangeForm"
import { ExerciseForm } from "./ExerciseForm"
import { FoodForm } from "./FoodForm"
import { GlucoseForm } from "./GlucoseForm"
import { InsulinForm } from "./InsulinForm"
import { LiquidForm } from "./LiquidForm"
import { MedicationForm } from "./MedicationForm"
import { NoteForm } from "./NoteForm"
import type {
  EditableSubEntry,
  EditableUserEntry,
  ActivityLookupByType,
  SubEntryType,
} from "src/types"
import { ActivityType } from "src/types"

interface Props {
  currentSubEntry: EditableSubEntry<SubEntryType>;
  setCurrentSubEntry: (subentry: EditableSubEntry<SubEntryType>) => void;
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function SubEntryForm(props: Props) {
  const {
    currentSubEntry,
    currentUserEntry,
    setCurrentSubEntry,
    setCurrentUserEntry,
  } = props

  const updateActivity = useCallback(
    (a: ActivityLookupByType<typeof currentSubEntry.subentry_type>) => {
      setCurrentSubEntry({
        ...currentSubEntry,
        [currentSubEntry.subentry_type]: a,
      })
    },
    [
      currentSubEntry,
      setCurrentSubEntry,
    ],
  )

  const updateSubEntry = useCallback(
    (subEntry: Partial<EditableSubEntry<SubEntryType>>) => {
      setCurrentSubEntry({
        ...currentSubEntry,
        ...subEntry,
      })
    },
    [
      currentSubEntry,
      setCurrentSubEntry,
    ],
  )

  switch (currentSubEntry.subentry_type) {
    case ActivityType.DeviceChange:
      return (
        <DeviceChangeForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Exercise:
      return (
        <ExerciseForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Food:
      return (
        <FoodForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Glucose:
      return (
        <GlucoseForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Insulin:
      return (
        <InsulinForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Liquid:
      return (
        <LiquidForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Medication:
      return (
        <MedicationForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    case ActivityType.Note:
      return (
        <NoteForm
          currentSubEntry={currentSubEntry}
          currentUserEntry={currentUserEntry}
          updateActivity={updateActivity}
          updateSubEntry={updateSubEntry}
          setCurrentUserEntry={setCurrentUserEntry}
        />
      )
    default:
      return null
  }
}
