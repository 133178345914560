import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useFind } from "src/hooks/rxdb"
import { accountInsulinCollectionKey } from "src/schema"
import type { AccountInsulin } from "src/types"

export function useFindAccountInsulins(
  queryObject: MangoQuery<AccountInsulin>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<AccountInsulin> {
  return useFind<AccountInsulin>(
    queryObject,
    accountInsulinCollectionKey,
    options,
  )
}
