import type { RawAxiosRequestHeaders } from "axios"
import { useMemo } from "react"
import { useAuthContext } from "src/contexts/AuthContext"

// this returns auth headers or undefined if there is no access token
export function useUserHeaders(): RawAxiosRequestHeaders | undefined {
  const {
    accessToken,
    isExpired,
  } = useAuthContext()

  const authHeader: RawAxiosRequestHeaders | undefined = useMemo(
    () => (accessToken === undefined || isExpired)
      ? undefined
      : ({ Authorization: `Bearer ${accessToken}` }),
    [
      accessToken,
      isExpired,
    ],
  )

  return authHeader
}
