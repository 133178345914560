import {
  InsightKey,
  InsightRange,
} from "src/types"

// all ranges except for "up to now" and "current rate"
const allRanges = [
  InsightRange.sinceWakeUp,
  InsightRange.threeHours,
  InsightRange.sixHours,
  InsightRange.twelveHours,
  InsightRange.oneDay,
  InsightRange.threeDays,
  InsightRange.oneWeek,
  InsightRange.twoWeeks,
  InsightRange.thirtyDays,
  InsightRange.sixtyDays,
  InsightRange.ninetyDays,
]

const oneDayOrShorter: Readonly<Array<InsightRange>> = [
  InsightRange.sinceWakeUp,
  InsightRange.threeHours,
  InsightRange.sixHours,
  InsightRange.twelveHours,
  InsightRange.oneDay,
]

const oneDayOrLonger = [
  InsightRange.oneDay,
  InsightRange.threeDays,
  InsightRange.oneWeek,
  InsightRange.twoWeeks,
  InsightRange.thirtyDays,
  InsightRange.sixtyDays,
  InsightRange.ninetyDays,
]

/*
 * all "average" tiles should use "oneDayOrLonger"
 * most tiles use "allRanges"
 */
export function copyInsightRangesByKey(): Record<InsightKey, Array<InsightRange>> {
  return {
    [InsightKey.ExerciseAverageDuration]: [...oneDayOrLonger],
    [InsightKey.ExerciseTotalDuration]: [...allRanges],
    [InsightKey.FoodAverageCalories]: [...oneDayOrLonger],
    [InsightKey.FoodAverageCarbs]: [...oneDayOrLonger],
    [InsightKey.FoodAverageFat]: [...oneDayOrLonger],
    [InsightKey.FoodAverageFiber]: [...oneDayOrLonger],
    [InsightKey.FoodAverageProtein]: [...oneDayOrLonger],
    [InsightKey.FoodTotalCalories]: [...allRanges],
    [InsightKey.FoodTotalCarbs]: [...allRanges],
    [InsightKey.FoodTotalFat]: [...allRanges],
    [InsightKey.FoodTotalFiber]: [...allRanges],
    [InsightKey.FoodTotalProtein]: [...allRanges],
    [InsightKey.GlucoseAverageReadingBedtime]: [...oneDayOrLonger],
    [InsightKey.GlucoseAverageReadingWakeUp]: [...oneDayOrLonger],
    [InsightKey.GlucoseAverageReading]: [...allRanges],
    [InsightKey.GlucoseCoefficientOfVariation]: [...allRanges],
    [InsightKey.GlucoseFluxGrade]: [...allRanges],
    [InsightKey.GlucoseHighsAndLows]: [...allRanges],
    [InsightKey.GlucoseManagementIndicator]: [...allRanges],
    [InsightKey.GlucoseMedianReading]: [...allRanges],
    [InsightKey.GlucosePercentageAbove]: [...allRanges],
    [InsightKey.GlucosePercentageBelow]: [...allRanges],
    [InsightKey.GlucosePercentageDistribution]: [...allRanges],
    [InsightKey.GlucosePercentageWithin]: [...allRanges],
    [InsightKey.GlucoseQuartiles]: [...allRanges],
    [InsightKey.GlucoseStandardDeviation]: [...allRanges],
    [InsightKey.GlucoseTotalUnicorns]: [...allRanges],
    [InsightKey.InsulinAverageBasal]: [...oneDayOrLonger],
    [InsightKey.InsulinAverageBolus]: [...oneDayOrLonger],
    [InsightKey.InsulinAverage]: [...oneDayOrLonger],
    [InsightKey.InsulinTotalBasal]: [...allRanges],
    [InsightKey.InsulinTotalBolus]: [...allRanges],
    [InsightKey.InsulinTotal]: [...allRanges],
    [InsightKey.MiniGraph]: [...oneDayOrShorter],
    [InsightKey.TimeInRange]: [InsightRange.upToNow],
  }
}

export const insightRangesByKey: Readonly<Record<InsightKey, Readonly<Array<InsightRange>>>> = copyInsightRangesByKey()
