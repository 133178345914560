import type { ReactNode } from "react"
import { Provider as RxHooksProvider } from "rxdb-hooks"
import { useRxDatabase } from "./useRxDatabase"
import { useAuthContext } from "src/contexts/AuthContext"

interface Props {
  children: ReactNode;
}

export function DbContextProvider(props: Props) {
  const {
    accessToken,
    isExpired,
  } = useAuthContext()

  const state = (
    !accessToken
      ? "destroy"
      : isExpired
        ? "wait"
        : "create"
  )

  const db = useRxDatabase(state)

  return (
    <RxHooksProvider db={db}>
      {props.children}
    </RxHooksProvider>
  )
}
