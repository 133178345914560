/* TODO reaplce w/ svg
import { DataSource } from "src/types"
import appleHealthKit from "images/activities/solid_heart.svg" // TODO
import type {
  ReadOnlyUserEvent,
  ReadOnlySubEntry,
} from "src/types"

// TODO add dexcom and nightscout icons ?
export function getActivitySourceIconPath(item: ReadOnlyUserEvent | ReadOnlySubEntry): string {
  if ((item as ReadOnlyUserEvent).source === DataSource.AppleHealthKit) {
    return appleHealthKit
  }

  if ((item as ReadOnlySubEntry).export_source === DataSource.AppleHealthKit) {
    return appleHealthKit
  }

  return ""
}
*/

export function getActivitySourceIconPath() {
  return ""
}
