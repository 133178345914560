import type { DeepReadonlyObject } from "rxdb"
import type {
  UserItemSharedProperties,
  SubEntryOfType,
  SubEntryType,
  UserEntry,
} from "src/types"

export function userEntriesToSubEntryUserItems<A extends SubEntryType = SubEntryType>(
  entries: Array<DeepReadonlyObject<UserEntry>>,
  activityType?: A, // optionally filter by specific sub entry type
): Array<SubEntryOfType<A> & UserItemSharedProperties> {
  const subEntries: Array<SubEntryOfType<A> & UserItemSharedProperties> = []

  entries.forEach(
    (userEntry) => {
      const sharedProperties: UserItemSharedProperties = {
        created_at: userEntry.created_at,
        started_at: userEntry.started_at,
        account_id: userEntry.account_id,
        ended_at: userEntry.ended_at,
        updated_at: userEntry.updated_at,
        deleted_at: userEntry.deleted_at,
        id: userEntry.id,
        pg_id: userEntry.pg_id,
        updated_by_webhook: userEntry.updated_by_webhook,
      }

      userEntry.subentries.forEach(
        (subEntry) => {
          if (activityType === undefined || subEntry.subentry_type === activityType) {
            subEntries.push({
              ...sharedProperties,
              ...(subEntry as SubEntryOfType<A>),
            })
          }
        },
      )
    },
  )

  return subEntries
}
