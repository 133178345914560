import { debounce } from "lodash-es"
import type { DebounceSettings } from "lodash-es"
import {
  useEffect,
  useMemo,
  useState,
} from "react"
import { useIsMounted } from "usehooks-ts"

export function useDebounceValue<T>(
  value: T,
  delay: number, // in ms
  options: DebounceSettings = {},
): T {
  const isMounted = useIsMounted()

  const [
    val,
    setVal,
  ] = useState<T>(value)

  const updateVal = useMemo(
    () => debounce(
      setVal,
      delay,
      options,
    ),
    [
      setVal,
      delay,
      options,
    ],
  )

  useEffect(
    () => {
      if (isMounted()) {
        updateVal(value)
      }
    },
    [
      updateVal,
      value,
      isMounted,
    ],
  )

  return val
}
