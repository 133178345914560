import type { MouseEventHandler } from "react"
import { useCallback } from "react"

import { GraphItem } from "../GraphItem"
import styles from "../GraphItem.module.scss"
import { ActivityTitle } from "./ActivityTitle"
import { getActivitySourceIconPath } from "./getActivitySourceIconPath"
import {
  useEntryModalPublicContext,
  ActivityIcon,
} from "src/components"
import { useDeviceSettingsProperty } from "src/hooks"
import type {
  ActivityPair,
  Activity as UserActivity,
  ActivityType,
  GlucoseActivity,
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
  ReadOnlySubEntry,
} from "src/types"

export interface Props {
  startedAt: string;
  endedAt: string;
  activityType: Exclude<ActivityType, ActivityType.Glucose>;
  activity: Exclude<UserActivity, GlucoseActivity>;
  subCellStartTime: string;
  subCellStopTime: string;
  item: ReadOnlyUserEvent | ReadOnlySubEntry;
  parentItem?: ReadOnlyUserEntry;
}

export function ActivityGraphItem(props: Props) {
  const {
    activity,
    activityType,
    subCellStartTime,
    subCellStopTime,
    endedAt,
    item,
    parentItem,
    startedAt,
  } = props

  const activityPair: ActivityPair = {
    activity,
    activityType,
  } as ActivityPair
  const { openEntryModal } = useEntryModalPublicContext()

  const graphIconInfo = useDeviceSettingsProperty("graph_icon_info")

  // TODO replace with a source icon
  const sourceIconPath = getActivitySourceIconPath()

  const onClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation()

      const subEntry = item as ReadOnlySubEntry
      if (parentItem && subEntry._id) {
        openEntryModal({
          userEntry: parentItem,
          subEntry: item as ReadOnlySubEntry,
        })
      }
    },
    [
      item,
      parentItem,
      openEntryModal,
    ],
  )

  return (
    <GraphItem
      onClick={onClick}
      startedAt={startedAt}
      endedAt={endedAt}
      subCellStartTime={subCellStartTime}
      subCellStopTime={subCellStopTime}
    >
      <ActivityIcon
        activityType={activityType}
        className={styles.activityIcon}
      />
      {graphIconInfo && (
        <ActivityTitle
          className={styles.message}
          {...activityPair}
        />
      )}
      {sourceIconPath && (
        <img
          alt="TODO"
          className={styles.sourceIcon}
          src={sourceIconPath}
        />
      )}
    </GraphItem>
  )
}
