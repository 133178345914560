import {
  createContext,
  useContext,
} from "react"

/*
 * this context allows any children to change the current search term, which
 * triggers a new food saerch
 */
export interface FoodSearchContextValue {
  setSearchValue: (s: string) => void;
}

type ContextType = FoodSearchContextValue | null
export const FoodSearchContext = createContext<ContextType>(null)

export function useFoodSearchContext() {
  const context = useContext(FoodSearchContext)

  if (context === null) {
    throw new Error("FoodSearch components must be wrapped in <FoodSearchContext />")
  }

  return context
}
