import classNames from "classnames"
import {
  useCallback,
  forwardRef,
} from "react"
import type {
  MouseEventHandler,
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { useTabContext } from "../TabContext"
import styles from "./TabComponent.module.scss"
import { usePolyglot } from "src/contexts"
import type { Tab } from "src/types"

type Props = ComponentPropsWithoutRef<"button"> & {
  tab: Tab;
}

export const TabComponent = forwardRef<HTMLButtonElement, Props>(
  function TabComponent(props: Props, propRef: Ref<HTMLButtonElement>) {
    const {
      tab,
      ...buttonProps
    } = props

    const polyglot = usePolyglot()

    const {
      currentTab,
      setCurrentTab,
    } = useTabContext()

    const buttonClassName = classNames(
      styles.button,
      props.className,
    )

    const headingClassName = classNames(
      styles.title,
      {
        [styles.activeTitle]: currentTab === props.tab,
      },
    )

    const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (ev) => {
        setCurrentTab(tab)

        if (props.onClick) {
          props.onClick(ev)
        }
      },
      [
        setCurrentTab,
        tab,
        props.onClick,
      ],
    )

    return (
      <button
        {...buttonProps}
        className={buttonClassName}
        onClick={onClick}
        ref={propRef}
      >
        <h2 className={headingClassName}>
          {polyglot.t(`pages.home.tabs.${tab}`)}
        </h2>
      </button>
    )
  },
)
