import { createContext } from "react"
import type { SignIn } from "./useSignIn"
import type { RoutePath } from "src/types"

export interface SignOutParams {
  loginRedirect?: RoutePath;
  logoutRedirect?: RoutePath;
}

export type AuthContextValue = {
  accessToken?: string;
  isExpired: boolean;
  signIn: SignIn;
  signOut: (params?: SignOutParams) => Promise<unknown>;
}

type ContextType = AuthContextValue | null

export const AuthContext = createContext<ContextType>(null)
