import { useContext } from "react"
import { CreationOverlayPublicContext } from "./CreationOverlayPublicContext"

export function useCreationOverlayPublicContext() {
  const context = useContext(CreationOverlayPublicContext)

  if (context == null) {
    throw new Error("CreationOverlay components must be wrapped in <CreationOverlay />")
  }

  return context
}
