import { useParams } from "@tanstack/react-router"
import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { get } from "src/api"
import type { StatusResponse } from "src/types"

type ValidateToken = () => Promise<AxiosResponse<StatusResponse>>

export function useValidateToken(): ValidateToken {
  const userId = useParams({
    from: "/app/public/users/$userId/password/edit",
    select: (s) => s.userId,
  })

  return useCallback(
    () => {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get("token") ?? ""

      return get({
        url: "/api/v3/passwords/validate_token",
        params: {
          account_id: userId,
          token,
        },
      })
    },
    [userId],
  )
}
