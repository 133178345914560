import dayjs from "dayjs"
import { useMemo } from "react"
import { createStartedAtSelector } from "src/utils"

export function useDaySelector(dayStart: string) {
  return useMemo(
    () => {
      const dayStop = dayjs(dayStart).endOf("day")
        .toISOString()

      return createStartedAtSelector(
        dayStart,
        dayStop,
      )
    },
    [dayStart],
  )
}
