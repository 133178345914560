import type { RxJsonSchema } from "rxdb"
import {
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./sharedSchema"
import type { Medication } from "src/types"


// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const medicationSchema: RxJsonSchema<Medication> = {
  title: "med_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    category: {
      type: "string",
      maxLength: 128,
    },
    subcategory: { type: "string" },
    description: { type: "string" },
    name: {
      type: "string",
      maxLength: 256,
    },
    ...sharedSchemaProperties,
  },
  required: [...sharedSchemaRequired],
  indexes: [
    "is_deleted",
    [
      "is_deleted",
      "category",
    ],
    [
      "category",
      "is_deleted",
      "name",
    ],
    ...sharedSchemaIndexes,
  ],
}
