import type { ReactNode } from "react"
import { useEffect } from "react"

import { useDeviceSettingsProperty } from "src/hooks"
import styles from "src/styles/themeClasses.module.scss"
import { ThemeAppearance } from "src/types"

interface Props {
  children: ReactNode;
}

// this component applies the current theme to the body element
export function ThemeComponent(props: Props) {
  const currentTheme = useDeviceSettingsProperty("theme")
  const currentThemeAppearance = useDeviceSettingsProperty("theme_appearance")

  useEffect(
    () => {
      const className = currentThemeAppearance === ThemeAppearance.Light
        ? styles[`${currentTheme}Light`]
        : currentThemeAppearance === ThemeAppearance.Dark
          ? styles[`${currentTheme}Dark`]
          : styles[currentTheme]

      document.body.classList.add(className)

      return () => {
        document.body.classList.remove(className)
      }
    },
    [
      currentTheme,
      currentThemeAppearance,
    ],
  )

  return (
    <>
      {props.children}
    </>
  )
}
