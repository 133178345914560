import { transformAccountMed } from "./transformAccountMedicationResponse"
import type {
  AccountMedication,
  StatusResponse,
} from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type AccountMedicationsResponse = StatusResponse & {
  account_meds: Array<AccountMedication>;
}

export function transformAccountMedicationsResponse(data: string): AccountMedicationsResponse {
  const aliasedData = aliasDeletedProperty(data)
  const json = JSON.parse(aliasedData) as AccountMedicationsResponse
  json.account_meds = json.account_meds.map(transformAccountMed)
  return json
}
