import {
  createContext,
  useContext,
} from "react"

import type {
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

interface OpenEntryModal {
  userEntry?: EditableUserEntry;
  subEntry?: EditableSubEntry;
}

export interface EntryModalPublicContextValue {
  hasUnsavedChanges: boolean;
  closeEntryModal: () => void;
  openEntryModal: (args?: OpenEntryModal) => void;
}

type ContextType = EntryModalPublicContextValue | null
export const EntryModalPublicContext = createContext<ContextType>(null)

export function useEntryModalPublicContext() {
  const context = useContext(EntryModalPublicContext)

  if (context === null) {
    throw new Error("EntryModal components must be wrapped in <EntryModalPublicContext />")
  }

  return context
}

