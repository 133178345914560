import { useSources } from "./useSources"
import type {
  Source,
  DexcomFollowSource,
  DexcomRestSource,
} from "src/types"
import { SourceType } from "src/types"

// returns the first dexcom source found in the sources array or undefined
export function useDexcomSource(): DexcomFollowSource | DexcomRestSource | undefined {
  const { data: sources } = useSources()
  if (!sources) {
    return undefined
  }

  return sources.find(
    (source: Source) => source.source_type === SourceType.DexcomFollow || source.source_type === SourceType.DexcomRest,
  ) as DexcomRestSource | DexcomFollowSource
}
