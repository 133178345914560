import type Polyglot from "node-polyglot"
import { Locale } from "src/types"

export const defaultPolyglot: Polyglot = {
  // returns a zero width space
  t: () => "\u200B",
  extend: () => ({}),
  unset: () => ({}),
  locale: (locale?: string) => locale ?? Locale.English,
  clear: () => ({}),
  replace: () => ({}),
  has: () => false,
}
