import { useCallback } from "react"
import { useGlucoseUnit } from "../settings"
import { GlucoseUnit } from "src/types"

/*
 * returns a function that returns the glucose value padded with ".0" when
 * the user uses millimoles per liter
 */
export function useFormatNumberInputGlucoseValue(): (value: number) => string {
  const glucoseUnit = useGlucoseUnit()

  return useCallback(
    (value: number) => {
      if (glucoseUnit === GlucoseUnit.MillimolesPerLiter && value % 1 === 0) {
        return `${value}.0`
      }

      return value.toLocaleString("en")
    },
    [glucoseUnit],
  )
}
