import { useContext } from "react"
import type { AuthContextValue } from "./AuthContext"
import { AuthContext } from "./AuthContext"

export function useAuthContext(): AuthContextValue {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("elements with useAuthContext must be wrapped in <AuthContextProvider>")
  }

  return context
}

