import dayjs from "dayjs"
import type {
  ChangeEventHandler,
  ChangeEvent,
} from "react"
import {
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react"

interface Props<Value> {
  value: Value;
  updatedAt?: string;
  onChange: (v: Value) => void;
  adapter: (c: ChangeEvent<HTMLInputElement>) => Value;
}

interface ReturnValue<Value> {
  value: Value;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

// TODO move this

/*
 * makes a state that tracks the value property
 */
export function useInputPropsForValue<Value>(
  props: Props<Value>,
): ReturnValue<Value> {
  const {
    value,
    updatedAt,
    onChange,
    adapter,
  } = props

  const [
    currentValue,
    setCurrentValue,
  ] = useState<Value>(value)

  const editedAtRef = useRef<string>(dayjs().toISOString())

  useEffect(
    () => {
      if (!updatedAt || dayjs(updatedAt).isAfter(editedAtRef.current)) {
        setCurrentValue(value)
      }
    },
    [
      value,
      updatedAt,
      setCurrentValue,
    ],
  )

  const handler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const newValue = adapter(e)
      setCurrentValue(newValue)
      onChange(newValue)
      editedAtRef.current = dayjs().toISOString()
    },
    [
      setCurrentValue,
      editedAtRef,
      onChange,
    ],
  )

  return useMemo(
    () => ({
      value: currentValue,
      onChange: handler,
    }),
    [
      handler,
      currentValue,
      adapter,
    ],
  )
}
