import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import {
  useDisplayGlucoseValue,
  useReadableGlucoseUnit,
} from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseMedianReading(props: Props) {
  const { range } = props

  const displayGlucoseValue = useDisplayGlucoseValue()
  const glucoseUnit = useReadableGlucoseUnit()

  const { quartileCalculations } = useCalculationsContext()
  const { quartile2 } = quartileCalculations[range] || {}

  const value: string | undefined = quartile2 === undefined
    ? undefined
    : displayGlucoseValue(quartile2)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseMedianReading} />
      <TileTimeSpan range={range} />
      <TileValue>
        {value === undefined ? "--" : value}
      </TileValue>
      <TileUnit>
        {glucoseUnit}
      </TileUnit>
    </>
  )
}
