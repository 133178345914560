import type { ReactNode } from "react"
import { CreationOverlayContent } from "./CreationOverlayContent"
import { CreationOverlayContext } from "./CreationOverlayContext"
import { CreationOverlayPublicContext } from "./CreationOverlayPublicContext"
import { useCreationOverlay } from "./useCreationOverlay"

interface Props {
  children: ReactNode;
}

export function CreationOverlay(props: Props) {
  const [
    creationOverlayContextValue,
    creationOverlayPublicContextValue,
  ] = useCreationOverlay({})

  return (
    <>
      <CreationOverlayPublicContext.Provider value={creationOverlayPublicContextValue}>
        {props.children}
      </CreationOverlayPublicContext.Provider>
      <CreationOverlayContext.Provider value={creationOverlayContextValue}>
        <CreationOverlayContent />
      </CreationOverlayContext.Provider>
    </>
  )
}
