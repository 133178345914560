import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { useMe } from "src/hooks/me"
import { useUserDelete } from "src/hooks/userApi"
import { noticeError } from "src/utils"

type DeleteAccount = () => Promise<AxiosResponse>

const url = "/api/v3/accounts"

export function useDeleteAccount(): DeleteAccount {
  const userDelete = useUserDelete()
  const { data: me } = useMe()
  const accountId = me?.account.id

  return useCallback(
    async () => {
      try {
        if (!userDelete || !accountId) {
          throw new Error("cannot delete account for unauthorized user")
        }

        const response = await userDelete({
          url: `${url}/${accountId}`,
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "DeleteAccount",
          { accountId },
        )

        throw e
      }
    },
    [
      accountId,
      userDelete,
    ],
  )
}
