import type { ComponentPropsWithoutRef } from "react"
import { useTotalCarbsDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function TotalCarbsDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  const value = useTotalCarbsDayMetric(dayStart)

  if (Number.isNaN(value)) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.total_carbs",
        {
          carbs: round(value).toLocaleString(navigator.language),
        },
      )}
    </button>
  )
}
