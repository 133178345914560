import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
  InfoButton,
  Label,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./AccountMedicationModal.module.scss"
import type { EditableAccountMedication } from "./EditableAccountMedication"
import { emptyAccountMedication } from "./EditableAccountMedication"
import { usePolyglot } from "src/contexts"
import { useMedicationsInCategory } from "src/hooks"

interface Props {
  currentAccountMedication: EditableAccountMedication;
  editAccountMedication: (e: EditableAccountMedication) => void;
}

interface Option {
  value: string;
  children: string;
  disabled?: boolean;
}

const id = "medication_name_select"
const polyglotPrefix = "components.account_medication_modal.name."

/*
 * renders a select element for choosing medication name
 */
export function MedicationNameSelect(props: Props) {
  const {
    currentAccountMedication,
    editAccountMedication,
  } = props

  const polyglot = usePolyglot()

  // medications in category alphabetized by name
  const medications = useMedicationsInCategory(currentAccountMedication.med?.category ?? "")

  // the options to present in the select menu
  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: `${emptyAccountMedication.med_id}`,
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      // add each medication to the options menu
      medications.forEach((medication) => {
        const phraseKey = `${polyglotPrefix}${medication.name}`

        // value is the medication id but the display value is the medication name
        optionsArray.push({
          value: medication.id,
          // TODO we are not yet localizing medication names
          children: polyglot.has(phraseKey)
            ? polyglot.t(phraseKey)
            : (medication.name ?? ""),
        })
      })

      return optionsArray
    },
    [medications],
  )

  // update the currentAccountMedication's med_id
  const onMedicationChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const medicationId = e.target.value

      // update med id
      editAccountMedication({
        ...currentAccountMedication,
        med_id: medicationId,
      })
    },
    [
      currentAccountMedication,
      editAccountMedication,
    ],
  )

  const disabled = (currentAccountMedication.med?.category ?? "") === emptyAccountMedication.med.category

  return (
    <div>
      <Label htmlFor={id}
        sizePreset="small"
        className={styles.label}
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Dropdown>
        <DropdownSelect
          id={id}
          disabled={disabled}
          value={currentAccountMedication.med_id ?? ""}
          onChange={onMedicationChange}
          required
        >
          {options.map(
            (selectOption) => (
              <DropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </DropdownSelect>
        <DropdownCurrentSelection />
        <DropdownCaret />
      </Dropdown>
    </div>
  )
}
