import {
  autoUpdate,
  arrow,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from "@floating-ui/react"
import {
  useMemo,
  useState,
  useRef,
} from "react"

import { useReferencesContext } from "../ReferencesContext"
import type { TooltipContextValue } from "./TooltipContext"
import type { TooltipOptions } from "./TooltipOptions"

export function useTooltip({
  placement = "left",
  glucoseUserEvent,
}: TooltipOptions = {}): TooltipContextValue {
  const { containerElementRef } = useReferencesContext()
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  /*
  const {
    delay,
    setCurrentId,
  } = useDelayGroupContext()
  */

  const arrowRef = useRef<HTMLDivElement>(null)

  const data = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(16),
      flip({
        boundary: containerElementRef.current ?? undefined,
      }),
      shift({
        boundary: containerElementRef.current ?? undefined,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const context = data.context
  const hover = useHover(context)
  const interactions = useInteractions([hover])

  return useMemo(
    () => ({
      open: isOpen,
      onOpenChange: setIsOpen,
      glucoseUserEvent,
      arrow: arrowRef,
      ...data,
      ...interactions,
    }),
    [
      isOpen,
      setIsOpen,
      data,
      glucoseUserEvent,
      arrowRef,
    ],
  )
}
