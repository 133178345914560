import {
  Form,
  LargeInput,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import escapeStringRegexp from "escape-string-regexp"
import { useCallback } from "react"
import type { ChangeEventHandler } from "react"

import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.sign_up.password."
const passwordId = "password-id"
const passwordConfirmationId = "password-confirmation-id"

// note forward slash symbol is added below
const acceptedSymbols = "! # $ % & ' ( ) * + , - : ; < = > ? @ [ ] ^ _ { | } ~".split(" ")

const lookAhead = (requirement: string) => `(?=.*${requirement})`

const symbols = `[\\/${escapeStringRegexp(acceptedSymbols.join(""))}]`

const passwordPattern = `^${lookAhead("\\d")}${lookAhead("[a-z]")}${lookAhead("[A-Z]")}${lookAhead(symbols)}.{8,}$`


export function PasswordPage(props: RegistrationPageProps) {
  const {
    children,
    onSubmit,
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        password: e.target.value,
      })
    },
    [updateRegistrationForm],
  )

  const onPasswordConfirmationChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        passwordConfirmation: e.target.value,
      })
    },
    [updateRegistrationForm],
  )

  const passwordConfirmationPattern = `^(${escapeStringRegexp(registrationForm.password)})?$`

  return (
    <Form
      className={styles.personalInformationForm}
      onSubmit={onSubmit}
    >
      <div className={styles.heading}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </div>
      <div className={styles.passwordRequirements}>
        <h3 className={styles.passwordRequirementsHeading}>
          {polyglot.t(`${polyglotPrefix}requirements.heading`)}
        </h3>
        <ul className={styles.passwordRequirementsList}>
          <li className={styles.passwordRequirement}>
            {polyglot.t(`${polyglotPrefix}requirements.character_requirement`)}
          </li>
          <li className={styles.passwordRequirement}>
            {polyglot.t(`${polyglotPrefix}requirements.number_requirement`)}
          </li>
          <li className={styles.passwordRequirement}>
            {polyglot.t(`${polyglotPrefix}requirements.lowercase_requirement`)}
          </li>
          <li className={styles.passwordRequirement}>
            {polyglot.t(`${polyglotPrefix}requirements.uppercase_requirement`)}
          </li>
          <li className={styles.passwordRequirement}>
            {polyglot.t(`${polyglotPrefix}requirements.symbol_requirement`)}
          </li>
        </ul>
      </div>
      <div className={styles.inputs}>
        <ValidationContainer>
          <LargeInput
            id={passwordId}
            placeholder={polyglot.t(`${polyglotPrefix}password.placeholder`)}
            className={styles.input}
            type="password"
            value={registrationForm.password}
            onChange={onPasswordChange}
            pattern={passwordPattern}
            autoComplete="new-password"
            minLength={8}
            required
          />
          <ValidationMessage
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              tooShort: polyglot.t("validation_message.password_too_short"),
              patternMismatch: polyglot.t("validation_message.password_invalid"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={passwordConfirmationId}
            placeholder={polyglot.t(`${polyglotPrefix}password_confirmation.placeholder`)}
            className={styles.input}
            type="password"
            value={registrationForm.passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            pattern={passwordConfirmationPattern}
            autoComplete="new-password"
            required
          />
          <ValidationMessage
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              patternMismatch: polyglot.t("validation_message.password_mismatch"),
            }}
          />
        </ValidationContainer>
      </div>
      {children}
    </Form>
  )
}
