import dayjs from "dayjs"
import { useMemo } from "react"

import type { PartialGlucoseReadingProps } from "../Cell/PartialGlucoseReadingProps"
import { useGlucoseValueAsPercentage } from "../hooks"
import { useVirtualListContext } from "../VirtualListContext"
import { useXAxisContext } from "../XAxisContext"
import { clamp } from "src/utils"


// if a glucose reading is within (0.5 * rowHeight) of the row boundary, then we add an extra row as buffer
const rowProximityAllowance = 0.5

interface Analysis {
  rising: boolean;
  rowsAboveGlucose: number;
  glucoseRows: number;
  rowsBelowGlucose: number;
  rowHeightPercentage: number;
}

/*
 * this hook analyzes the glucose data to tell us how much space we have above and below it
 * in which to place graph items
 */
export function useAvailableSpaceAnalysis(
  cellStopTime: string,
  firstGlucoseReading?: PartialGlucoseReadingProps,
  lastGlucoseReading?: PartialGlucoseReadingProps,
): Analysis {
  const { minutesPerCell } = useXAxisContext()
  const { numberOfRows } = useVirtualListContext()

  // alternates between true and false for each cell
  const gridShift: boolean = useMemo(
    () => 0 === Math.floor(dayjs(cellStopTime).minute() / minutesPerCell) % 2,
    [cellStopTime],
  )

  const hasGlucoseEvents = !!(firstGlucoseReading && lastGlucoseReading)
  const [firstGlucosePercentage] = useGlucoseValueAsPercentage(
    firstGlucoseReading?.glucose.mg_dl || 0,
  )
  const [lastGlucosePercentage] = useGlucoseValueAsPercentage(
    lastGlucoseReading?.glucose.mg_dl || 0,
  )

  return useMemo(
    () => {
      // defaults in case there are no glucose events
      let rising = true
      let glucoseRows = 0
      let rowsAboveGlucose = Math.ceil(numberOfRows / 2)
      let rowsBelowGlucose = numberOfRows - rowsAboveGlucose
      const rowHeightPercentage = 100 / numberOfRows

      if (hasGlucoseEvents) {
        const minGlucosePercentage = Math.min(
          firstGlucosePercentage,
          lastGlucosePercentage,
        )
        let minGlucoseRow = Math.floor(
          minGlucosePercentage / rowHeightPercentage,
        )
        const minGlucoseRemainder =
        minGlucosePercentage / rowHeightPercentage - minGlucoseRow
        if (minGlucoseRemainder <= rowProximityAllowance) {
          minGlucoseRow = minGlucoseRow - 1
        }

        const maxGlucosePercentage = Math.max(
          firstGlucosePercentage,
          lastGlucosePercentage,
        )
        let maxGlucoseRow = Math.floor(
          maxGlucosePercentage / rowHeightPercentage,
        )
        const maxGlucoseRemainder =
        maxGlucosePercentage / rowHeightPercentage - maxGlucoseRow
        if (maxGlucoseRemainder >= 1 - rowProximityAllowance) {
          maxGlucoseRow = maxGlucoseRow + 1
        }

        const lowestUnavailableRow = clamp(
          minGlucoseRow,
          0,
          numberOfRows - 1,
        )
        const highestUnavailableRow = clamp(
          maxGlucoseRow,
          0,
          numberOfRows - 1,
        )

        if (firstGlucosePercentage > lastGlucosePercentage) {
          rising = false
        }

        rowsAboveGlucose = numberOfRows - highestUnavailableRow - 1
        glucoseRows = highestUnavailableRow - lowestUnavailableRow + 1
        rowsBelowGlucose = lowestUnavailableRow
      }

      // when grid shift is true, rows above glucose must start on an even numbered row
      // when grid shift is false, rows above glucose must start on an odd numbered row
      const shiftRowsAboveGlucose =
      rowsAboveGlucose > 0 &&
      ((gridShift && rowsAboveGlucose % 2 === 1) ||
        (!gridShift && rowsAboveGlucose % 2 === 0))

      if (shiftRowsAboveGlucose) {
        rowsAboveGlucose = rowsAboveGlucose - 1
        glucoseRows = glucoseRows + 1
      }

      // when grid shift is false, rows above glucose must start on an even numbered row
      // when grid shift is true, rows below glucose must start on an odd numbered row
      const shiftRowsBelowGlucose =
      rowsBelowGlucose > 0 &&
      ((!gridShift && rowsBelowGlucose % 2 === 1) ||
        (gridShift && rowsBelowGlucose % 2 === 0))

      if (shiftRowsBelowGlucose) {
        rowsBelowGlucose = rowsBelowGlucose - 1
        glucoseRows = glucoseRows + 1
      }

      return {
        rising,
        rowsAboveGlucose,
        glucoseRows,
        rowsBelowGlucose,
        rowHeightPercentage,
      }
    },
    [
      cellStopTime,
      hasGlucoseEvents,
      firstGlucosePercentage,
      lastGlucosePercentage,
      gridShift,
      numberOfRows,
    ],
  )
}
