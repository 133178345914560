import type { ReactNode } from "react"
import {
  useState,
  useMemo,
} from "react"
import { TabContext } from "./TabContext"
import { Tab } from "src/types"

interface Props {
  children: ReactNode;
}

export function TabContextProvider(props: Props) {
  const [
    currentTab,
    setCurrentTab,
  ] = useState<Tab>(Tab.Insights)

  const value = useMemo(
    () => ({
      currentTab,
      setCurrentTab,
    }),
    [
      currentTab,
      setCurrentTab,
    ],
  )

  return (
    <TabContext.Provider value={value}>
      {props.children}
    </TabContext.Provider>
  )
}
