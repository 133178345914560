import {
  useMemo,
  forwardRef,
} from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { useGetGlucoseValueAsPercentage } from "../hooks"
import { useDeviceSettingsProperty } from "src/hooks"

type Props = ComponentPropsWithoutRef<"div">

export const UrgentLow = forwardRef<HTMLDivElement, Props>(
  function UrgentLow(props: Props, propsRef: Ref<HTMLDivElement>) {
    const getGlucoseValueAsPercentage = useGetGlucoseValueAsPercentage()
    const urgentLow = useDeviceSettingsProperty("urgent_low")

    const [
      urgentLowPercentage,
      urgentLowClamped,
    ] = getGlucoseValueAsPercentage(urgentLow)

    const urgentLowStyles = useMemo(
      () => ({
        bottom: `${urgentLowPercentage}%`,
      }),
      [urgentLowPercentage],
    )

    if (urgentLowClamped) {
      return null
    }

    return (
      <div
        {...props}
        ref={propsRef}
        style={urgentLowStyles}
      />
    )
  },
)
