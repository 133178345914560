import { useContext } from "react"
import { TabContext } from "./TabContext"

export function useTabContext() {
  const context = useContext(TabContext)

  if (!context) {
    throw new Error("components using TabContext must be wrapped in <TabContextProvider />")
  }

  return context
}
