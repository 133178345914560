import { useMemo } from "react"
import { useFindAccountMedications } from "./useFindAccountMedications"
import { useAccountProperty } from "src/hooks/me"
import { createAccountMedicationQueryObject } from "src/utils"

export function useAccountMedications() {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createAccountMedicationQueryObject(
      accountId,
      {
        sort: [{ "med.name": "asc" as const }],
      },
    ),
    [accountId],
  )

  return useFindAccountMedications(queryObject)
}
