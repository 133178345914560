import {
  useCallback,
  useState,
  useMemo,
} from "react"

interface ReturnValue<T> {
  current: T;
  original: T;
  hasChanged: boolean;
  update: (t: T) => void;
  save: (t: T) => void;
}

// TODO move
export function useEditableType<T>(item: T): ReturnValue<T> {
  const [
    current,
    setCurrent,
  ] = useState<T>(item)
  const [
    original,
    setOriginal,
  ] = useState<T>(item)

  const save = useCallback(
    (updatedItem: T) => {
      setCurrent(updatedItem)
      setOriginal(updatedItem)
    },
    [
      setCurrent,
      setOriginal,
    ],
  )

  return useMemo(
    () => ({
      current,
      original,
      hasChanged: current !== original,
      update: setCurrent,
      save,
    }),
    [
      current,
      original,
      setCurrent,
      save,
    ],
  )
}
