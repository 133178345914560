import { useMergeRefs } from "@floating-ui/react"
import classNames from "classnames"
import type {
  CSSProperties,
  ForwardedRef,
} from "react"
import {
  forwardRef,
  useMemo,
} from "react"
import styles from "./TooltipContent.module.scss"
import { useTooltipContext } from "./useTooltipContext"

type Props = React.HTMLProps<HTMLElement>

type Placement = "top" | "right" | "bottom" | "left"

// based on the placement of the tooltip, returns the side of the tooltip that
// the arrow should be on
const placementToArrowSide = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right",
}

// based on the placement of the tooltip, returns the sides of the arrow that
// are overlapping the tooltip
const placementToBorderSides = {
  bottom: [
    "borderBottom",
    "borderRight",
  ],
  left: [
    "borderLeft",
    "borderBottom",
  ],
  top: [
    "borderTop",
    "borderLeft",
  ],
  right: [
    "borderRight",
    "borderTop",
  ],
}

/*
 * this arrow attaches to the tooltip to point to the correct glucose event
 */
export const TooltipArrow = forwardRef<HTMLDivElement, Props>(
  function TooltipArrow(props: Props, propRef: ForwardedRef<HTMLDivElement>) {
    const {
      middlewareData,
      placement,
      arrow,
    } = useTooltipContext()

    const ref = useMergeRefs([
      arrow,
      propRef,
    ])

    const style: CSSProperties = useMemo(
      () => {
        if (!middlewareData.arrow || !arrow.current) {
          return {}
        }

        const tooltipSide = placement.split("-")[0] as Placement
        const arrowElement = arrow.current
        const {
          x,
          y,
        } = middlewareData.arrow

        const arrowSide = placementToArrowSide[tooltipSide]
        const borderSides = placementToBorderSides[tooltipSide]

        return {
          // TODO support rtl ?
          left: x != null ? `${x}px` : "",
          top: y != null ? `${y}px` : "",
          [arrowSide]: `-${arrowElement.offsetWidth / 2}px`,
          [`${borderSides[0]}Width`]: "0px",
          [`${borderSides[1]}Width`]: "0px",
          ...props.style,
        }
      },
      [
        middlewareData.arrow,
        placement,
        arrow,
        props.style,
      ],
    )

    return (
      <div
        {...props}
        ref={ref}
        style={style}
        className={classNames(
          props.className,
          styles.arrow,
        )}
      />
    )
  },
)
