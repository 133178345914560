import { usePolyglot } from "src/contexts"
import { InsightRange } from "src/types"

interface Props {
  range: InsightRange;
}

export function InsightRangeString(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()

  let children = polyglot.t(
    "time.day",
    Math.round(range / 24),
  )

  if (range === InsightRange.upToNow) {
    children = polyglot.t("time.up_to_now")
  } else if (range === InsightRange.sinceWakeUp) {
    children = polyglot.t("time.since_wake_up")
  } else if (range <= InsightRange.oneHour) {
    children = polyglot.t(
      "time.minute",
      Math.round(range * 60),
    )
  } else if (range <= InsightRange.oneDay) {
    children = polyglot.t(
      "time.num_hour",
      Math.round(range),
    )
  }

  return (
    <>
      {children}
    </>
  )
}
