import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindMedications } from "./useFindMedications"
import type { Medication } from "src/types"
import { createMedicationQueryObject } from "src/utils"

export function useMedication(id: number | string | undefined | null): DeepReadonlyObject<Medication> | undefined {
  const queryObject = useMemo(
    () => {
      const queryId = (typeof id === "undefined" || typeof id === "object")
        ? ""
        : `${id}`

      return createMedicationQueryObject({
        selector: {
          id: { $eq: queryId },
        },
        limit: 1,
      })
    },
    [id],
  )

  const { result } = useFindMedications(queryObject)
  return result[0]
}
