import dayjs from "dayjs"
import { useMemo } from "react"
import { useXAxisContext } from "../XAxisContext"
import { TimeMarker } from "./TimeMarker"
import { useToday } from "src/hooks"

interface Props {
  cellStartTime: string;
  cellStopTime: string;
}

export function TimeMarkers(props: Props) {
  const { minutesPerTimeMarker } = useXAxisContext()
  const today = useToday()

  const {
    cellStartTime,
    cellStopTime,
  } = props

  const timeMarkers = useMemo(
    () => {
      const stopDiff = dayjs(cellStopTime).diff(
        today,
        "minutes",
      )
      const startDiff = dayjs(cellStartTime).diff(
        today,
        "minutes",
      )

      const markerStartingIndex = Math.ceil(stopDiff / minutesPerTimeMarker)
      const markerEndingIndex = Math.ceil(startDiff / minutesPerTimeMarker)

      const markers: string[] = []
      for (let i = markerStartingIndex; i < markerEndingIndex; i++) {
        const time = dayjs(today)
          .add(
            i * minutesPerTimeMarker,
            "minutes",
          )
          .toISOString()
        markers.push(time)
      }
      return markers
    },
    [
      cellStartTime,
      cellStopTime,
      minutesPerTimeMarker,
      today,
    ],
  )

  return (
    <>
      {timeMarkers.map((time: string) => (
        <TimeMarker key={time}
          time={time}
          cellStopTime={cellStopTime}
        />
      ))}
    </>
  )
}
