import type { ReactNode } from "react"
import {
  useCallback,
  useMemo,
  useState,
} from "react"

import { VirtualListContext } from "./VirtualListContext"
import type { VirtualListContextValue } from "./VirtualListContextValue"

import {
  useDeviceSettingsProperty,
  useOnMountAndResize,
} from "src/hooks"

export interface Props {
  children: ReactNode;
}

type Values = Omit<VirtualListContextValue, "containerRef">

export function VirtualListContextProvider(props: Props) {
  const graphIconScale = useDeviceSettingsProperty("graph_icon_scale")

  const [
    values,
    setValues,
  ] = useState<Values>({
    numberOfRows: 9,
    topPaddingInRem: 0.375,
    dataHeightInRem: 14,
    containerBoundingClientRect: new DOMRect(
      0,
      0,
      0,
      0,
    ),
  })

  const updateVirtualListContext = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        const computedStyles = getComputedStyle(element)
        const rowHeightInRem = parseFloat(computedStyles.getPropertyValue("--graph-row-height"))
        const dataHeightInRem = parseFloat(computedStyles.getPropertyValue("--graph-data-height"))
        const topPaddingInRem = parseFloat(computedStyles.getPropertyValue("--graph-top-padding"))
        const numberOfRows = Math.floor(dataHeightInRem / rowHeightInRem)
        const containerBoundingClientRect = element.getBoundingClientRect()

        setValues({
          numberOfRows,
          topPaddingInRem,
          dataHeightInRem,
          containerBoundingClientRect,
        })
      }
    },
    // TODO test update this when graph icon size changes
    [
      setValues,
      graphIconScale,
    ],
  )

  // this must be attached to the VirtualList outermost div
  const containerRef = useOnMountAndResize(updateVirtualListContext)

  const contextValue = useMemo(
    () => ({
      ...values,
      containerRef,
    }),
    [
      values,
      containerRef,
    ],
  )

  return (
    <VirtualListContext.Provider value={contextValue}>
      {props.children}
    </VirtualListContext.Provider>
  )
}
