import { Navigate } from "@tanstack/react-router"

/*
 * this just redirects to the data source settings page and forwards the URL params
 */
export function SourcesCreated() {
  return (
    <Navigate
      to="/settings/data_source"
      search={Object.fromEntries(new URLSearchParams(window.location.search))}
      replace
    />
  )
}
