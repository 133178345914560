import { useToasterContext } from "@tandemdiabetes/sugarmate-react-components"
import type { ReactNode } from "react"
import { useEffect } from "react"
import {
  AddSourceDexcomAccessDeniedErrorToast,
  AddSourceDexcomConnectionErrorToast,
} from "src/toasts"

interface Props {
  children: ReactNode;
}

// TODO we might not need this anymore
// this component shows any registered flash messages as toasts
export function FlashComponent(props: Props) {
  const { showToast } = useToasterContext()

  useEffect(
    () => {
      const flashString = new URLSearchParams(window.location.search).get("flash")

      if (!flashString) {
        return
      }

      flashString.split(",").forEach(
        (flashCode: string) => {
          switch (flashCode) {
            case "dexcom_source_access_denied":
              showToast(
                "dexcom-source-access-denied",
                AddSourceDexcomAccessDeniedErrorToast,
              )
              break
            case "dexcom_source_connection_error":
              showToast(
                "dexcom-source-connection-error",
                AddSourceDexcomConnectionErrorToast,
              )
              break
            default:
              break
          }
        },
      )
    },
    [],
  )

  return (
    <>
      {props.children}
    </>
  )
}
