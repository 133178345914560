import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import type { MedicationActivity } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: MedicationActivity;
}

// TODO in the future we want to translate medication names
export const MedicationActivityTitle = forwardRef<HTMLDivElement, Props>(
  function MedicationActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {activity.medication.name}
      </div>
    )
  },
)
