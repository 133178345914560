import type { DeepReadonlyObject } from "rxdb"
import type {
  AccountInsulin,
  Insulin,
} from "src/types"

export type EditableAccountInsulin = DeepReadonlyObject<Partial<Omit<AccountInsulin, "insulin">>> & {
  insulin?: DeepReadonlyObject<Partial<Insulin>>;
}

export const emptyAccountInsulin = {
  id: undefined,
  insulin_id: "",
  form: "",
  precision: undefined,
  secondary_precision: null,
  insulin: {
    category: "",
  },
}
