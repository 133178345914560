import styles from "./Activity.module.scss"
import { ActivityButtons } from "./ActivityButtons"
import { DayStats } from "./DayStats"
import { HideNotesHistory } from "./HideNotesHistory"

import {
  useDebouncedUpdater,
  useDeviceProperty,
  useUpdateSettings,
} from "src/hooks"

export function Activity() {
  const updatedAt = useDeviceProperty(
    "updated_at",
    undefined,
  )

  // TODO catch error and show toast
  const updateSettings = useUpdateSettings()
  const debouncedUpdateSettings = useDebouncedUpdater(updateSettings)

  return (
    <div className={styles.container}>
      <ActivityButtons
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <HideNotesHistory
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
      <DayStats
        updatedAt={updatedAt}
        updateSettings={debouncedUpdateSettings}
      />
    </div>
  )
}
