import { useMemo } from "react"
import { useUserGet } from "./useUserGet"
import { responseData } from "src/api"

export function useUserGetData<T>() {
  const userGet = useUserGet()

  return useMemo(
    () => userGet && responseData<T>(userGet),
    [userGet],
  )
}
