import { useContext } from "react"
import type { OauthAuthorizeContextValue } from "./OauthAuthorizeContext"
import { OauthAuthorizeContext } from "./OauthAuthorizeContext"

export function useOauthAuthorizeContext(): OauthAuthorizeContextValue {
  const context = useContext(OauthAuthorizeContext)

  if (!context) {
    throw new Error("elements with useOauthAuthorizeContext must be wrapped in <OauthAuthorizeContextProvider>")
  }

  return context
}
