import classNames from "classnames"
import { FavoriteFeedItem } from "./FavoriteFeedItem"
import styles from "./FeedItem.module.scss"
import { UserEntryFeedItem } from "./UserEntryFeedItem"
import { UserEventFeedItem } from "./UserEventFeedItem"
import type { FeedItem } from "src/pages/Home/Feed/ItemContext"
import type {
  FavoriteUserEntry,
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"

interface Props {
  userItem: FeedItem;
}

/*
 * this component renders an activity as either an event or an entry
 * with the relevant header and details
 */
export function FeedItem(props: Props) {
  const { userItem } = props
  const className = classNames(
    styles.container,
    {
      [styles.firstOfDay]: userItem.isFirstOfDay,
      [styles.lastOfDay]: userItem.isLastOfDay,
    },
  )

  const userEvent = userItem as ReadOnlyUserEvent
  if (userEvent.event_type) {
    return (
      <UserEventFeedItem
        className={className}
        userEvent={userEvent}
      />
    )
  }

  const userEntry = userItem as ReadOnlyUserEntry

  if (userEntry.entry_template_id) {
    return (
      <FavoriteFeedItem
        className={className}
        userEntry={userEntry as FavoriteUserEntry}
      />
    )
  }

  return (
    <UserEntryFeedItem
      className={className}
      userEntry={userEntry}
    />
  )
}
