import { useUpdateCollectionItem } from "src/hooks/rxdb"
import { entryTemplateCollectionKey } from "src/schema"
import type { EntryTemplate } from "src/types"

export function useUpdateEntryTemplate() {
  return useUpdateCollectionItem<"entry_template", EntryTemplate>(
    entryTemplateCollectionKey,
    "entry_template",
    "/api/v3/entry_templates",
  )
}
