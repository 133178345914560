import dayjs from "dayjs"

// value is between 0 and 48 and represents half hour increments from 6 pm
// returns a unix timestamp in milliseconds
export function timeToSleepHours(time: string): number {
  const value = dayjs().startOf("day")
    .subtract(
      1,
      "day",
    )
    .hour(18)
    .diff(
      time,
      "hour",
      true,
    )

  return Math.abs(value * 2) % 48
}
