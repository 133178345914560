import {
  useContext,
  createContext,
} from "react"
import type { TimeInRangeCalculationValue } from "src/hooks"
import type { InsightCalculatorGroup } from "src/types"
import type {
  ExerciseCalculationsValue,
  FoodCalculationsValue,
  GlucoseCalculationsValue,
  InsulinCalculationsValue,
  QuartileCalculationValue,
} from "src/workers/insightCalculationWorker"

export interface CalculationsContextValue {
  queryEndTime: string;
  timeSinceWakeUpInHours?: number;
  [InsightCalculatorGroup.Exercise]: ExerciseCalculationsValue;
  [InsightCalculatorGroup.Food]: FoodCalculationsValue;
  [InsightCalculatorGroup.Glucose]: GlucoseCalculationsValue;
  [InsightCalculatorGroup.Insulin]: InsulinCalculationsValue;
  [InsightCalculatorGroup.Quartile]: Record<QuartileCalculationValue[0], QuartileCalculationValue[1]>;
  [InsightCalculatorGroup.TimeInRange]: TimeInRangeCalculationValue | null;
}

type ContextType = CalculationsContextValue | null

export const CalculationsContext = createContext<ContextType>(null)

export function useCalculationsContext() {
  const context = useContext(CalculationsContext)

  if (!context) {
    throw new Error("Insights must be wrapped in <CalculationsContextProvider />")
  }

  return context
}
