import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useMemo,
  useState,
  useRef,
} from "react"

import { defaultXAxisContextValue } from "./defaultXAxisContextValue"
import { pixelsPerMinuteToMinutesPerMarker } from "./pixelsPerMinuteToMinutesPerMarker"
import { XAxisContext } from "./XAxisContext"
import { useThisMinute } from "src/hooks"
import { roundTimeDown } from "src/utils"

interface Props {
  children: ReactNode;
}

export function XAxisContextProvider(props: Props) {
  const [minutesPerCell] = useState<number>(defaultXAxisContextValue.minutesPerCell)

  const minutesPerCellRef = useRef<number>(minutesPerCell)
  minutesPerCellRef.current = minutesPerCell

  const [
    cellWidth,
    setCellWidth,
  ] = useState<number>(defaultXAxisContextValue.cellWidth)

  const cellWidthRef = useRef<number>(cellWidth)
  cellWidthRef.current = cellWidth

  const thisMinute = useThisMinute()

  const graphStart = useMemo(
    () => dayjs(thisMinute).add(
      1,
      "hours",
    )
      .toISOString(),
    [thisMinute],
  )

  const graphStartRef = useRef<string>(graphStart)
  graphStartRef.current = graphStart

  const minutesPerTimeMarker = useMemo(
    () => pixelsPerMinuteToMinutesPerMarker(cellWidth / minutesPerCell),
    [
      cellWidth,
      minutesPerCell,
    ],
  )

  const firstCellEnd = useMemo(
    () => roundTimeDown(
      graphStart,
      60 / minutesPerCell,
    ).toISOString(),
    [
      graphStart,
      minutesPerCell,
    ],
  )

  const value = useMemo(
    () => ({
      cellWidth,
      cellWidthRef,
      subCellsPerCell: defaultXAxisContextValue.subCellsPerCell,
      subCellWidth: cellWidth / defaultXAxisContextValue.subCellsPerCell,
      minutesPerSubCell: minutesPerCell / defaultXAxisContextValue.subCellsPerCell,
      firstCellEnd,
      graphStart,
      graphStartRef,
      minutesPerCell,
      minutesPerCellRef,
      minutesPerTimeMarker,
      setCellWidth,
    }),
    [
      cellWidth,
      cellWidthRef,
      firstCellEnd,
      graphStart,
      graphStartRef,
      minutesPerCell,
      minutesPerCellRef,
      minutesPerTimeMarker,
      setCellWidth,
    ],
  )

  return (
    <XAxisContext.Provider value={value}>
      {props.children}
    </XAxisContext.Provider>
  )
}
