export enum Trend {
  DoubleDown = "DOUBLE_DOWN",
  DoubleUp = "DOUBLE_UP",
  Flat = "FLAT",
  FortyFiveDown = "FORTY_FIVE_DOWN",
  FortyFiveUp = "FORTY_FIVE_UP",
  None = "NONE",
  NotComputable = "NOT_COMPUTABLE",
  OutOfRange = "OUT_OF_RANGE",
  SingleDown = "SINGLE_DOWN",
  SingleUp = "SINGLE_UP",
}
