import dayjs from "dayjs"
import {
  forwardRef,
  useMemo,
} from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { usePolyglot } from "src/contexts"
import type {
  ReadOnlySubEntry,
  ReadOnlyUserEvent,
  ExerciseActivity,
} from "src/types"
import { ExerciseIntensity } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: ExerciseActivity;
  item: ReadOnlySubEntry | ReadOnlyUserEvent;
}

export const ExerciseActivityMessage = forwardRef<HTMLDivElement, Props>(
  function ExerciseActivityMessage(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      item,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const minutes = useMemo(
      () => {
        const userEvent = item as ReadOnlyUserEvent
        const subEntry = item as ReadOnlySubEntry

        if (userEvent.event_type) {
          return dayjs(userEvent.started_at).diff(
            userEvent.ended_at,
            "minute",
          )
        }

        return (subEntry.duration_in_seconds || 0) / 60
      },
      [item],
    )

    const durationMessage = minutes && polyglot.t(
      "time.min",
      minutes,
    )

    // get intensity from "activity.intensity"
    const intensityMessage = activity.intensity === ExerciseIntensity.None
      ? null
      : polyglot.t(`components.activity_summary.message.exercise_intensity.${activity.intensity}`)

    const message = (durationMessage && intensityMessage) ?
      polyglot.t(
        "components.activity_summary.message.exercise_summary",
        {
          duration_message: durationMessage,
          intensity_message: intensityMessage,
        },
      ) : durationMessage || intensityMessage || item.note

    if (!message) {
      return null
    }

    // TODO span ?
    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {message}
      </div>
    )
  },
)
