import {
  Toggle,
  Label,
  InfoButton,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"
import styles from "./Reports.module.scss"
import { usePolyglot } from "src/contexts"
import type { Report } from "src/types"

const id = "show-photos-input-id"
const polyglotPrefix = "pages.generate_report.show_photos."

interface Props {
  report: Report;
  updateReport: (report: Partial<Report>) => void;
}

export function ShowPhotosToggle(props: Props) {
  const {
    updateReport,
    report,
  } = props

  const polyglot = usePolyglot()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateReport({
        show_photos: e.target.checked,
      })
    },
    [updateReport],
  )

  return (
    <div className={styles.toggleRow}>
      <Label htmlFor={id}>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}info_title`)}>
          {polyglot.t(`${polyglotPrefix}info_description`)}
        </InfoButton>
      </Label>
      <Toggle
        checked={report.show_photos}
        onChange={onChange}
        id={id}
      />
    </div>
  )
}
