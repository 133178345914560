import { useContext } from "react"
import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"
import { useRxData } from "rxdb-hooks"
import { useIsFetchingForQuery } from "./useIsFetchingForQuery"
import { useQueryFunction } from "./useQueryFunction"
import type { CollectionContext } from "src/types"

/*
 * a special find function for collections that have their own context
 */
export function useFindCollectionContext<T>(
  Context: CollectionContext,
  queryObject: MangoQuery<T>,
  options: Omit<UseRxQueryOptions, "json"> = {},
): RxQueryResult<T> {
  const { collectionKey } = useContext(Context)
  const queryFunction = useQueryFunction<T>(queryObject)
  const result = useRxData<T>(
    collectionKey,
    queryFunction,
    {
      ...options,
      json: true,
    },
  )

  // check to see if more data is being requested that might be relevant
  // to this query
  const isFetching = useIsFetchingForQuery(
    Context,
    queryObject,
  )

  return {
    ...result,
    isFetching: isFetching || result.isFetching,
  }
}
