import { usePolyglot } from "src/contexts"
import type { ExerciseSubEntry } from "src/types"
import { ExerciseType } from "src/types"
import { round } from "src/utils"

const polyglotPrefix = "components.favorite_summary.message.exercise."

interface Props {
  subEntry: ExerciseSubEntry;
}

export function ExerciseMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  const exercise = subEntry.exercise.exercise_type === ExerciseType.None
    ? polyglot.t(`${polyglotPrefix}fallback`)
    : polyglot.t(`exercise_type.${subEntry.exercise.exercise_type}`)

  if (!subEntry.duration_in_seconds) {
    return (
      <>
        {exercise}
      </>
    )
  }

  return (
    <>
      {polyglot.t(
        `${polyglotPrefix}summary`,
        {
          exercise,
          duration: round(subEntry.duration_in_seconds / 60),
        },
      )
      }
    </>
  )
}
