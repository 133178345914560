import { useEffect } from "react"
import { useTimeInRangeCalculation } from "src/hooks"
import type { TimeInRangeCalculationValue } from "src/hooks"

interface Props {
  setState: (c: TimeInRangeCalculationValue) => void;
}

export function TimeInRangeCalculation(props: Props) {
  const { setState } = props

  const value = useTimeInRangeCalculation()

  useEffect(
    () => {
      setState(value)
    },
    [
      value,
      setState,
    ],
  )

  return null
}
