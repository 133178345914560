import dayjs from "dayjs"
import type { XAxisContextValue } from "./XAxisContextValue"
import { roundTimeDown } from "src/utils"

const graphStart = dayjs().toISOString()
const minutesPerCell = 60 * 12
const cellWidth = 160 * 12
const subCellsPerCell = 12

// eslint-disable-next-line @typescript-eslint/no-empty-function
const voidFunction = () => {}

export const defaultXAxisContextValue: XAxisContextValue = {
  cellWidth,
  cellWidthRef: { current: cellWidth },
  firstCellEnd: roundTimeDown(
    graphStart,
    60 / minutesPerCell,
  ).toISOString(),
  graphStart,
  graphStartRef: { current: graphStart },
  minutesPerCell,
  minutesPerCellRef: { current: minutesPerCell },
  minutesPerTimeMarker: 60,
  setCellWidth: voidFunction,
  subCellWidth: cellWidth / subCellsPerCell,
  minutesPerSubCell: minutesPerCell / subCellsPerCell,
  subCellsPerCell: subCellsPerCell,
}
