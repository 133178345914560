import { useMemo } from "react"
import { useFindMedications } from "./useFindMedications"
import { createMedicationQueryObject } from "src/utils"

export function useMedicationsInCategory(category: string) {
  const queryObject = useMemo(
    () => createMedicationQueryObject({
      selector: {
        category: { $eq: category },
      },
      sort: [{ name: "asc" as const }],
    }),
    [category],
  )

  const { result } = useFindMedications(queryObject)
  return result
}
