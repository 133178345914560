import { Outlet } from "@tanstack/react-router"
import { FlashComponent } from "./FlashComponent"
import { ThemeComponent } from "./ThemeComponent"
import { OauthAuthorizeContextProvider } from "src/contexts"

export function OauthAuthorizeRootComponent() {
  return (
    <OauthAuthorizeContextProvider>
      <FlashComponent>
        <ThemeComponent>
          <Outlet />
        </ThemeComponent>
      </FlashComponent>
    </OauthAuthorizeContextProvider>
  )
}
