import { round } from "./round"
import { LiquidUnit } from "src/types"

const microlitersPerOz = 29573.5

function roundToQuarter(value: number) {
  return round(value * 4) / 4
}

/*
 * this function convers ml to liquid unit and currently only uses US units
 *
 * note: a fluid ounce is 29.5735 in the US, an imperial fluid ounce is 28.4131
 * note: a cup is 236.588 ml (8 us fl oz) in the US and 250 ml in Australia, Canada, New Zealand, and the UK, and an imperial cup is 284.131 ml
 * note: a pint is 473.176 ml (16 us fl oz) in the US and an imperial pint is 568 (20 imperial fl oz)
 */
export function liquidMicrolitersToUnit(microliters: number, unit: LiquidUnit): number {
  const ounces = microliters / microlitersPerOz

  switch (unit) {
    case LiquidUnit.Cups:
      return roundToQuarter(ounces / 8)
    case LiquidUnit.Pints:
      return roundToQuarter(ounces / 16)
    case LiquidUnit.FluidOunces:
      return roundToQuarter(ounces)
    case LiquidUnit.Liters:
      return round(microliters / 1000000)
    case LiquidUnit.Milliliters:
      return round(microliters / 1000)
    default:
      return NaN
  }
}
