import { useContext } from "react"
import { CreationOverlayContext } from "./CreationOverlayContext"

export function useCreationOverlayContext() {
  const context = useContext(CreationOverlayContext)

  if (context == null) {
    throw new Error("CreationOverlay components must be wrapped in <CreationOverlay />")
  }

  return context
}
