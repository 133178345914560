import dayjs from "dayjs"
import { useMemo } from "react"
import type { CSSProperties } from "react"
import {
  useCellSelector,
  useGetTimeDifferenceInPixels,
} from "../hooks"

import { useXAxisContext } from "../XAxisContext"
import type { PartialGlucoseReadingProps } from "./PartialGlucoseReadingProps"
import type { PartialGraphItemProps } from "./PartialGraphItemProps"
import { sortGlucoseReadings } from "./sortGlucoseReadings"
import { sortGraphItems } from "./sortGraphItems"
import {
  useFindUserEntries,
  useFindUserEvents,
} from "src/hooks"

import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
  SubEntry,
  SubEntryType,
  UserEventType,
  UserEvent,
  UserEntry,
} from "src/types"
import { ActivityType } from "src/types"
import { mergeSortedArrays } from "src/utils"

interface SubCell {
  subCellStartTime: string; // later than stop time
  subCellStopTime: string; // earlier than start time
  subCellStyles: CSSProperties;
}

interface CellDataResponse {
  subCells: Array<SubCell>;
  graphItemsProps: Array<Array<PartialGraphItemProps>>;
  glucoseReadingsProps: Array<Array<PartialGlucoseReadingProps>>;
}

export function useCellReadingsAndGraphItems(
  cellStartTime: string,
  cellStopTime: string,
): CellDataResponse {
  const getTimeDifferenceInPixels = useGetTimeDifferenceInPixels()

  // note cell selector sorts in ascending order
  const cellSelector = useCellSelector(cellStartTime)
  const {
    minutesPerSubCell,
    subCellsPerCell,
    subCellWidth,
  } = useXAxisContext()

  // get all events and separate into glucose events and otehr events
  const { result: events } = useFindUserEvents<UserEvent>(cellSelector)

  const subCells = useMemo(
    () => Array.from(Array(subCellsPerCell)).map(
      (_, index) => {
        const subCellStartTime = dayjs(cellStartTime)
          .subtract(
            minutesPerSubCell * index,
            "minute",
          )
          .toISOString()

        const subCellStopTime = dayjs(cellStartTime)
          .subtract(
            minutesPerSubCell * (index + 1),
            "minute",
          )
          .toISOString()

        const subCellXPosition = getTimeDifferenceInPixels(
          subCellStopTime,
          cellStopTime,
        )

        const subCellStyles = {
          left: subCellXPosition,
          width: subCellWidth,
        }

        return {
          subCellStartTime,
          subCellStopTime,
          subCellStyles,
        }
      },
    ),
    [
      cellStartTime,
      cellStopTime,
      minutesPerSubCell,
      subCellsPerCell,
      subCellWidth,
      getTimeDifferenceInPixels,
    ],
  )

  const {
    eventGlucoseReadingsProps,
    eventActivitiesProps,
  } = useMemo(
    () => {
      const glucoseReadingsProps: Array<Array<PartialGlucoseReadingProps>> = Array.from(Array(subCellsPerCell)).map(() => [])
      const graphItemsProps: Array<Array<PartialGraphItemProps>> = Array.from(Array(subCellsPerCell)).map(() => [])

      events.forEach((userEvent: ReadOnlyUserEvent) => {
        const subCellIndex = Math.floor(
          dayjs(cellStartTime).diff(
            userEvent.started_at,
            "minute",
            true,
          ) / minutesPerSubCell,
        )

        if (userEvent.event_type === ActivityType.Glucose) {
          glucoseReadingsProps[subCellIndex]?.push({
            id: userEvent.id,
            key: userEvent.id,
            glucose: userEvent.glucose,
            readingTime: userEvent.started_at,
            item: userEvent,
          })
        } else {
          graphItemsProps[subCellIndex]?.push({
            itemType: "activity",
            key: userEvent.id,
            startedAt: userEvent.started_at,
            endedAt: userEvent.ended_at,
            activityType: userEvent.event_type,
            activity: userEvent[userEvent.event_type as (UserEventType & keyof typeof userEvent)],
            item: userEvent,
          })
        }
      })

      return {
        eventGlucoseReadingsProps: glucoseReadingsProps,
        eventActivitiesProps: graphItemsProps,
      }
    },
    [
      events,
      cellStartTime,
      minutesPerSubCell,
      subCellsPerCell,
    ],
  )

  // get all entries and separate into glucose entries and other entries
  const { result: entries } = useFindUserEntries<UserEntry>(cellSelector)

  const {
    entryGlucoseReadingsProps,
    entryActivitiesProps,
  } = useMemo(
    () => {
      const glucoseReadingsProps: Array<Array<PartialGlucoseReadingProps>> = Array.from(Array(subCellsPerCell)).map(() => [])
      const graphItemsProps: Array<Array<PartialGraphItemProps>> = Array.from(Array(subCellsPerCell)).map(() => [])

      entries.forEach((userEntry: ReadOnlyUserEntry) => {
        const subCellIndex = Math.floor(
          dayjs(cellStartTime).diff(
            userEntry.started_at,
            "minute",
            true,
          ) / minutesPerSubCell,
        )

        if (userEntry.entry_template_id) {
          graphItemsProps[subCellIndex]?.push({
            itemType: "favorite",
            key: userEntry.id,
            startedAt: userEntry.started_at,
            endedAt: userEntry.ended_at,
            entryTemplateId: userEntry.entry_template_id,
            item: userEntry,
          })
        } else {
          userEntry.subentries.forEach((subEntry: SubEntry) => {
            if (subEntry.subentry_type === ActivityType.Glucose) {
              glucoseReadingsProps[subCellIndex]?.push({
                key: subEntry._id,
                id: subEntry._id,
                glucose: subEntry.glucose,
                readingTime: userEntry.started_at,
                item: subEntry,
                parentItem: userEntry,
              })
            } else {
              graphItemsProps[subCellIndex]?.push({
                itemType: "activity",
                key: subEntry._id,
                startedAt: userEntry.started_at,
                endedAt: dayjs(userEntry.started_at)
                  .add(
                    subEntry.duration_in_seconds ?? 0,
                    "seconds",
                  )
                  .toISOString(),
                activityType: subEntry.subentry_type,
                activity: subEntry[subEntry.subentry_type as (SubEntryType & keyof typeof subEntry)],
                item: subEntry,
                parentItem: userEntry,
              })
            }
          })
        }
      })

      return {
        entryGlucoseReadingsProps: glucoseReadingsProps,
        entryActivitiesProps: graphItemsProps,
      }
    },
    [
      entries,
      minutesPerSubCell,
      subCellsPerCell,
    ],
  )

  // combine the arrays of glucose entries with glucose events, and the other entries with other events
  // also sort the arrays
  return useMemo(
    () => {
      const glucoseReadingsProps = subCells.map(
        (_, index) => {
          const eventGlucoseReadings = eventGlucoseReadingsProps[index]
          const entryGlucoseReadings = entryGlucoseReadingsProps[index]

          return mergeSortedArrays(
            eventGlucoseReadings,
            entryGlucoseReadings,
            sortGlucoseReadings,
          )
        },
      )

      const graphItemsProps = subCells.map(
        (_, index) => {
          const eventActivities = eventActivitiesProps[index]
          const entryActivities = entryActivitiesProps[index]

          return mergeSortedArrays(
            eventActivities,
            entryActivities,
            sortGraphItems,
          )
        },
      )

      return {
        subCells,
        glucoseReadingsProps,
        graphItemsProps,
      }
    },
    [
      subCells,
      eventGlucoseReadingsProps,
      eventActivitiesProps,
      entryGlucoseReadingsProps,
      entryActivitiesProps,
    ],
  )
}
