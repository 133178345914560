import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"

import { transformAccountInsulinResponse } from "./transformAccountInsulinResponse"
import {
  useCreateCollectionItem,
  useUpdateCollectionItem,
} from "src/hooks/rxdb"
import { accountInsulinCollectionKey } from "src/schema"
import type {
  DeepPartial,
  AccountInsulin,
} from "src/types"

type PartialAccountInsulin = DeepReadonlyObject<DeepPartial<AccountInsulin>>

export function useSaveAccountInsulin() {
  const createAccountInsulin = useCreateCollectionItem(
    accountInsulinCollectionKey,
    "account_insulin",
    "/api/v3/account_insulins",
    transformAccountInsulinResponse,
  )

  const updateAccountInsulin = useUpdateCollectionItem(
    accountInsulinCollectionKey,
    "account_insulin",
    "/api/v3/account_insulins",
    transformAccountInsulinResponse,
  )

  return useCallback(
    (accountInsulin: PartialAccountInsulin) => {
      if (accountInsulin.id) {
        return updateAccountInsulin({
          ...accountInsulin,
          id: accountInsulin.id,
        })
      }

      return createAccountInsulin(accountInsulin)
    },
    [
      createAccountInsulin,
      updateAccountInsulin,
    ],
  )
}
