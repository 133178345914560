import dayjs from "dayjs"
import {
  useCallback,
  useEffect,
  useRef,
} from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useNewestAccountInsulin } from "src/hooks"
import type {
  AccountInsulin,
  InsulinActivity,
} from "src/types"

export function useOnAccountInsulinCreated(
  activity: InsulinActivity,
  updateActivity: (a: InsulinActivity) => void,
) {
  const lastUpdatedRef = useRef<string>(dayjs().toISOString())
  const newestAccountInsulin = useNewestAccountInsulin()

  // this function updates the activity with the provided account insulin
  const updateActivityWithAccountInsulin = useCallback(
    (accountInsulin: DeepReadonlyObject<AccountInsulin>) => {
      updateActivity({
        ...activity,
        insulin_med: {
          account_insulin_id: Number(accountInsulin.id),
          insulin_id: Number(accountInsulin.insulin_id),
          category: accountInsulin.insulin.category ?? "",
          description: accountInsulin.insulin.description ?? "",
          name: accountInsulin.name_override ?? accountInsulin.insulin.name ?? "",
          form: accountInsulin.form ?? accountInsulin.insulin.forms?.[0] ?? "",
          onset: accountInsulin.onset ?? NaN,
          peak: accountInsulin.peak ?? null,
          duration: accountInsulin.duration ?? NaN,
          precision: accountInsulin.precision,
          secondary_precision: accountInsulin.secondary_precision ?? null,
          is_basal: accountInsulin.is_basal,
        },
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  useEffect(
    () => {
      if (
        newestAccountInsulin &&
        dayjs(newestAccountInsulin.created_at).isAfter(lastUpdatedRef.current)
      ) {
        updateActivityWithAccountInsulin(newestAccountInsulin)
        lastUpdatedRef.current = newestAccountInsulin.created_at
      }
    },
    [
      lastUpdatedRef,
      newestAccountInsulin,
      updateActivityWithAccountInsulin,
    ],
  )
}
