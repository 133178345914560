import {
  useRef,
  useEffect,
  useCallback,
} from "react"
import { useRxCollection } from "rxdb-hooks"
import type { Handler } from "./Handler"
import { noticeError } from "src/utils"

interface Item {
  id: string;
  upated_by_webhook?: boolean;
}

export function useCollectionHandler<T extends Item>(
  collectionKey: string,
): Handler<T> {
  const collection = useRxCollection<T>(collectionKey)
  const unsavedCollectionItems = useRef<Array<T>>([])

  // add the unsaved collection items to the collection when the collection is made available
  useEffect(
    () => {
      if (collection && unsavedCollectionItems.current.length) {
        collection
          .bulkUpsert(unsavedCollectionItems.current)
          .then(
            (savedCollectionItems) => {
              const savedCollectionItemIds = savedCollectionItems.map(
                (savedCollectionItem) => savedCollectionItem.toJSON().id,
              )

              unsavedCollectionItems.current = unsavedCollectionItems.current.filter(
                (unsavedCollectionItem) => !savedCollectionItemIds.includes(unsavedCollectionItem.id),
              )
            },
          )
          .catch(
            (e) => {
              noticeError(
                e,
                "RxDBWebsocketBulkUpsert",
                { collectionKey },
              )
            },
          )
      }
    },
    [
      collectionKey,
      collection,
      unsavedCollectionItems,
    ],
  )

  return useCallback(
    async (websocketItem: T) => {
      const item = {
        ...websocketItem,
        updated_by_webhook: true,
      }

      if (collection) {
        try {
          await collection.upsert(item)
        } catch (e) {
          noticeError(
            e,
            "RxDBWebsocketUpsert",
            { item },
          )
        }
      } else {
        unsavedCollectionItems.current.push(item)
      }
    },
    [
      collection,
      unsavedCollectionItems,
    ],
  )
}
