import { useMemo } from "react"
import type {
  Insight,
  InsightRange,
} from "src/types"
import {
  InsightKey,
  InsightCalculatorGroup,
} from "src/types"

// maps each insight type onto the hook that can calculate its value
const hooksByInsightType: Record<InsightKey, InsightCalculatorGroup> = {
  [InsightKey.ExerciseTotalDuration]: InsightCalculatorGroup.Exercise,
  [InsightKey.ExerciseAverageDuration]: InsightCalculatorGroup.Exercise,
  [InsightKey.FoodAverageCalories]: InsightCalculatorGroup.Food,
  [InsightKey.FoodAverageCarbs]: InsightCalculatorGroup.Food,
  [InsightKey.FoodAverageFat]: InsightCalculatorGroup.Food,
  [InsightKey.FoodAverageFiber]: InsightCalculatorGroup.Food,
  [InsightKey.FoodAverageProtein]: InsightCalculatorGroup.Food,
  [InsightKey.FoodTotalCalories]: InsightCalculatorGroup.Food,
  [InsightKey.FoodTotalCarbs]: InsightCalculatorGroup.Food,
  [InsightKey.FoodTotalFat]: InsightCalculatorGroup.Food,
  [InsightKey.FoodTotalFiber]: InsightCalculatorGroup.Food,
  [InsightKey.FoodTotalProtein]: InsightCalculatorGroup.Food,
  [InsightKey.GlucoseAverageReading]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseAverageReadingBedtime]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseAverageReadingWakeUp]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseCoefficientOfVariation]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseFluxGrade]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseHighsAndLows]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseManagementIndicator]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseMedianReading]: InsightCalculatorGroup.Quartile,
  [InsightKey.GlucosePercentageAbove]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucosePercentageBelow]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucosePercentageDistribution]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucosePercentageWithin]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseQuartiles]: InsightCalculatorGroup.Quartile,
  [InsightKey.GlucoseStandardDeviation]: InsightCalculatorGroup.Glucose,
  [InsightKey.GlucoseTotalUnicorns]: InsightCalculatorGroup.Glucose,
  [InsightKey.InsulinAverageBasal]: InsightCalculatorGroup.Insulin,
  [InsightKey.InsulinAverageBolus]: InsightCalculatorGroup.Insulin,
  [InsightKey.InsulinAverage]: InsightCalculatorGroup.Insulin,
  [InsightKey.InsulinTotalBasal]: InsightCalculatorGroup.Insulin,
  [InsightKey.InsulinTotalBolus]: InsightCalculatorGroup.Insulin,
  [InsightKey.InsulinTotal]: InsightCalculatorGroup.Insulin,
  [InsightKey.TimeInRange]: InsightCalculatorGroup.TimeInRange,
  [InsightKey.MiniGraph]: InsightCalculatorGroup.None,
}

type ArrayTypeHook =
  InsightCalculatorGroup.Exercise |
  InsightCalculatorGroup.Glucose |
  InsightCalculatorGroup.Food |
  InsightCalculatorGroup.Quartile |
  InsightCalculatorGroup.Insulin

// these hooks use an array of range values
const arrayHookTypes: Array<ArrayTypeHook> = [
  InsightCalculatorGroup.Exercise,
  InsightCalculatorGroup.Glucose,
  InsightCalculatorGroup.Food,
  InsightCalculatorGroup.Quartile,
  InsightCalculatorGroup.Insulin,
]

type BooleanTypeHook = InsightCalculatorGroup.TimeInRange

// these hooks are either on or off
const booleanHooks = [InsightCalculatorGroup.TimeInRange]

type HookRanges = Record<ArrayTypeHook, number[]> & Record<BooleanTypeHook, boolean>

// function for comparing numbers
function compare(a: number, b: number) {
  return a - b
}

export interface CalculatorGroupsState {
  [InsightCalculatorGroup.Exercise]: InsightRange[];
  [InsightCalculatorGroup.Glucose]: InsightRange[];
  [InsightCalculatorGroup.Food]: InsightRange[];
  [InsightCalculatorGroup.Quartile]: InsightRange[];
  [InsightCalculatorGroup.Insulin]: InsightRange[];
  [InsightCalculatorGroup.TimeInRange]: boolean;
}

export function useCalculatorGroupsState(
  insights: Insight[],
): CalculatorGroupsState {
  return useMemo(
    () => {
      const hookRanges: HookRanges = {
        [InsightCalculatorGroup.Exercise]: [],
        [InsightCalculatorGroup.Glucose]: [],
        [InsightCalculatorGroup.Food]: [],
        [InsightCalculatorGroup.Quartile]: [],
        [InsightCalculatorGroup.Insulin]: [],
        [InsightCalculatorGroup.TimeInRange]: false,
      }

      insights.forEach(
        (insight) => {
          const hook = hooksByInsightType[insight.key]
          const arrayTypeHook = hook as ArrayTypeHook
          const booleanTypeHook = hook as BooleanTypeHook

          if (arrayHookTypes.includes(arrayTypeHook)) {
            if (insight.range !== undefined && !hookRanges[arrayTypeHook].includes(insight.range)) {
              hookRanges[arrayTypeHook].push(insight.range)
            }
          } else if (booleanHooks.includes(hook)) {
            hookRanges[booleanTypeHook] = true
          }
        },
      )

      arrayHookTypes.forEach(
        (arrayHookType) => {
          hookRanges[arrayHookType].sort(compare)
        },
      )

      return hookRanges
    },
    [insights],
  )
}
