import { useLastOutsideOfNormalReadingTime } from "./useLastOutsideOfNormalReadingTime"
import { useNextGlucoseReading } from "./useNextGlucoseReading"

export type ReturnValue = string | undefined

/*
 * this hook returns the timestamp of the most recent glucose reading that
 * is outside of the normal range, or undefined if there is no such reading
 */
export function useTimeInRangeCalculation(): ReturnValue {
  // TODO handle edge cases like when the user has always been in range
  const lastOutsideOfNormalReadingTime = useLastOutsideOfNormalReadingTime()
  const nextGlucoseReadingTime = useNextGlucoseReading(lastOutsideOfNormalReadingTime)
  return nextGlucoseReadingTime
}
