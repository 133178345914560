// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
// there should be a locale file in the locales directory for each enum value
export enum Locale {
  Czech = "cs",
  Danish = "da",
  Dutch = "nl", // AKA Flemish
  English = "en",
  Finnish = "fi",
  French = "fr",
  FrenchCanada = "fr-CA",
  German = "de",
  Italian = "it",
  NorwegianBokmal = "nb",
  Portuguese = "pt",
  Spanish = "es",
  Swedish = "sv",
}
