import {
  TrendFallIcon,
  TrendFallRapidIcon,
  TrendFallSlowIcon,
  TrendFlatIcon,
  TrendNotComputableIcon,
  TrendOutOfRangeIcon,
  TrendRiseIcon,
  TrendRiseRapidIcon,
  TrendRiseSlowIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { Trend } from "src/types"

export interface Props extends ComponentPropsWithoutRef<"svg"> {
  trend: Trend;
}

/*
 * this icon renders an icon that illustrates the corresponding trend
 */
export const TrendIcon = forwardRef<SVGElement, Props>(
  function TrendIcon(props: Props, ref: Ref<SVGElement>) {
    const {
      trend,
      ...svgProps
    } = props

    switch (trend) {
      case Trend.FortyFiveDown:
        return (
          <TrendFallSlowIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.SingleDown:
        return (
          <TrendFallIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.DoubleDown:
        return (
          <TrendFallRapidIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.FortyFiveUp:
        return (
          <TrendRiseSlowIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.SingleUp:
        return (
          <TrendRiseIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.DoubleUp:
        return (
          <TrendRiseRapidIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.Flat:
        return (
          <TrendFlatIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.NotComputable:
        return (
          <TrendNotComputableIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.OutOfRange:
        return (
          <TrendOutOfRangeIcon
            {...svgProps}
            ref={ref}
          />
        )
      case Trend.None:
      default:
        return null
    }
  },
)
