import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeading,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  ReactNode,
  ChangeEventHandler,
  MouseEventHandler,
} from "react"
import {
  useState,
  useCallback,
  useMemo,
} from "react"
import { AddInsightButton } from "./AddInsightButton"
import styles from "./AddInsightModal.module.scss"
import { usePolyglot } from "src/contexts"
import { useCreateInsights } from "src/hooks"
import { AddInsightErrorToast } from "src/toasts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

const statsAndVisualizationInsights = [
  InsightKey.GlucosePercentageAbove,
  InsightKey.GlucosePercentageBelow,
  InsightKey.GlucosePercentageWithin,
  InsightKey.GlucosePercentageDistribution,
  InsightKey.GlucoseAverageReading,
  InsightKey.GlucoseAverageReadingBedtime,
  InsightKey.GlucoseAverageReadingWakeUp,
  InsightKey.GlucoseMedianReading,
  InsightKey.GlucoseHighsAndLows,
  InsightKey.GlucoseFluxGrade,
  InsightKey.MiniGraph,
  InsightKey.TimeInRange,
  InsightKey.GlucoseTotalUnicorns,
]

const estimateInsights = [InsightKey.GlucoseManagementIndicator]

const insulinInsights = [
  InsightKey.InsulinAverage,
  InsightKey.InsulinAverageBasal,
  InsightKey.InsulinAverageBolus,
  InsightKey.InsulinTotal,
  InsightKey.InsulinTotalBasal,
  InsightKey.InsulinTotalBolus,
]

const foodInsights = [
  InsightKey.FoodAverageCalories,
  InsightKey.FoodAverageCarbs,
  InsightKey.FoodAverageFat,
  // InsightKey.FoodAverageFiber, TODO not yet supported on backend
  InsightKey.FoodAverageProtein,
  InsightKey.FoodTotalCalories,
  InsightKey.FoodTotalCarbs,
  InsightKey.FoodTotalFat,
  // InsightKey.FoodTotalFiber, TODO not yet supported on backend
  InsightKey.FoodTotalProtein,
]

const exerciseInsights = [
  InsightKey.ExerciseAverageDuration,
  InsightKey.ExerciseTotalDuration,
]

const advancedStatsInsights = [
  InsightKey.GlucoseCoefficientOfVariation,
  InsightKey.GlucoseQuartiles,
  InsightKey.GlucoseStandardDeviation,
]

const polyglotPrefix = "pages.home.insights.add_insight_modal."

interface Props {
  children: ReactNode;
  defaultInsightRangeByKey: Record<InsightKey, InsightRange | undefined>;
}

export function AddInsightModal(props: Props) {
  const polyglot = usePolyglot()
  const createInsights = useCreateInsights()
  const { defaultInsightRangeByKey } = props
  const { showToast } = useToasterContext()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState<boolean>(false)

  const [
    currentInsightKeys,
    setCurrentInsightKeys,
  ] = useState<Partial<Record<InsightKey, boolean>>>({})

  const enableAddButton = useMemo(
    () => {
      for (const key in currentInsightKeys) {
        const insightKey = key as InsightKey

        if (currentInsightKeys[insightKey]) {
          return true
        }
      }

      return false
    },
    [currentInsightKeys],
  )

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setCurrentInsightKeys({
        ...currentInsightKeys,
        [e.target.value as InsightKey]: e.target.checked,
      })
    },
    [
      currentInsightKeys,
      setCurrentInsightKeys,
    ],
  )

  const addInsight: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      const newInsights = []

      for (const key in currentInsightKeys) {
        const insightKey = key as InsightKey

        if (currentInsightKeys[insightKey]) {
          const range = defaultInsightRangeByKey[insightKey]

          if (range !== undefined) {
            newInsights.push({
              key: insightKey,
              range,
            })
          }
        }
      }

      void (async () => {
        try {
          if (!newInsights.length) {
            throw new Error("no insights to add")
          }

          await createInsights(newInsights)
          setIsModalOpen(false)
        } catch (e) {
          showToast(
            "add-insight-error-toast",
            AddInsightErrorToast,
          )
        }
      })()
    },
    [
      currentInsightKeys,
      createInsights,
      defaultInsightRangeByKey,
      showToast,
    ],
  )

  const renderSection = useCallback(
    (insightKeys: Array<InsightKey>, heading: string) => (
      <div>
        <div className={styles.sectionHeading}>
          {heading}
        </div>
        <div className={styles.section}>
          {insightKeys.map(
            (insightKey) => (
              <div
                className={styles.button}
                key={insightKey}
              >
                <AddInsightButton
                  insightKey={insightKey}
                  onChange={onChange}
                  disabled={defaultInsightRangeByKey[insightKey] === undefined}
                  checked={!!currentInsightKeys[insightKey]}
                />
              </div>
            ),
          )}
        </div>
      </div>
    ),
    [
      defaultInsightRangeByKey,
      currentInsightKeys,
      onChange,
    ],
  )

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        setCurrentInsightKeys({})
      }

      setIsModalOpen(isOpen)
    },
    [
      setIsModalOpen,
      setCurrentInsightKeys,
    ],
  )

  return (
    <Modal open={isModalOpen}
      onOpenChange={onOpenChange}
    >
      {props.children}
      <ModalContent>
        <ModalHeading className={styles.modalHeading} >
          {polyglot.t(`${polyglotPrefix}title`)}
          <button
            className={styles.addButton}
            onClick={addInsight}
            disabled={!enableAddButton}
          >
            {polyglot.t(`${polyglotPrefix}add_cta`)}
          </button>
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          {renderSection(
            statsAndVisualizationInsights,
            polyglot.t(`${polyglotPrefix}stats_and_visualization_heading`),
          )}
          {renderSection(
            estimateInsights,
            polyglot.t(`${polyglotPrefix}estimates_heading`),
          )}
          {renderSection(
            insulinInsights,
            polyglot.t(`${polyglotPrefix}insulin_heading`),
          )}
          {renderSection(
            foodInsights,
            polyglot.t(`${polyglotPrefix}food_heading`),
          )}
          {renderSection(
            exerciseInsights,
            polyglot.t(`${polyglotPrefix}exercise_heading`),
          )}
          {renderSection(
            advancedStatsInsights,
            polyglot.t(`${polyglotPrefix}advanced_stats_heading`),
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
