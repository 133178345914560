import {
  useCallback,
  useState,
} from "react"
import { useEventListener } from "usehooks-ts"
import { useVirtualListContext } from "../VirtualListContext"

/*
 * returns the mouseX relative to the left edge of the graph
 */
export function useMouseX(): number {
  const { containerBoundingClientRect } = useVirtualListContext()

  const [
    mouseX,
    setMouseX,
  ] = useState<number>(0)

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      setMouseX(e.clientX)
    },
    [setMouseX],
  )

  useEventListener(
    "mousemove",
    onMouseMove,
  )

  return mouseX - containerBoundingClientRect.x
}
