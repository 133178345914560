import { createContext } from "react"

export interface InsightsContextValue {
  isEditing: boolean;
  setIsEditing: (b: boolean) => void;
}

type ContextType = InsightsContextValue | null

export const InsightsContext = createContext<ContextType>(null)
