import dayjs from "dayjs"
import { useMemo } from "react"
import { useDisplayGlucoseValue } from "./useDisplayGlucoseValue"
import { useEventsAndSubEntriesOfType } from "src/hooks/userActivity"
import type {
  ReadOnlyGlucoseUserEvent,
  UserItemSharedProperties,
  SubEntryOfType,
} from "src/types"
import { ActivityType } from "src/types"

const glucoseRelevanceThresholdUnit = "minutes"
const glucoseRelevanceThresholdValue = 2.5

// type GlucoseUserItem = ReadOnlyGlucoseUserEvent | ReadOnlyUserEntry

interface ReturnValue {
  readableValue: string;
  isUserEntry: boolean;
}

type GlucoseSubEntryUserItem = SubEntryOfType<ActivityType.Glucose> & UserItemSharedProperties
type GlucoseUserItem = ReadOnlyGlucoseUserEvent | GlucoseSubEntryUserItem

function getEarliestRelevantTime(time: string) {
  return dayjs(time)
    .subtract(
      glucoseRelevanceThresholdValue,
      glucoseRelevanceThresholdUnit,
    )
    .toISOString()
}

function getLatestRelevantTime(time: string) {
  return dayjs(time)
    .add(
      glucoseRelevanceThresholdValue,
      glucoseRelevanceThresholdUnit,
    )
    .toISOString()
}

/*
 * this hooks takes a time string and returns the glucose value of the nearest
 * glucose reading, converted to the user's preferred glucose unit
 *
 * if there is no glucose reading withing 2.5 minutes then it returns undefined
 */
export function useReadableNearestGlucoseReading(time: string): ReturnValue | undefined {
  const startedAtSelector = useMemo(
    () => ({
      $and: [
        {
          started_at: {
            $gte: getEarliestRelevantTime(time),
          },
        },
        {
          started_at: {
            $lte: getLatestRelevantTime(time),
          },
        },
      ],
    }),
    [time],
  )

  const {
    events,
    subEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Glucose,
    startedAtSelector,
    1,
  )

  const formatGlucoseValue = useDisplayGlucoseValue()

  // find the nearest item from all of the results
  // if this gets slow, consider waiting until request fetching is over
  const nearestResult = useMemo(
    () => {
      const allResults = (events as Array<GlucoseUserItem>).concat(subEntries as Array<GlucoseUserItem>)

      return allResults.reduce(
        (
          accumulator: GlucoseUserItem | undefined,
          result: GlucoseUserItem,
        ) => {
          if (!accumulator) {
            return result
          }

          const accumulatorDiff = Math.abs(dayjs(time).diff(accumulator.started_at))
          const resultDiff = Math.abs(dayjs(time).diff(result.started_at))

          if (accumulatorDiff < resultDiff) {
            return accumulator
          }

          return result
        },
        undefined,
      )
    },
    [
      subEntries,
      events,
      time,
    ],
  )

  if (!nearestResult) {
    return undefined
  }

  return {
    isUserEntry: !(nearestResult as ReadOnlyGlucoseUserEvent).event_type,
    readableValue: formatGlucoseValue(nearestResult.glucose.mg_dl),
  }
}
