import {
  HomeIcon,
  GearOutlineIcon,
  QuestionMarkOutlineIcon,
  StatsIcon,
  MoonOutlineIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import classNames from "classnames"
import {
  forwardRef,
  useCallback,
} from "react"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"

import { AccountSummaryLink } from "./AccountSummaryLink"
import styles from "./NavigationMenu.module.scss"
import { useNavigationMenuContext } from "./useNavigationMenuContext"

import { UserInitial } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useMe,
  useAccountProperty,
} from "src/hooks"

const polyglotPrefix = "components.navigation_menu."
type Props = ComponentPropsWithoutRef<"nav">

export const NavigationMenuNav = forwardRef<HTMLElement, Props>(
  function NavigationMenuNav(props: Props, ref: Ref<HTMLElement>) {
    const { data: me } = useMe()
    const accountId = me?.account.id ?? -1

    const className = classNames(
      styles.nav,
      props.className,
    )

    const { setOpen } = useNavigationMenuContext()
    const polyglot = usePolyglot()

    const closeMenu = useCallback(
      () => {
        setOpen(false)
      },
      [setOpen],
    )

    const userName = useAccountProperty(
      "nickname",
      polyglot.t("components.user_initial.default_user_name"),
    )

    return (
      <nav
        {...props}
        className={className}
        ref={ref}
      >
        <div className={styles.userList}>
          <div className={styles.accountsHeading}>
            {polyglot.t(`${polyglotPrefix}accounts`)}
          </div>
          <AccountSummaryLink
            accountId={accountId}
            onClick={closeMenu}
            selected
          />
        </div>
        <div className={styles.navigationList}>
          <div className={styles.userHeading}>
            <UserInitial className={styles.smallUserInitial} />
            {userName}
          </div>
          <Link
            to="/home"
            className={styles.link}
            onClick={closeMenu}
          >
            <HomeIcon className={styles.linkIcon} />
            {polyglot.t(`${polyglotPrefix}home`)}
          </Link>
          <Link
            to="/settings"
            className={styles.link}
            onClick={closeMenu}
          >
            <GearOutlineIcon className={styles.linkIcon} />
            {polyglot.t(`${polyglotPrefix}settings`)}
          </Link>
          <Link
            to="/reports"
            className={styles.link}
            onClick={closeMenu}
          >
            <StatsIcon className={styles.linkIcon} />
            {polyglot.t(`${polyglotPrefix}reports`)}
          </Link>
          <Link
            to="/nightstand"
            className={styles.link}
            onClick={closeMenu}
          >
            <MoonOutlineIcon className={styles.linkIcon} />
            {polyglot.t(`${polyglotPrefix}nightstand`)}
          </Link>
          <Link
            to="/help_and_feedback"
            className={styles.link}
            onClick={closeMenu}
          >
            <QuestionMarkOutlineIcon className={styles.linkIcon} />
            {polyglot.t(`${polyglotPrefix}help_and_feedback`)}
          </Link>
        </div>
      </nav>
    )
  },
)
