import { WakeUpFillIcon } from "@tandemdiabetes/sugarmate-react-components"
import { useCalculationsContext } from "../CalculationsContext"
import styles from "./Tile.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import {
  useDisplayGlucoseValue,
  useReadableGlucoseUnit,
} from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseAverageReadingWakeUp(props: Props) {
  const { range } = props

  const displayGlucoseValue = useDisplayGlucoseValue()
  const glucoseUnit = useReadableGlucoseUnit()

  const { glucoseCalculations } = useCalculationsContext()
  const { wakeUpAverage } = glucoseCalculations[range] || {}

  const value = (isNaN(wakeUpAverage) || wakeUpAverage === undefined)
    ? "--"
    : displayGlucoseValue(wakeUpAverage)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseAverageReadingWakeUp}>
        <WakeUpFillIcon className={styles.titleIcon} />
      </TileTitle>
      <TileTimeSpan range={range} />
      <TileValue>
        {value}
      </TileValue>
      <TileUnit>
        {glucoseUnit}
      </TileUnit>
    </>
  )
}
