import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import {
  useUpdateSettings,
  useDeviceSettingsProperty,
} from "src/hooks/settings"
import type { Insight } from "src/types"

export function useCreateInsights() {
  const currentInsights = useDeviceSettingsProperty("gauges")
  const updateSettings = useUpdateSettings()

  const createInsights = useCallback(
    (insights: Array<Insight>): Promise<AxiosResponse> => {
      const data = {
        gauges: [
          ...currentInsights,
          ...insights,
        ],
      }

      return updateSettings(data)
    },
    [
      currentInsights,
      updateSettings,
    ],
  )

  return createInsights
}
