export enum Theme {
  Bubblegum = "bubblegum",
  Cavern = "cavern",
  Chocolate = "chocolate",
  Desert = "desert",
  Flex = "flex",
  Midnight = "midnight",
  MoonMoss = "moon_moss",
  Pacific = "pacific",
  SeaGreen = "sea_green",
  StrawberryLemonade = "strawberry_lemonade",
  Sugarmate = "sugarmate",
  SunriseSunset = "sunrise_sunset",
  Tandem = "tandem",
  Titanium = "titanium",
  Ugly = "ugly",
  Y2KRevival = "y2k_revival",
}
