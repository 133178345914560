import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import { usePolyglot } from "src/contexts"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"
import { round } from "src/utils"

interface Props {
  range: InsightRange;
}

export function ExerciseAverageDuration(props: Props) {
  const { range } = props

  const polyglot = usePolyglot()

  const days = range / 24

  const { exerciseCalculations } = useCalculationsContext()
  const { totalInSeconds } = exerciseCalculations[range] || {}

  const value = totalInSeconds === undefined ?
    NaN :
    round((totalInSeconds / 60) / days)

  const unit = polyglot.t(
    "unit.min_day",
    isNaN(value) ? 0 : value,
  )

  return (
    <>
      <TileTitle insightKey={InsightKey.ExerciseAverageDuration} />
      <TileTimeSpan range={range} />
      <TileValue>
        {isNaN(value) ? "--" : `${value}`}
      </TileValue>
      <TileUnit>
        {unit}
      </TileUnit>
    </>
  )
}
