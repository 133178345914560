import type { Settings } from "src/types"

interface SettingsRequest {
  settings: Partial<Settings>;
}

export function transformSettingsRequest(data: Partial<Settings>): string {
  const requestBody: SettingsRequest = {
    settings: data,
  }

  return JSON.stringify(requestBody)
}
