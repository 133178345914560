import type { FeedButton } from "src/types"
import { ActivityType } from "src/types"

const defaultFeedButtons: Array<FeedButton> = [
  ActivityType.Food,
  ActivityType.Exercise,
  ActivityType.Insulin,
  ActivityType.Medication,
  ActivityType.Glucose,
  ActivityType.DeviceChange,
  ActivityType.Liquid,
  ActivityType.Note,
  "favorite",
  "search",
]

/*
 * this function adds any missing feed items to the list
 *
 * it replaces "reading" with ActivityType.Glucose
 */
export function enforceFeedButtons(feedButtons: Array<FeedButton>): Array<FeedButton> {
  return [
    ...new Set(
      feedButtons.map(
        (feedButton) => (feedButton as string) === "reading" ? ActivityType.Glucose : feedButton,
      ).concat(defaultFeedButtons),
    ),
  ]
}
