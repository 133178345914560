// round a number to a specific number of decimal places
export function round(
  n: number,
  decimals = 0,
): number {
  const scale = Math.pow(
    10,
    decimals,
  )
  return Math.round(n * scale) / scale
}
