import { DexcomAttribution } from "./DexcomAttribution"
import { NormalRange } from "./NormalRange"
import styles from "./Overlay.module.scss"
import { UrgentLow } from "./UrgentLow"

export function Overlay() {
  return (
    <div className={styles.container}>
      <DexcomAttribution className={styles.dexcomAttribution} />
      <NormalRange className={styles.normalRange} />
      <UrgentLow className={styles.urgentLow} />
    </div>
  )
}
