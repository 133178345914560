import classNames from "classnames"
import dayjs from "dayjs"
import {
  useCallback,
  useMemo,
} from "react"

import { DayMetricComponent } from "./DayMetricComponent"
import styles from "./FeedDayHeader.module.scss"
import { useReferencesContext } from "./ReferencesContext"
import { useScrollPositionContext } from "./ScrollPositionContext"
import {
  UserEventsLoadedComponent,
  UserEntriesLoadedComponent,
} from "src/components"
import { useDisplayDate } from "src/hooks"

interface Props {
  isFirstItem?: boolean;
  day: string;
  index: number;
}

/*
 * this component renders a header for a specific day of activities
 */
export function FeedDayHeader(props: Props) {
  const {
    isFirstItem,
    day,
    index,
  } = props
  const displayDate = useDisplayDate()
  const { listItemHeaderRef } = useReferencesContext()
  const { visibleIndices } = useScrollPositionContext()
  const lowestVisibleIndex = visibleIndices?.[0] ?? 0

  const dayStart = useMemo(
    () => dayjs(day)
      .startOf("day")
      .toISOString(),
    [day],
  )

  // when this index is the lowest visible assign this header to listItemHeaderRef
  const refCallback = useCallback(
    (node: HTMLDivElement) => {
      if (index === lowestVisibleIndex) {
        listItemHeaderRef.current = node
      }
    },
    [
      index,
      lowestVisibleIndex,
    ],
  )

  const className = classNames(
    styles.container,
    // this reduces the space between the "today" header and this header if there are no items between them
    isFirstItem && index !== 0 && styles.removeTopPadding,
  )

  return (
    <div
      className={className}
      ref={refCallback}
    >
      {displayDate(dayStart)}
      <UserEventsLoadedComponent>
        <UserEntriesLoadedComponent>
          <DayMetricComponent dayStart={dayStart} />
        </UserEntriesLoadedComponent>
      </UserEventsLoadedComponent>
    </div>
  )
}
