import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import type { ReadOnlyEntryTemplate } from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & {
  entryTemplate?: ReadOnlyEntryTemplate;
}

export const FavoriteTitle = forwardRef<HTMLDivElement, Props>(
  function FavoriteTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      entryTemplate,
      ...htmlDivProps
    } = props

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {entryTemplate?.title ?? "\u200B"}
      </div>
    )
  },
)
