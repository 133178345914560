import {
  useEffect,
  useMemo,
} from "react"
import type {
  RxJsonSchema,
  RxCollection,
} from "rxdb"
import { useRxDB } from "rxdb-hooks"
import { usePageLifecycleContext } from "src/contexts/PageLifecycleContext"
import { PageLifecycle } from "src/types"
import { noticeError } from "src/utils"

export type Loader<T> = ((collection: RxCollection<T>) => Promise<void>) | undefined

export type ResetLoader = (() => void) | undefined

export function useLoadRxDBCollection<T>(
  schema: RxJsonSchema<T>,
  collectionKey: string,
  loader?: Loader<T>,
  resetLoader?: () => void,
) {
  const db = useRxDB()
  const pageLifecycle = usePageLifecycleContext()

  const shouldLoad = (
    pageLifecycle === PageLifecycle.Active ||
    pageLifecycle === PageLifecycle.Passive
  )

  // this returns falsy when there is not a loader or when the page is not visible
  // otherwise it wraps the loader in a try-catch
  const executeLoader = useMemo(
    () => loader && shouldLoad && (
      async (collection: RxCollection<T>) => {
        try {
          await loader(collection)
        } catch (e) {
          noticeError(
            e,
            "RxDBLoadCollection",
            { collectionKey },
          )
        }
      }
    ),
    [
      shouldLoad,
      loader,
      collectionKey,
    ],
  )

  useEffect(
    () => {
      if (!db) {
        resetLoader?.()
        return
      }

      // if the collection already exists
      if (
        (collectionKey in db.collections) &&
        !db.collections[collectionKey].destroyed
      ) {
        // load new items
        if (executeLoader) {
          void executeLoader(db.collections[collectionKey])
        }
        return
      }

      // if the collection does not exist, add it
      db.addCollections({
        [collectionKey]: { schema },
      }).then(
        async (collections) => {
          // load new items
          if (executeLoader) {
            await executeLoader(collections[collectionKey])
          }
        },
      )
        .catch(
          (e) => {
            noticeError(
              e,
              "RxDBCreateCollection",
              { collectionKey },
            )
          },
        )
    },
    [
      collectionKey,
      db,
      executeLoader,
      resetLoader,
      schema,
    ],
  )
}
