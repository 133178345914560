import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"
import { forwardRef } from "react"

import styles from "./Graph.module.scss"
import { Header } from "./Header"
import { ReferencesContextProvider } from "./ReferencesContext"
import { ScrollPositionContextProvider } from "./ScrollPositionContext"
import { ScrollPositionHandler } from "./ScrollPositionHandler"
import { TooltipContextProvider } from "./TooltipContext"
import { VirtualList } from "./VirtualList"
import type { Props as VirtualListProps } from "./VirtualList"
import { VirtualListContextProvider } from "./VirtualListContext"

import { XAxisContextProvider } from "./XAxisContext"
import { YAxis } from "./YAxis"
import { YAxisContextProvider } from "./YAxisContext"

interface Props extends HTMLAttributes<HTMLDivElement>, VirtualListProps {
  updateScrollController: () => void;
}

export const Graph = forwardRef<HTMLDivElement, Props>(
  function Graph(props: Props, propRef: ForwardedRef<HTMLDivElement>) {
    const {
      graphStop,
      scrollToTime,
      onScrollChange,
      updateScrollController,
      ...divProps
    } = props

    return (
      <VirtualListContextProvider>
        <ReferencesContextProvider>
          <XAxisContextProvider>
            <YAxisContextProvider>
              <ScrollPositionContextProvider>
                <TooltipContextProvider>
                  <div
                    {...divProps}
                    ref={propRef}
                    onFocus={updateScrollController}
                    onTouchStart={updateScrollController}
                    onMouseOver={updateScrollController}
                  >
                    <ScrollPositionHandler />
                    <Header />
                    <div className={styles.content}>
                      <VirtualList
                        graphStop={graphStop}
                        onScrollChange={onScrollChange}
                        scrollToTime={scrollToTime}
                      />
                      <YAxis />
                    </div>
                  </div>
                </TooltipContextProvider>
              </ScrollPositionContextProvider>
            </YAxisContextProvider>
          </XAxisContextProvider>
        </ReferencesContextProvider>
      </VirtualListContextProvider>
    )
  },
)
