import { noticeError } from "src/utils"

export async function imageURLToFile(
  url: string,
  explicitFileName?: string,
): Promise<File | void> {
  let fileName = explicitFileName

  if (!fileName) {
    const urlSegments = url.split("/")
    fileName = urlSegments[urlSegments.length - 1] ?? "filename"
  }

  try {
    const response = await fetch(url)
    const data = await response.blob()

    const file = new File(
      [data],
      fileName,
      { type: data.type },
    )

    return file
  } catch (e) {
    noticeError(
      e,
      "ImageFileURL",
      { url },
    )
  }
}
