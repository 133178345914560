import type { ReactNode } from "react"
import styles from "./Page.module.scss"

interface Props {
  children: ReactNode;
}

// the styles for a page that appears below a PageHeader
export function Page(props: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {props.children}
      </div>
    </div>
  )
}
