import { useCallback } from "react"
import { useVirtualListContext } from "../VirtualListContext"
import { useGetGlucoseValueAsPercentage } from "./useGetGlucoseValueAsPercentage"
import { useRemToPixels } from "src/hooks"

export function useGetGlucoseValueAsYPosition(): (glucoseValue: number) => number {
  const getGlucoseValueAsPercentage = useGetGlucoseValueAsPercentage()
  const remToPixels = useRemToPixels()

  const {
    dataHeightInRem,
    topPaddingInRem,
  } = useVirtualListContext()

  return useCallback(
    (glucoseValue: number) => {
      const [percentage] = getGlucoseValueAsPercentage(glucoseValue)
      const distanceFromTop = (1 - percentage / 100) * remToPixels(dataHeightInRem)
      return distanceFromTop + remToPixels(topPaddingInRem)
    },
    [
      getGlucoseValueAsPercentage,
      dataHeightInRem,
      topPaddingInRem,
      remToPixels,
    ],
  )
}
