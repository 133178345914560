import { Link } from "@tanstack/react-router"
import styles from "./UserHeader.module.scss"
import { UserHeaderData } from "./UserHeaderData"
import {
  NavigationMenuTrigger,
  UserInitialButton,
} from "src/components"
import { usePolyglot } from "src/contexts"
import { useAccountProperty } from "src/hooks"

export function UserHeader() {
  const polyglot = usePolyglot()
  const userName = useAccountProperty(
    "nickname",
    polyglot.t("pages.home.user_header.default_user_name"),
  )

  return (
    <div className={styles.container}>
      <Link
        to="/nightstand"
        className={styles.data}
      >
        <UserHeaderData userName={userName} />
      </Link>
      <NavigationMenuTrigger asChild>
        <UserInitialButton className={styles.userInitial} />
      </NavigationMenuTrigger>
    </div>
  )
}
