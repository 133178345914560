import type {
  AccountMedication,
  StatusResponse,
} from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type AccountMedicationResponse = StatusResponse & {
  account_med: AccountMedication;
}

// TODO remove this when the IDs are strings on the backend
export function transformAccountMed(accountMed: AccountMedication): AccountMedication {
  return {
    ...accountMed,
    id: `${accountMed.id}`,
    med_id: accountMed.med_id === null ? null : `${accountMed.med_id}`,
    is_deleted: !!accountMed.is_deleted,
    med: {
      ...accountMed.med,
      id: `${accountMed.med.id}`,
    },
  }
}

export function transformAccountMedicationResponse(data: string): AccountMedicationResponse {
  const aliasedData = aliasDeletedProperty(data)
  const json = JSON.parse(aliasedData) as AccountMedicationResponse
  json.account_med = transformAccountMed(json.account_med)
  return json
}
