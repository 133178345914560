import type { InsulinActivity } from "src/types"
import { insulinUnitsToPrecision } from "src/utils"

export const defaultInsulinActivity: InsulinActivity = {
  num_units: 0,
  insulin_med: {
    name: "",
    insulin_id: -1,
    account_insulin_id: -1,
    description: "",
    category: "",
    form: "",
    onset: 0,
    duration: 0,
    peak: null,
    precision: insulinUnitsToPrecision(1),
    secondary_precision: null,
    is_basal: false,
  },
}
