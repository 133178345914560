import type { ReactNode } from "react"
import { useEffect } from "react"
import { SWRConfig } from "swr"
import { LocalStorageSWRCache } from "./localStorageSWRCacheProvider"
import { useAuthContext } from "src/contexts/AuthContext"

const cache = new LocalStorageSWRCache()

interface Props {
  children: ReactNode;
}

// a provider so that the swr hooks can use the same config with local storage cache
export function SWRConfigProvider(props: Props) {
  const { accessToken } = useAuthContext()

  useEffect(
    () => {
      if (accessToken === undefined) {
        for (const key of cache.keys()) {
          cache.delete(key)
        }
      }
    },
    [accessToken],
  )

  return (
    <SWRConfig value={{ provider: () => cache }}>
      {props.children}
    </SWRConfig>
  )
}
