import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import { usePolyglot } from "src/contexts"
import type { DeviceChangeActivity } from "src/types"
import { DeviceType } from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  activity: DeviceChangeActivity;
}

export const DeviceChangeActivityTitle = forwardRef<HTMLDivElement, Props>(
  function DeviceChangeActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      ...htmlDivProps
    } = props

    const polyglot = usePolyglot()

    const deviceType = (
      activity.device_type &&
        activity.device_type !== DeviceType.None &&
        activity.device_type !== DeviceType.NotProvided &&
        polyglot.t(
          `device_type.${activity.device_type}`,
        )
    )

    if (!deviceType) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {deviceType}
      </div>
    )
  },
)
