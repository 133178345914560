import type { ReactNode } from "react"
import { PageLifecycleContext } from "./PageLifecycleContext"
import { usePageLifecycle } from "./usePageLifecycle"

interface Props {
  children: ReactNode;
}

export function PageLifecycleContextProvider(props: Props) {
  const pageLifecycle = usePageLifecycle()

  return (
    <PageLifecycleContext.Provider value={pageLifecycle}>
      {props.children}
    </PageLifecycleContext.Provider>
  )
}
