import type { RxJsonSchema } from "rxdb"
import { activitySchema } from "./activitySchema"
import {
  dateTimeSchema,
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./newSharedSchema"
import type { UserEvent } from "src/types"

// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const userEventSchema: RxJsonSchema<UserEvent> = {
  title: "user_event_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    account_id: {
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
      multipleOf: 1,
      type: "number",
    },
    started_at: { ...dateTimeSchema },
    ended_at: { ...dateTimeSchema },
    event_type: {
      type: "string",
      maxLength: 128,
    },
    note: { type: "string" },
    source: { type: "string" },
    source_id: { type: "string" },
    source_subtype: { type: "string" },
    source_child_ids: {
      type: "array",
      items: {
        type: "string",
      },
    },
    updated_by_webhook: { type: "boolean" },
    pg_id: { type: "string" },
    ...activitySchema,
    ...sharedSchemaProperties,
  },
  required: [
    ...sharedSchemaRequired,
    "account_id",
    "ended_at",
    "started_at",
    "event_type",
  ],
  indexes: [
    ...sharedSchemaIndexes,
    [
      "account_id",
      "deleted_at",
    ],
    [
      "account_id",
      "deleted_at",
      "started_at",
    ],
    [
      "account_id",
      "deleted_at",
      "event_type",
      "started_at",
    ],
    // index glucose events glucose value ( sorted by started_at )
    [
      "account_id",
      "deleted_at",
      "event_type",
      "glucose.mg_dl",
      "started_at",
    ],
    // index insulin event on insulin_med.is_basal ( sorted by started_at )
    /*
    [
      "account_id",
      "deleted_at",
      "event_type",
      "insulin.insulin_med.is_basal", can't index on non-required boolean field
      "started_at",
    ],
    */
    // index for event_type and started_at ( sorted by glucose value )
    [
      "account_id",
      "deleted_at",
      "event_type",
      "started_at",
      "glucose.mg_dl",
    ],
  ],
} as RxJsonSchema<UserEvent>
