import type { AxiosResponse } from "axios"
import { debounce } from "lodash-es"
import {
  useCallback,
  useRef,
} from "react"

type Updater<T> = (updates: Partial<T>) => Promise<AxiosResponse>
type DebouncedUpdater<T> = (updates: Partial<T>) => void

const defaultDebounceTime = 250
const defaultMaxWait = 5000

// TODO move this
// takes an updater function and returns a debounced version of it
export function useDebouncedUpdater<T>(
  updater: Updater<T>,
  debounceTime: number = defaultDebounceTime,
  maxWait: number = defaultMaxWait,
): DebouncedUpdater<T> {
  const unsavedChangesRef = useRef<Partial<T>>({})

  const debouncedUpdater = useCallback(
    debounce(
      async () => {
        const unsavedChanges = unsavedChangesRef.current

        try {
          // clear the unsaved changes
          unsavedChangesRef.current = {}

          // make request
          await updater(unsavedChanges)
        } catch (e) {
          // re-store the unsaved changes if the request fails
          unsavedChangesRef.current = {
            ...unsavedChanges,
            ...unsavedChangesRef.current,
          }
        }
      },
      debounceTime,
      { maxWait },
    ),
    [
      updater,
      debounceTime,
      maxWait,
    ],
  )

  // this function stashes the changes and calls the debounced udpater
  return useCallback(
    (updates: Partial<T>) => {
      // store the unsaved changes
      unsavedChangesRef.current = {
        ...unsavedChangesRef.current,
        ...updates,
      }

      void debouncedUpdater()
    },
    [debouncedUpdater],
  )
}
