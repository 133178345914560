import {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react"
import { useIsMounted } from "usehooks-ts"
import { useUserPost } from "src/hooks/userApi"
import type {
  FoodImageResponse,
  FoodSearchFood,
} from "src/types"
import { noticeError } from "src/utils"

let cachedResponses: Record<string, FoodImageResponse> = {}

interface ReturnValue {
  response: FoodImageResponse | null;
  clearCache: () => void;
}

export function useFoodImage(food: FoodSearchFood | null): ReturnValue {
  const userPost = useUserPost()
  const isMounted = useIsMounted()

  const [
    imageResponse,
    setImageResponse,
  ] = useState<FoodImageResponse | null>(null)

  const clearCache = useCallback(
    () => {
      cachedResponses = {}
    },
    [],
  )

  const requestImage = useCallback(
    async (query: string) => {
      // use the cached response if there is one
      if (cachedResponses[query]) {
        setImageResponse(cachedResponses[query])
      } else {
        // hit the endpoint
        try {
          if (!userPost) {
            throw new Error("cannot get food image for unauthorized user")
          }

          const response = await userPost<FoodImageResponse>({
            data: {
              query,
            },
            url: "/api/v3/nutritional/foods/image_query",
          })

          if (isMounted()) {
            // update state w/ the  response
            setImageResponse(response.data)

            // cache the response
            cachedResponses[query] = response.data
          }
        } catch (e) {
          noticeError(
            e,
            "SearchFoodImage",
            { query },
          )
        }
      }
    },
    [
      userPost,
      isMounted,
      setImageResponse,
    ],
  )

  // invoke the get function every time the foodName changes
  useEffect(
    () => {
      if (food !== null) {
        const query = `${food.brand} ${food.name}`.trim()

        if (query) {
          void requestImage(query)
          return
        }
      }

      setImageResponse(null)
    },
    [
      setImageResponse,
      requestImage,
      food,
    ],
  )

  return useMemo(
    () => ({
      response: imageResponse,
      clearCache,
    }),
    [
      imageResponse,
      clearCache,
    ],
  )
}
