import type { ComponentPropsWithoutRef } from "react"
import { useTotalCaloriesDayMetric } from "../hooks"
import { usePolyglot } from "src/contexts"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function TotalCaloriesDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const polyglot = usePolyglot()

  const value = useTotalCaloriesDayMetric(dayStart)

  if (Number.isNaN(value)) {
    return null
  }

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.total_calories",
        {
          calories: round(
            value,
            1,
          ).toLocaleString(navigator.language),
        },
      )}
    </button>
  )
}
