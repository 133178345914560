import { round } from "./round"

/*
 * rounds a number to the nearest value that mathes the step size
 *
 * ex: if the step size is 10, it will round to the nearest 10s place
 */
export function roundForStep(n: number, step: number): number {
  const exp = Math.log10(step)
  return round(
    n,
    -Math.floor(exp),
  )
}
