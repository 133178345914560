import { useCalculationsContext } from "../CalculationsContext"
import styles from "./Quartiles.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { useDisplayGlucoseValue } from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseQuartiles(props: Props) {
  const { range } = props

  const displayGlucoseValue = useDisplayGlucoseValue()
  const { quartileCalculations } = useCalculationsContext()

  const {
    quartile1,
    quartile2,
    quartile3,
  } = quartileCalculations[range] || {}

  const value1: string | undefined = (quartile1 === undefined || isNaN(quartile1))
    ? "--"
    : displayGlucoseValue(quartile1)

  const value2: string | undefined = (quartile2 === undefined || isNaN(quartile2))
    ? "--"
    : displayGlucoseValue(quartile2)

  const value3: string | undefined = (quartile3 === undefined || isNaN(quartile3))
    ? "--"
    : displayGlucoseValue(quartile3)

  const size = 100
  const start = `0 ${size}`
  const end = `${size} ${size}`
  const viewBox = `0 0 ${size} ${size}`

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseQuartiles} />
      <TileTimeSpan range={range} />
      <div className={styles.quartiles}>
        <svg
          className={styles.quartilesCurve}
          preserveAspectRatio="none"
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={`M${start} C ${0.25 * size} ${size}, ${0.25 * size} 0, ${0.5 * size} 0, ${0.75 * size} 0, ${0.75 * size} ${size}, ${end}`} />
        </svg>
        <svg
          className={styles.quartilesSeparators}
          preserveAspectRatio="none"
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={`M${0.33 * size} 0 L${0.33 * size} ${size}`}
            vectorEffect="non-scaling-stroke"
          />
          <path
            d={`M${0.5 * size} 0 L${0.5 * size} ${size}`}
            vectorEffect="non-scaling-stroke"
          />
          <path
            d={`M${0.67 * size} 0 L${0.67 * size} ${size}`}
            vectorEffect="non-scaling-stroke"
          />
        </svg>
        <div className={styles.quartilesValues}>
          <div className={styles.quartile1}>
            <div>
              {value1}
            </div>
          </div>
          <div className={styles.quartile2}>
            <div>
              {value2}
            </div>
          </div>
          <div className={styles.quartile3}>
            <div>
              {value3}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
