import { useCallback } from "react"
import { useRxCollection } from "rxdb-hooks"
import { noticeError } from "src/utils"

type UpsertCollectionItem<T> = (item: T) => Promise<void>

export function useUpsertCollectionItem<T>(
  collectionKey: string,
): UpsertCollectionItem<T> {
  const collection = useRxCollection<T>(collectionKey)

  return useCallback(
    async (item: T) => {
      if (collection) {
        try {
          await collection.upsert(item)
        } catch (e) {
          noticeError(
            e,
            "RxDBUpsertCollectionItem",
            {
              item,
              collectionKey,
            },
          )
        }
      }
    },
    [collection],
  )
}
