import {
  useEffect,
  useState,
} from "react"
import { useDaySelector } from "../useDaySelector"
import { useEventsAndSubEntriesOfType } from "src/hooks"
import { ActivityType } from "src/types"

export function useTotalCaloriesDayMetric(dayStart: string) {
  const daySelector = useDaySelector(dayStart)

  const [
    totalCalories,
    setTotalCalories,
  ] = useState<number>(NaN)

  const {
    isFetchingEvents,
    isFetchingEntries,
    events: foodEvents,
    subEntries: foodSubEntries,
  } = useEventsAndSubEntriesOfType(
    ActivityType.Food,
    daySelector,
  )

  useEffect(
    () => {
      if (isFetchingEntries || isFetchingEvents) {
        return
      }

      let calories = 0

      foodEvents.forEach(
        (foodEvent) => {
          if (foodEvent.food.calories) {
            calories += foodEvent.food.calories
          }
        },
      )

      foodSubEntries.forEach(
        (subEntry) => {
          if (subEntry.food.calories) {
            calories += subEntry.food.calories
          }
        },
      )

      setTotalCalories(calories)
    },
    [
      isFetchingEntries,
      isFetchingEvents,
      setTotalCalories,
      foodEvents,
      foodSubEntries,
    ],
  )

  return totalCalories
}
