import { useCalculationsContext } from "../CalculationsContext"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import { TileUnit } from "./TileUnit"
import { TileValue } from "./TileValue"
import {
  useDisplayGlucoseValue,
  useReadableGlucoseUnit,
} from "src/hooks"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

export function GlucoseAverageReading(props: Props) {
  const { range } = props

  const displayGlucoseValue = useDisplayGlucoseValue()
  const glucoseUnit = useReadableGlucoseUnit()

  const { glucoseCalculations } = useCalculationsContext()
  const { average } = glucoseCalculations[range] || {}

  const value = (isNaN(average) || average === undefined)
    ? "--"
    : displayGlucoseValue(average)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseAverageReading}/>
      <TileTimeSpan range={range} />
      <TileValue>
        {value}
      </TileValue>
      <TileUnit>
        {glucoseUnit}
      </TileUnit>
    </>
  )
}
