import type {
  AccountInsulin,
  StatusResponse,
} from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type AccountInsulinResponse = StatusResponse & {
  account_insulin: AccountInsulin;
}

// TODO remove this when the IDs are strings on the backend
export function transformAccountInsulin(accountInsulin: AccountInsulin): AccountInsulin {
  return {
    ...accountInsulin,
    id: `${accountInsulin.id}`,
    insulin_id: accountInsulin.insulin_id === null ? null : `${accountInsulin.insulin_id}`,
    is_deleted: !!accountInsulin.is_deleted,
    insulin: {
      ...accountInsulin.insulin,
      id: `${accountInsulin.insulin.id}`,
    },
  }
}

export function transformAccountInsulinResponse(data: string): AccountInsulinResponse {
  const aliasedData = aliasDeletedProperty(data)
  const json = JSON.parse(aliasedData) as AccountInsulinResponse
  json.account_insulin = transformAccountInsulin(json.account_insulin)
  return json
}
