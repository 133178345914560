import dayjs from "dayjs"
import type { UserItemQuerySelector } from "src/types"

export function createStartedAtRangeSelector(
  queryEndTime: string, // the time up to which the query goes
  queryLengthInHours = 0,
): UserItemQuerySelector {
  const queryStart = dayjs(queryEndTime)
    .subtract(
      queryLengthInHours,
      "hour",
    )
    .toISOString()

  return {
    $and: [
      { started_at: { $lt: queryEndTime } },
      { started_at: { $gte: queryStart } },
    ],
  }
}
