import dayjs from "dayjs"
import type { ReactNode } from "react"
import {
  useMemo,
  useCallback,
} from "react"

import { EntryModalPrivateContext } from "./EntryModalPrivateContext"
import { newSubEntryTemplate } from "./newSubEntryTemplate"
import {
  useSaveUserEntry,
  useDeleteUserEntry,
} from "src/hooks"
import type {
  ReadOnlyUserEntry,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"
import { noticeError } from "src/utils"

interface Props {
  children?: ReactNode;
  currentSubEntry: EditableSubEntry | undefined;
  currentUserEntry: EditableUserEntry;
  editSubEntry: (e: EditableSubEntry | undefined) => void;
  editUserEntry: (e: EditableUserEntry) => void;
  originalSubEntry: EditableSubEntry | undefined;
  originalUserEntry: EditableUserEntry;
  resetSubEntry: (e: EditableSubEntry | undefined) => void;
  resetUserEntry: (e: EditableUserEntry) => void;
  subEntryHasChanged: boolean;
  userEntryHasChanged: boolean;
}

export function EntryModalPrivateContextProvider(props: Props) {
  const {
    currentSubEntry,
    currentUserEntry,
    editUserEntry,
    originalUserEntry,
    originalSubEntry,
    resetSubEntry,
    resetUserEntry,
    subEntryHasChanged,
  } = props

  // api methods for making calls to entry endpoints
  const deleteUserEntry = useDeleteUserEntry()
  const saveUserEntry = useSaveUserEntry()

  // merges the currentSubEntry into currentUserEntry
  const mergeCurrentSubEntry = useCallback(
    (): EditableUserEntry => {
      if (currentSubEntry) {
        const newSubEntry: EditableSubEntry = {
          ...currentSubEntry,
          note: currentSubEntry.note || null, // replace empty string with null
        }

        // store newSubEntry in currentUserEntry
        if (newSubEntry._id) {
          const subentries = currentUserEntry.subentries.map(
            (sub) => {
              if (newSubEntry._id === sub._id) {
                return newSubEntry
              }

              return sub
            },
          )

          return {
            ...currentUserEntry,
            subentries,
          }
        }
        // add new subEntry to currentUserEntry
        const subentries = [
          ...currentUserEntry.subentries,
          newSubEntry,
        ]

        return {
          ...currentUserEntry,
          subentries,
        }

      }
      return currentUserEntry

    },
    [
      editUserEntry,
      currentSubEntry,
      currentUserEntry,
    ],
  )

  // a function that initiates adding another subentry and handles the currentSubEntry
  const addSubEntry = useCallback(
    () => {
      const updatedUserEntry = mergeCurrentSubEntry()

      editUserEntry(updatedUserEntry)
      resetSubEntry(newSubEntryTemplate)
    },
    [
      mergeCurrentSubEntry,
      resetSubEntry,
      editUserEntry,
    ],
  )

  // a function the deletes this entry
  const destroyEntry = useCallback(
    async () => {
      const id = currentUserEntry.id

      if (id) {
        await deleteUserEntry(id)
      }
    },
    [
      currentUserEntry.id,
      deleteUserEntry,
    ],
  )

  // remove a sub entry from the entry
  const removeCurrentSubEntry = useCallback(
    () => {
      if (!currentSubEntry) {
        return
      }

      const subentries = currentUserEntry.subentries.filter(
        (sub) => currentSubEntry !== sub,
      )

      editUserEntry({
        ...currentUserEntry,
        subentries,
      })

      // TODO should these changes be immediately saved ?
      resetSubEntry(undefined)
    },
    [
      currentUserEntry,
      editUserEntry,
      currentSubEntry,
      resetSubEntry,
    ],
  )

  // a function that saves or creates the current entry and subentry
  const saveEntry = useCallback(
    async (): Promise<ReadOnlyUserEntry> => {
      const updatedUserEntry = mergeCurrentSubEntry()
      const response = await saveUserEntry(updatedUserEntry)
      resetUserEntry(response.data.entry)
      return response.data.entry
    },
    [
      mergeCurrentSubEntry,
      saveUserEntry,
    ],
  )

  // a function that duplicates the most recent saved version of the current sub entry
  const duplicateEntry = useCallback(
    async (): Promise<ReadOnlyUserEntry> => {
      const updatedUserEntry = {
        ...originalUserEntry,
        started_at: dayjs().toISOString(),
        ended_at: undefined,
        id: undefined,
      }

      try {
        const response = await saveUserEntry(updatedUserEntry)
        return response.data.entry
      } catch (e) {
        noticeError(
          e,
          "DuplicateEntry",
          {
            entryId: originalUserEntry.id,
            entry: updatedUserEntry,
          },
        )

        throw (e)
      }
    },
    [
      originalUserEntry,
      saveUserEntry,
    ],
  )

  // a function that creates a new entry from the last saved version of the current subentry
  const duplicateCurrentSubEntry = useCallback(
    async (): Promise<ReadOnlyUserEntry> => {
      const updatedUserEntry = {
        ...originalUserEntry,
        started_at: dayjs().toISOString(),
        ended_at: undefined,
        id: undefined,
      }

      updatedUserEntry.subentries = updatedUserEntry.subentries.filter(
        (subentry) => subentry._id === currentSubEntry?._id,
      )

      try {
        const response = await saveUserEntry(updatedUserEntry)
        return response.data.entry
      } catch (e) {
        noticeError(
          e,
          "DuplicateSubEntry",
        )

        throw (e)
      }
    },
    [
      currentSubEntry,
      originalUserEntry,
      resetSubEntry,
      saveUserEntry,
    ],
  )

  // open editor for this entry
  const showEntry = useCallback(
    () => {
      if (subEntryHasChanged || (currentSubEntry && !currentSubEntry._id)) {
        const updatedUserEntry = mergeCurrentSubEntry()
        editUserEntry(updatedUserEntry)
      }

      resetSubEntry(undefined)
    },
    [
      currentSubEntry,
      subEntryHasChanged,
      mergeCurrentSubEntry,
      resetSubEntry,
      editUserEntry,
    ],
  )

  // a function that opens the given subentry for editing and handles the currentUserEntry
  const showSubEntry = useCallback(
    (subEntry: EditableSubEntry) => {
      resetSubEntry(subEntry)

      // temporarily removes a new subEntry from the currentUserEntries subentries array
      // because it will be added back when it is saved or when a new item is added
      if (!subEntry._id) {
        const subentries = currentUserEntry.subentries.filter((sub) => sub !== subEntry)

        if (subentries.length !== currentUserEntry.subentries.length) {
          editUserEntry({
            ...currentUserEntry,
            subentries,
          })
        }
      }
    },
    [
      resetSubEntry,
      editUserEntry,
      currentUserEntry,
    ],
  )

  // provides functions that can be used by the modal chidlren
  const privateContextValue = useMemo(
    () => ({
      addSubEntry,
      destroyEntry,
      duplicateEntry,
      duplicateCurrentSubEntry,
      removeCurrentSubEntry,
      saveEntry,
      showEntry,
      showSubEntry,
      originalUserEntry,
      originalSubEntry,
      formId: "entryFormId",
    }),
    [
      addSubEntry,
      destroyEntry,
      duplicateEntry,
      duplicateCurrentSubEntry,
      removeCurrentSubEntry,
      saveEntry,
      showEntry,
      showSubEntry,
      originalUserEntry,
      originalSubEntry,
    ],
  )

  return (
    <EntryModalPrivateContext.Provider value={privateContextValue}>
      {props.children}
    </EntryModalPrivateContext.Provider>
  )
}
