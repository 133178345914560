import type { ComponentPropsWithoutRef } from "react"
import { useMemo } from "react"
import { usePolyglot } from "src/contexts"
import {
  useReadableNearestGlucoseReading,
  useReadableGlucoseUnit,
  useDeviceSettingsProperty,
} from "src/hooks"
import { sleepHoursToTime } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"button"> {
  dayStart: string;
}

export function BedTimeGlucoseDayMetricComponent(props: Props) {
  const {
    dayStart,
    children,
    ...buttonProps
  } = props

  const sleepBottom = useDeviceSettingsProperty("sleep_bottom")

  const time = useMemo(
    () => sleepHoursToTime(
      sleepBottom,
      dayStart,
    ),
    [
      sleepBottom,
      dayStart,
    ],
  )

  const reading = useReadableNearestGlucoseReading(time)
  const glucoseUnit = useReadableGlucoseUnit()

  if (reading === undefined) {
    return null
  }

  const { readableValue } = reading
  const polyglot = usePolyglot()

  return (
    <button {...buttonProps}>
      {children}
      {polyglot.t(
        "pages.home.activity_list.day_stats.glucose_bedtime",
        {
          glucoseUnit,
          glucoseValue: readableValue,
        },
      )}
    </button>
  )
}
