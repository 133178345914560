import type { ReactNode } from "react"
import { useMemo } from "react"
import { RecentReadingContext } from "./RecentReadingContext"
import { useMostRecentGlucoseReading } from "src/hooks/glucoseReadings"
import { useAccountProperty } from "src/hooks/me"

interface Props {
  children: ReactNode;
}

export function RecentReadingContextProvider(props: Props) {
  const accountId = useAccountProperty(
    "id",
    undefined,
  )

  const queryResult = useMostRecentGlucoseReading()

  // TODO in the future this should track values for all followed users
  const value = useMemo(
    () => accountId ? ({
      [`${accountId}`]: queryResult,
    }) : ({}),
    [
      accountId,
      queryResult,
    ],
  )

  return (
    <RecentReadingContext.Provider value={value}>
      {props.children}
    </RecentReadingContext.Provider>
  )
}
