import { unix } from "dayjs"
import Cookies from "js-cookie"
import {
  useCallback,
  useState,
  useEffect,
} from "react"
import type { UserAuthCookie } from "src/types"
import {
  setHasCookieLocalStorage,
  noticeError,
} from "src/utils"

const userAuthCookieKey = "lS_user_auth"

function getAuthCookie(): UserAuthCookie | undefined {
  const userAuthString = Cookies.get(userAuthCookieKey)

  if (!userAuthString) {
    return undefined
  }

  try {
    return JSON.parse(userAuthString) as unknown as UserAuthCookie
  } catch (e) {
    noticeError(
      e,
      "AuthCookieParse",
    )

    Cookies.remove(userAuthCookieKey)
  }

  return undefined
}

export type SetAuthCookie = (data?: UserAuthCookie) => void
type ReturnValue = [UserAuthCookie | undefined, SetAuthCookie]

export function useAuthCookieState(): ReturnValue {
  const [
    authCookieState,
    setAuthCookieState,
  ] = useState<UserAuthCookie | undefined>(
    getAuthCookie(),
  )

  // this side effect relies on our swr cache changing when the user is logged
  // in and logged out
  useEffect(
    () => {
      const onStorageEvent = (ev: StorageEvent) => {
        if (ev.storageArea === localStorage) {
          setAuthCookieState(getAuthCookie())
        }
      }

      addEventListener(
        "storage",
        onStorageEvent,
      )

      return () => {
        removeEventListener(
          "storage",
          onStorageEvent,
        )
      }
    },
    [setAuthCookieState],
  )

  const setAuthCookie = useCallback(
    (cookieData: UserAuthCookie | undefined) => {
      if (cookieData === undefined) {
        Cookies.remove(userAuthCookieKey)
        setAuthCookieState(cookieData)
        setHasCookieLocalStorage("false")
      } else {
        const expires = !cookieData.expires_in || !cookieData.created_at
          ? undefined
          : unix(cookieData.created_at)
            .add(
              cookieData.expires_in,
              "second",
            )
            .toDate()

        Cookies.set(
          userAuthCookieKey,
          JSON.stringify(cookieData),
          { expires },
        )
        setAuthCookieState({ ...cookieData })
        setHasCookieLocalStorage("true")
      }
    },
    [setAuthCookieState],
  )

  return [
    authCookieState,
    setAuthCookie,
  ]
}
