import { InsightsEditButton } from "./InsightsEditButton"
import styles from "./InsightsHeader.module.scss"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.home.insights."

export function InsightsHeader() {
  const polyglot = usePolyglot()

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {polyglot.t(`${polyglotPrefix}header_title`)}
      </h2>
      <InsightsEditButton />
    </div>
  )
}
