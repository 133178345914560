import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import { ExerciseActivityMessage } from "./ExerciseActivityMessage"
import type {
  ReadOnlySubEntry,
  ReadOnlyUserEvent,
  ActivityPair,
} from "src/types"
import { ActivityType } from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & ActivityPair & {
  item: ReadOnlySubEntry | ReadOnlyUserEvent;
}

export const ActivityMessage = forwardRef<HTMLDivElement, Props>(
  function ActivityMessage(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      activityType,
      item,
      ...htmlDivProps
    } = props

    if (activityType === ActivityType.Exercise) {
      return (
        <ExerciseActivityMessage
          {...htmlDivProps}
          item={item}
          activity={activity}
        />
      )
    }

    if (activityType === ActivityType.Note) {
      return null
    }

    if (!item.note) {
      return null
    }

    return (
      <div
        {...htmlDivProps}
        ref={ref}
      >
        {item.note}
      </div>
    )
  },
)
