import dayjs from "dayjs"
import { useMemo } from "react"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"
import { ActivityType } from "src/types"

function userItemEndedAtGlucoseTime(
  userItem: ReadOnlyUserEvent | ReadOnlyUserEntry,
  activityType: ActivityType,
): string | undefined {
  switch (activityType) {
    case ActivityType.Food:
      return dayjs(userItem.started_at)
        .add(
          2,
          "hour",
        )
        .toISOString()
    case ActivityType.Exercise:
      return userItem.ended_at
    default:
      return undefined
  }
}

export function useEndedAtGlucoseTime(userItem: ReadOnlyUserEvent | ReadOnlyUserEntry): string | undefined {
  return useMemo(
    () => {
      const userEvent = userItem as ReadOnlyUserEvent
      const userEntry = userItem as ReadOnlyUserEntry

      // if it's a user event, return the appropriate time
      if (userEvent.event_type) {
        return userItemEndedAtGlucoseTime(
          userEvent,
          userEvent.event_type,
        )
      }

      // if it's a user entry event, get the time for each subentry
      // and return the latest time
      return userEntry.subentries.map(
        (subEntry) => userItemEndedAtGlucoseTime(
          userEntry,
          subEntry.subentry_type,
        ),
      ).reduce(
        (
          accumulator: undefined | string,
          time: undefined | string,
        ) => {
          if (!accumulator) {
            return time
          }

          if (!time) {
            return accumulator
          }

          if (dayjs(time).isAfter(accumulator)) {
            return time
          }

          return accumulator
        },
        undefined,
      )

    },
    [userItem],
  )
}
