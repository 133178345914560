import {
  useState,
  useEffect,
} from "react"

const onboardingStorageKey = "sm-onboarding-dexcom-source"

function setIsOnboardingDexcomSource(isOnboardingDexcomSource: boolean) {
  if (isOnboardingDexcomSource) {
    localStorage.setItem(
      onboardingStorageKey,
      `${isOnboardingDexcomSource}`,
    )
  } else {
    localStorage.removeItem(onboardingStorageKey)
  }
}

function getIsOnboardingDexcomSource(): boolean {
  const value = localStorage.getItem(onboardingStorageKey)
  return value === "true"
}

// this is only used for redirecting the user back to the onboarding flow
// after adding a dexcom data source during onboarding
// otherwise the user will end up on the settings/data_source page
export function useIsOnboardingDexcomSource() {
  const state = useState<boolean>(getIsOnboardingDexcomSource())

  useEffect(
    () => {
      setIsOnboardingDexcomSource(state[0])
    },
    [state[0]],
  )

  return state
}
