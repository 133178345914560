import {
  WakeUpFillIcon,
  BedtimeFillIcon,
  UnicornIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { InsightKey } from "src/types"

export function useInsightIcon(insightKey: InsightKey) {
  switch (insightKey) {
    case InsightKey.GlucoseAverageReadingBedtime:
      return BedtimeFillIcon
    case InsightKey.GlucoseAverageReadingWakeUp:
      return WakeUpFillIcon
    case InsightKey.GlucoseTotalUnicorns:
      return UnicornIcon
    default:
      return null
  }
}
