import dayjs from "dayjs"
import { useMemo } from "react"
import { useScrollPositionContext } from "../ScrollPositionContext"
import { useVirtualListContext } from "../VirtualListContext"
import { useXAxisContext } from "../XAxisContext"

/*
 * returns the time of the specified "xPercentage" where the xPercentage is relative
 * to the left edge of the graph
 */
export function useXPercentageAsTime(xPercentage: number): string {
  const { scrollPosition } = useScrollPositionContext()
  const { containerBoundingClientRect } = useVirtualListContext()
  const containerWidth = containerBoundingClientRect.width

  const {
    cellWidth,
    graphStart,
    minutesPerCell,
  } = useXAxisContext()

  return useMemo(
    () => {
      if (!containerWidth) {
        return ""
      }
      if (isNaN(xPercentage)) {
        return ""
      }

      const pixelsPerMinute = cellWidth / minutesPerCell
      const value = containerWidth * (1 - (xPercentage / 100))
      const distanceFromRight = scrollPosition + value
      const time = dayjs(graphStart)
        .subtract(
          distanceFromRight / pixelsPerMinute,
          "minutes",
        )
        .toISOString()

      return time
    },
    [
      xPercentage,
      cellWidth,
      minutesPerCell,
      scrollPosition,
      graphStart,
      containerWidth,
    ],
  )
}
