import { useContext } from "react"
import { DayMetricContext } from "./DayMetricContext"

export function useDayMetricContext() {
  const context = useContext(DayMetricContext)

  if (!context) {
    throw new Error("Components using DayMetricContext must be children of <DayMetricContextProvider>")
  }

  return context
}
