import dayjs from "dayjs"
import { useMemo } from "react"
import type { RxQueryResult } from "rxdb-hooks"
import { useFirstGlucoseReading } from "./useFirstGlucoseReading"
import { useThisMinute } from "src/hooks/time"
import type { GlucoseUserEvent } from "src/types"

// this function returns the nearest glucose reading that is considered
// "recent", which means within the last 10 minutes
export function useMostRecentGlucoseReading(): RxQueryResult<GlucoseUserEvent> {
  const thisMinute = useThisMinute()

  const tenMinutesAgo = useMemo(
    () => dayjs(thisMinute)
      .subtract(
        10,
        "minute",
      )
      .toISOString(),
    [thisMinute],
  )

  const firstGlucoseReading = useFirstGlucoseReading()

  return useMemo(
    () => {
      const result = firstGlucoseReading.result[0]

      const reading = (
        result && dayjs(tenMinutesAgo).isBefore(result.started_at)
      )
        ? result
        : undefined

      return {
        ...firstGlucoseReading,
        result: reading ? [reading] : [],
      }
    },
    [
      firstGlucoseReading,
      tenMinutesAgo,
    ],
  )
}
