import type { Insulin } from "src/types"
import { aliasDeletedProperty } from "src/utils"

export type InsulinsResponse = {
  insulins: Array<Insulin>;
}

export function transformInsulinsResponse(dataString: string): InsulinsResponse {
  const aliasedDataString = aliasDeletedProperty(dataString)
  const json = JSON.parse(aliasedDataString) as InsulinsResponse

  // TODO remove this once the backend provides IDs as strings instead of numbers
  json.insulins = json.insulins.map((insulin) => ({
    ...insulin,
    id: `${insulin.id}`,
    is_deleted: !!insulin.is_deleted,
  }))

  return json
}
