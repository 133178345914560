import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import { useFindAccountInsulins } from "./useFindAccountInsulins"
import { useAccountProperty } from "src/hooks/me"
import type { AccountInsulin } from "src/types"
import { createAccountInsulinQueryObject } from "src/utils"

export function useNewestAccountInsulin(): DeepReadonlyObject<AccountInsulin> | undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const queryObject = useMemo(
    () => createAccountInsulinQueryObject(
      accountId,
      {
        limit: 1,
        sort: [{ created_at: "desc" as const }],
      },
    ),
    [accountId],
  )

  const { result } = useFindAccountInsulins(queryObject)
  return result[0]
}
