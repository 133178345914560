import type { ReactNode } from "react"
import {
  useMemo,
  useState,
  useCallback,
} from "react"
import { SessionStorageContext } from "./SessionStorageContext"
import type { RoutePath } from "src/types"

interface Props {
  children: ReactNode;
}

const loginRedirectKey = "login_redirect"
const defaultLoginRedirect: RoutePath = "/home"
const logoutRedirectKey = "logout_redirect"
const defaultLogoutRedirect: RoutePath = "/"

export function SessionStorageContextProvider(props: Props) {
  const [
    loginRedirect,
    setLoginRedirectState,
  ] = useState<RoutePath>(
    (window.sessionStorage.getItem(loginRedirectKey) as RoutePath | null) ?? defaultLoginRedirect,
  )

  const setLoginRedirect = useCallback(
    (path: RoutePath = defaultLoginRedirect) => {
      setLoginRedirectState(path)

      window.sessionStorage.setItem(
        loginRedirectKey,
        path,
      )
    },
    [setLoginRedirectState],
  )

  const [
    logoutRedirect,
    setLogoutRedirectState,
  ] = useState<RoutePath>(
    (window.sessionStorage.getItem(logoutRedirectKey) as RoutePath | null) ?? defaultLogoutRedirect,
  )

  const setLogoutRedirect = useCallback(
    (path: RoutePath = defaultLogoutRedirect) => {
      setLogoutRedirectState(path)

      window.sessionStorage.setItem(
        logoutRedirectKey,
        path,
      )
    },
    [setLogoutRedirectState],
  )

  const value = useMemo(
    () => ({
      loginRedirect,
      setLoginRedirect,
      logoutRedirect,
      setLogoutRedirect,
    }),
    [
      loginRedirect,
      setLoginRedirect,
      logoutRedirect,
      setLogoutRedirect,
    ],
  )

  return (
    <SessionStorageContext.Provider value={value}>
      {props.children}
    </SessionStorageContext.Provider>
  )
}
