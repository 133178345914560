import type { MangoQuery } from "rxdb"
import type { AccountMedication } from "src/types"

const defaultSelector = {}

// adds "is_deleted" and "account_id" selector to account medication query
export function createAccountMedicationQueryObject(
  accountId: number,
  {
    selector,
    sort,
    limit,
  }: MangoQuery<AccountMedication>,
): MangoQuery<AccountMedication> {
  return {
    selector: {
      account_id: { $eq: accountId },
      ...(selector ?? defaultSelector),
      $or: (selector?.$or ?? []).concat([
        { is_deleted: { $eq: false } },
        { is_deleted: { $exists: false } },
      ]),
    },
    sort,
    limit,
  }
}
