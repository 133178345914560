import { useCallback } from "react"
import { useXAxisContext } from "../XAxisContext"

export function useGetPixelDifferenceInMinutes(): (n: number) => number {
  const {
    minutesPerCell,
    cellWidth,
  } = useXAxisContext()

  return useCallback(
    (pixels: number) => {
      const minutesPerPixel = minutesPerCell / cellWidth
      return pixels * minutesPerPixel
    },
    [
      minutesPerCell,
      cellWidth,
    ],
  )
}
