import styles from "./Percentage.module.scss"

interface Props {
  percentage?: number;
}

const size = 48
const strokeWidth = 3.5
// const offset = strokeWidth / 2
const radius = (size - strokeWidth) / 2
const xAxisRotation = 0
const sweepFlag = 1
const centerX = size / 2
const centerY = size / 2
const circumference = radius * Math.PI * 2
const startingRotation = 0 * Math.PI * 2

// this value is used to compensate for the small circles drawn at the edges of the curve
const adjustment = ((0.25 * strokeWidth) / circumference) * Math.PI * 2

export function TilePercentage(props: Props) {
  const isNullOrNaN = props.percentage === undefined || isNaN(props.percentage)
  const numberPercentage = isNullOrNaN ? 0 : (props.percentage as number)

  const largeArcFlag = numberPercentage > 0.5 ? 1 : 0

  const xStartPosition = centerX + radius * Math.cos(startingRotation + adjustment)
  const yStartPosition = centerY + radius * Math.sin(startingRotation + adjustment)

  const endingRotation = numberPercentage * 2 * Math.PI + startingRotation
  const xEndPosition = centerX + radius * Math.cos(endingRotation - adjustment)
  const yEndPosition = centerY + radius * Math.sin(endingRotation - adjustment)

  const start = `${xStartPosition} ${yStartPosition}`
  const curve = `${radius} ${radius} ${xAxisRotation} ${largeArcFlag} ${sweepFlag}`
  const end = `${xEndPosition} ${yEndPosition}`
  const svgPath = `M${start} A${curve} ${end}`
  const viewBox = `0 0 ${size} ${size}`

  return (
    <div className={styles.percentage}>
      <svg
        className={styles.percentageCircle}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={centerX}
          cy={centerY}
          r={radius}
        />
      </svg>
      <svg
        className={styles.percentageFill}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={svgPath} />
      </svg>
      <svg
        className={styles.percentageCorner}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx={xStartPosition}
          cy={yStartPosition}
          r={strokeWidth / 2}
        />
        <circle
          cx={xEndPosition}
          cy={yEndPosition}
          r={strokeWidth / 2}
        />
      </svg>
      <div className={styles.percentageValue}>
        {isNullOrNaN ? "--" : Math.round(numberPercentage * 100)}
      </div>
    </div>
  )
}
