import { useMemo } from "react"
import { useFindInsulins } from "./useFindInsulins"
import { createInsulinQueryObject } from "src/utils"

export function useInsulinsInCategory(category: string) {
  const queryObject = useMemo(
    () => createInsulinQueryObject({
      selector: {
        category: { $eq: category },
      },
      sort: [{ name: "asc" as const }],
    }),
    [category],
  )

  const { result } = useFindInsulins(queryObject)
  return result
}
