import {
  useCallback,
  useState,
  useRef,
} from "react"
import { useEventListener } from "usehooks-ts"
import { PageLifecycle } from "src/types"

// https://developer.chrome.com/articles/page-lifecycle-api/
function getState(): PageLifecycle {
  if (document.visibilityState === "hidden") {
    return PageLifecycle.Hidden
  }
  if (document.hasFocus()) {
    return PageLifecycle.Active
  }
  return PageLifecycle.Passive
}

// Options used for all event listeners.
const opts = { capture: true }

export function usePageLifecycle(): PageLifecycle {
  // stateRef is kept in sync w/ state
  // it reduces how often the hooks' dependencies are re-triggered
  const stateRef = useRef<PageLifecycle>(getState())

  const [
    state,
    setState,
  ] = useState<PageLifecycle>(stateRef.current)

  const logStateChange = useCallback(
    (nextState: PageLifecycle) => {
      if (nextState !== stateRef.current) {
        stateRef.current = nextState
        setState(stateRef.current)
      }
    },
    [
      stateRef,
      setState,
    ],
  )

  const onEvent = useCallback(
    () => {
      logStateChange(getState())
    },
    [logStateChange],
  )

  const onFreeze = useCallback(
    () => {
      logStateChange(PageLifecycle.Frozen)
    },
    [logStateChange],
  )

  const onPageHide = useCallback(
    (ev: PageTransitionEvent) => {
      logStateChange(
        ev.persisted
          ? PageLifecycle.Frozen
          : PageLifecycle.Terminated,
      )
    },
    [logStateChange],
  )

  useEventListener(
    "pageshow",
    onEvent,
    undefined,
    opts,
  )

  useEventListener(
    "focus",
    onEvent,
    undefined,
    opts,
  )

  useEventListener(
    "blur",
    onEvent,
    undefined,
    opts,
  )

  useEventListener(
    "visibilitychange" as keyof WindowEventMap,
    onEvent,
    undefined,
    opts,
  )

  useEventListener(
    "resume" as keyof WindowEventMap,
    onEvent,
    undefined,
    opts,
  )

  useEventListener(
    "freeze" as keyof WindowEventMap,
    onFreeze,
    undefined,
    opts,
  )

  useEventListener(
    "pagehide",
    onPageHide,
    undefined,
    opts,
  )

  return state
}
