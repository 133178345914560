import { useMemo } from "react"
import {
  useAccountProperty,
  useFindUserEntries,
} from "src/hooks"
import { ActivityType } from "src/types"
import { createEntryQueryObject } from "src/utils"

const emptyLiquidTypes = [
  null,
  undefined,
  "",
]

/*
 * this returns the most recent notes for the specified activity type
 */
export function usePreviousLiquidTypes(
  skipSubentries: string[], // the IDs of subentries to skip, usually this is just the subentry that we are currently editing
  skipLiquidTypes: string[],
  numberOfPreviousLiquidTypes = 5,
): string[] {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  // TODO this is not indexed
  const previousNEntriesQuery = useMemo(
    () => createEntryQueryObject(
      accountId,
      {
        selector: {
          subentries: {
            $elemMatch: {
              subentry_type: { $eq: ActivityType.Liquid },
              "liquid.liquid_type": {
                $nin: emptyLiquidTypes.concat(skipLiquidTypes),
              },
            },
          },
        },
        sort: [{ started_at: "desc" as const }],
        limit: skipSubentries.length + numberOfPreviousLiquidTypes,
      },
    ),
    [
      accountId,
      skipSubentries.length,
      skipLiquidTypes,
      numberOfPreviousLiquidTypes,
    ],
  )

  const entries = useFindUserEntries(previousNEntriesQuery)

  return useMemo(
    () => {
      const previousLiquidTypes: string[] = []

      for (const entry of entries.result) {
        if (previousLiquidTypes.length >= numberOfPreviousLiquidTypes) {
          break
        }

        for (const subentry of entry.subentries) {
          if (previousLiquidTypes.length >= numberOfPreviousLiquidTypes) {
            break
          }

          if (
            subentry.subentry_type === ActivityType.Liquid &&
            !skipSubentries.includes(subentry._id) &&
            !emptyLiquidTypes.includes(subentry.liquid.liquid_type) &&
            subentry.liquid.liquid_type &&
            !previousLiquidTypes.includes(subentry.liquid.liquid_type)
          ) {
            previousLiquidTypes.push(subentry.liquid.liquid_type)
          }
        }
      }

      return previousLiquidTypes
    },
    [
      entries.result,
      numberOfPreviousLiquidTypes,
    ],
  )
}
