import type { FoodActivity } from "src/types"

export const defaultFoodActivity: FoodActivity = {
  title: "",
  carbs: null,
  fiber: null,
  fat: null,
  sugar: null,
  protein: null,
  calories: null,
  nutritional_reference: null,
}
