import { createContext } from "react"
import type { RxQueryResult } from "rxdb-hooks"
import type { GlucoseUserEvent } from "src/types"

// TODO support glucose sub entries, too
type RecentReadings = RxQueryResult<GlucoseUserEvent>

// in the future this will track all recent readings for all followed users
export type RecentReadingContextValue = Record<string, RecentReadings>

type ContextType = RecentReadingContextValue | null

export const RecentReadingContext = createContext<ContextType>(null)
