import { FoodOutlineIcon } from "@tandemdiabetes/sugarmate-react-components"
import {
  forwardRef,
  useMemo,
} from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"

import styles from "./FeedItemHeader.module.scss"
import { usePolyglot } from "src/contexts"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"
import { ActivityType } from "src/types"
import { round } from "src/utils"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userItem: ReadOnlyUserEvent | ReadOnlyUserEntry;
}

export const FeedItemHeaderCarbSummary = forwardRef<HTMLDivElement, Props>(
  function FeedItemHeaderCarbSummary(props: Props, propsRef: Ref<HTMLDivElement>) {
    const { userItem } = props
    const polyglot = usePolyglot()

    const totalCarbs = useMemo(
      () => {
        const userEvent = userItem as ReadOnlyUserEvent
        if (userEvent.event_type) {
          if (userEvent.event_type === ActivityType.Food) {
            return userEvent.food.carbs || 0
          }

          return 0
        }

        const userEntry = userItem as ReadOnlyUserEntry
        return userEntry.subentries.reduce(
          (accumulator: number, subEntry: ReadOnlyUserEntry["subentries"][0]) => {
            if (subEntry.subentry_type === ActivityType.Food) {
              return accumulator + (subEntry.food.carbs || 0)
            }

            return accumulator
          },
          0,
        )
      },
      [userItem],
    )

    if (!totalCarbs) {
      return null
    }

    return (
      <div
        ref={propsRef}
        className={props.className}
      >
        <FoodOutlineIcon className={styles.summaryIcon} />
        <pre className={styles.pre}>
          {` ${polyglot.t(
            "unit.num_grams",
            {
              grams: round(
                totalCarbs,
                1,
              ).toLocaleString(navigator.language),
            },
          )}`}
        </pre>
      </div>
    )
  },
)
