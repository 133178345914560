import {
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react"
import type { RxDatabase } from "rxdb"
import { useIsMounted } from "usehooks-ts"
import { usePageLifecycleContext } from "src/contexts"
import { PageLifecycle } from "src/types"
import {
  createSugarmateRxDatabase,
  noticeError,
} from "src/utils"

// this hook instantiates the local database
// it should only be used by the Database Provider
// returns undefined until the local database is instantiated
export function useRxDatabase(
  state: "create" | "destroy" | "wait",
): RxDatabase | undefined {
  const [
    database,
    setDatabase,
  ] = useState<RxDatabase | undefined>(undefined)

  const databaseRef = useRef<RxDatabase | undefined>(database)
  const pageLifecycle = usePageLifecycleContext()

  const updateDatabase = useCallback(
    (db: RxDatabase | undefined) => {
      databaseRef.current = db
      setDatabase(db)
    },
    [
      setDatabase,
      databaseRef,
    ],
  )

  const isMounted = useIsMounted()

  const createDatabase = useCallback(
    async (): Promise<RxDatabase> => {
      const currentDb = databaseRef.current

      if (currentDb && !currentDb.destroyed) {
        return currentDb
      }

      try {
        const db = await createSugarmateRxDatabase()

        if (isMounted()) {
          updateDatabase(db)
        }

        return db
      } catch (e) {
        noticeError(
          e,
          "RxDBCreateDatabase",
        )

        throw e
      }
    },
    [
      databaseRef,
      updateDatabase,
    ],
  )

  const destroyDatabase = useCallback(
    async (forceCreate: boolean): Promise<void> => {
      try {
        // instantiate the database if it isn't already instantiated
        const db = forceCreate
          ? await createDatabase()
          : databaseRef.current

        await db?.remove()

        // set the database to undefined so no collections try to add themselves to it
        if (isMounted()) {
          updateDatabase(undefined)
        }

      } catch (e) {
        noticeError(
          e,
          "RxDBRemoveDatabase",
        )

        throw e
      }
    },
    [
      createDatabase,
      updateDatabase,
    ],
  )

  const isActive = pageLifecycle === PageLifecycle.Active || pageLifecycle === PageLifecycle.Passive

  useEffect(
    () => {
      if (state === "destroy") {
        // don't create the database unless the page is active
        void destroyDatabase(isActive)
      }

      // don't create the database unless the page is active
      if (state === "create" && isActive) {
        void createDatabase()
      }
    },
    [
      isActive,
      state,
      createDatabase,
      destroyDatabase,
    ],
  )

  return database
}
