import type {
  RxJsonSchema,
  JsonSchema,
} from "rxdb"
import type { NotUndefined } from "src/types"

export const dateTimeSchema: JsonSchema = {
  type: "string",
  format: "date-time",
  maxLength: 64,
}

interface Shared {
  id: "string";
  deleted_at: string;
  created_at: string;
  updated_at: string;
}

type SharedSchema = RxJsonSchema<Shared>

export const sharedSchemaProperties: SharedSchema["properties"] = {
  id: {
    type: "string",
    maxLength: 256,
  },
  created_at: { ...dateTimeSchema },
  updated_at: { ...dateTimeSchema },
  deleted_at: { ...dateTimeSchema },
}

export const sharedSchemaRequired: NotUndefined<SharedSchema["required"]> = [
  "created_at",
  "id",
  "deleted_at",
  "updated_at",
]

export const sharedSchemaIndexes: NotUndefined<SharedSchema["indexes"]> = ["updated_at"]
