import classNames from "classnames"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"
import { forwardRef } from "react"

import styles from "./UserInitial.module.scss"
import { useUserInitials } from "./useUserInitials"

type Props = ComponentPropsWithoutRef<"div">

/*
 * this component renders a div that shows the user's initials in a circle
 */
export const UserInitial = forwardRef<HTMLDivElement, Props>(
  function UserInitial(props: Props, propRef: Ref<HTMLDivElement>) {
    const userInitials = useUserInitials()

    const className = classNames(
      props.className,
      styles.userInitial,
    )

    return (
      <div
        {...props}
        ref={propRef}
        className={className}
      >
        {userInitials}
      </div>
    )
  },
)
