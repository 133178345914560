import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import { DeviceChangeActivityTitle } from "./DeviceChangeActivityTitle"
import { ExerciseActivityTitle } from "./ExerciseActivityTitle"
import { FoodActivityTitle } from "./FoodActivityTitle"
import { InsulinActivityTitle } from "./InsulinActivityTitle"
import { LiquidActivityTitle } from "./LiquidActivityTitle"
import { MedicationActivityTitle } from "./MedicationActivityTitle"
import { NoteActivityTitle } from "./NoteActivityTitle"
import type { ActivityPair } from "src/types"
import { ActivityType } from "src/types"

export type Props = ComponentPropsWithoutRef<"div"> & ActivityPair

export const ActivityTitle = forwardRef<HTMLDivElement, Props>(
  function ActivityTitle(props: Props, ref: Ref<HTMLDivElement>) {
    const {
      activity,
      activityType,
      ...htmlDivProps
    } = props

    switch (activityType) {
      case ActivityType.DeviceChange:
        return (
          <DeviceChangeActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Exercise:
        return (
          <ExerciseActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Food:
        return (
          <FoodActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Liquid:
        return (
          <LiquidActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Insulin:
        return (
          <InsulinActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Medication:
        return (
          <MedicationActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      case ActivityType.Note:
        return (
          <NoteActivityTitle
            {...htmlDivProps}
            ref={ref}
            activity={activity}
          />
        )
      default:
        return null
    }
  },
)
