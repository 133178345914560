const scaleFactor = 4
const values = [
  1,
  2,
  2.5,
  4,
  5,
  8,
]

// returns the number of units between each horizontal line that
// marks the value on the y axis
export function graphRangeToUnitsPerMarker(range: number): number {
  const scaledRange = range * scaleFactor
  const log = Math.floor(Math.log10(scaledRange))
  // divided is a number between 1 (inclusive) and 10 (exclusive)
  const targetInterval = scaledRange / Math.pow(
    10,
    log,
  )

  const nearestValue = values.reduce(
    (nearValue, nextValue) => {
      const nearestDiff = Math.abs(targetInterval - nearValue)
      const nextDiff = Math.abs(targetInterval - nextValue)

      if (nextDiff <= nearestDiff) {
        return nextValue
      }

      return nearValue
    },
    values[0],
  )

  return nearestValue * Math.pow(
    10,
    log - 1,
  )
}
