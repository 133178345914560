import classNames from "classnames"
import {
  useState,
  useEffect,
  useLayoutEffect,
} from "react"

import { defaultFoodImageResponse } from "./defaultFoodImageResponse"
import { defaultFoodSearchFood } from "./defaultFoodSearchFood"
import { FoodSearchButtons } from "./FoodSearchButtons"
import { FoodSearchNavigation } from "./FoodSearchNavigation"
import styles from "./FoodSearchResults.module.scss"
import { FoodServingView } from "./FoodServingView"
import { ServingsRangeInput } from "./ServingsRangeInput"
import { usePolyglot } from "src/contexts"
import { useFoodImage } from "src/hooks"
import type {
  ActivityType,
  FoodActivity,
  EditableSubEntry,
  FoodImageResponse,
  FoodSearchResponse,
} from "src/types"

interface Props {
  searchResults: FoodSearchResponse & { searchExpression: string };
  currentFoodIndex: number;
  searchValue: string;
  setCurrentFoodIndex: (n: number) => void;
  activity: FoodActivity;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Food>>) => void;
}

/*
 * this component renders:
 * - a message saying how many results there are
 * - the food item at currentFoodIndex in search results
 * - the navigation for browsing search results
 * - the buttons for closing or selecting the current food result
 */
export function FoodSearchResults(props: Props) {
  const {
    activity,
    currentFoodIndex,
    searchResults,
    searchValue,
    setCurrentFoodIndex,
    updateSubEntry,
  } = props

  const polyglot = usePolyglot()

  const [
    numberOfServings,
    setNumberOfServings,
  ] = useState<number>(1)

  const [
    currentServingIndex,
    setCurrentServingIndex,
  ] = useState<number>(0)

  // when the current food or serving changes, reset the number of servings to 1
  useEffect(
    () => {
      setNumberOfServings(1)
    },
    [
      currentFoodIndex,
      currentServingIndex,
      setNumberOfServings,
    ],
  )

  const foodSearchFood = searchResults.foods[currentFoodIndex] ?? defaultFoodSearchFood

  const {
    response,
    clearCache,
  } = useFoodImage(foodSearchFood || null)

  const foodImageResponse: FoodImageResponse = response || defaultFoodImageResponse

  // when this component unmounts, clear the food food response cache
  useEffect(
    () => () => clearCache(),
    [clearCache],
  )

  // every time foodSearchFood changes reset the serving index
  useLayoutEffect(
    () => {
      setCurrentServingIndex(0)
    },
    [
      foodSearchFood,
      setCurrentServingIndex,
    ],
  )

  const foodSearchServing = foodSearchFood.nutritional_servings[currentServingIndex] ?? defaultFoodSearchFood.nutritional_servings[0]

  const resultsClassName = classNames(
    styles.results,
    {
      [styles.hiddenResults]: searchResults.foods.length === 0,
    },
  )

  const hidden = foodSearchFood === defaultFoodSearchFood
  const isLoading = searchValue !== searchResults.searchExpression

  const resultClassName = classNames(
    styles.result,
    {
      [styles.hiddenResult]: hidden,
      [styles.loadingResult]: isLoading && !hidden,
    },
  )

  const foodImageURL = foodImageResponse.food.thumbnail_url || foodImageResponse.food.image_url

  return (
    <>
      <div className={resultsClassName}>
        <div className={styles.total}>
          {polyglot.t(
            "components.food_search.total_results",
            searchResults.foods.length,
          )}
        </div>
        <div className={styles.separator} />
      </div>
      <div className={resultClassName}>
        <div className={styles.servingImageRow}>
          <div className={styles.contentColumn}>
            <div className={styles.resultText}>
              <div className={styles.resultProduct}>
                {foodSearchFood.name}
              </div>
              <div className={styles.resultBrand}>
                {foodSearchFood.brand}
              </div>
            </div>
            <FoodServingView
              foodSearchServing={foodSearchServing}
              numberOfServings={numberOfServings}
            />
          </div>
          {foodImageURL && (
            <img
              alt={foodSearchFood.name}
              src={foodImageURL}
              className={styles.image}
            />
          )}
        </div>
        <ServingsRangeInput
          value={numberOfServings}
          setValue={setNumberOfServings}
          servings={foodSearchFood.nutritional_servings}
          currentServingIndex={currentServingIndex}
          setCurrentServingIndex={setCurrentServingIndex}
        />
        <div className={styles.separator} />
        <FoodSearchNavigation
          currentFoodIndex={currentFoodIndex}
          setCurrentFoodIndex={setCurrentFoodIndex}
          searchResults={searchResults}
        />
        <FoodSearchButtons
          foodSearchFood={foodSearchFood}
          foodImageResponse={response ?? undefined}
          foodSearchServing={foodSearchServing}
          numberOfServings={numberOfServings}
          activity={activity}
          updateSubEntry={updateSubEntry}
        />
      </div>
    </>
  )
}
