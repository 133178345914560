import dayjs from "dayjs"
import {
  useContext,
  useMemo,
} from "react"
import type { MangoQuery } from "rxdb"
import type { CollectionContext } from "src/types"
import { getTimeConstraintFromQueryObject } from "src/utils"

// this hook returns true if data that might be relevant to this query is
// still being fetched
export function useIsFetchingForQuery<T>(
  Context: CollectionContext,
  queryObject: MangoQuery<T>,
): boolean {
  const {
    loading,
    loadedUntil,
  } = useContext(Context)

  const queryTime = useMemo(
    () => getTimeConstraintFromQueryObject(queryObject),
    [queryObject],
  )

  const isFetching = (
    // if loading and we have not loaded past this query time
    loading && (queryTime === null || dayjs(queryTime).isBefore(loadedUntil))
  )

  return isFetching
}
