import { useDeleteCollectionItem } from "src/hooks/rxdb"
import { userEntryCollectionKey } from "src/schema"
import type { UserEntry } from "src/types"


export function useDeleteUserEntry() {
  return useDeleteCollectionItem<"entry", UserEntry>(
    userEntryCollectionKey,
    "entry",
    "/api/v3/entries",
  )
}
