import {
  ActionButton,
  DialogTrigger,
} from "@tandemdiabetes/sugarmate-react-components"
import { Navigate } from "@tanstack/react-router"
import { useEffect } from "react"

import { AddDataSourceDialog } from "./AddDataSourceDialog"
import { DataConsentDialog } from "./DataConsentDialog"
import styles from "./DataSource.module.scss"
import { DataSourceComponent } from "./DataSourceComponent"
import { DexcomConnectDialog } from "./DexcomConnectDialog"
import { NightscoutDelay } from "./NightscoutDelay"
import { NightscoutDialog } from "./NightscoutDialog"
import { WarningDialogs } from "./WarningDialogs"
import { usePolyglot } from "src/contexts"
import {
  useSources,
  useIsOnboardingDexcomSource,
} from "src/hooks"

const polyglotPrefix = "pages.settings.data_source."

export function DataSource() {
  const polyglot = usePolyglot()

  const {
    data: sources,
  } = useSources()

  const [
    isOnboardingDexcomSource,
    setIsOnboardingDexcomSource,
  ] = useIsOnboardingDexcomSource()

  // clear the value in local storage that tells us to resume onboarding after dexcom redirects
  useEffect(
    () => {
      if (isOnboardingDexcomSource) {
        setIsOnboardingDexcomSource(false)
      }
    },
    [
      isOnboardingDexcomSource,
      setIsOnboardingDexcomSource,
    ],
  )

  // respect the value in local storage that tells us to resume onboarding after dexcom redirects
  if (isOnboardingDexcomSource) {
    return (
      <Navigate
        to="/onboarding/data_source"
        search={Object.fromEntries(new URLSearchParams(window.location.search))}
        replace
      />
    )
  }

  // TODO
  if (!sources) {
    return null
  }

  const source = sources[0]

  return (
    <WarningDialogs source={source}>
      <NightscoutDialog>
        <DexcomConnectDialog>
          <DataConsentDialog>
            <AddDataSourceDialog>
              <div className={styles.container}>
                <div className={styles.dataSource}>
                  <div className={styles.description}>
                    {polyglot.t(`${polyglotPrefix}description`)}
                  </div>
                  {source ? (
                    <DataSourceComponent
                      source={source}
                    />
                  ) : (
                    <>
                      <div className={styles.description}>
                        {polyglot.t(`${polyglotPrefix}no_source_message`)}
                      </div>
                      <DialogTrigger asChild>
                        <ActionButton className={styles.addCta}>
                          {polyglot.t(`${polyglotPrefix}add_cta`)}
                        </ActionButton>
                      </DialogTrigger>
                    </>
                  )}
                </div>
                <NightscoutDelay />
              </div>
            </AddDataSourceDialog>
          </DataConsentDialog>
        </DexcomConnectDialog>
      </NightscoutDialog>
    </WarningDialogs>
  )
}
