import {
  createContext,
  useContext,
} from "react"

export interface NightscoutDialogContextValue {
  openNightscoutDialog: () => void;
}

type ContextType = NightscoutDialogContextValue | null

export const NightscoutDialogContext = createContext<ContextType>(null)

export function useNightscoutDialogContext() {
  const context = useContext(NightscoutDialogContext)

  if (context === null) {
    throw new Error("NightscoutDialog components must be wrapped in <NightscoutDialogContext />")
  }

  return context
}
