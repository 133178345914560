import {
  createContext,
  useContext,
} from "react"
import type { DeepReadonlyObject } from "rxdb"
import type { AccountInsulin } from "src/types"

export interface AccountInsulinModalContextValue {
  openAccountInsulinModal: (
    accountInsulin?: DeepReadonlyObject<AccountInsulin>,
  ) => void;
}

type ContextType = AccountInsulinModalContextValue | null
export const AccountInsulinModalContext = createContext<ContextType>(null)

export function useAccountInsulinModalContext() {
  const context = useContext(AccountInsulinModalContext)

  if (context === null) {
    throw new Error("AccountInsulinModal components must be wrapped in <AccountInsulinModalContext />")
  }

  return context
}

