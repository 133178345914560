import { useMemo } from "react"

import styles from "./FeedItemHeader.module.scss"
import { FeedItemHeaderCarbSummary } from "./FeedItemHeaderCarbSummary"
import { FeedItemHeaderGlucoseSummary } from "./FeedItemHeaderGlucoseSummary"
import { FeedItemHeaderInsulinSummary } from "./FeedItemHeaderInsulinSummary"
import {
  UserEventsLoadedComponent,
  UserEntriesLoadedComponent,
} from "src/components"
import { useEntryModalPublicContext } from "src/components/EntryModal"
import { useReadableTime } from "src/hooks"
import type {
  ReadOnlyUserEvent,
  ReadOnlyUserEntry,
} from "src/types"

interface Props {
  userItem: ReadOnlyUserEvent | ReadOnlyUserEntry;
}

/*
 * this component renders the timestamp and starting and ending glucose values for an event or entry
 */
export function FeedItemHeader(props: Props) {
  const { userItem } = props
  const startTime = userItem.started_at
  const readableTime = useReadableTime(startTime)

  const userEvent = userItem as ReadOnlyUserEvent
  const { openEntryModal } = useEntryModalPublicContext()

  const userEntry = userItem as ReadOnlyUserEntry
  const onClick = useMemo(
    () => userEvent.event_type
      ? undefined
      : () => {
        const onlySubEntry = userEntry.subentries.length === 1
          ? userEntry.subentries[0]
          : undefined

        openEntryModal({
          userEntry,
          subEntry: onlySubEntry,
        })
      },
    [
      userEntry,
      userEvent,
      openEntryModal,
    ],
  )

  return (
    <button
      className={styles.container}
      onClick={onClick}
      disabled={!onClick}
    >
      <div className={styles.time}>
        {readableTime}
      </div>
      <FeedItemHeaderInsulinSummary
        className={styles.summary}
        userItem={userItem}
      />
      <FeedItemHeaderCarbSummary
        className={styles.summary}
        userItem={userItem}
      />
      <UserEventsLoadedComponent>
        <UserEntriesLoadedComponent>
          <FeedItemHeaderGlucoseSummary
            className={styles.summary}
            userItem={userItem}
          />
        </UserEntriesLoadedComponent>
      </UserEventsLoadedComponent>
    </button>
  )
}
