import type { ReactNode } from "react"
import { CollectionContextProvider } from "./CollectionContextProvider"
import { UserEventsCollectionContext } from "./UserEventsCollectionContext"
import {
  userEventSchema,
  userEventCollectionKey,
} from "src/schema"

interface Props {
  children?: ReactNode;
}

export function UserEventsCollectionContextProvider(props: Props) {
  return (
    <CollectionContextProvider
      Context={UserEventsCollectionContext}
      collectionKey={userEventCollectionKey}
      schema={userEventSchema}
      cacheAmount={90}
      cacheUnit={"days"}
      responseKey={"events"}
      url={"/api/v3/events"}
    >
      {props.children}
    </CollectionContextProvider>
  )
}
