import { useCallback } from "react"
import type {
  MangoQuery,
  RxCollection,
} from "rxdb"
import type { QueryConstructor } from "rxdb-hooks"

// converts a query object to a query function that can be used with useRxData
export function useQueryFunction<T>(
  queryObject: MangoQuery<T>,
): QueryConstructor<T> {
  return useCallback(
    (collection: RxCollection<T>) => collection.find(queryObject),
    [queryObject],
  )
}
