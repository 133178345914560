import { forwardRef } from "react"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"

import {
  FeedItemContent,
  FeedItemContentText,
} from "./FeedItemContent"
import styles from "./FeedItemContent/FeedItemContent.module.scss"
import { FeedItemHeader } from "./FeedItemHeader"

import {
  ActivityIcon,
  ActivityNoteIcon,
  ActivityTitle,
  ActivityData,
  ActivityDescription,
  ActivitySource,
  ActivityLockIcon,
  ActivityMessage,
} from "src/components"
import type {
  ReadOnlyUserEvent,
  UserEventType,
} from "src/types"

interface Props extends ComponentPropsWithoutRef<"div"> {
  userEvent: ReadOnlyUserEvent;
}

export const UserEventFeedItem = forwardRef<HTMLDivElement, Props>(
  function UserEventFeedItem(props: Props, propRef: Ref<HTMLDivElement>) {
    const { userEvent } = props

    const activity = userEvent[userEvent.event_type as (UserEventType & keyof typeof userEvent)]
    const activityType = userEvent.event_type

    return (
      <div
        className={props.className}
        ref={propRef}
      >
        <FeedItemHeader userItem={userEvent} />
        <FeedItemContent disabled>
          <ActivityIcon
            activityType={activityType}
            className={styles.feedItemContentIcon}
          />
          <FeedItemContentText>
            <ActivityTitle
              activity={activity}
              activityType={activityType}
              item={userEvent}
              className={styles.feedItemContentTitle}
            />
            <ActivityDescription
              className={styles.feedItemContentDescription}
              activity={activity}
              activityType={activityType}
              item={userEvent}
            >
              <ActivityMessage
                activity={activity}
                activityType={activityType}
                item={userEvent}
                className={styles.feedItemContentMessage}
              />
              <ActivityNoteIcon
                activity={activity}
                activityType={activityType}
                item={userEvent}
                className={styles.feedItemContentNoteIcon}
              />
            </ActivityDescription>
            <ActivitySource
              item={userEvent}
              className={styles.feedItemContentSource}
            />
          </FeedItemContentText>
          <div className={styles.feedItemContentDataContainer}>
            <ActivityData
              activity={activity}
              activityType={activityType}
              className={styles.feedItemContentData}
            />
            <ActivityLockIcon className={styles.feedItemContentLockIcon} />
          </div>
        </FeedItemContent>
      </div>
    )
  },
)
