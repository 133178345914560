import {
  Label,
  InfoButton,
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import {
  useCallback,
  useMemo,
} from "react"

import styles from "./DataSource.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useNightscoutSource,
  useInputPropsForValue,
  useUpdateSource,
  useDebouncedUpdater,
} from "src/hooks"
import type { NightscoutSource } from "src/types"

const min = 0
const max = 120
const step = 1
const polyglotPrefix = "pages.settings.data_source.nightscout_delay."

export function NightscoutDelay() {
  const polyglot = usePolyglot()

  const nightscoutSource = useNightscoutSource()

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => Number(e.target.value),
    [],
  )

  const updateSource = useUpdateSource(nightscoutSource?.id)
  const debouncedUpdateSource = useDebouncedUpdater(updateSource)
  const updateSourceDelay = useCallback(
    (sourceDelay: NightscoutSource["delay"]) => {
      debouncedUpdateSource({
        delay: sourceDelay,
      })
    },
    [updateSource],
  )

  // TODO missing updated_at
  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: nightscoutSource?.delay ?? 0,
    onChange: updateSourceDelay,
    adapter,
  })

  // shared props for all inputs
  const sharedProps = useMemo(
    () => ({
      max,
      min,
      onChange,
      step,
      value,
    }),
    [
      value,
      onChange,
    ],
  )

  if (!nightscoutSource) {
    return null
  }

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Label>
      <div className={styles.rangeInputContainer}>
        <SliderValue
          sizePreset="small"
          alignmentPreset="start"
        >
          <SliderValueInput
            {...sharedProps}
            id="nightscout-delay-number"
          />
          <SliderValueLabelContainer>
            <SliderValueLabel>
              {polyglot.t(
                "time.seconds_abbreviated",
                value,
              )}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
        <Slider className={styles.rangeInputSlider}>
          <SliderInput
            {...sharedProps}
            id="nightscout-delay-slider"
          />
          <SliderTracks
            min={sharedProps.min}
            max={sharedProps.max}
          />
        </Slider>
      </div>
    </div>
  )
}
