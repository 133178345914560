import { usePolyglot } from "src/contexts"
import type { MedicationSubEntry } from "src/types"

const polyglotPrefix = "components.favorite_summary.message.medication."

interface Props {
  subEntry: MedicationSubEntry;
}

export function MedicationMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  return (
    <>
      {
        polyglot.t(
          `${polyglotPrefix}summary`,
          {
            value: subEntry.med.num_units,
            units: subEntry.med.medication.units,
            medication: subEntry.med.medication.name,
          },
        )
      }
    </>
  )
}
