import dayjs from "dayjs"
import {
  useLayoutEffect,
  useRef,
} from "react"
import { useReferencesContext } from "./ReferencesContext"
import { useScrollPositionContext } from "./ScrollPositionContext"
import { useXAxisContext } from "./XAxisContext"

/*
 * this component handles when changes to graph context values would
 * interfere with the user's scroll position
 */
export function ScrollPositionHandler() {
  const { scrollPositionRef } = useScrollPositionContext()

  const {
    listElementRef,
    containerElementRef,
  } = useReferencesContext()

  const {
    cellWidth,
    minutesPerCell,
    graphStart,
  } = useXAxisContext()

  // update the list layout if graphStart changes
  const previousGraphStart = useRef<string>(graphStart)

  useLayoutEffect(
    () => {
      const graphElement = listElementRef.current
      const scrollOffset = scrollPositionRef.current

      if (!graphElement || scrollOffset == null) {
        return
      }

      const diffInMinutes = Math.abs(
        dayjs(graphStart).diff(
          previousGraphStart.current,
          "minutes",
          true,
        ),
      )
      const diffInPixels = (cellWidth * diffInMinutes) / minutesPerCell

      graphElement.resetAfterIndex(
        0,
        true,
      )
      // only scroll if the user is not at the end of the graph
      if (scrollOffset > 0) {
        graphElement.scrollTo(scrollOffset + diffInPixels)
      }
      previousGraphStart.current = graphStart
    },
    [
      listElementRef,
      scrollPositionRef,
      graphStart,
      cellWidth,
      minutesPerCell,
    ],
  )

  // update the list layout if cellWidth changes
  const previousCellWidth = useRef<number>(cellWidth)
  useLayoutEffect(
    () => {
      const graphElement = listElementRef.current
      const scrollOffset = scrollPositionRef.current
      const containerElement = containerElementRef.current

      if (!graphElement || scrollOffset == null) {
        return
      }

      const changeProportion = cellWidth / previousCellWidth.current
      const offset = (containerElement?.clientWidth || 0) / 2

      graphElement.resetAfterIndex(
        0,
        true,
      )
      graphElement.scrollTo((scrollOffset + offset) * changeProportion - offset)
      previousCellWidth.current = cellWidth
    },
    [
      listElementRef,
      scrollPositionRef,
      containerElementRef,
      cellWidth,
    ],
  )

  return null
}
