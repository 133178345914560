import {
  createRxDatabase,
  addRxPlugin,
} from "rxdb"
import type { RxDatabase } from "rxdb"
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode"
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder"
// import { getRxStorageDexie } from "rxdb/plugins/storage-dexie"
import { getRxStorageLoki } from "rxdb/plugins/storage-lokijs"
import { observeNewCollections } from "rxdb-hooks"

// eslint-disable-next-line
const LokiIncrementalIndexedDBAdapter = require("lokijs/src/incremental-indexeddb-adapter")

//side effects
addRxPlugin(RxDBQueryBuilderPlugin)
addRxPlugin(observeNewCollections)

// in development run in dev mode
if (process.env?.NODE_ENV === "development") {
  addRxPlugin(RxDBDevModePlugin)
}

// TODO use a name that differentiates local and deployed envs
// const dbName = "sugarmate_web_app_db_dexie"
const dbName = "sugarmate_web_app_db_loki"

// const storage = getRxStorageDexie()
/*
const storage = getRxStorageLoki({
  // eslint-disable-next-line
  adapter: new LokiIncrementalIndexedDBAdapter(),
})
*/

export function createSugarmateRxDatabase(): Promise<RxDatabase> {
  return createRxDatabase({
    name: dbName,
    // storage: getRxStorageDexie(),
    storage: getRxStorageLoki({
      // eslint-disable-next-line
      adapter: new LokiIncrementalIndexedDBAdapter(),
    }),
    eventReduce: true,
    multiInstance: true,
    ignoreDuplicate: true,
  })
}
