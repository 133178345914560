import type { MangoQuery } from "rxdb"
import type {
  RxQueryResult,
  UseRxQueryOptions,
} from "rxdb-hooks"

import { useFind } from "src/hooks/rxdb"
import { insulinCollectionKey } from "src/schema"
import type { Insulin } from "src/types"

export function useFindInsulins(
  queryObject: MangoQuery<Insulin>,
  options?: Omit<UseRxQueryOptions, "json">,
): RxQueryResult<Insulin> {
  return useFind<Insulin>(
    queryObject,
    insulinCollectionKey,
    options,
  )
}
