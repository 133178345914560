import type { MedicationActivity } from "src/types"

export const defaultMedicationActivity: MedicationActivity = {
  num_units: 0,
  medication: {
    name: "",
    med_id: -1,
    account_med_id: -1,
    description: "",
    category: "",
    subcategory: "",
    units: "",
  },
}
