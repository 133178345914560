import {
  InfoButton,
  Label,
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"

const polyglotPrefix = "pages.settings.display.graph_icon_scale."

const sharedProps = {
  min: 0.5,
  max: 2,
  step: 0.1,
}

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function GraphIconScale(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentGraphIconScale = useDeviceSettingsProperty("graph_icon_scale")

  const onValueChange = useCallback(
    (graphIconScale: UserDeviceSettings["graph_icon_scale"]) => {
      updateSettings({
        graph_icon_scale: graphIconScale,
      })
    },
    [updateSettings],
  )

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => Number(e.target.value),
    [],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentGraphIconScale,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  const displayValue = (value) % 1 === 0
    ? `${value}.0`
    : `${value}`

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Label>
      <div className={styles.inputContainer}>
        <SliderValue
          sizePreset="large"
          alignmentPreset="start"
        >
          <SliderValueInput
            {...sharedProps}
            value={displayValue}
            onChange={onChange}
            id="graph-icon-scale-number"
          />
          <SliderValueLabelContainer unit="x" />
        </SliderValue>
        <Slider className={styles.rangeInputSlider}>
          <SliderInput
            {...sharedProps}
            value={displayValue}
            onChange={onChange}
            id="graph-icon-scale-slider"
          />
          <SliderTracks
            min={sharedProps.min}
            max={sharedProps.max}
          />
        </Slider>
      </div>
    </div>
  )
}
