/*
 * any strings we want to index need a maximum length
 * any numbers we want to index need minimum, maximum, and multiple_of
 * any boolean we want to index must be required
 */
export const activitySchema = {
  glucose: {
    type: "object",
    properties: {
      mg_dl: {
        minimum: 0,
        maximum: 1000,
        multipleOf: 0.0001,
        type: "number",
      },
      trend: {
        type: [
          "string",
          "null",
        ],
      },
    },
  },
  device_change: {
    type: "object",
    properties: {
      device_type: {
        type: "string",
      },
    },
  },
  exercise: {
    type: "object",
    properties: {
      exercise_type: {
        type: "string",
      },
      intensity: {
        type: "string",
      },
      steps: {
        type: [
          "number",
          "null",
        ],
      },
    },
  },
  insulin: {
    type: "object",
    properties: {
      num_units: {
        type: "number",
      },
      insulin_med: {
        type: "object",
        properties: {
          insulin_id: {
            type: "number",
          },
          account_insulin_id: {
            type: "number",
          },
          name: {
            type: "string",
          },
          description: {
            type: "string",
          },
          category: {
            type: "string",
          },
          form: {
            type: "string",
          },
          onset: {
            type: "number",
          },
          duration: {
            type: "number",
          },
          peak: {
            type: [
              "number",
              "null",
            ],
          },
          precision: {
            type: "number",
          },
          secondary_precision: {
            type: [
              "number",
              "null",
            ],
          },
          is_basal: {
            type: "boolean",
          },
        },
      },
    },
  },
  medication: {
    type: "object",
    properties: {
      num_units: {
        type: "number",
      },
      medication: {
        type: "object",
        properties: {
          med_id: {
            type: "number",
          },
          account_med_id: {
            type: "number",
          },
          name: {
            type: "string",
          },
          description: {
            type: "string",
          },
          category: {
            type: "string",
          },
          subcategory: {
            type: "string",
          },
          units: {
            type: "string",
          },
        },
      },
    },
  },
  liquid: {
    type: "object",
    properties: {
      units: {
        type: "string",
      },
      microliters: {
        type: "number",
      },
      liquid_type: {
        type: "string",
        maxLength: 250,
      },
    },
  },
}
