import dayjs from "dayjs"

// value is between 0 and 48 and represents half hour increments from 6 pm
// returns an iso time string
export function sleepHoursToTime(
  value: number,
  day: string = dayjs().toISOString(),
): string {
  const hours = ((value / 2) + 18) % 24
  return dayjs(day).startOf("day")
    .add(
      hours,
      "hour",
    )
    .toISOString()
}
