import {
  ActionButton,
  DialogTrigger,
  ActionLink,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import { useCallback } from "react"

import { OnboardingPageHeader } from "../OnboardingPageHeader"
import styles from "./DataSource.module.scss"
import { Page } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useSources,
  useIsOnboardingDexcomSource,
} from "src/hooks"
import { AddDataSourceDialog } from "src/pages/Settings/DataSource/AddDataSourceDialog"
import { DataConsentDialog } from "src/pages/Settings/DataSource/DataConsentDialog"
import sharedStyles from "src/pages/Settings/DataSource/DataSource.module.scss"
import { DataSourceComponent } from "src/pages/Settings/DataSource/DataSourceComponent"
import { DexcomConnectDialog } from "src/pages/Settings/DataSource/DexcomConnectDialog"
import { NightscoutDelay } from "src/pages/Settings/DataSource/NightscoutDelay"
import { NightscoutDialog } from "src/pages/Settings/DataSource/NightscoutDialog"
import { WarningDialogs } from "src/pages/Settings/DataSource/WarningDialogs"

const settingsPolyglotPrefix = "pages.settings.data_source."
const onboardingPolyglotPrefix = "pages.onboarding.data_source."

export function DataSource() {
  const polyglot = usePolyglot()
  const [
    ,
    setIsOnboardingDexcomSource,
  ] = useIsOnboardingDexcomSource()

  // save a value in local storage that tells us to resume onboarding after dexcom redirects
  const onDexcomAdd = useCallback(
    () => {
      setIsOnboardingDexcomSource(true)
    },
    [setIsOnboardingDexcomSource],
  )

  // clear the value that tells us to resume onboarding after dexcom redirects
  const onNext = useCallback(
    () => {
      setIsOnboardingDexcomSource(false)
    },
    [setIsOnboardingDexcomSource],
  )

  const {
    data: sources,
  } = useSources()

  // TODO
  if (!sources) {
    return null
  }

  const source = sources[0]

  return (
    <>
      <OnboardingPageHeader>
        {polyglot.t(`${onboardingPolyglotPrefix}title`)}
      </OnboardingPageHeader>
      <Page>
        <WarningDialogs source={source}>
          <NightscoutDialog>
            <DexcomConnectDialog>
              <DataConsentDialog
                onDexcomAdd={onDexcomAdd}
              >
                <AddDataSourceDialog>
                  <div className={sharedStyles.container}>
                    <div className={sharedStyles.dataSource}>
                      <div className={sharedStyles.description}>
                        {polyglot.t(`${settingsPolyglotPrefix}description`)}
                      </div>
                      {source ? (
                        <DataSourceComponent
                          source={source}
                        />
                      ) : (
                        <>
                          <div className={sharedStyles.description}>
                            {polyglot.t(`${settingsPolyglotPrefix}no_source_message`)}
                          </div>
                          <DialogTrigger asChild>
                            <ActionButton className={sharedStyles.addCta}>
                              {polyglot.t(`${settingsPolyglotPrefix}add_cta`)}
                            </ActionButton>
                          </DialogTrigger>
                          <Link
                            to="/onboarding/ranges"
                            onClick={onNext}
                            className={styles.skipCta}
                          >
                            {polyglot.t(`${onboardingPolyglotPrefix}skip_cta`)}
                          </Link>
                        </>
                      )}
                    </div>
                    <NightscoutDelay />
                    {source && (
                      <ActionLink
                        asChild
                        className={styles.nextCta}
                        onClick={onNext}
                      >
                        <Link to="/onboarding/ranges">
                          {polyglot.t(`${onboardingPolyglotPrefix}next_cta`)}
                        </Link>
                      </ActionLink>
                    )}
                  </div>
                </AddDataSourceDialog>
              </DataConsentDialog>
            </DexcomConnectDialog>
          </NightscoutDialog>
        </WarningDialogs>
      </Page>
    </>
  )
}
