import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"

import {
  useCreateCollectionItem,
  useUpdateCollectionItem,
} from "src/hooks/rxdb"
import { userEntryCollectionKey } from "src/schema"
import type {
  DeepPartial,
  UserEntry,
} from "src/types"

type PartialUserEntry = DeepReadonlyObject<DeepPartial<UserEntry>>

export function useSaveUserEntry() {
  const createUserEntry = useCreateCollectionItem<"entry", UserEntry>(
    userEntryCollectionKey,
    "entry",
    "/api/v3/entries",
  )

  const updateUserEntry = useUpdateCollectionItem<"entry", UserEntry>(
    userEntryCollectionKey,
    "entry",
    "/api/v3/entries",
  )

  return useCallback(
    (userEntry: PartialUserEntry) => {
      if (userEntry.id) {
        return updateUserEntry({
          ...userEntry,
          id: userEntry.id,
        })
      }

      return createUserEntry(userEntry)
    },
    [
      createUserEntry,
      updateUserEntry,
    ],
  )
}
