import { useContext } from "react"
import { NavigationMenuContext } from "./NavigationMenuContext"

export function useNavigationMenuContext() {
  const context = useContext(NavigationMenuContext)

  if (context == null) {
    throw new Error("NavigationMenu components must be wrapped in <NavigationMenu />")
  }

  return context
}
