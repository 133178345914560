import { IconButton } from "@tandemdiabetes/sugarmate-react-components"
import classNames from "classnames"
import { useCallback } from "react"
import type { MouseEventHandler } from "react"

import styles from "./CreationOverlay.module.scss"
import { useCreationOverlayContext } from "./useCreationOverlayContext"
import {
  FavoriteIcon,
  useFavoritesModalContext,
} from "src/components"
import { usePolyglot } from "src/contexts"

export function FavoriteButton() {
  const { openFavoritesModal } = useFavoritesModalContext()
  const state = useCreationOverlayContext()
  const polyglot = usePolyglot()

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      openFavoritesModal(state.creationTime)
    },
    [
      openFavoritesModal,
      state.creationTime,
    ],
  )

  const containerClassName = classNames(
    styles.buttonContainer,
    state.colorScheme === "light"
      ? styles.buttonContainerLight
      : styles.buttonContainerDark,
  )

  const favoriteButtonClassName = classNames(
    styles.favoriteButton,
    state.colorScheme === "dark" && styles.favoriteButtonDark,
  )

  return (
    <div className={containerClassName}>
      <IconButton
        data-state={state.open ? "open" : "closed"}
        ref={state.refs.setReference}
        {...state.getReferenceProps({
          onClick,
          className: favoriteButtonClassName,
        })}
      >
        <FavoriteIcon />
      </IconButton>
      {polyglot.t("components.creation_overlay.favorite_label")}
    </div>
  )
}
