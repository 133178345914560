import { useToasterContext } from "@tandemdiabetes/sugarmate-react-components"
import { useNavigate } from "@tanstack/react-router"
import { useCallback } from "react"

import { useOauthAuthorize } from "./useOauthAuthorize"
import { useSignIn } from "./useSignIn"

import { useCreatePhone } from "src/hooks"
import { RegistrationFlow } from "src/pages/SignUp"
import type { RegistrationForm } from "src/pages/SignUp"
import { SignInAfterSignUpErrorToast } from "src/toasts"

export function OauthAuthorizeSignUp() {
  const signIn = useSignIn()
  const oauthAuthorize = useOauthAuthorize()
  const { showToast } = useToasterContext()
  const navigate = useNavigate()
  const createPhone = useCreatePhone()

  const onAccountCreate = useCallback(
    async (registrationForm: RegistrationForm) => {
      try {
        const response = await signIn(
          registrationForm.emailAddress,
          registrationForm.password,
        )

        void createPhone(
          registrationForm.phoneNumber,
          response.data.access_token,
        ).catch(() => {
          // TODO consider handling missing phone in onboarding
          // ignore phone failure
        })

        await oauthAuthorize(response.data.access_token)
      } catch (e) {
        showToast(
          "sign-in-after-sign-up-error-toast",
          SignInAfterSignUpErrorToast,
        )
      }
    },
    [
      signIn,
      oauthAuthorize,
      createPhone,
    ],
  )

  const goBack = useCallback(
    () => {
      void navigate({ to: "/oauth/authorize/" })
    },
    [navigate],
  )

  return (
    <RegistrationFlow
      onAccountCreate={onAccountCreate}
      goBack={goBack}
    />
  )
}
