import type { ScrollPositionContextValue } from "./ScrollPositionContextValue"

// eslint-disable-next-line  @typescript-eslint/no-empty-function
const voidFunction = () => {}

export const defaultScrollPositionContextValue: ScrollPositionContextValue = {
  scrollPosition: 0,
  scrollPositionRef: { current: 0 },
  setScrollPosition: voidFunction,
  setVisibleIndices: voidFunction,
  visibleIndices: [
    0,
    1,
  ] as [number, number],
  visibleIndicesRef: {
    current: [
      0,
      1,
    ] as [number, number],
  },
}
