import {
  GearFillIcon,
  IconButton,
  PlusIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"

import { useAccountInsulinModalContext } from "src/components/AccountInsulinModal"
import type { AccountInsulin } from "src/types"

interface Props {
  currentAccountInsulin?: DeepReadonlyObject<AccountInsulin>;
}

export function AccountInsulinButton(props: Props) {
  const { currentAccountInsulin } = props
  const { openAccountInsulinModal } = useAccountInsulinModalContext()

  const onClick = useCallback(
    () => {
      openAccountInsulinModal(currentAccountInsulin)
    },
    [
      currentAccountInsulin,
      openAccountInsulinModal,
    ],
  )

  return (
    <IconButton onClick={onClick}>
      {currentAccountInsulin ? <GearFillIcon /> : <PlusIcon />}
    </IconButton>
  )
}
