import styles from "./Loading.module.scss"
import { usePolyglot } from "src/contexts"

interface Props {
  userName: string;
}

export function Loading(props: Props) {
  const { userName } = props

  const polyglot = usePolyglot()

  return (
    <>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingIcon}>
          {Array.from(Array(5)).map(
            (_, index) => (
              <div
                key={`loading-bar-${index}`}
                className={styles.loadingBar}
                data-index={index}
              />
            ),
          )}
        </div>
        <div className={styles.loadingText}>
          Loading...
        </div>
      </div>
      <div className={styles.info}>
        {polyglot.t(
          "pages.home.user_header.users_data",
          { user: userName },
        )}
      </div>
    </>
  )
}
