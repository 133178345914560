import type {
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import axios from "axios"
import {
  acceptHeader,
  contentTypeHeader,
  baseURL,
} from "./axiosConfigValues"
import { mergeAxiosConfigs } from "./mergeAxiosConfigs"

export const putConfig = {
  method: "put",
  baseURL,
  headers: {
    ...contentTypeHeader,
    ...acceptHeader,
  },
}

export function put<T>(
  config: AxiosRequestConfig,
): Promise<AxiosResponse<T>> {
  return axios.request<T>(mergeAxiosConfigs(
    putConfig,
    config,
  ))
}
