import {
  createContext,
  useContext,
} from "react"

export interface FavoritesModalContextValue {
  openFavoritesModal: (time?: string) => void;
}

type ContextType = FavoritesModalContextValue | null
export const FavoritesModalContext = createContext<ContextType>(null)

export function useFavoritesModalContext() {
  const context = useContext(FavoritesModalContext)

  if (context === null) {
    throw new Error("FavoritesModal components must be wrapped in <FavoritesModalContext />")
  }

  return context
}

