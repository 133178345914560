import {
  EditIcon,
  Modal,
  ActionSubmit,
  ModalBody,
  ModalCloseX,
  ModalContentForm,
  ModalHeading,
  ModalTrigger,
} from "@tandemdiabetes/sugarmate-react-components"
import type { FormEventHandler } from "react"
import {
  useState,
  useCallback,
} from "react"

import { DateInput } from "./DateInput"
import styles from "./DateTimeInput.module.scss"
import { TimeInput } from "./TimeInput"
import { usePolyglot } from "src/contexts"
import {
  useDisplayTime,
  useDisplayDate,
} from "src/hooks"
import type { EditableUserEntry } from "src/types"

const polyglotPrefix = "components.entry_modal."

interface Props {
  currentUserEntry: EditableUserEntry;
  setCurrentUserEntry?: (userEntry: EditableUserEntry) => void;
  readonly?: boolean;
}

export function DateTimeInput(props: Props) {
  const {
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const polyglot = usePolyglot()
  const [
    isOpen,
    setIsOpen,
  ] = useState<boolean>(false)

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      setIsOpen(false)
    },
    [setIsOpen],
  )

  const displayDate = useDisplayDate()
  const displayTime = useDisplayTime()

  const datetime = polyglot.t(
    `${polyglotPrefix}datetime.summary`,
    {
      date: currentUserEntry && displayDate(currentUserEntry.started_at ?? ""),
      time: currentUserEntry && displayTime(currentUserEntry.started_at ?? ""),
    },
  )

  if (props.readonly || !props.setCurrentUserEntry) {
    return (
      <div className={styles.datetime}>
        {datetime}
      </div>
    )
  }

  return (
    <Modal
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <ModalTrigger className={styles.datetime}>
        {datetime}
        <EditIcon className={styles.icon}/>
      </ModalTrigger>
      <ModalContentForm onSubmit={onSubmit}>
        <ModalHeading>
          {polyglot.t(`${polyglotPrefix}datetime.modal_heading`)}
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          <DateInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
          <TimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
          <ActionSubmit
            value={polyglot.t(`${polyglotPrefix}done_cta`)}
          />
        </ModalBody>
      </ModalContentForm>
    </Modal>
  )
}
