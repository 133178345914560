import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
} from "@floating-ui/react"
import type {
  Ref,
  ComponentPropsWithoutRef,
  CSSProperties,
} from "react"
import {
  forwardRef,
  useMemo,
} from "react"

import styles from "./NavigationMenu.module.scss"
import { NavigationMenuNav } from "./NavigationMenuNav"
import { useNavigationMenuContext } from "./useNavigationMenuContext"

type Props = ComponentPropsWithoutRef<"nav">

/*
 * this component handles the floating behavior for the navigation menu
 */
export const NavigationMenuContent = forwardRef<HTMLElement, Props>(
  function NavigationMenuContent(props: Props, propRef: Ref<HTMLElement>) {
    const state = useNavigationMenuContext()

    const ref = useMergeRefs([
      state.refs.setFloating,
      propRef,
    ])

    const floatingProperties: Props = state.getFloatingProps(props)

    const style: CSSProperties = useMemo(
      () => ({
        transitionDuration: `${state.transitionMs}ms`,
      }),
      [state.transitionMs],
    )

    return (
      <FloatingPortal>
        {state.isMounted && (
          <FloatingOverlay
            className={styles.overlay}
            data-status={state.status}
            style={style}
            lockScroll
          >
            <FloatingFocusManager context={state.context}>
              <NavigationMenuNav
                ref={ref}
                {...floatingProperties}
                data-status={state.status}
                style={{
                  ...floatingProperties.style,
                  ...style,
                }}
              />
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    )
  },
)
