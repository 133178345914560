import { useCallback } from "react"
import type { DeepReadonlyObject } from "rxdb"

import { transformAccountMedicationResponse } from "./transformAccountMedicationResponse"
import {
  useCreateCollectionItem,
  useUpdateCollectionItem,
} from "src/hooks/rxdb"
import { accountMedicationCollectionKey } from "src/schema"
import type {
  AccountMedication,
  DeepPartial,
} from "src/types"

type PartialAccountInsulin = DeepReadonlyObject<DeepPartial<AccountMedication>>

export function useSaveAccountMedication() {
  const createAccountMedication = useCreateCollectionItem(
    accountMedicationCollectionKey,
    "account_med",
    "/api/v3/account_meds",
    transformAccountMedicationResponse,
  )

  const updateAccountMedication = useUpdateCollectionItem(
    accountMedicationCollectionKey,
    "account_med",
    "/api/v3/account_meds",
    transformAccountMedicationResponse,
  )

  return useCallback(
    (accountInsulin: PartialAccountInsulin) => {
      if (accountInsulin.id) {
        return updateAccountMedication({
          ...accountInsulin,
          id: accountInsulin.id,
        })
      }

      return createAccountMedication(accountInsulin)
    },
    [
      createAccountMedication,
      updateAccountMedication,
    ],
  )
}
