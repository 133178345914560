import type { FC } from "react"
import { useMemo } from "react"
import { defaultInsightRangeByKey } from "../defaultInsightRangeByKey"

import { ExerciseAverageDuration } from "./ExerciseAverageDuration"
import { ExerciseTotalDuration } from "./ExerciseTotalDuration"
import { FoodAverageCalories } from "./FoodAverageCalories"
import { FoodAverageCarbs } from "./FoodAverageCarbs"
import { FoodAverageFat } from "./FoodAverageFat"
import { FoodAverageFiber } from "./FoodAverageFiber"
import { FoodAverageProtein } from "./FoodAverageProtein"
import { FoodTotalCalories } from "./FoodTotalCalories"
import { FoodTotalCarbs } from "./FoodTotalCarbs"
import { FoodTotalFat } from "./FoodTotalFat"
import { FoodTotalFiber } from "./FoodTotalFiber"
import { FoodTotalProtein } from "./FoodTotalProtein"
import { GlucoseAverageReading } from "./GlucoseAverageReading"
import { GlucoseAverageReadingBedtime } from "./GlucoseAverageReadingBedtime"
import { GlucoseAverageReadingWakeUp } from "./GlucoseAverageReadingWakeUp"
import { GlucoseCoefficientOfVariation } from "./GlucoseCoefficientOfVariation"
import { GlucoseFluxGrade } from "./GlucoseFluxGrade"
import { GlucoseHighsAndLows } from "./GlucoseHighsAndLows"
import { GlucoseManagementIndicator } from "./GlucoseManagementIndicator"
import { GlucoseMedianReading } from "./GlucoseMedianReading"
import { GlucosePercentageAbove } from "./GlucosePercentageAbove"
import { GlucosePercentageBelow } from "./GlucosePercentageBelow"
import { GlucosePercentageDistribution } from "./GlucosePercentageDistribution"
import { GlucosePercentageWithin } from "./GlucosePercentageWithin"
import { GlucoseQuartiles } from "./GlucoseQuartiles"
import { GlucoseStandardDeviation } from "./GlucoseStandardDeviation"
import { GlucoseTotalUnicorns } from "./GlucoseTotalUnicorns"
import { InsulinAverage } from "./InsulinAverage"
import { InsulinAverageBasal } from "./InsulinAverageBasal"
import { InsulinAverageBolus } from "./InsulinAverageBolus"
import { InsulinTotal } from "./InsulinTotal"
import { InsulinTotalBasal } from "./InsulinTotalBasal"
import { InsulinTotalBolus } from "./InsulinTotalBolus"
import { MiniGraph } from "./MiniGraph"
import { TimeInRange } from "./TimeInRange"
import { InsightKey } from "src/types"
import type { InsightRange } from "src/types"

interface Props {
  id: string;
  insightKey: InsightKey;
  range?: InsightRange;
  units?: number;
}

export function TileChildren(props: Props) {
  const { insightKey } = props

  const TileComponent: FC<{ range: InsightRange }> | null = useMemo(
    () => {
      switch (insightKey) {
        case InsightKey.ExerciseAverageDuration:
          return ExerciseAverageDuration
        case InsightKey.ExerciseTotalDuration:
          return ExerciseTotalDuration
        case InsightKey.FoodAverageCalories:
          return FoodAverageCalories
        case InsightKey.FoodAverageCarbs:
          return FoodAverageCarbs
        case InsightKey.FoodAverageFat:
          return FoodAverageFat
        case InsightKey.FoodAverageFiber:
          return FoodAverageFiber
        case InsightKey.FoodAverageProtein:
          return FoodAverageProtein
        case InsightKey.FoodTotalCalories:
          return FoodTotalCalories
        case InsightKey.FoodTotalCarbs:
          return FoodTotalCarbs
        case InsightKey.FoodTotalFat:
          return FoodTotalFat
        case InsightKey.FoodTotalFiber:
          return FoodTotalFiber
        case InsightKey.FoodTotalProtein:
          return FoodTotalProtein
        case InsightKey.GlucoseAverageReading:
          return GlucoseAverageReading
        case InsightKey.GlucoseAverageReadingBedtime:
          return GlucoseAverageReadingBedtime
        case InsightKey.GlucoseAverageReadingWakeUp:
          return GlucoseAverageReadingWakeUp
        case InsightKey.GlucoseCoefficientOfVariation:
          return GlucoseCoefficientOfVariation
        case InsightKey.GlucoseFluxGrade:
          return GlucoseFluxGrade
        case InsightKey.GlucoseHighsAndLows:
          return GlucoseHighsAndLows
        case InsightKey.GlucoseManagementIndicator:
          return GlucoseManagementIndicator
        case InsightKey.GlucoseMedianReading:
          return GlucoseMedianReading
        case InsightKey.GlucosePercentageAbove:
          return GlucosePercentageAbove
        case InsightKey.GlucosePercentageBelow:
          return GlucosePercentageBelow
        case InsightKey.GlucosePercentageDistribution:
          return GlucosePercentageDistribution
        case InsightKey.GlucosePercentageWithin:
          return GlucosePercentageWithin
        case InsightKey.GlucoseQuartiles:
          return GlucoseQuartiles
        case InsightKey.GlucoseStandardDeviation:
          return GlucoseStandardDeviation
        case InsightKey.GlucoseTotalUnicorns:
          return GlucoseTotalUnicorns
        case InsightKey.InsulinAverage:
          return InsulinAverage
        case InsightKey.InsulinAverageBasal:
          return InsulinAverageBasal
        case InsightKey.InsulinAverageBolus:
          return InsulinAverageBolus
        case InsightKey.InsulinTotal:
          return InsulinTotal
        case InsightKey.InsulinTotalBasal:
          return InsulinTotalBasal
        case InsightKey.InsulinTotalBolus:
          return InsulinTotalBolus
        case InsightKey.MiniGraph:
          return MiniGraph
        case InsightKey.TimeInRange:
          return TimeInRange
        default:
          return null
      }
    },
    [insightKey],
  )

  const range = props.range || defaultInsightRangeByKey[props.insightKey]

  return TileComponent && <TileComponent range={range} />
}
