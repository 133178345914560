import { useCallback } from "react"
import { useGlucoseUnit } from "src/hooks/settings"
import { GlucoseUnit } from "src/types"
import { mgdlToMmoll } from "src/utils"


/*
 * returns a function that takes a glucose value and returns that value scaled
 * to the user's glucose unit
 */
export function useGetGlucoseValue(): (n: number) => number {
  const glucoseUnit = useGlucoseUnit()

  return useCallback(
    (value: number) => {
      if (glucoseUnit === GlucoseUnit.MillimolesPerLiter) {
        return mgdlToMmoll(value)
      }

      return value
    },
    [glucoseUnit],
  )
}

