import { usePolyglot } from "src/contexts"
import type { LiquidSubEntry } from "src/types"
import { liquidMicrolitersToUnit } from "src/utils"

interface Props {
  subEntry: LiquidSubEntry;
}

export function LiquidMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  const value = liquidMicrolitersToUnit(
    subEntry.liquid.microliters,
    subEntry.liquid.units,
  )

  return (
    <>
      {
        polyglot.t(
          `unit.${subEntry.liquid.units}`,
          {
            smart_count: value,
            value: value.toLocaleString(navigator.language),
          },
        )
      }
    </>
  )
}
