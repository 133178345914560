import classNames from "classnames"
import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"
import { forwardRef } from "react"
import styles from "./Tile.module.scss"
import { usePolyglot } from "src/contexts"
import type { InsightKey } from "src/types"

interface Props extends HTMLAttributes<HTMLDivElement> {
  insightKey: InsightKey;
}

export const TileTitle = forwardRef<HTMLDivElement, Props>(
  function TileTitle(props: Props, ref: ForwardedRef<HTMLDivElement>) {
    const {
      insightKey,
      ...divProps
    } = props

    const polyglot = usePolyglot()
    const className = classNames(
      props.className,
      styles.title,
    )

    return (
      <div
        {...divProps}
        ref={ref}
        className={className}
      >
        {props.children}
        {polyglot.t(`pages.home.insights.title.${insightKey}`)}
      </div>
    )

  },
)
