import { useCreateCollectionItem } from "src/hooks/rxdb"
import { entryTemplateCollectionKey } from "src/schema"
import type { EntryTemplate } from "src/types"

interface CreateEntryTemplate {
  entry_id: string;
  title: string;
}

export function useCreateEntryTemplate() {
  return useCreateCollectionItem<"entry_template", CreateEntryTemplate, EntryTemplate>(
    entryTemplateCollectionKey,
    "entry_template",
    "/api/v3/entry_templates",
  )
}
