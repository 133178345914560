import {
  createContext,
  useContext,
} from "react"

export interface DataSourceAlreadyExistsDialogContextValue {
  openDataSourceAlreadyExistsDialog: () => void;
}

type ContextType = DataSourceAlreadyExistsDialogContextValue | null

export const DataSourceAlreadyExistsDialogContext = createContext<ContextType>(null)

export function useDataSourceAlreadyExistsDialogContext() {
  const context = useContext(DataSourceAlreadyExistsDialogContext)

  if (context === null) {
    throw new Error("DataSourceAlreadyExistsDialog components must be wrapped in <DataSourceAlreadyExistsDialogContext />")
  }

  return context
}
