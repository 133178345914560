import { useCallback } from "react"
import type { SetAuthCookie } from "./useAuthCookieState"
import { post } from "src/api"
import type { UserAuthCookie } from "src/types"

type RefreshToken = (accessToken: string, refreshToken: string) => Promise<void>

// returns a function that refreshes the access token
export function useRefreshToken(setAuthCookie: SetAuthCookie): RefreshToken {
  return useCallback(
    async (
      accessToken: string,
      refreshToken: string,
    ) => {
      try {
        const response = await post<UserAuthCookie>({
          url: "/oauth/web/refresh",
          data: {
            access_token: accessToken,
            refresh_token: refreshToken,
          },
        })

        setAuthCookie(response.data)
      } catch (e) {
        setAuthCookie(undefined)
      }
    },
    [setAuthCookie],
  )
}
