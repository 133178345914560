/*
 * if you are merging arrays sorted in ascending order
 * then compareItems returns negative is a is less than b and positive if a is greater than b
 *
 * if you are merging arrays sorted in descending order
 * then compareItems returns negative is a is greater than b and positive if a is less than b
 */
export function mergeSortedArrays<T>(
  arrayA: T[],
  arrayB: T[],
  compareItems: (itemA: T, itemB: T) => number,
): T[] {
  const totalItems = arrayA.length + arrayB.length
  const mergedArray: T[] = []

  let indexA = 0
  let indexB = 0

  while (indexA + indexB < totalItems) {
    const itemA = arrayA[indexA]
    const itemB = arrayB[indexB]

    if (itemB === undefined) {
      mergedArray.push(itemA)
      indexA++
    } else if (itemA === undefined) {
      mergedArray.push(itemB)
      indexB++
    } else if (
      compareItems(
        itemA,
        itemB,
      ) < 0
    ) {
      mergedArray.push(itemA)
      indexA++
    } else {
      mergedArray.push(itemB)
      indexB++
    }
  }

  return mergedArray
}
