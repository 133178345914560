import {
  Outlet,
  RootRoute,
  Route,
  Router,
} from "@tanstack/react-router"

import { getAuthProtectedComponent } from "./getAuthProtectedComponent"
import { getDeauthProtectedComponent } from "./getDeauthProtectedComponent"
import { OauthAuthorizeRootComponent } from "./OauthAuthorizeRootComponent"
import { RootComponent } from "./RootComponent"
import {
  About,
  Account,
  Activity,
  OauthAuthorizeIndexPage,
  OauthAuthorizeForgotPassword,
  OauthAuthorizeSignIn,
  OauthAuthorizeSignUp,
  DataSource,
  Display,
  ForgotPassword,
  Reports,
  Home,
  IndexPage,
  InsulinAndMedication,
  Nightstand,
  OnboardingDataSource,
  OnboardingRanges,
  Ranges,
  ResetPassword,
  Settings,
  SettingsIndex,
  SignIn,
  SignUp,
  SourcesCreated,
  Help,
  HelpAndFeedback,
  HelpAndFeedbackIndex,
} from "src/pages"

const rootRoute = new RootRoute({
  component: Outlet,
})

const oauthAuthorizeRootRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/oauth/authorize",
  component: OauthAuthorizeRootComponent,
})

const oauthAuthorizeIndexRoute = new Route({
  getParentRoute: () => oauthAuthorizeRootRoute,
  path: "/",
  component: OauthAuthorizeIndexPage,
})

const oauthAuthorizeSignInRoute = new Route({
  getParentRoute: () => oauthAuthorizeRootRoute,
  path: "/sign_in",
  component: OauthAuthorizeSignIn,
})

const oauthAuthorizeSignUpRoute = new Route({
  getParentRoute: () => oauthAuthorizeRootRoute,
  path: "/sign_up",
  component: OauthAuthorizeSignUp,
})

const oauthAuthorizeForgotPasswordRoute = new Route({
  getParentRoute: () => oauthAuthorizeRootRoute,
  path: "/forgot_password",
  component: OauthAuthorizeForgotPassword,
})

const appRootRoute = new Route({
  getParentRoute: () => rootRoute,
  id: "app",
  component: RootComponent,
})

const publicRoute = new Route({
  getParentRoute: () => appRootRoute,
  id: "public",
  component: getDeauthProtectedComponent(Outlet),
})

const indexRoute = new Route({
  getParentRoute: () => publicRoute,
  path: "/",
  component: IndexPage,
})

const signInRoute = new Route({
  getParentRoute: () => publicRoute,
  path: "/sign_in",
  component: SignIn,
})

const signUpRoute = new Route({
  getParentRoute: () => publicRoute,
  path: "/sign_up",
  component: SignUp,
})

const forgotPasswordRoute = new Route({
  getParentRoute: () => publicRoute,
  path: "/forgot_password",
  component: ForgotPassword,
})

const resetPasswordRoute = new Route({
  getParentRoute: () => publicRoute,
  path: "/users/$userId/password/edit",
  component: ResetPassword,
})

const privateRoute = new Route({
  getParentRoute: () => appRootRoute,
  id: "private",
  component: getAuthProtectedComponent(Outlet),
})

const nightstandRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/nightstand",
  component: Nightstand,
})

const homeRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/home",
  component: Home,
})

const reportsRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/reports",
  component: Reports,
})

const helpAndFeedbackRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/help_and_feedback",
  component: HelpAndFeedback,
})

const helpAndFeedbackIndexRoute = new Route({
  getParentRoute: () => helpAndFeedbackRoute,
  path: "/",
  component: HelpAndFeedbackIndex,
})

const helpAndFeedbackHelpRoute = new Route({
  getParentRoute: () => helpAndFeedbackRoute,
  path: "/help",
  component: Help,
})

const onboardingDataSourceRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/onboarding/data_source",
  component: OnboardingDataSource,
})

const onboardingRangesRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/onboarding/ranges",
  component: OnboardingRanges,
})

const settingsRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/settings",
  component: Settings,
})

const settingsIndexRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/",
  component: SettingsIndex,
})

const settingsDataSourceRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/data_source",
  component: DataSource,
})

const settingsRangesRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/ranges",
  component: Ranges,
})

const settingsDisplayRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/display",
  component: Display,
})

const settingsInsulinAndMedicationRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/insulin_and_medication",
  component: InsulinAndMedication,
})

const settingsActivityRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/activity",
  component: Activity,
})

const settingsAccountRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/account",
  component: Account,
})

const settingsAboutRoute = new Route({
  getParentRoute: () => settingsRoute,
  path: "/about",
  component: About,
})

const sourcesCreatedRoute = new Route({
  getParentRoute: () => privateRoute,
  path: "/sources/created",
  component: SourcesCreated,
})

// TODO not found route
export const routeTree = rootRoute.addChildren([
  appRootRoute.addChildren([
    publicRoute.addChildren([
      indexRoute,
      signUpRoute,
      signInRoute,
      forgotPasswordRoute,
      resetPasswordRoute,
    ]),
    privateRoute.addChildren([
      onboardingDataSourceRoute,
      onboardingRangesRoute,
      nightstandRoute,
      homeRoute,
      sourcesCreatedRoute,
      reportsRoute,
      helpAndFeedbackRoute.addChildren([
        helpAndFeedbackIndexRoute,
        helpAndFeedbackHelpRoute,
      ]),
      settingsRoute.addChildren([
        settingsAboutRoute,
        settingsAccountRoute,
        settingsActivityRoute,
        settingsDataSourceRoute,
        settingsDisplayRoute,
        settingsInsulinAndMedicationRoute,
        settingsRangesRoute,
        settingsIndexRoute,
      ]),
    ]),
  ]),
  oauthAuthorizeRootRoute.addChildren([
    oauthAuthorizeIndexRoute,
    oauthAuthorizeSignUpRoute,
    oauthAuthorizeSignInRoute,
    oauthAuthorizeForgotPasswordRoute,
  ]),
])

export const router = new Router({
  routeTree,
  defaultPreload: "intent",
})

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
