import type { ReactNode } from "react"
import {
  useEffect,
  useState,
} from "react"
import { DarkModeContext } from "./DarkModeContext"

const darkModeQuery = "(prefers-color-scheme: dark)"

function prefersDarkMode() {
  return window.matchMedia && window.matchMedia(darkModeQuery).matches
}

interface Props {
  children: ReactNode;
}

export function DarkModeContextProvider(props: Props) {
  const [
    darkMode,
    setDarkMode,
  ] = useState<boolean>(prefersDarkMode())

  useEffect(
    () => {
      if (!window.matchMedia) {
        return
      }

      const updateDarkMode = () => {
        setDarkMode(prefersDarkMode())
      }

      window.matchMedia(darkModeQuery).addEventListener(
        "change",
        updateDarkMode,
      )

      return () => {
        window.matchMedia(darkModeQuery).removeEventListener(
          "change",
          updateDarkMode,
        )
      }
    },
    [setDarkMode],
  )

  return (
    <DarkModeContext.Provider value={darkMode}>
      {props.children}
    </DarkModeContext.Provider>
  )
}
