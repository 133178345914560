import type { MangoQuery } from "rxdb"
import type { UserEntry } from "src/types"

const defaultSelector = {}
const defaultSort = [{ started_at: "desc" as const }]

// adds "deleted_at" and "account_id" selector to entry query
export function createEntryQueryObject(
  accountId: number,
  {
    selector,
    sort,
    limit,
  }: MangoQuery<UserEntry>,
): MangoQuery<UserEntry> {
  return {
    selector: {
      deleted_at: { $eq: null },
      ...(selector ?? defaultSelector),
    },
    sort: (sort ?? defaultSort),
    limit,
  }
}
