import { useParams } from "@tanstack/react-router"
import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { post } from "src/api"
import type { StatusResponse } from "src/types"
import { noticeError } from "src/utils"

type ResetPassword = (password: string) => Promise<AxiosResponse<StatusResponse>>

export function useResetPassword(): ResetPassword {
  const userId = useParams({
    from: "/app/public/users/$userId/password/edit",
    select: (s) => s.userId,
  })

  return useCallback(
    async (password: string) => {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get("token") ?? ""

      try {
        const response = await post<StatusResponse>({
          url: "/api/v3/passwords/reset",
          data: {
            account_id: userId,
            new_password: password,
            token,
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "ResetPassword",
        )

        throw e
      }
    },
    [userId],
  )
}
