import { useMemo } from "react"
import { useFindInsulins } from "./useFindInsulins"
import { createInsulinQueryObject } from "src/utils"

export function useInsulinCategories(): string[] {
  const queryObject = useMemo(
    () => createInsulinQueryObject({
      sort: [{ category: "asc" as const }],
    }),
    [],
  )

  const { result } = useFindInsulins(queryObject)

  const categories = useMemo(
    () => {
      const categorySet = new Set<string>()
      result.forEach((insulin) => {
        if (insulin.category) {
          categorySet.add(insulin.category)
        }
      })
      return Array.from(categorySet.keys()).sort()
    },
    [result],
  )

  return categories
}
