import { useMemo } from "react"
import { useMe } from "./useMe"
import type { UserDevice } from "src/types"

export function useDeviceProperty<
  K extends string & keyof UserDevice,
  V,
>(
  property: K,
  defaultValue?: V,
): V

export function useDeviceProperty<
  K extends string & keyof UserDevice,
>(
  property: K,
  defaultValue: UserDevice[K],
): UserDevice[K]

export function useDeviceProperty<
  K extends keyof UserDevice,
>(
  property: K,
  defaultValue?: UserDevice[K],
) {
  const { data } = useMe()

  return useMemo(
    () => data?.device[property] ?? defaultValue ?? undefined,
    [
      data,
      property,
      defaultValue,
    ],
  )
}
