import type {
  RxCollection,
  MangoQuerySortPart,
  MangoQuerySelector,
} from "rxdb"

interface Item {
  updated_at: string;
}

/*
 * this function gets the oldest and newest items in the collection that were not added by webhooks
 */
export async function getOldestAndNewest<T extends Item>(
  collection: RxCollection<T>,
): Promise<[string | null, string | null]> {
  const [
    oldestItem,
    newestItem,
  ] = await Promise.all([
    collection.findOne({
      selector: {
        // TODO add as index if we can use a storage layer other than Dexie
        /*
        updated_by_webhook: {
          $ne: true,
        },
        */
      } as MangoQuerySelector<T>,
      sort: [{ updated_at: "asc" as const } as MangoQuerySortPart<T>],
    }).exec(),
    collection.findOne({
      /*
      selector: {
        updated_by_webhook: {
          $ne: true,
        },
      } as MangoQuerySelector<T>,
      */
      sort: [{ updated_at: "desc" as const } as MangoQuerySortPart<T>],
    }).exec(),
  ])

  return [
    (oldestItem?.get("updated_at") || null) as string | null,
    (newestItem?.get("updated_at") || null) as string | null,
  ]
}
