import type { SWRResponse } from "swr"
import useSWR from "swr"

import { useSourcesSWRKey } from "./useSourcesSWRKey"
import { useUserGetData } from "src/hooks/userApi"
import type { Source } from "src/types"

export function useSources(): SWRResponse<Array<Source>> {
  const sourcesSWRKey = useSourcesSWRKey()
  const userGetData = useUserGetData<Array<Source>>()

  return useSWR<Array<Source>>(
    userGetData && sourcesSWRKey,
    userGetData,
  )
}
