import type { FoodImageResponse } from "src/types"

/*
 * this food is used as the placeholder food image response
 *
 * it is not meant to be displayed
 */
export const defaultFoodImageResponse: FoodImageResponse = {
  food: {
    image_url: "",
    thumbnail_url: "",
  },
}
