import type { Country } from "src/types"
import { GlucoseUnit } from "src/types"

// TODO this will be removed when we have an endpoint for the country constants
// countries indexed by their alpha-2 iso code
export const countries: Record<string, Country> = Object.freeze({
  AS: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  AT: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  AU: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  BE: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  BR: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  CA: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  CH: {
    is_supported: true,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  CL: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  CZ: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  DE: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  DK: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  EE: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  ES: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  FI: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  FR: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  GB: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  GU: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  HK: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  HR: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  HU: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  IE: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  IL: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  IT: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  KR: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  LT: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  LU: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  LV: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  MP: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  MT: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: false,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  MX: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  NL: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  NO: {
    is_supported: true,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  NZ: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  PH: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  PL: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  PR: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  PT: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  SE: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
  SG: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
  },
  UM: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  US: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: true,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  VI: {
    is_supported: true,
    is_dexcom: true,
    is_dexcom_us: true,
    phone_supported: false,
    units: GlucoseUnit.MilligramsPerDeciliter,
  },
  ZA: {
    is_supported: false,
    is_dexcom: false,
    is_dexcom_us: false,
    phone_supported: true,
    units: GlucoseUnit.MillimolesPerLiter,
  },
})
