import type { DeepReadonlyObject } from "rxdb"
import styles from "./CurrentReading.module.scss"
import { TrendIcon } from "src/components"
import {
  useReadableGlucoseUnit,
  useReadableGlucoseValue,
  useReadableTime,
} from "src/hooks"
import type { GlucoseUserEvent } from "src/types"
import { Trend } from "src/types"

interface Props {
  glucoseUserEvent: DeepReadonlyObject<GlucoseUserEvent>;
}

export function CurrentReading(props: Props) {
  const { glucoseUserEvent } = props

  const unit = useReadableGlucoseUnit()
  const value = useReadableGlucoseValue(glucoseUserEvent.glucose.mg_dl)
  const time = useReadableTime(glucoseUserEvent.started_at)

  return (
    <div className={styles.container}>
      <div className={styles.reading}>
        <div className={styles.readingTime}>
          {time}
        </div>
        <div className={styles.readingValue}>
          {value}
        </div>
        <div className={styles.readingUnit}>
          {unit}
        </div>
      </div>
      <TrendIcon
        className={styles.trendIcon}
        trend={glucoseUserEvent.glucose.trend ?? Trend.None}
      />
    </div>
  )
}
