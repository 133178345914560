import {
  FieldSet,
  Legend,
  RadioButton,
  RadioButtonCheckMark,
  RadioButtons,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Activity.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"
import { DayStat } from "src/types"

const polyglotPrefix = "pages.settings.activity.day_stat."

const radioButtons: Array<DayStat> = [
  DayStat.NormalRange,
  DayStat.AverageGlucose,
  DayStat.WakeUpGlucose,
  DayStat.BedTimeGlucose,
  DayStat.StandardDeviation,
  DayStat.TotalCarbs,
  DayStat.TotalCalories,
  DayStat.TotalUnits,
]

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function DayStats(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentDayStat = useDeviceSettingsProperty("day_stat_header")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value as DayStat,
    [],
  )

  const onValueChange = useCallback(
    (dayStat: UserDeviceSettings["day_stat_header"]) => {
      updateSettings({
        day_stat_header: dayStat,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentDayStat,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <FieldSet>
      <Legend>
        {polyglot.t(`${polyglotPrefix}label`)}
      </Legend>
      <div className={styles.dayStatsDescription}>
        {polyglot.t(`${polyglotPrefix}description`)}
      </div>
      <RadioButtons>
        {radioButtons.map(
          (dayMetric) => (
            <RadioButton
              key={dayMetric}
              name="day-metric"
              value={dayMetric}
              checked={value === dayMetric}
              onChange={onChange}
              htmlFor={dayMetric}
              required
            >
              <RadioButtonCheckMark/>
              {polyglot.t(`${polyglotPrefix}${dayMetric}`)}
            </RadioButton>
          ),
        )}
      </RadioButtons>
    </FieldSet>
  )
}
