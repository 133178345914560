import type { AxiosResponse } from "axios"
import { useCallback } from "react"

import { useSources } from "./useSources"
import { useMe } from "src/hooks/me"
import { useUserPut } from "src/hooks/userApi"
import type { Source } from "src/types"
import { noticeError } from "src/utils"

type UpdateSourceFunction = (
  data: Partial<Source>
) => Promise<AxiosResponse<Array<Source>>>

// TODO make this more like the other update functions
export function useUpdateSource(sourceId?: number): UpdateSourceFunction {
  const { data: me } = useMe()
  const { mutate } = useSources()
  const userPut = useUserPut()
  const accountId = me?.account.id

  const updateSource = useCallback(
    async (data: Partial<Source>): Promise<AxiosResponse> => {
      try {
        if (!accountId) {
          throw new Error("cannot update source for unknown account")
        }

        if (!userPut) {
          throw new Error("cannot update source for unauthorized user")
        }

        const url = `/api/v2/accounts/${accountId}/sources/${sourceId}`

        const axiosResponse = await userPut<Array<Source>>({
          url,
          data,
        })

        void mutate()
        return axiosResponse
      } catch (e) {
        noticeError(
          e,
          "UpdateSource",
          { source: data },
        )

        throw e
      }
    },
    [
      userPut,
      accountId,
      mutate,
      sourceId,
    ],
  )

  return updateSource
}
