import type {
  useInteractions,
  UseFloatingReturn,
} from "@floating-ui/react"
import type { RefObject } from "react"
import { createContext } from "react"
import type { DeepReadonlyObject } from "rxdb"
import type { GlucoseUserEvent } from "src/types"

export interface TooltipContextValue extends UseFloatingReturn, ReturnType<typeof useInteractions> {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  glucoseUserEvent?: DeepReadonlyObject<GlucoseUserEvent>;
  arrow: RefObject<HTMLDivElement>;
}

type ContextType = TooltipContextValue | null
export const TooltipContext = createContext<ContextType>(null)
