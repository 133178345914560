import { useContext } from "react"
import { InsightsContext } from "./InsightsContext"

export function useInsightsContext() {
  const context = useContext(InsightsContext)

  if (context == null) {
    throw new Error("Insight components must be wrapped in <InsightContext />")
  }

  return context
}
