import type { ReactNode } from "react"
import {
  useMemo,
  useRef,
  useState,
} from "react"
import { defaultScrollPositionContextValue } from "./defaultScrollPositionContextValue"
import { ScrollPositionContext } from "./ScrollPositionContext"

interface Props {
  children: ReactNode;
}

const {
  visibleIndices: defaultVisibleIndices,
  scrollPosition: defaultScrollPosition,
} = defaultScrollPositionContextValue

export function ScrollPositionContextProvider(props: Props) {
  const [
    visibleIndices,
    setVisibleIndices,
  ] = useState<[number, number]>(
    defaultVisibleIndices,
  )
  const [
    scrollPosition,
    setScrollPosition,
  ] = useState<number>(
    defaultScrollPosition,
  )
  const scrollPositionRef = useRef<number>(scrollPosition)
  scrollPositionRef.current = scrollPosition

  const visibleIndicesRef = useRef<[number, number]>(visibleIndices)
  visibleIndicesRef.current = visibleIndices

  const value = useMemo(
    () => ({
      scrollPosition,
      scrollPositionRef,
      setScrollPosition,
      setVisibleIndices,
      visibleIndices,
      visibleIndicesRef,
    }),
    [
      scrollPosition,
      scrollPositionRef,
      setScrollPosition,
      setVisibleIndices,
      visibleIndices,
      visibleIndicesRef,
    ],
  )

  return (
    <ScrollPositionContext.Provider value={value}>
      {props.children}
    </ScrollPositionContext.Provider>
  )
}
