import { useCalculationsContext } from "../CalculationsContext"
import styles from "./HighsAndLows.module.scss"
import { TileTimeSpan } from "./TileTimeSpan"
import { TileTitle } from "./TileTitle"
import type { InsightRange } from "src/types"
import { InsightKey } from "src/types"

interface Props {
  range: InsightRange;
}

const size = 48
const strokeWidth = 2.4
// const offset = strokeWidth / 2
const radius = (size - strokeWidth) / 2
const centerX = size / 2
const centerY = size / 2

export function GlucoseHighsAndLows(props: Props) {
  const { range } = props

  const { glucoseCalculations } = useCalculationsContext()
  const {
    highs,
    lows,
  } = glucoseCalculations[range] || {}

  const viewBox = `0 0 ${size} ${size}`
  const highsString = highs === undefined || isNaN(highs) ? "--" : highs.toLocaleString(navigator.language)
  const lowsString = lows === undefined || isNaN(lows) ? "--" : lows.toLocaleString(navigator.language)

  return (
    <>
      <TileTitle insightKey={InsightKey.GlucoseHighsAndLows} />
      <TileTimeSpan range={range} />
      <div className={styles.highsAndLows}>
        <svg
          className={styles.highsAndLowsCircle}
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx={centerX}
            cy={centerY}
            r={radius}
          />
        </svg>
        <div className={styles.highsAndLowsValues}>
          {highsString}
          <div className={styles.highsAndLowsValueSeparator} />
          {lowsString}
        </div>
      </div>
    </>
  )
}
