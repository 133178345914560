import type { RxJsonSchema } from "rxdb"

import {
  dateTimeSchema,
  sharedSchemaRequired,
  sharedSchemaIndexes,
  sharedSchemaProperties,
} from "./newSharedSchema"
import type { EntryTemplate } from "src/types"

// json-schema.org
// https://rxdb.info/rx-schema.html#example
export const entryTemplateSchema: RxJsonSchema<EntryTemplate> = {
  title: "entry_template_schema",
  version: 0,
  primaryKey: "id",
  type: "object",
  properties: {
    account_id: {
      minimum: 0,
      maximum: Number.MAX_SAFE_INTEGER,
      multipleOf: 1,
      type: "number",
    },
    title: {
      type: "string",
      maxLength: 256,
    },
    last_used_at: { ...dateTimeSchema },
    // TODO consider indexing by this field
    updated_by_webhook: { type: "boolean" },
    subentries: {
      type: "array",
      uniqueItems: true,
      items: {
        type: "object",
        properties: {
          _id: { type: "string" },
          subentry_type: { type: "string" },
          image_id: { type: "string" },
          image_url: { type: "string" },
          thumbnail: { type: "string" },
          note: { type: "string" },
          duration_in_seconds: { type: "number" },
          export_source_id: { type: "string" },
          export_source: { type: "string" },
        },
        required: [
          "_id",
          "subentry_type",
        ],
      },
    },
    ...sharedSchemaProperties,
  },
  required: [
    "account_id",
    "title",
    "last_used_at",
    "subentries",
    ...sharedSchemaRequired,
  ],
  indexes: [
    [
      "account_id",
      "deleted_at",
    ],
    [
      "account_id",
      "deleted_at",
      "title",
    ],
    ...sharedSchemaIndexes,
  ],
}
