import type { MangoQuery } from "rxdb"
import type { EntryTemplate } from "src/types"

const defaultSelector = {}

// adds "deleted_at" and "account_id" selector to entry template query
export function createEntryTemplateQueryObject(
  accountId: number,
  {
    selector,
    sort,
    limit,
  }: MangoQuery<EntryTemplate>,
): MangoQuery<EntryTemplate> {
  return {
    selector: {
      account_id: { $eq: accountId },
      deleted_at: { $eq: null },
      ...(selector ?? defaultSelector),
    },
    sort,
    limit,
  }
}
