import type { YAxisContextValue } from "./YAxisContextValue"

export const defaultYAxisContextValue: YAxisContextValue = {
  max: 300,
  min: 0,
  unitMarkings: [
    0,
    50,
    100,
    150,
    200,
    250,
    300,
  ],
  unitsPerMarker: 50,
}
