import { GlucoseUnit } from "src/types"

export interface RegistrationForm {
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  countryOfResidence: string;
  termsOfUseConsented: boolean;
  privacyPolicyConsented: boolean;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
  units: GlucoseUnit;
}

export const defaultRegistrationForm: RegistrationForm = Object.freeze({
  firstname: "",
  lastname: "",
  dateOfBirth: "",
  countryOfResidence: "",
  termsOfUseConsented: false,
  privacyPolicyConsented: false,
  emailAddress: "",
  phoneNumber: "",
  password: "",
  passwordConfirmation: "",
  units: GlucoseUnit.MilligramsPerDeciliter,
})
