import {
  Navigate,
  useRouterState,
} from "@tanstack/react-router"
import type { FC } from "react"
import { useEffect } from "react"
import { NavigationMenu } from "src/components"
import {
  useAuthContext,
  useSessionStorageContext,
  RecentReadingContextProvider,
} from "src/contexts"
import { useUpdateTitleEffect } from "src/hooks"
import type { RoutePath } from "src/types"

export function getAuthProtectedComponent(Component: FC<object>) {
  return function AuthProtectedComponent() {
    const currentPath = useRouterState({
      // note use .href if you want to include query params
      select: (s) => s.resolvedLocation.pathname,
    })

    const { accessToken } = useAuthContext()

    const {
      logoutRedirect,
      setLoginRedirect,
    } = useSessionStorageContext()

    // in case the user session expires, save the current path as the login path
    useEffect(
      () => {
        if (accessToken) {
          setLoginRedirect(currentPath as RoutePath)
        }
      },
      [
        accessToken,
        currentPath,
      ],
    )

    useUpdateTitleEffect()

    return accessToken
      ? (
        <RecentReadingContextProvider>
          <NavigationMenu>
            <Component />
          </NavigationMenu>
        </RecentReadingContextProvider>
      ) : (
        <Navigate
          to={logoutRedirect}
          params={{}}
          replace
        />
      )
  }
}
