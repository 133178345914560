import type { AxiosResponse } from "axios"
import { useCallback } from "react"
import { post } from "src/api"
import type { Phone } from "src/types"
import { noticeError } from "src/utils"

interface Response {
  phone: Phone;
}

type CreatePhone = (
  phoneNumber: string,
  accessToken: string,
) => Promise<AxiosResponse<Response>>

export function useCreatePhone(): CreatePhone {
  return useCallback<CreatePhone>(
    async (phoneNumber, accessToken) => {
      try {
        const response = await post<Response>({
          url: "/api/v3/phones",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            phone: {
              phone_number: phoneNumber,
            },
          },
        })

        return response
      } catch (e) {
        noticeError(
          e,
          "CreatePhone",
          { phoneNumber },
        )

        throw e
      }
    },
    [],
  )
}
