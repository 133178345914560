import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStatus,
} from "@floating-ui/react"
import {
  useState,
  useMemo,
} from "react"

import type { NavigationMenuOptions } from "./NavigationMenuOptions"

export function useNavigationMenu({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  transitionMs = 250,
}: NavigationMenuOptions = {}) {
  const [
    uncontrolledOpen,
    setUncontrolledOpen,
  ] = useState(initialOpen)

  const [
    labelId,
    setLabelId,
  ] = useState<string | undefined>()

  const [
    descriptionId,
    setDescriptionId,
  ] = useState<string | undefined>()

  const isOpen = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
  })

  const context = data.context

  const transitionData = useTransitionStatus(
    context,
    { duration: transitionMs },
  )

  const clickInteraction = useClick(context)

  const dismissInteraction = useDismiss(
    context,
    // eager dismiss on mouse input, lazy on touch input
    { outsidePressEvent: "mousedown" },
  )

  const roleInteraction = useRole(context)

  const interactions = useInteractions([
    clickInteraction,
    dismissInteraction,
    roleInteraction,
  ])

  return useMemo(
    () => ({
      open: isOpen,
      setOpen,
      ...data,
      ...interactions,
      ...transitionData,
      descriptionId,
      labelId,
      setDescriptionId,
      setLabelId,
      transitionMs,
    }),
    [
      data,
      descriptionId,
      interactions,
      isOpen,
      labelId,
      setDescriptionId,
      setLabelId,
      setOpen,
      transitionData,
      transitionMs,
    ],
  )
}
