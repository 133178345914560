import classNames from "classnames"
import {
  useCallback,
  useMemo,
} from "react"
import styles from "./FoodSearchNavigation.module.scss"
import { useFoodImage } from "src/hooks"
import type { FoodSearchFood } from "src/types"

interface Props {
  centerIndex: number;
  currentFoodIndex: number;
  foodSearchFood?: FoodSearchFood;
  firstVisibleDotIndex: number;
  foodIndex: number;
  lastVisibleDotIndex: number;
  setCurrentFoodIndex: (n: number) => void;
  totalResults: number;
}

/*
 * this component renders a dot that represents a search result
 */
export function FoodSearchNavigationDot(props: Props) {
  const {
    centerIndex,
    currentFoodIndex,
    foodSearchFood,
    firstVisibleDotIndex,
    foodIndex,
    lastVisibleDotIndex,
    setCurrentFoodIndex,
    totalResults,
  } = props

  const active = foodIndex === currentFoodIndex
  const hidden = foodIndex < firstVisibleDotIndex || foodIndex > lastVisibleDotIndex
  const indicesFromMiddle = foodIndex - centerIndex

  // prefetch the food corresponding to this dot
  useFoodImage(hidden ? null : (foodSearchFood ?? null))

  // view this food index if dot is clicked
  const onClick = useCallback(
    () => {
      setCurrentFoodIndex(foodIndex)
    },
    [
      setCurrentFoodIndex,
      foodIndex,
    ],
  )

  // scale the size of the dot to indicate whether or not there are more results
  const dotScale = useMemo(
    () => {
      if (
        foodIndex < firstVisibleDotIndex ||
        foodIndex > lastVisibleDotIndex ||
        (foodIndex === firstVisibleDotIndex && foodIndex >= 2) ||
        (foodIndex === lastVisibleDotIndex && foodIndex < totalResults - 2)
      ) {
        return 0.5
      }

      if (
        (foodIndex === firstVisibleDotIndex && foodIndex === 1) ||
        (foodIndex === firstVisibleDotIndex + 1 && foodIndex >= 3) ||
        (foodIndex === lastVisibleDotIndex && foodIndex === totalResults - 1) ||
        (foodIndex === lastVisibleDotIndex - 1 && foodIndex < totalResults - 3)
      ) {
        return 0.75
      }

      return 1
    },
    [
      foodIndex,
      lastVisibleDotIndex,
      firstVisibleDotIndex,
      totalResults,
    ],
  )

  // note this works because each button is currently 1rem wide
  // if the button size changes then this transform should also change
  const buttonStyle = { transform: `translateX(${indicesFromMiddle}rem)` }
  const buttonClassName = classNames(
    styles.dotButton,
    {
      [styles.hiddenDotButton]: hidden,
    },
  )

  const dotStyle = { transform: `scale(${dotScale})` }
  const dotClassName = classNames(
    styles.dot,
    {
      [styles.activeDot]: active,
      [styles.hiddenDot]: hidden,
    },
  )

  return (
    <button
      className={buttonClassName}
      style={buttonStyle}
      onClick={onClick}
    >
      <div
        className={dotClassName}
        style={dotStyle}
      />
    </button>
  )
}
