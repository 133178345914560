import dayjs from "dayjs"
import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"

import styles from "./Reading.module.scss"
import { TrendIcon } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  useFirstGlucoseReading,
  useReadableGlucoseUnit,
  useReadableGlucoseValue,
  useDeviceSettingsProperty,
  useReadingDelta,
  useThisMinute,
} from "src/hooks"
import type { GlucoseUserEvent } from "src/types"
import { Trend } from "src/types"

interface Props {
  userName: string;
  glucoseUserEvent: DeepReadonlyObject<GlucoseUserEvent>;
}

export function Reading(props: Props) {
  const {
    userName,
    glucoseUserEvent,
  } = props

  const polyglot = usePolyglot()
  const thisMinute = useThisMinute()
  const previousGlucoseUserEvent = useFirstGlucoseReading(glucoseUserEvent.started_at).result[0]
  const unit = useReadableGlucoseUnit()
  const value = useReadableGlucoseValue(glucoseUserEvent.glucose.mg_dl)

  const minutesAgo = useMemo(
    () => dayjs(thisMinute).diff(
      glucoseUserEvent.started_at,
      "minute",
    ),
    [
      glucoseUserEvent,
      thisMinute,
    ],
  )

  const readingDelta = useReadingDelta(
    glucoseUserEvent,
    previousGlucoseUserEvent,
  )

  const showDelta = useDeviceSettingsProperty("show_delta")

  const minutesAgoMessage = polyglot.t(
    "pages.home.user_header.users_data_min_ago",
    {
      smart_count: minutesAgo,
      user: userName,
    },
  )

  return (
    <>
      <div className={styles.units}>
        {unit}
      </div>
      <div className={styles.readingContainer}>
        <div className={styles.reading}>
          {value}
        </div>
        <TrendIcon
          className={styles.trendIcon}
          trend={glucoseUserEvent.glucose.trend ?? Trend.None}
        />
        {showDelta && (
          <div className={styles.delta}>
            {readingDelta}
          </div>
        )}
      </div>
      <div className={styles.info}>
        {minutesAgoMessage}
      </div>
    </>
  )
}
