import type { AxiosRequestConfig } from "axios"

// NOTE the type definition for AxiosRequestConfig defines "params" as "any"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Params = undefined | URLSearchParams | Record<string, any>

function mergeParams(
  paramsA: Params,
  paramsB: Params,
): Params {
  if (paramsA === undefined && paramsB === undefined) {
    return undefined
  }

  const objectA = paramsA instanceof URLSearchParams ?
    Object.fromEntries(paramsA.entries()) :
    (paramsA ?? {})

  const objectB = paramsB instanceof URLSearchParams ?
    Object.fromEntries(paramsB.entries()) :
    (paramsB ?? {})

  return {
    ...objectA,
    ...objectB,
  }
}

/*
 * a function that merges two axios request configs
 */
export function mergeAxiosConfigs(
  ...configs: Array<AxiosRequestConfig>
): AxiosRequestConfig {
  return configs.reduce(
    (
      accumulatedConfig: AxiosRequestConfig,
      currentConfig: AxiosRequestConfig,
    ) => ({
      ...accumulatedConfig,
      ...currentConfig,
      headers: {
        ...accumulatedConfig.headers,
        ...currentConfig.headers,
      },
      params: mergeParams(
        accumulatedConfig.params as Params,
        currentConfig.params as Params,
      ),
    }),
    {},
  )
}
