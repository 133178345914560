import { usePolyglot } from "src/contexts"
import type { InsulinSubEntry } from "src/types"

const polyglotPrefix = "components.favorite_summary.message.insulin."

interface Props {
  subEntry: InsulinSubEntry;
}

export function InsulinMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  return (
    <>
      {
        polyglot.t(
          `${polyglotPrefix}summary`,
          {
            value: subEntry.insulin.num_units,
            insulin: subEntry.insulin.insulin_med.name,
          },
        )
      }
    </>
  )
}
