import { createContext } from "react"

// these are provided as url params
export interface OauthAuthorizeContextValue {
  client_id: string;
  code_challenge?: string;
  code_challenge_method?: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  state: string;
  p?: string; // device identifier
  device_type?: string;
}

type ContextType = OauthAuthorizeContextValue | null

export const OauthAuthorizeContext = createContext<ContextType>(null)
