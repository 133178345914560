import dayjs from "dayjs"
import { useMemo } from "react"
import type { MangoQuery } from "rxdb"
import { useAccountProperty } from "src/hooks/me"
import { useDeviceSettingsProperty } from "src/hooks/settings"
import { useFindUserEntries } from "src/hooks/userEntry"
import { useFindUserEvents } from "src/hooks/userEvent"
import type {
  UserEvent,
  UserEntry,
} from "src/types"
import { ActivityType } from "src/types"
import {
  createEntryQueryObjectForType,
  createEventQueryObjectForType,
} from "src/utils"

/*
 * this hook returns the timestamp of the most recent glucose reading that
 * is outside of the normal range, or undefined if there is no such reading
 */
export function useLastOutsideOfNormalReadingTime(): string | undefined {
  const normalRangeTop = useDeviceSettingsProperty("range_normal_top")
  const normalRangeBottom = useDeviceSettingsProperty("range_normal_bottom")

  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const outsideOfNormalEventQuery: MangoQuery<UserEvent> = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          $or: [
            { [`${ActivityType.Glucose}.mg_dl`]: { $lt: normalRangeBottom } },
            { [`${ActivityType.Glucose}.mg_dl`]: { $gt: normalRangeTop } },
          ],
        },
        sort: [{ started_at: "desc" as const }],
        limit: 1,
      },
    ),
    [
      accountId,
      normalRangeTop,
      normalRangeBottom,
    ],
  )

  const outsideOfNormalEntryQuery: MangoQuery<UserEntry> = useMemo(
    () => createEntryQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          $or: [
            {
              subentries: {
                $elemMatch: {
                  [`${ActivityType.Glucose}.mg_dl`]: {
                    $lt: normalRangeBottom,
                  },
                },
              },
            },
            {
              subentries: {
                $elemMatch: {
                  [`${ActivityType.Glucose}.mg_dl`]: {
                    $gt: normalRangeTop,
                  },
                },
              },
            },
          ],
        },
        sort: [{ started_at: "desc" as const }],
        limit: 1,
      },
    ),
    [
      accountId,
      normalRangeTop,
      normalRangeBottom,
    ],
  )

  const {
    result: outsideOfNormalEventResult,
    isFetching: eventIsFetching,
  } = useFindUserEvents(outsideOfNormalEventQuery)

  const {
    result: outsideOfNormalEntryResult,
    isFetching: entryIsFetching,
  } = useFindUserEntries(outsideOfNormalEntryQuery)

  const lastOutsideOfNormalReadingTime = useMemo(
    () => {
      if (entryIsFetching && eventIsFetching) {
        return undefined
      }

      const outsideOfNormalEventResultStartedAt = outsideOfNormalEventResult[0]?.started_at
      const outsideOfNormalEntryResultStartedAt = outsideOfNormalEntryResult[0]?.started_at

      if (!outsideOfNormalEntryResultStartedAt && !outsideOfNormalEventResultStartedAt) {
        return undefined
      }

      if (!outsideOfNormalEntryResultStartedAt) {
        return outsideOfNormalEventResultStartedAt
      }

      if (!outsideOfNormalEventResultStartedAt) {
        return outsideOfNormalEntryResultStartedAt
      }

      if (dayjs(outsideOfNormalEntryResultStartedAt).isBefore(outsideOfNormalEventResultStartedAt)) {
        return outsideOfNormalEventResultStartedAt
      }

      return outsideOfNormalEntryResultStartedAt
    },
    [
      outsideOfNormalEventResult,
      outsideOfNormalEntryResult,
    ],
  )

  return lastOutsideOfNormalReadingTime
}
