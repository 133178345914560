import { useSources } from "./useSources"
import type {
  Source,
  NightscoutSource,
} from "src/types"
import { SourceType } from "src/types"

// returns the first nightscout source found in the sources array or undefined
export function useNightscoutSource(): NightscoutSource | undefined {
  const { data: sources } = useSources()
  if (!sources) {
    return undefined
  }

  return sources.find(
    (source: Source) => source.source_type === SourceType.Nightscout,
  ) as NightscoutSource
}
