/*
 * TODO missing sugar, liquid ?
 */
export enum InsightKey {
  // exercise (useExerciseCalculations)
  ExerciseAverageDuration = "avg_exercise",
  ExerciseTotalDuration = "total_exercise",

  // food (useFoodCalculations)
  FoodAverageCalories = "avg_calories",
  FoodAverageCarbs = "avg_carbs",
  FoodAverageFat = "avg_fat",
  FoodAverageFiber = "avg_fiber",
  FoodAverageProtein = "avg_protein",
  FoodTotalCalories = "total_calories",
  FoodTotalCarbs = "total_carbs",
  FoodTotalFat = "total_fat",
  FoodTotalFiber = "total_fiber",
  FoodTotalProtein = "total_protein",

  // glucose (useGlucoseCalculations)
  GlucoseAverageReading = "average",
  GlucoseAverageReadingBedtime = "bedtime_avg",
  GlucoseAverageReadingWakeUp = "wake_up_avg",
  GlucoseCoefficientOfVariation = "cv",
  GlucoseFluxGrade = "flux",
  GlucoseHighsAndLows = "high_low",
  GlucoseManagementIndicator = "gmi",
  GlucosePercentageAbove = "above_range",
  GlucosePercentageBelow = "below_range",
  GlucosePercentageDistribution = "normal_range",
  GlucosePercentageWithin = "in_range",
  GlucoseStandardDeviation = "stddev",
  GlucoseTotalUnicorns = "unicorns",

  // glucose (useQuartileCalculations)
  GlucoseQuartiles = "quartiles",
  GlucoseMedianReading = "median",

  // insulin (useInsulinCalculations)
  InsulinAverage = "avg_total_insulin",
  InsulinAverageBasal = "avg_long_insulin",
  InsulinAverageBolus = "avg_short_insulin",
  InsulinTotal = "total_insulin",
  InsulinTotalBasal = "long_insulin",
  InsulinTotalBolus = "short_insulin",

  // other
  MiniGraph = "graph",
  TimeInRange = "time_in_range", // useTimeInaRangeCalculation
}
