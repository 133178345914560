import type { MangoQuery } from "rxdb"
import { createEntryQueryObject } from "./createEntryQueryObject"
import type {
  SubEntryType,
  UserEntry,
} from "src/types"

const defaultSelector = {}

// adds "subentry_type" selector to entry query
export function createEntryQueryObjectForType<T extends SubEntryType>(
  accountId: number,
  activityType: T,
  {
    selector,
    sort,
    limit,
  }: MangoQuery<UserEntry>,
) {
  return createEntryQueryObject(
    accountId,
    {
      selector: {
        subentries: {
          $elemMatch: {
            subentry_type: { $eq: activityType },
          },
        },
        ...(selector ?? defaultSelector),
      },
      sort,
      limit,
    },
  )
}
