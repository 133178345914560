import { useMemo } from "react"
import type { DeepReadonlyObject } from "rxdb"
import {
  useGetGlucoseValue,
  useDisplayGlucoseValue,
} from "src/hooks/glucoseFormatting"
import type { GlucoseUserEvent } from "src/types"

export function useReadingDelta(
  glucoseUserEvent?: DeepReadonlyObject<GlucoseUserEvent>,
  previousGlucoseUserEvent?: DeepReadonlyObject<GlucoseUserEvent>,
): string | null {
  const getGlucoseValue = useGetGlucoseValue()
  const displayGlucoseValue = useDisplayGlucoseValue()

  return useMemo(
    () => {
      // if there is no current or prior reading then render nothing
      if (!glucoseUserEvent || !previousGlucoseUserEvent) {
        return null
      }

      // this only works because 0 mmol = 0 mgdl
      const recentValue = glucoseUserEvent.glucose.mg_dl
      const secondValue = previousGlucoseUserEvent.glucose.mg_dl
      const diffValue = getGlucoseValue(recentValue - secondValue)
      const diffString = displayGlucoseValue(diffValue)

      if (diffValue >= 0) {
        return `+${diffString}`
      }

      return `${diffString}`
    },
    [
      getGlucoseValue,
      displayGlucoseValue,
      glucoseUserEvent,
      previousGlucoseUserEvent,
    ],
  )
}
