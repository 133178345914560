import classNames from "classnames"
import type {
  Ref,
  ComponentPropsWithoutRef,
} from "react"
import { forwardRef } from "react"

import styles from "./UserInitial.module.scss"
import { useUserInitials } from "./useUserInitials"

type Props = ComponentPropsWithoutRef<"button">

/*
 * this component renders a button that shows the user's initials in a circle
 */
export const UserInitialButton = forwardRef<HTMLButtonElement, Props>(
  function UserInitialButton(props: Props, propRef: Ref<HTMLButtonElement>) {
    const userInitials = useUserInitials()

    const className = classNames(
      props.className,
      styles.userInitialButton,
    )

    return (
      <button
        {...props}
        ref={propRef}
        className={className}
      >
        {userInitials}
      </button>
    )
  },
)
