import dayjs from "dayjs"
import { useMemo } from "react"
import type { MangoQuery } from "rxdb"
import { useAccountProperty } from "src/hooks/me"
import { useFindUserEntries } from "src/hooks/userEntry"
import { useFindUserEvents } from "src/hooks/userEvent"
import type {
  UserEvent,
  UserEntry,
} from "src/types"
import { ActivityType } from "src/types"
import {
  createEntryQueryObjectForType,
  createEventQueryObjectForType,
} from "src/utils"

export function useNextGlucoseReading(afterTimeString: string | undefined): string | undefined {
  const accountId = useAccountProperty(
    "id",
    NaN,
  )

  const nextGlucoseEventSelector: MangoQuery<UserEvent> = useMemo(
    () => createEventQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          started_at: { $gt: afterTimeString },
        },
        sort: [{ started_at: "asc" as const }],
        limit: 1,
      },
    ),
    [
      accountId,
      afterTimeString,
    ],
  )

  const nextGlucoseEntrySelector: MangoQuery<UserEntry> = useMemo(
    () => createEntryQueryObjectForType(
      accountId,
      ActivityType.Glucose,
      {
        selector: {
          started_at: { $gt: afterTimeString },
        },
        sort: [{ started_at: "asc" as const }],
        limit: 1,
      },
    ),
    [
      accountId,
      afterTimeString,
    ],
  )

  const {
    result: nextGlucoseEventResult,
    isFetching: eventIsFetching,
  } = useFindUserEvents(nextGlucoseEventSelector)

  const {
    result: nextGlucoseEntryResult,
    isFetching: entryIsFetching,
  } = useFindUserEntries(nextGlucoseEntrySelector)

  const nextReadingTime = useMemo(
    () => {
      if (entryIsFetching && eventIsFetching) {
        return undefined
      }

      if (afterTimeString === undefined) {
        return undefined
      }

      const nextGlucoseEventResultStartedAt = nextGlucoseEventResult[0]?.started_at
      const nextGlucoseEntryResultStartedAt = nextGlucoseEntryResult[0]?.started_at

      if (!nextGlucoseEntryResultStartedAt && !nextGlucoseEventResultStartedAt) {
        return undefined
      }

      if (!nextGlucoseEntryResultStartedAt) {
        return nextGlucoseEventResultStartedAt
      }

      if (!nextGlucoseEventResultStartedAt) {
        return nextGlucoseEntryResultStartedAt
      }

      if (dayjs(nextGlucoseEntryResultStartedAt).isBefore(nextGlucoseEventResultStartedAt)) {
        return nextGlucoseEntryResultStartedAt
      }

      return nextGlucoseEventResultStartedAt
    },
    [
      nextGlucoseEventResult,
      nextGlucoseEntryResult,
      eventIsFetching,
      entryIsFetching,
      afterTimeString,
    ],
  )

  return dayjs(nextReadingTime).isAfter(afterTimeString)
    ? nextReadingTime
    : undefined
}
