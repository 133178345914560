import {
  useState,
  useMemo,
} from "react"
import type { ReactNode } from "react"

import { InsightsContext } from "./InsightsContext"

interface Props {
  children: ReactNode;
}

export function InsightsContextProvider(props: Props) {
  const [
    isEditing,
    setIsEditing,
  ] = useState<boolean>(false)

  const contextValue = useMemo(
    () => ({
      isEditing,
      setIsEditing,
    }),
    [
      isEditing,
      setIsEditing,
    ],
  )

  return (
    <InsightsContext.Provider value={contextValue}>
      {props.children}
    </InsightsContext.Provider>
  )
}
