import { usePolyglot } from "src/contexts"
import type { DeviceChangeSubEntry } from "src/types"

const polyglotPrefix = "components.favorite_summary.message.device_change."

interface Props {
  subEntry: DeviceChangeSubEntry;
}

export function DeviceChangeMessage(props: Props) {
  const {
    subEntry,
  } = props

  const polyglot = usePolyglot()

  if (polyglot.has(`${polyglotPrefix}${subEntry.device_change.device_type}`)) {
    return (
      <>
        {polyglot.t(`${polyglotPrefix}${subEntry.device_change.device_type}`)}
      </>
    )
  }

  return (
    <>
      {polyglot.t(`${polyglotPrefix}fallback`)}
    </>
  )
}
